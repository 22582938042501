import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { ActivarAdicion } from '../../store/actions/categoriaMenuActions'

//*MATERIAL UI
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Divider from '@material-ui/core/Divider'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    cursor: {
        cursor: 'pointer'
    }
})

const Ingrediente = (props) => {

    const [Data, setData] = useState()

    useEffect(() => {

    }, [])

    const handleDisabledIngrediente = () => {
        props.ActivarAdicion(props.ingrediente)
    }

     //*render
     const { classes, ingrediente } = props

    return (
        <Fragment>
            <Divider />
            <ListItem
                // button
                // onClick={menuBloqueado ? null : handleOpenMenu}
                >

                <ListItemIcon className={classes.cursor} onClick={handleDisabledIngrediente}>
                    {ingrediente.disabled ?
                    <BlockIcon fontSize="small" color="error" />
                    : 
                    <CheckCircleOutlineIcon fontSize="small" color="secondary" />
                    }
                </ListItemIcon>
                <ListItemText 
                    primary={
                        <Typography variant="h6">
                            {ingrediente.nombre}
                        </Typography>
                    } 
                    secondary={
                        ingrediente.descripcion
                    }
                />
            </ListItem>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         ActivarAdicion: (datos) => dispatch(ActivarAdicion(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Ingrediente))

Ingrediente.propTypes = {
    
}