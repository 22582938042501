import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import Cuenta from './Cuenta'
import { VerificarCuentaAdmin, CrearCuentaUsuario, EliminarUsuario } from '../../../../store/actions/perfilComercioActions'
import { SendMenssageAction } from '../../../../store/actions/messageActions'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import Grow from '@material-ui/core/Grow'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Avatar from '@material-ui/core/Avatar'
import { cyan } from '@material-ui/core/colors'
import Drawer from '@material-ui/core/Drawer'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'


import { withStyles } from '@material-ui/styles'

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})

const Transition = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Grow ref={ref} {...props} />
})

const styles = theme => ({
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    avatarPerson: {
        color: '#fff',
        backgroundColor: cyan[500],
        width: theme.spacing(7),
        height: theme.spacing(7),
        cursor: 'pointer'
    },
    messageTitle: {
        backgroundColor: '#263238',
        color: '#fff'
    },
    message: {
        color: '#263238',
        fontWeight: 'bold'
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
    cancelar: {
        backgroundColor: '#e57373',
        '&:hover': {
            backgroundColor: "#e57373",
        },
        color: '#fff',
    }
})

const initialState = {
    usuario: '',
    password: ''
}

const Cuentas = (props) => {

    const [adminPassword, setAdminPassword] = useState('')
    const [cuentasUsuario, setCuentasUsuario] = useState(null)
    const [usuario, setUsuario] = useState(initialState)
    const [openAdmin, setOpenAdmin] = useState(false)
    const [openCrearUsuario, setOpenCrearUsuario] = useState(false)
    const [openEliminarUsuario, setOpenEliminarUsuario] = useState(false)
    
    const [eliminarCuenta, setEliminarCuenta] = useState(false)
    const [crearCuenta, setCrearCuenta] = useState(false)


    useEffect(() => {

        if(props.storageDataReducer.FSusuarios){
            setCuentasUsuario(props.storageDataReducer.FSusuarios)
        }
        
    }, [props.storageDataReducer.FSusuarios])

    const handleChange = (e) => {
        setUsuario({
            ...usuario,
            [e.target.name] : e.target.value.trim()
        })
    }

    const handleChangeAdmin = (e) => {
        setAdminPassword(e.target.value.trim())
    }

    const clearState = () => {

        setUsuario(initialState)
        setCrearCuenta(false)
        setEliminarCuenta(false)

    }

    const handleVerificarAdmin = async () => {

        props.SendMenssageAction({option: 'inicio'})
        const verificar = await props.VerificarCuentaAdmin({password: adminPassword})
        handleCloseAdmin()
        await new Promise(resolve => setTimeout( resolve , 500))  

        if(verificar.exito){
            if(crearCuenta){
                setOpenCrearUsuario(true)
            }else if(eliminarCuenta){
                setOpenEliminarUsuario(true)
            }
        }
        props.SendMenssageAction(verificar)
    }

    const handleCrearUsuario = async () => {
        props.SendMenssageAction({option: 'inicio'})
        const crear = await props.CrearCuentaUsuario(usuario)

        if(crear.exito){
            setOpenCrearUsuario(false)
            clearState()
        }

        props.SendMenssageAction(crear)

    }

    const handleOpenCrearUsuario = () => {
        setCrearCuenta(true)        
        setEliminarCuenta(false)

        setOpenAdmin(true)
    }

    const handleCloseCrearUsuario = () => {
        setOpenCrearUsuario(false)
        clearState()
    }
   
    const handleOpenEliminarUsuario = (datos) => {

        setAdminPassword('')

        setUsuario(datos)
        
        setEliminarCuenta(true)
        setCrearCuenta(false)
        setOpenAdmin(true)
    }

    const handleCloseAdmin = () => {
        setOpenAdmin(false)
        setAdminPassword('')
    }

    const handleEliminarUsuario = async () => {
        props.SendMenssageAction({option: 'inicio'})
        handleCloseEliminarUsuario()
        const eliminar = await props.EliminarUsuario(usuario)

        props.SendMenssageAction(eliminar)
    }

    const handleCloseEliminarUsuario = () => {

        setOpenEliminarUsuario(false)
        clearState()
    }

    
    

    //*render
    const { classes, openCuentas, setOpenCuentas } = props

    return (
        <Fragment>
            <Dialog 
            open={openCuentas} 
            // TransitionComponent={TransitionSlide} 
            // maxWidth={'xs'} 
            fullScreen 
            onClose={() => setOpenCuentas(false)}
            PaperProps={{
                style: {
                  backgroundColor: '#eceff1',
                  boxShadow: "none"
                }
              }}
            >
            <Grid item className={classes.styleContenedor} xs={12}  >

                <Box p={1} display="flex" className={classes.fixedUp} onClick={() => setOpenCuentas(false)}>
                    <Box mx={1}>
                        <ArrowBackIcon />
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h6" justify="center" >
                            Cuentas de Usuario
                        </Typography>
                    </Box>
                    <Box mx={2}></Box>
                </Box>

                <Box pr={1} mt={7} align="right">
                    <Avatar className={classes.avatarPerson} onClick={handleOpenCrearUsuario}>
                        <PersonAddIcon />
                    </Avatar>
                </Box>

                <Box mt={5}>
                <List component="nav" >

                    {cuentasUsuario && cuentasUsuario.map(cuentaUsuario => {
                        return(
                            <Cuenta
                            key={cuentaUsuario.id_usuario}
                            usuario={cuentaUsuario}
                            handleOpenEliminarUsuario={handleOpenEliminarUsuario}
                            />
                        )
                    })}
                    
                </List>
                </Box>

            </Grid>
            </Dialog>

            <Drawer anchor={'top'} open={openCrearUsuario} >
                <Grid item className={classes.styleContenedor} xs={12}  >
                    <Box px={6} mt={2}>
                        <TextField
                            label="Usuario"
                            name="usuario"
                            value={usuario.usuario}
                            onChange={handleChange}
                            color="secondary"
                            autoComplete="off"
                            fullWidth
                        />
                    </Box>
                    <Box px={6} my={2}>
                        <TextField
                            label="Contrasena"
                            name="password"
                            type="password"
                            value={usuario.password}
                            onChange={handleChange}
                            color="secondary"
                            autoComplete="off"
                            fullWidth
                        />
                    </Box>

                <Box display="flex">

                    <Box py={1} px={6} flexGrow={1} >
                        <Button variant="contained" className={classes.cancelar} onClick={handleCloseCrearUsuario} disableElevation >
                            CANCELAR
                        </Button>
                    </Box>
                    <Box py={1} px={6} >
                        <Button variant="contained" color="secondary" onClick={handleCrearUsuario} disableElevation>
                            ACEPTAR
                        </Button>
                    </Box>
                </Box>

            </Grid>
            </Drawer>

                

            <Drawer anchor={'top'} open={openAdmin} >
            <Grid item className={classes.styleContenedor} xs={12}  >
                <Box p={2} align="center" >
                    <Typography variant="h5" >
                        Administrador
                    </Typography>
                </Box>
                <Box px={6} mb={2}>
                <TextField
                    label="Contrasena"
                    name="password"
                    type="password"
                    value={adminPassword}
                    onChange={handleChangeAdmin}
                    color="secondary"
                    autoComplete="off"
                    fullWidth
                />
                </Box>

                <Box display="flex">

                    <Box py={1} px={6} flexGrow={1} >
                        <Button variant="contained" className={classes.cancelar} onClick={handleCloseAdmin} disableElevation >
                            CANCELAR
                        </Button>
                    </Box>
                    <Box py={1} px={6} >
                        <Button variant="contained" color="secondary" onClick={handleVerificarAdmin} disableElevation>
                            ACEPTAR
                        </Button>
                    </Box>
                </Box>

            </Grid>
            </Drawer>

            <Dialog open={openEliminarUsuario} TransitionComponent={Transition} maxWidth={'xs'} fullWidth onClose={handleCloseEliminarUsuario}>                
                <Box p={1} textAlign="center" className={classes.messageTitle}>
                    <Typography variant="h6">
                        ELIMINAR USUARIO
                    </Typography>
                </Box>

                <Box align={'center'} p={2}  >
                    <Typography variant="h4" className={classes.message}>
                        {usuario.usuario}
                    </Typography>
                </Box>

                <Box align="center" mb={2}>
                        <Button variant="contained" color="secondary" disableElevation onClick={handleEliminarUsuario} >Eliminar</Button>
                </Box>                  
            </Dialog>

        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        storageDataReducer: state.storageDataReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         VerificarCuentaAdmin: (datos) => dispatch(VerificarCuentaAdmin(datos)),
         CrearCuentaUsuario: (datos) => dispatch(CrearCuentaUsuario(datos)),
         EliminarUsuario: (datos) => dispatch(EliminarUsuario(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Cuentas))

Cuentas.propTypes = {
    
}