import { newDateZone } from '../../allFunctions'
import { 
    GUARDAR_TIPODISPOSITIVO_EXITO,
    SET_TIEMPOACTUAL_EXITO,
    SET_SHOWINSTALLAPP_EXITO,
    SET_SHOWINSTALLNOTIFICATION_EXITO,
    SHOW_INSTALLEDMESSAGE_EXITO,
    SET_GEOLOCATIONACTIVO_EXITO,
    SET_ONLINE_EXITO,
    SET_APPACTIVA_EXITO,
} from '../actionTypes'


const initState = {
    tiempoActual: Math.floor(newDateZone().getTime()/1000.0),
    showInstallApp: false,
    showInstallNotification: false,
    showInstalledMessage: false,
    geolocationActivo: true,
    online: true,
    appActiva: true,
    dispositivo: null,
    appVersion: '1.0.2',
    fechaVersion: '10/23/2020',
}

const configReducer = (state = initState, action) => {
    switch (action.type) {
        case GUARDAR_TIPODISPOSITIVO_EXITO:
            return {
            ...state,
            dispositivo: action.payload,
        }
        case SET_TIEMPOACTUAL_EXITO:
            return {
                ...state,
                tiempoActual: action.payload
        }
        case SET_SHOWINSTALLAPP_EXITO:
            return {
                ...state,
                showInstallApp: action.payload
            }  
        case SET_SHOWINSTALLNOTIFICATION_EXITO:
            return {
                ...state,
                showInstallNotification: action.payload
            }  
        case SHOW_INSTALLEDMESSAGE_EXITO:
            return {
                ...state,
                showInstalledMessage: action.payload
            }  
        case SET_GEOLOCATIONACTIVO_EXITO:
            return {
                ...state,
                geolocationActivo: action.payload
        }
        case SET_ONLINE_EXITO:
            return {
                ...state,
                online: action.payload
        }
        case SET_APPACTIVA_EXITO:
            return {
                ...state,
                appActiva: action.payload
        }
        default:
        return state
    }
}

export default configReducer