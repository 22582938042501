import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

//*COMPONENTES
import Producto from './Producto'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    root: {
        maxWidth: '100%',
    },    
    title: {
        // backgroundColor: '#ffd740'
        backgroundColor: '#bcaaa4'
    }
})

const Categorias = (props) => {

    const [Data, setData] = useState()

    useEffect(() => {

    }, [])

    const { classes, categoria } = props
    return (
        <Box mt={1}>
            <Card className={classes.root} variant="outlined" elevation={0}>
                <Box p={0.5} className={classes.title}>
                    <Typography variant="h5" noWrap align={'center'}>
                        {categoria.nombreCategoria}
                    </Typography>
                </Box>
                <Divider />
                <List component="nav" aria-label="secondary mailbox folder" dense={true}>

                    {categoria.menu.map(producto => {
                        return(
                            <Producto 
                                key={producto.id_menu}
                                producto={producto}
                                nivelCategoria={categoria.nivel}
                                handleOpenMenu={props.handleOpenMenu}
                            />
                        )
                    })}
                </List>
            </Card>
            </Box>
    )
}

export default withStyles(styles)(Categorias)

Categorias.propTypes = {
    classes: PropTypes.object.isRequired,   
    categoria: PropTypes.object.isRequired,   
    
}