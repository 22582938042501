import { useState } from "react";
import { connect } from 'react-redux'

import { Box, Button, Drawer, Typography } from "@material-ui/core"
import { TimePicker } from "@material-ui/pickers"
import moment from "moment";
import styled from "styled-components";
import { getFecha, newDateZone, newDateZoneSeconds } from "../../allFunctions";
import { AgregarNuevoHorario } from "../../store/actions/ClientesAction";
import { SendMenssageAction } from "../../store/actions/messageActions";
import Swal from "sweetalert2";
moment.locale('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
  monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
});

const NuevoHorarioContainer = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

  max-width: 350px;

  .button-add-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .button-add {
    height: 40px;
  }

  .format-horario-selected {
    padding-left: 10px;
    text-align: center;
    margin-bottom: 20px;
  }

`}
`

const NuevoHorarioDrawer = (props) => {

  const { open, handleClose, fechaCalendarioSelected, AgregarNuevoHorario} = props

  const [date, changeDate] = useState(newDateZone());

  const fecha = moment(date)
  // console.log('dateFF', moment(date))
  // console.log('date', date)
  // console.log('hora', fecha.hours())
  // console.log('minutos', fecha.minutes())
  const dateTime = newDateZoneSeconds(fechaCalendarioSelected?.fecha?.seconds)
  
  dateTime.setHours(fecha.hours())
  dateTime.setMinutes(fecha.minutes())

  const handleAgregarHorario = async () => {


    const horarioSelected = newDateZoneSeconds(fechaCalendarioSelected?.fecha?.seconds)
    horarioSelected.setHours(fecha.hours())
    horarioSelected.setMinutes(fecha.minutes())
    
    const idHorario = horarioSelected.getTime() / 1000;
    const horarioDesc = getFecha(idHorario, 4)

    props.SendMenssageAction({option: 'inicio'})
    const agregar = await AgregarNuevoHorario({idFechaCalendario: fechaCalendarioSelected?.id, idHorario, horarioDesc})

    if(agregar?.exito && agregar.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${agregar.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })

    }else {
      handleClose()
      Swal.fire({
        icon: 'success',
        title: `<strong>Bloquedo con exito</strong>`,
        showConfirmButton: false,
        timer: 2000
      })
    }


    props.SendMenssageAction(agregar)
  }

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={open}
        onClose={handleClose}
      >
        <NuevoHorarioContainer>
          <Box>
            <TimePicker 
            autoOk
            variant="static"
            openTo="hours"
            value={date}
            onChange={changeDate}
            />
          </Box>

          <Typography className="format-horario-selected" variant="h3">
            {moment(dateTime).format("dddd Do MMMM, h:mm a")}
          </Typography>

          <Box className="button-add-container">
            <Button variant="contained" color="primary" fullWidth onClick={handleAgregarHorario} className="button-add">
            <Typography variant="h3">
              Agregar
              </Typography>
            </Button>
          </Box>
        </NuevoHorarioContainer>
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return{
      auth: state.firebase.auth, 
      profile: state.firebase.profile, 
      FScalendarioData: state.calendarioReducer.calendarioData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      AgregarNuevoHorario: (datos) => dispatch(AgregarNuevoHorario(datos)),
      SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
      // SetEnabledHorarioByAdmin: (datos) => dispatch(SetEnabledHorarioByAdmin(datos)), 
     
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NuevoHorarioDrawer)
