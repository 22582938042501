import { useState, forwardRef } from 'react';
import { connect } from 'react-redux'

import { Avatar, Button, Card, Dialog, Divider, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import { getFecha } from '../../allFunctions';
import { red, green, grey } from '@material-ui/core/colors'
import CreditosCliente from '../menuComercio/CreditosCliente';
import { logout } from '../../store/actions/authActions';
import ClientePassword from '../menuComercio/ClientePassword';
import { Payment } from '@material-ui/icons';
import PaymentCard from '../checkout/PaymentCard';
import Drawer from '@material-ui/core/Drawer'
import Grow from '@material-ui/core/Grow'
import Grid from '@material-ui/core/Grid'

import { withStyles } from '@material-ui/styles'
import Swal from 'sweetalert2';
import HistorialPagosTarjeta from './HistorialPagosTarjeta';

const Transition = forwardRef(function Transition(props, ref) {
  props.timeout.enter = 500
  props.timeout.exit = 500
  return <Grow ref={ref} {...props} />
})

const styles = theme => ({
  fixedUp: {
      position: 'fixed',
      top: 0,
      // bottom: 0,
      width: '100%',
      backgroundColor: '#263238',
      color: '#ffffff',
      zIndex: 999,
      maxWidth: 900,

  },
  styleContenedor: {
      [theme.breakpoints.up('sm')]: {
          margin: 'auto',
          minWidth: 900,
      } 
  },
})

const ContainerProfileCliente = styled(({ ...props }) => <Box {...props} />)`
${({ theme }) => `

  margin-top: 80px;

  .logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .logo {
    width: 150px;
    height: 150px;
  }

  .user-info-container {
    text-align: center;
  }

  .user-credits-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .cliente-name {
    font-size: 18px;
    font-weight: bold;
  }

  .creditos-color {
    color: ${green["400"]};
  }

  .cliente-vigencia {
    font-size: 14px;
    font-weight: bold;
    color: ${grey["700"]};
  }

  .creditos-color-none {
    color: ${red["900"]};
  }

  .recent-class {
    text-align: center;
    font-size: 18px;
  }

  .reservation-classes-container {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
  }

  .clase-horario {
    font-size: 16px;
  }

  .proximas-clases {
    font-size: 18px;
    font-weight: bold;
  }

  .action-password-button {
  }

`}
`;



const ProfileCliente = (props) => {

  const { profile, auth, FSlugaresReservados, FSclienteCreditos, FSclienteNumCreditos, classes } = props

  const [openDrawer, setOpenDrawer] = useState(false)
  const [openDrawerPagos, setOpenDrawerPagos] = useState(false)
  const [openPasswordCliente, setOpenPasswordCliente] = useState(false)
  const [openPagoTarjeta, setOpenPagoTarjeta] = useState(false)
  const [paquete, setPaquete] = useState(null)


  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }

  const handleCloseDrawerPagos = () => {
    setOpenDrawerPagos(false)
  }

  const handleCloseClientePassword = () => {
    setOpenPasswordCliente(false)
  }

  const handleLogout = () => {
    props.logout()
  }

  const handleComprarPaqueteCliente = (paqueteData) => {

    console.log('paqueteData', paqueteData)
    setPaquete(paqueteData)
    setOpenDrawer(false)
    setOpenPagoTarjeta(true)
  }

  const handlePaymentResult = (result) => {

    setOpenPagoTarjeta(false)

    if(result.message === 'succeeded'){
        // const dataInfo = {
        //     recoleccion: valueRecoleccion,
        //     nombre: nombre,
        //     apellido: apellido,
        //     telefono: telefono,
        //     domicilio: domicilio,
        //     colonia: colonia,
        //     idColonia: idColonia,
        //     referencia: referencia,
        //     metodoPago: valueMetodoPago,
        //     id_orden: result.id_orden,
        //     pagaraCon: pagaraCon
        // }
        
        let timerInterval
        Swal.fire({
              title: 'Pago Exitoso!',
              icon: 'success',
              confirmButtonText: '',
              timer: 5000,
              timerProgressBar: true,
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
                const timer = Swal.getPopup().querySelector("b");
                timerInterval = setInterval(() => {
                  timer.textContent = `${Swal.getTimerLeft()}`;
                }, 100);
              },
              willClose: () => {
                clearInterval(timerInterval);
              }
        })
        // .then((response) => {
           
            
        //     // handleEnviarOrden(result.id_orden)
        //     props.setOpenPagoExitoso(true)
        //     // props.setDataPagoExitoso(dataInfo)
        //     props.setOpenOrden(false)
        // })
    }else{
        Swal.fire({
            title: 'Pago Declinado!',
            icon: 'error',
            confirmButtonText: 'Cerrar'
        })

        
    }

    setPaquete(null)

}

console.log("profileWWW", profile)
console.log("auth", auth)

  let creditosColor = 'creditos-color'
  if(FSclienteNumCreditos === 0){
    creditosColor = 'creditos-color-none'
  }

  if(auth?.uid === 'GQmvrpgmcDfsz3s1iuhapiUhOtx1') return (
    <ContainerProfileCliente>

    <Box display='flex' justifyContent="center" height={30}>
      <Box flexGrow={1} onClick={handleLogout}>
        <Button variant='contained' color="primary">
          Salir
        </Button>
      </Box>
      <Box >
        <Typography>
          version 1.01
        </Typography>
      </Box>
    </Box>

    <Box className='logo-container'>
    <Avatar alt="Logo" src="/static/images/avatar/1.jpg" className="logo" />
    </Box>

    <Box className='user-info-container mb-8'>
      <Typography className='cliente-name'>
        {profile?.nombre}
      </Typography>
      <Typography className='cliente-name'>
        {profile?.apellidos}
      </Typography>

    </Box>

    <Divider className='mv-1 mh-2' />

</ContainerProfileCliente>
  )

  return (
    <ContainerProfileCliente>

        <Box display='flex' justifyContent="center" height={30}>
          <Box flexGrow={1} onClick={handleLogout}>
            <Button variant='contained' color="primary">
              Salir
            </Button>
          </Box>
          <Box >
            <Typography>
              version 2.2
            </Typography>
          </Box>
        </Box>

        <Box className='logo-container'>
        <Avatar alt="Logo" src="/static/images/avatar/1.jpg" className="logo" />
        </Box>

        <Box className='user-info-container mb-8'>
          <Typography className='cliente-name'>
            {profile?.nombre}
          </Typography>
          <Typography className='cliente-name'>
            {profile?.apellidos}
          </Typography>
          {FSclienteNumCreditos === 0 ?
          <Box className="user-credits-container">
            <Typography className={`cliente-name ${creditosColor}`}>
              Creditos: {FSclienteNumCreditos} 
            </Typography>
          </Box>
          :
          null
          }
          {FSclienteCreditos && FSclienteCreditos.map(clienteCreditos => {

              return (
                <Box key={clienteCreditos?.uid} className="user-credits-container">
                  {clienteCreditos?.creditos > 0 ?
                  <Typography className={`cliente-name ${creditosColor}`}>
                    Creditos: {clienteCreditos?.creditos} 
                  </Typography>
                  :
                  null
                  }
                  {clienteCreditos?.ListaEspera > 0 ?
                  <Typography className={`cliente-name creditos-color`}>
                    Creditos lista en espera: {clienteCreditos?.ListaEspera} 
                  </Typography>
                  :
                  null
                  }
                  {clienteCreditos?.vipCredit ?
                  <Typography className="cliente-vigencia">
                    Sin igencia
                  </Typography>
                  :
                  <Typography className="cliente-vigencia">
                    Vigencia: {getFecha(clienteCreditos?.idVigencia, 1)} 
                  </Typography>
                  }
                </Box>

              )
          })}

        </Box>

        <Typography className='ml-2 proximas-clases'>
        Próximas clases
        </Typography>

        <Divider className='mv-1 mh-2' />

          {FSlugaresReservados && FSlugaresReservados.slice(0,3).map(lugarReservado => {
            
            return (
              <Box className="reservation-classes-container">
                <Box flexGrow={1}>
                    <Typography  className='clase-horario'>
                    {getFecha(lugarReservado?.idHorario, 10)}
                    </Typography>
                </Box>
                <Box className='mr-2'>
                    <Typography className='clase-horario'>
                    {getFecha(lugarReservado?.idHorario, 4)}
                    </Typography>
                </Box>
                <Box className='ml-2'>
                    <Typography className='clase-horario-time'>
                    Cama {lugarReservado?.idLugar}
                    </Typography>
                </Box>
            </Box>

            )
          })}

        <Divider className='mv-1 mh-2' />

        <Button fullWidth className='action-password-button mt-2' variant='contained' color='primary'
          onClick={() => setOpenDrawer(true)} 
        >
            Paquetes de creditos
        </Button>
        <Button fullWidth className='action-password-button mt-2' variant='contained' color='primary'
          onClick={() => setOpenPasswordCliente(true)} 
        >
            Cambiar contraseña
        </Button>
        <Button fullWidth className='action-password-button mt-2' variant='contained' color='primary'
          onClick={() => setOpenDrawerPagos(true)} 
        >
            Mis pagos con tarjeta
        </Button>

        <CreditosCliente
          openDrawer={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
          handleComprarPaqueteCliente={handleComprarPaqueteCliente}
        />

        <HistorialPagosTarjeta
          openDrawer={openDrawerPagos}
          handleCloseDrawer={handleCloseDrawerPagos} 

        />

        <ClientePassword openDrawer={openPasswordCliente} selectedCliente={profile} handleCloseDrawer={handleCloseClientePassword} />

        <Drawer anchor={'bottom'} open={openPagoTarjeta} onClose={() => setOpenPagoTarjeta(false)} >
          <Grid item className={classes.styleContenedor} xs={12}  >

            <PaymentCard
              idCliente={auth?.uid}
              total={paquete?.tarjeta}
              paquete={paquete}
              setOpenPagoTarjeta={setOpenPagoTarjeta}
              nombre={profile?.nombre}
              apellido={profile?.apellidos}
              telefono={profile?.telefono}
              domicilio={""}
              referencia={""}
              handlePaymentResult={handlePaymentResult}
              // handleEnviarOrden={handleEnviarOrden}
            />

          </Grid>
        </Drawer>

    </ContainerProfileCliente>
  )
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth, 
    profile: state.firebase.profile, 
    FSlugaresReservados: state.storageDataReducer.FSlugaresReservados,
    FSclienteCreditos: state.storageDataReducer.FSclienteCreditos,
    FSclienteNumCreditos: state.storageDataReducer.FSclienteNumCreditos,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      logout: (datos) => dispatch(logout(datos)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileCliente))