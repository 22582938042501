import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box'
import Dialog from "@material-ui/core/Dialog"

// Inspired by the Facebook spinners.
const useStylesFacebook = makeStyles({
    root: {
        position: 'relative',
    },
    top: {
        color: '#000000',
    },
    bottom: {
        color: '#00BCD4',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },    

})

function FacebookProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={100}
        thickness={4}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={100}
        thickness={4}
        {...props}
      />
    </div>
  )
}

const CustomizedProgressBars = (props) => { 

  const [cargando, setCargando] = useState(false)

  useEffect(() => {
    
    setCargando(props.cargando)

  }, [props.cargando])

  // const { cargando } = props

  return (
    <div>      
        <Dialog
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none"
          }
        }}
        open={cargando}
      >
          <Box p={4}>
              <FacebookProgress />
          </Box>

        </Dialog>

    </div>
  )
}

export default CustomizedProgressBars
