import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import Ingrediente from './Ingrediente'

//*MATERIAL UI
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { withStyles } from '@material-ui/styles'


const styles = theme => ({
    title: {
        // backgroundColor: '#ffd740'
        backgroundColor: '#bcaaa4'
    }
})

const Adicion = (props) => {

    const [Data, setData] = useState()

    useEffect(() => {

    }, [])

    //*render
    const { classes, adicion } = props

    return (
        <Box>
            <Box px={3} className={classes.title}>
                <Typography variant='h5'>
                    <b>{adicion.titulo}</b>
                </Typography>
            </Box>

            {adicion.ingredientes.map(ingrediente => {
                return(

                    <Ingrediente 
                    key={ingrediente.id} 
                    ingrediente={ingrediente}

                    />
                        
                )
            })}
        </Box>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Adicion))

Adicion.propTypes = {
    
}