import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles'
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import { CardTitle } from '../../elementos/card-title'
import { Card, Container, Dialog, Grid } from '@material-ui/core';
import { getFecha, newDateZone, ZENSEADMIN_UID, ZENSE_UID } from '../../allFunctions'
import { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import CalendarioHorarios from './CalendarioHorarios'
import LugaresDrawer from './LugaresDrawer'
import { green, red, grey } from '@material-ui/core/colors';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';

// import { motion } from "framer-motion"
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion"
import moment from 'moment-timezone'
import Swal from 'sweetalert2';
import { SendMenssageAction } from '../../store/actions/messageActions';
import { GetLocalTime, SetDisabledFechaByAdmin, SetEnabledFechaByAdmin } from '../../store/actions/ClientesAction';

const ContainerCalendario = styled(({ ...props }) => <Box {...props} />)`
  ${({ theme }) => `

    .horarios-container {
      width: 100%;
      padding: 10px;
    }
    
    .horario-dia {
      width: 100%;
      padding: 10px;
      background-color: ${green["A100"]};
      margin-bottom: 5px;
      cursor: pointer;
    }
    
    .horario-dia:hover {
      background-color: ${green["A400"]};
      
    }
    
    .horario-selected {
      background-color: ${green["A400"]};

    }

    .calendar-card-container {
      height: 100%;
      border-radius: 15px 15px 10px 10px;
    }

    .motion-day-container {
      width: calc(100% / 4 - 20px);
      height: 150px;
      border: none;
      cursor: pointer;
      margin: 10px;
      border-radius: 15px 15px 10px 10px;
      padding: 0px;
      ${theme.breakpoints.down('sm')} {
        height: 80px;
      }
    }

    .calendar-day-month {
      background-color: ${red["A200"]};
      color: #fff;
      font-size: 13px;
      ${theme.breakpoints.down('sm')} {
        font-size: 13px;
      }

    }

    .calendar-day-sunday {
      background-color: ${grey["600"]};
      color: #fff;
      font-size: 13px;
      ${theme.breakpoints.down('sm')} {
        font-size: 13px;
      }

    }

    .calendar-day-container {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      ${theme.breakpoints.down('sm')} {
        height: 60px;
      }
    }

    .calendar-day-num {
      font-size: 25px;
      ${theme.breakpoints.down('sm')} {
        font-size: 25px;
      }
    }

    .calendar-day {
      font-size: 12px;
      ${theme.breakpoints.down('sm')} {
        font-size: 12px;
      }
    }

    .horarios-day-container {
      height: 300px;
    }

    .admin-container-height {

      height: 110px;
    }

    .block-icon {
      color: ${red["900"]};
    }
    
    .checkcircle-icon {
      color: ${green["600"]};
    }


    .horario-mantenimiento {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
    }

    .horario-mantenimiento-text {
      font-size: 20px;
    }


`}
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const CalendarDays = (props) => {

  const { FScalendarioData, profile, auth, FSconfig } = props
  const [fechaCalendarioSelected, setFechaCalendarioSelected] = useState(null)
  const [horariosSelected, setHorariosSelected] = useState(null)
  const [openHorario, setOpenHorario] = useState(false)
  const [openDayTimeDialog, setOpenDayTimeDialog] = useState(false)
  const [selectedCliente, setSelectedCliente] = useState(null)


  useEffect(() => {
    if(profile?.roll === 'cliente'){
      setSelectedCliente({
        ...profile,
        id: auth.uid
      })
    }

    return () => {
    }
  }, [profile])

  useEffect(() => {
    
    if(fechaCalendarioSelected && FScalendarioData){
      const fechaSelected = FScalendarioData.find(fechaCalendar => fechaCalendar?.id === fechaCalendarioSelected?.id)
      setFechaCalendarioSelected(fechaSelected)
    }
    
  }, [FScalendarioData])
  
  const handleOpenDayTimeDialog = (fechaCalendario) => {
    setOpenDayTimeDialog(true)
    setFechaCalendarioSelected(fechaCalendario)

  }

  const handleCloseDayTimeDialog = () => {
    setOpenDayTimeDialog(false)
    setFechaCalendarioSelected(null)

  }

  const handleOpenHorario = (horarios) => {
    setHorariosSelected(horarios)
    setOpenHorario(true)
  }

  const handleCloseHorario = () => {
    setHorariosSelected(null)
    setOpenHorario(false)
  }

  const handleDisabledFecha = async (fechaData) => {

    let ocupado = false
    fechaData && fechaData?.horarios.map(horarios => {

      if(ocupado) return horarios

      horarios?.lugares.map(lugar => {

        if(ocupado) return lugar

        if(lugar?.ocupado){
          // ocupado = true
        }

        return lugar

      })

      return horarios
    })

    if(ocupado){
      Swal.fire({
        icon: 'error',
        title: '<strong>Contiene lugares reservados</strong>',
        confirmButtonColor: '#3085d6',
      })
      return
    }

    props.SendMenssageAction({option: 'inicio'})

    const enabled = await props.SetDisabledFechaByAdmin({
      idFecha: fechaData?.id,
    })

    if(enabled?.exito && enabled.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${enabled.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }else {
      Swal.fire({
        icon: 'success',
        title: `<strong>Bloquedo con exito</strong>`,
        showConfirmButton: false,
        timer: 2000
      })
    }

    props.SendMenssageAction(enabled)



  }

  const handleEnabledFecha = async (fechaData) => {

    props.SendMenssageAction({option: 'inicio'})

    const enabled = await props.SetEnabledFechaByAdmin({
      idFecha: fechaData?.id,
    })

    if(enabled?.exito && enabled.message === 'success'){
      Swal.fire({
        icon: 'success',
        title: `<strong>Habilitado con exito</strong>`,
        showConfirmButton: false,
        timer: 2000
      })
    }

    props.SendMenssageAction(enabled)

  }

  const handleAskDisabledFecha = (fechaData) => {

    Swal.fire({
      title: `¿Desea bloquear esta Fecha? ${getFecha(fechaData.fecha.seconds, 1)}`,
      confirmButtonText: 'Bloquear',
      confirmButtonColor: '#3085d6',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDisabledFecha(fechaData)
      } 
    })

  }

  const handleAskEnabledFecha = (fechaData) => {

    Swal.fire({
      title: `¿Desea habilitar esta Fecha? ${getFecha(fechaData.fecha.seconds, 1)}`,
      confirmButtonText: 'Habilitar',
      confirmButtonColor: '#3085d6',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleEnabledFecha(fechaData)
      } 
    })

  }

  if(!FScalendarioData || FScalendarioData?.length === 0) return null

  if(FSconfig?.mantenimiento && auth?.uid !== ZENSEADMIN_UID && auth?.uid !== ZENSE_UID){
    return (
      <>
      <ContainerCalendario className="horario-mantenimiento">
  
        <Box align="center" mt={20}>
          <Typography className='horario-mantenimiento-text'>
            Sitio en Mantenimiento
          </Typography>
          <Typography className='horario-mantenimiento-text'>
          Se restablera en un par de horas
          </Typography>
          <Typography className='horario-mantenimiento-text'>
          Gracias por su comprensión
          </Typography>
  
        </Box>
  
      </ContainerCalendario>
      </>
    )

  }


  return (
    <ContainerCalendario>

        <CardTitle title="Calendario" />

        <div>
          {FScalendarioData.map(fechaCalendario => {

            // const month  = getFecha(fechaCalendario.fecha.seconds, 6)
            // const dayNum  = getFecha(fechaCalendario.fecha.seconds, 7)
            // const day  = getFecha(fechaCalendario.fecha.seconds, 8)

            console.log('fechaCalendarioRRR', fechaCalendario)

            const [month, day, dayNum] = fechaCalendario.fullDate.split("-")
            // const 

             return (
              <>
               <motion.button
                key={fechaCalendario?.id}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className={`motion-day-container ${profile?.roll === 'cliente' ? '' : 'admin-container-height'}`}
              >
               <Card className="calendar-card-container" variant="outlined">
                <Box onClick={auth?.uid === ZENSEADMIN_UID || auth?.uid === ZENSE_UID ? () => handleOpenDayTimeDialog(fechaCalendario) : !fechaCalendario?.disponible ? null : () => handleOpenDayTimeDialog(fechaCalendario)}>
                {/* <Box onClick={() => handleOpenDayTimeDialog(fechaCalendario)}> */}
                  <Typography className={!fechaCalendario?.disponible ? 'calendar-day-sunday' : 'calendar-day-month'}>
                  {month}
                  </Typography>
                <Box className="calendar-day-container">
                  <Box>
                    <Typography className='calendar-day-num'>
                      {dayNum}
                    </Typography>
                    <Typography className='calendar-day'>
                      {day}
                    </Typography>
                  </Box>
                </Box>
                </Box>
                {profile?.roll === 'cliente' ?
                  null
                  :
                  <Box>
                    {fechaCalendario?.disponible ?
                    <IconButton 
                    onClick={() => handleAskDisabledFecha(fechaCalendario)} 
                    size="small"
                    >
                      <CheckCircleOutlineSharpIcon className="checkcircle-icon" />
                    </IconButton>
                    :
                    <IconButton 
                    onClick={() => handleAskEnabledFecha(fechaCalendario)} 
                    size="small"
                    >
                    <BlockIcon className="block-icon" />
                    </IconButton>
                    }
                  </Box>
                  }
                </Card>
              </motion.button>

             
              </>
             )
          })}
        </div>

        <CalendarioHorarios 
        openDayTimeDialog={openDayTimeDialog}
        handleCloseDayTimeDialog={handleCloseDayTimeDialog}
        fechaCalendarioSelected={fechaCalendarioSelected}
        handleOpenHorario={handleOpenHorario}
        />

      <LugaresDrawer 
        fechaCalendarioSelected={fechaCalendarioSelected} 
        horariosSelected={horariosSelected} 
        openHorario={openHorario} 
        handleCloseHorario={handleCloseHorario} 
        selectedCliente={selectedCliente}
      />


    </ContainerCalendario>
  );
}

const mapStateToProps = (state) => {
  return{
      auth: state.firebase.auth, 
      profile: state.firebase.profile, 
      FScalendarioData: state.calendarioReducer.calendarioData,
      FSconfig: state.storageDataReducer.FSconfig,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      SetEnabledFechaByAdmin: (datos) => dispatch(SetEnabledFechaByAdmin(datos)),
      SetDisabledFechaByAdmin: (datos) => dispatch(SetDisabledFechaByAdmin(datos)),
      GetLocalTime: () => dispatch(GetLocalTime()),
      SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
     
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDays)
