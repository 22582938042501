import { useState } from 'react';
import { connect } from 'react-redux'

import styled from 'styled-components'
import { Box, Container, Card } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { CardTitle } from '../../../elementos/card-title';
import { getFecha } from '../../../allFunctions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LugaresDrawer from '../../profile/LugaresDrawer';


const ContainerCalendario = styled(({ ...props }) => <Container {...props} />)`
  ${({ theme }) => `

  margin-top: 80px;

  .horarios-container {
    width: 100%;
    padding: 10px;
  }
  
  .horario-dia {
    width: 100%;
    padding: 10px;
    background-color: #c8f4d5;
    margin-bottom: 5px;
    cursor: pointer;
  }
  
  .horario-dia:hover {
    background-color: #7ee59c;
    
  }
  
  .horario-selected {
    background-color: #7ee59c;

  }

`}
`;

const UserCalendar = (props) => {

  const { FScalendarioData, profile } = props

  const [fechaCalendarioSelected, setFechaCalendarioSelected] = useState(null)
  const [horariosSelected, setHorariosSelected] = useState(null)
  const [openHorario, setOpenHorario] = useState(false)

  const handleOpenHorario = (fechaCalendario, horarios) => {

    console.log('fechaCalendario', fechaCalendario)
    console.log('horarios', horarios)
    setFechaCalendarioSelected(fechaCalendario)
    setHorariosSelected(horarios)
    setOpenHorario(true)
  }

  const handleCloseHorario = () => {
    console.log('here')
    setFechaCalendarioSelected(null)
    setHorariosSelected(null)
    setOpenHorario(false)
  }

  if(!FScalendarioData || FScalendarioData?.length === 0) return null


  return (
    <ContainerCalendario maxWidth="md">

    <Card>

    <CardTitle title="Calendario" />

    <div>
      {FScalendarioData.map(fechaCalendario => {

          const fecha  = getFecha(fechaCalendario.fecha.seconds, 5)

        console.log('fechaCalendario', fechaCalendario)
          // return (
          //   <List component="nav" aria-label="main mailbox folders">
          //     <ListItem button onClick={() => handleOpenHorarios(horarios)}>
          //       <ListItemIcon>
          //         <CalendarTodayIcon />
          //       </ListItemIcon>
          //       <ListItemText primary={fecha} />
          //     </ListItem>
          //     <Divider />
          //   </List>
          // )


        return (
          <Accordion key={fechaCalendario?.id}> 
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="">{fecha}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="horarios-container">
                {fechaCalendario.horarios.map(horario => {

                  const ocupados = horario.lugares.filter(lugar => lugar.ocupado === true)
                  
                  let selected = ''
                  if(horariosSelected && fechaCalendarioSelected && horariosSelected?.horario?.id === horario?.id && fechaCalendarioSelected?.id === fechaCalendario?.id){
                    selected = 'horario-selected'
                  }

                  return (
                  <Box key={horario?.id} className={`horario-dia ${selected}`} 
                  onClick={() => handleOpenHorario(fechaCalendario, horario)}
                  >
                    <Typography>
                    {getFecha(horario?.id, 4)} ocupados: {ocupados?.length}
                    </Typography>
                  </Box>

                  )
                })}

              </Box>
            </AccordionDetails>
          </Accordion>

        )
      })}

      </div>
      </Card>

      <LugaresDrawer
        fechaCalendarioSelected={fechaCalendarioSelected} 
        horariosSelected={horariosSelected} 
        openHorario={openHorario} 
        handleCloseHorario={handleCloseHorario} 
        profile={profile}
      />

    </ContainerCalendario>
  )
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth, 
    profile: state.firebase.profile, 
    FScalendarioData: state.calendarioReducer.calendarioData
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      // UpdateCalendarioFecha: (datos) => dispatch(UpdateCalendarioFecha(datos)),
      // logout: (datos) => dispatch(logout(datos)),
     
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCalendar)