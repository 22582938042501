import { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import moment from "moment";

import { GuardarDiasHorarios } from '../../../store/actions/ClientesAction';
import { SendMenssageAction } from '../../../store/actions/messageActions';

//*components
import { getFecha, newDateZone, newDateZoneZeroHours } from '../../../allFunctions'
//*MUI
import { Box, Container, Card, Divider, Drawer, Button, Dialog, AppBar, Toolbar, Typography, List, ListItem, ListItemText, DialogTitle, DialogContent, DialogActions, ListItemSecondaryAction } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import { TimePicker } from "@material-ui/pickers"
import DeleteIcon from '@material-ui/icons/Delete';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { red } from '@material-ui/core/colors';
import Swal from 'sweetalert2';

const ContainerHorariosConfig = styled(({ ...props }) => <Box {...props} />)`
${({ theme }) => `

  .list-horarios-container {
    margin-top: 60px;
  }

  .button-close-horario {
    cursor: pointer;
  }


`}
`;

const ConfigHorarios = (props) => {

  const { openHorarios, handleCloseConfig } = props

  const [horarioDias, setHorariosDias] = useState(null)
  const [openNewHorario, setOpenNewHorario] = useState(false)
  const [horarioData, setHorariosData] = useState(null)
  const [date, changeDate] = useState(newDateZoneZeroHours());

  useEffect(() => {
   
    if(props.FSconfig && props.FSconfig?.dias){
      setHorariosDias(props.FSconfig?.dias)
    }
    
  }, [props.FSconfig])

  const handleAskDeleteHorario = (data) => {
    setHorariosData(data)

    const hoursMinutes = data?.horario.split(':')
    // const fecha = moment(date)
    const dateTime = newDateZone()
    dateTime.setHours(hoursMinutes[0])
    dateTime.setMinutes(hoursMinutes[1])
    console.log('dateTime', dateTime)
    // changeDate(dateTime)

    const selectedDay = data?.dia?.dia
    const selectedTime = data?.horario
    const now = newDateZone()
    now.setHours(hoursMinutes[0],hoursMinutes[1],0)

    const msgTime = `Eliminar dia ${selectedDay} ${now.toLocaleTimeString('es-MX', {timeZone: 'America/Mazatlan', hour: '2-digit', minute:'2-digit', hour12: true})}`
    Swal.fire({
      title: msgTime,
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {

        const newHorarioDias = JSON.parse(JSON.stringify(horarioDias))

        console.log('newHorarioDias', newHorarioDias)

        newHorarioDias.map(dias => {

          if(dias?.dia === selectedDay){
            dias.horarios = dias?.horarios.filter(horario => horario !== selectedTime)
          }
        })

        console.log('newHorarioDias', newHorarioDias)
        setHorariosDias(newHorarioDias)

        Swal.fire({
          icon: 'success',
          title: 'Eliminado',
          showConfirmButton: false,
          timer: 1500
        })

      } else if (result.isDenied) {
        // Swal.fire('Changes are not saved', '', 'info')
      }
    })

  
  }

  const handleOpenNewHorario = (data) => {
    setOpenNewHorario(true)
    setHorariosData(data)
  }

  const handleChangeTime = (time) => {
    changeDate(time)
  }

  const handleActionAcept = () => {
    console.log('date', moment(date).hour())
    console.log('date', moment(date).minutes())

    const hours = moment(date).hour()
    const minutes = moment(date).minutes()
    const newHorario = `${hours}:${minutes}`

    const hasHorario = horarioData?.horarios.find(horario => horario === newHorario)
    console.log('hasHorario', hasHorario)
    if(hasHorario){


      Swal.fire({
        icon: 'error',
        title: 'Horario duplicado',
        showConfirmButton: false,
        timer: 1500
      })
      return
    }

    let selectedIndex = 0

    horarioData?.horarios.map((horario, index) => {

      const hoursMinutes = horario.split(':')
      // const fecha = moment(date)
      const dateTime = newDateZone()
      dateTime.setHours(hoursMinutes[0])
      dateTime.setMinutes(hoursMinutes[1])

      console.log('fechas',moment(date) > moment(dateTime))

      if(moment(date) > moment(dateTime)){
        selectedIndex = index + 1
      }
      return true
    })

    const newHorariosDay = [
      ...horarioData?.horarios.slice(0, selectedIndex),
      newHorario,
      ...horarioData?.horarios.slice(selectedIndex)
    ];
  
    console.log('selectedIndex', selectedIndex)
    console.log(newHorariosDay); 

    const newHorarioDias = JSON.parse(JSON.stringify(horarioDias))

    newHorarioDias.map(dias => {

      if(dias?.dia === horarioData?.dia){
        dias.horarios = newHorariosDay
      }
    })

    console.log('newHorarioDias', newHorarioDias)
    setHorariosDias(newHorarioDias)
    setOpenNewHorario(false)
    setHorariosData(null)

    Swal.fire({
      icon: 'success',
      title: 'Horario creado',
      showConfirmButton: false,
      timer: 1500
    })

    // const selectedDay = newHorarioDias.find(dia => dia?.dia === horarioData?.dia)


  }

  const handleCloseHorario = () => {
    setHorariosData(null)
    setOpenNewHorario(false)
  }

  const handleSaveConfig = async () => {

    console.log('horarioDias', horarioDias)

    props.SendMenssageAction({option: 'inicio'})
    

    const guardar = await props.GuardarDiasHorarios(horarioDias)

    console.log('guardarX', guardar)
    if(guardar?.exito && guardar?.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${guardar?.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }

    if(guardar?.exito && guardar?.message === 'success'){
      handleCloseHorario()
      handleCloseConfig('horarios')
      Swal.fire({
        icon: 'success',
        title: '<strong>Horario actualizado</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(guardar)

  }

  function getAMPMTime(hour) {
    if (hour >= 0 && hour < 24) {
        if (hour >= 12) {
            return "pm";
        } else {
            return "am";
        }
    } else {
        return "Invalid hour";
    }
}


  
  // console.log('horarioDias', horarioDias)
  // console.log('horarioData', horarioData)
  // console.log('date', date)
  return (
    <>
    <Dialog 
        open={openHorarios}
        // TransitionComponent={Transition}
        // maxWidth='sm'
        fullScreen
        fullWidth
        onClose={() => handleCloseConfig('horarios')}
        >

        <ContainerHorariosConfig>

          <AppBar className={''}>
              <Toolbar>
                <Box>
                  <IconButton edge="start" color="inherit" onClick={() => handleCloseConfig('horarios')} aria-label="close">
                  <CloseIcon />
                  </IconButton>

                </Box>
                <Box flexGrow={1}>
                  <Typography variant="h6" className={'button-close-horario'} onClick={() => handleCloseConfig('horarios')}>
                  Cerrar
                  </Typography>
                </Box>
                <Box>
                  <Button autoFocus color="inherit" onClick={handleSaveConfig}>
                    Guardar
                  </Button>
                </Box>
              </Toolbar>
          </AppBar>

          <Box border={1} className="list-horarios-container">
            <List component="nav" className=''>
              {horarioDias && horarioDias.map(dia => {
                if(dia?.id === 0) return null

                return (
                  <Box key={dia?.id}>
                    <Divider />
                      <ListItem
                        key={dia.id}
                      >
                        <ListItemText primary={dia?.dia} />
                        <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete" onClick={() => handleOpenNewHorario(dia)}>
                            <ScheduleIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    <Divider />

                    {dia?.horarios.map((horario, index) => {

                      const now = newDateZoneZeroHours()
                      const horarioData = horario.split(':')
                      now.setHours(horarioData[0],horarioData[1],0)

                      // Example usage
                      const hour = horarioData[0]; // Replace with the desired hour
                      const period = getAMPMTime(hour);
                      const horaActual = hour % 12
                      const horaAmPm = `${horaActual < 10 ? `0${horaActual}`:`${horaActual}`}:${horarioData[1] < 10 ? `0${horarioData[1]}`:`${horarioData[1]}`} ${period}`
                      // console.log('horarioData', horarioData)
                      return (
                        <ListItem
                          button
                          key={horario}
                          // onClick={() => handleDeleteHorario({dia, horario})}
                        >
                          <ListItemText primary={horaAmPm} />
                            <ListItemSecondaryAction>
                              <IconButton edge="end" aria-label="delete" onClick={() => handleAskDeleteHorario({dia, horario})}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                      )

                    })}
                  </Box>
                )
              })}
            </List>
          </Box>


        </ContainerHorariosConfig>
    </Dialog>

    <Dialog
        open={openNewHorario}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

      {/* <DialogTitle id="alert-dialog-title">{"Crear nuevo horario"}</DialogTitle> */}
      {/* <DialogContent> */}
          <Box>
            <TimePicker 
            autoOk
            variant="static"
            openTo="hours"
            value={date}
            onChange={handleChangeTime}
            />
          </Box>
      {/* </DialogContent> */}

      <Typography className="format-horario-selected" variant="h3" align='center'>
        {horarioData?.dia} {moment(date).format("h:mm a")}
      </Typography>

      <DialogActions>
          <Button onClick={handleCloseHorario} style={{color: red[900]}} className='button-cancel-color'>
            Cancelar
          </Button>
          <Button onClick={handleActionAcept} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>

      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth, 
    profile: state.firebase.profile, 
    FSconfig: state.storageDataReducer.FSconfig,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      GuardarDiasHorarios: (datos) => dispatch(GuardarDiasHorarios(datos)),
      SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigHorarios)