import { capitalLetter } from '../../allFunctions'
import { LOGIN_INICIA, 
    LOGIN_EXITO, 
    LOGIN_ERROR,
    RESET_STATE_LOGIN } from '../actionTypes'

import { validarLoginVacio, validarCorreo } from '../../allFunctions'


const verificarLogin = async (credentials, firebase) => {

    let respuesta    
    const funct = firebase.functions() 
    const loginUserByEmail = funct.httpsCallable('global_loginUserByEmail')

    await loginUserByEmail(credentials)
    .then(response => {
        respuesta = response.data
    })
    .catch(error => {
        respuesta = error
    })
    return respuesta
 
}

export const login = (credentials, recaptchaToken) => {
    return async (dispatch, getState, {getFirebase}) => {
        
        const firebase = getFirebase()
        const funct = firebase.functions()
        dispatch({ type: LOGIN_INICIA })

        credentials.email = `${credentials.usuario}@fitness.com`
        const validacion = validarLoginVacio(credentials)
        if(validacion.error){

            setTimeout(() => {
                dispatch({ type: LOGIN_ERROR, payload: validacion.mensajeError })
            }, 1000)
            return
        }

        // const pirvateGoogleTokenKey = "6LciYO8kAAAAACmwV56JL64ApVTDej96AkmRV_YQ";

        // await fetch(`https://www.google.com/recaptcha/api/siteverify?secret=${pirvateGoogleTokenKey}&response=${credentials?.recaptcha}`)
        //   .then((response) => response.json())
        //   .then((data) => {
        //     console.log("data", data);
        //   })
        //   .catch((error) => {
        //     console.log("error", error);
        //   });


        const checkRecaptchaToken = funct.httpsCallable('check_recaptcha_token')
        let recaptchaResponse = false
        await checkRecaptchaToken(credentials)
        .then(response => {
          if(response && response?.data && response?.data?.success && response?.data?.score > 0.4){
            recaptchaResponse = true
          }

        })
        .catch(error => {
            console.log('error', error);
        })

        if(!recaptchaResponse){
            const mensajeError = 'Se produjo un Error'
            dispatch({ type: LOGIN_ERROR, payload: mensajeError })  
            return
        }

        // const verificarUsuario = await verificarLogin(credentials, firebase)

        // if(verificarUsuario.encontrado){
            // if(verificarUsuario.claims.perfil !== 'comercio'){
            //     dispatch({ type: LOGIN_ERROR, payload: 'Plataforma equivocada' })
            //     return
            // }

            firebase.auth().signInWithEmailAndPassword(
                credentials.email,
                credentials.password
            ).then((data) =>{    
                dispatch({ type: LOGIN_EXITO })        
            })
            .catch((error) => {
                let mensajeError
                console.log(error?.code)
                if(error.code === 'auth/user-not-found'){       
                    mensajeError = 'Usuario o contraseña invalida'           
                }else if(error.code === 'auth/wrong-password'){
                    mensajeError = 'Usuario o contraseña invalida'
                }else if(error.code === 'auth/invalid-email'){
                    mensajeError = 'El formato del usuario es invalido'
                }else if(error.code === 'auth/user-disabled'){
                    mensajeError = 'Cuenta deshabilitada'
                }else{
                    mensajeError = 'Se produjo un Error'
                }              
    
                dispatch({ type: LOGIN_ERROR, payload: mensajeError })  
            })

        // }else{
        //     dispatch({ type: LOGIN_ERROR, payload: 'Usuario o contraseña invalida' })
        //     return
        // }

    }
}

export const resetStateLogin = () => {
    return (dispatch, getState) => {

        dispatch({ type: RESET_STATE_LOGIN})
    }
}


export const logout = () => {
    return async (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase()


        firebase.logout()
        await firebase.auth().signOut().then(() => {
           
           window.location.reload(false)
        })
        .catch(error => {
            console.log(error);
        })
    }
}

export const MessageAuthActions = () => {
    return async (dispatch, getState, {getFirebase}) => {
        // dispatch({ type: CREAR_CUENTACLIENTE_PREVE, payload: 'Para el usuario solo se aceptan numeros y letras' })
    }
}