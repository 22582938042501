import moment from 'moment-timezone'


export const validarLoginVacio = (datos) => {
   
    let error = false
    let mensajeError = ''

    if(datos.email === '' || datos.password === ''){
        error = true
        mensajeError =  'Todos los campos son obligatiros'
    }else if(!validarCorreo(datos.email)){//
        error = true
        mensajeError = 'El formato del usuario es invalido'
    }else if(datos.password.length < 6){
        error = true
        mensajeError = 'Usuario o contraseña invalida'
    }

    const validacion = {
        error,
        mensajeError
    }
    
    return validacion
}

export const esNumero = (num) => {

       if (/^\d+$/.test(num) || num === '') {
        return true
    }
    return false
}

export const onlyLetters = (data) => {

    const xprex = /^[A-Za-z\s]*$/;

    if(data.match(xprex)){

        return true
    }
    return false
}

export const onlyLettersNumbers = (data) => {

    const xprex = /^[0-9a-zA-Z\s]*$/;

    if(data.match(xprex)){

        return true
    }
    return false
}


export const capitalLetter = (str) => {

    str = str.split(" ");

    for (var i = 0, x = str.length; i < x; i++) {
        if(str[i][0]){
            str[i] = str[i][0].toUpperCase() + str[i].substr(1);
        }
    }

    return str.join(" ");
}

export const validarCorreo = (correo) => {
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(correo.match(regEx)){
        return true // el correo es correcto
    }
    return false
}

export const telFormat = (telefono) => {

    const input = telefono.replace(/\D/g,'').substring(0,10);
    const zip = input.substring(0,3);
    const middle = input.substring(3,6);
    const last = input.substring(6,10);

    let tel = ''
    if(input.length > 6){
        tel = `(${zip}) ${middle} - ${last}`
    }else if(input.length > 3){
        tel = `(${zip}) ${middle}`
    }else if(input.length > 0){
        tel = `(${zip}`
    }

    return tel

}

export const getFileExtension = (filename) => {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
  }

export const arrayMeses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
export const ArraydiasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado")

export const getFecha = (seconds, option) => {

    const date = new Date( seconds * 1000);

    const arrayMeses = new Array ("Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre");
    const ArraydiasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado")

    if(option === 1){
        return `${date.getDate()} de ${arrayMeses[date.getMonth()]} ${date.getFullYear()}`
    }else if(option === 2){
        return `${date.getDate()} de ${arrayMeses[date.getMonth()]} ${date.getFullYear()} ${date.toLocaleTimeString('es-MX', {timeZone: 'America/Mazatlan', hour: '2-digit', minute:'2-digit', hour12: true })}`
    }else if(option === 3){
        return `${ArraydiasSemana[date.getDay()]} ${date.getDate()} de ${arrayMeses[date.getMonth()]} ${date.toLocaleTimeString('es-MX', {timeZone: 'America/Mazatlan', hour: '2-digit', minute:'2-digit', hour12: true })}`
    }else if(option === 4){
        return `${date.toLocaleTimeString('es-MX', {timeZone: 'America/Mazatlan', hour: '2-digit', minute:'2-digit', hour12: true})}`
    }else if(option === 5){
        return `${ArraydiasSemana[date.getDay()]} ${date.getDate()} de ${arrayMeses[date.getMonth()]} ${date.getFullYear()}`
    }else if(option === 6){
        return `${arrayMeses[date.getMonth()]}`
    }else if(option === 7){
        return `${date.getDate()}`
    }else if(option === 8){
        return `${ArraydiasSemana[date.getDay()]}`
    }else if(option === 9){
        return `${ArraydiasSemana[date.getDay()]}`
    }else if(option === 10){
        return `${ArraydiasSemana[date.getDay()]}  ${date.getDate()} de ${arrayMeses[date.getMonth()]}`
    }

    return null
}


export const getCalendario = () => {

    const dataDay = {
        horarios: [
            {
                id: "6am",
                disponible: true,
                horario: '6 am',
                lugares: [
                    {
                        id: 1,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 2,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 3,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 4,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 5,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 6,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                ]

            },
            {
                id: 2,
                disponible: true,
                horario: '8 a 9',
                lugares: [
                    {
                        id: 1,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 2,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 3,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 4,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 5,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 6,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                ]

            },
            {
                id: 3,
                disponible: true,
                horario: '9 a 10',
                lugares: [
                    {
                        id: 1,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 2,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 3,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 4,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 5,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                    {
                        id: 6,
                        ocupado: false,
                        cliente: '',
                        idCliente: ''
                    },
                ]

            },
        ]
    }

    return dataDay



}

export const getAllYears = () => {

    const year = new Date().getFullYear()

    const years = []
    for (let index = 0; index < 80; index++) {
        
        years.push(year - index)
        
    }

    return years
}

export const getAllMonths = () => {

    return [
        {
            id: 0,
            mes: 'Enero'
        },
        {
            id: 1,
            mes: 'Febrero'
        },
        {
            id: 2,
            mes: 'Marzo'
        },
        {
            id: 3,
            mes: 'Abril'
        },
        {
            id: 4,
            mes: 'Mayo'
        },
        {
            id: 5,
            mes: 'Junio'
        },
        {
            id: 6,
            mes: 'Julio'
        },
        {
            id: 7,
            mes: 'Agosto'
        },
        {
            id: 8,
            mes: 'Septiembre'
        },
        {
            id: 9,
            mes: 'Octubre'
        },
        {
            id: 10,
            mes: 'Noviembre'
        },
        {
            id: 11,
            mes: 'Diciembre'
        }
]
}


export const getAllDays = () => {

    const days = []
    for (let index = 1; index < 32; index++) {
       days.push(index);
    }

    return days
}

export const checkUserHasReservationInDay = (day, cliente) => {

    let encontrado = null
    let mensaje = ''

    day?.horarios && day?.horarios.length !== 0 && day.horarios.map(horario => {

        const listaEspera = horario?.listaEspera.find(lista => lista?.idCliente === cliente?.id)

        if(listaEspera){
            encontrado = getFecha(horario?.id, 4)
            mensaje = 'Ya estas en la lista de espera'
        }

        horario?.lugares && horario?.lugares?.length !== 0 && horario?.lugares.map(lugar => {


            if(lugar?.idCliente === cliente?.id){
                encontrado = getFecha(horario?.id, 4)
                mensaje = 'Ya tienes reservación'
            }

            return true
        })

        return true
    })

    return {encontrado, mensaje}
}

export const checkUserCanCancelReservation = (fechaCalendario, horario, cliente, time) => {

    const dayTimeReservation = fechaCalendario?.horarios.find(hor => hor?.id === horario?.id)
    const reservacion = dayTimeReservation?.lugares.find(lugar => lugar?.idCliente === cliente?.id)
    let canCancel = false
    if(reservacion){

        const currentDay = newDateZoneSeconds(time?.time?.seconds)
        // const horarioReservacion = new Date(dayTimeReservation?.id * 1000); // Epoch
        const horarioReservacion = newDateZoneSeconds(dayTimeReservation?.id); // Epoch
        
        currentDay.setHours(currentDay.getHours() + 12)
        
        if(currentDay > horarioReservacion){
            canCancel = false
        }else{
            canCancel = true
        }
    }

    return canCancel

}

export const checkUserCanSetListaEspera = (fechaCalendario, horario, cliente) => {

    let canSetListaEspera= false

    const dayTimeReservation = fechaCalendario?.horarios.find(hor => hor?.id === horario?.id)

    const currentDay = newDateZone()
    // const horarioReservacion = new Date(dayTimeReservation?.id * 1000); // Epoch
    const horarioReservacion = newDateZoneSeconds(dayTimeReservation?.id); // Epoch

    currentDay.setHours(currentDay.getHours() + 12)

    if(currentDay > horarioReservacion){
        canSetListaEspera= false
    }else{
        canSetListaEspera= true
    }

    return canSetListaEspera

}


export const newDateZone = () => {

    const dateZone = moment().tz("America/Mazatlan")
    const now = new Date(dateZone.year(),dateZone.month(),dateZone.date(), dateZone.hours(), dateZone.minutes(), dateZone.seconds(), 0)

    return now

}

export const newDateZoneZeroHours = () => {

    const dateZone = moment().tz("America/Mazatlan")
    const now = new Date(dateZone.year(),dateZone.month(),dateZone.date(), dateZone.hours(), dateZone.minutes(), dateZone.seconds(), 0)
    now.setHours(0)
    now.setMinutes(0)
    return now

}

export const newDateZoneSeconds = (seconds) => {

    const dateZone = moment(seconds * 1000).tz("America/Mazatlan")
    const now = new Date(dateZone.year(),dateZone.month(),dateZone.date(), dateZone.hours(), dateZone.minutes(), dateZone.seconds(), 0)

    return now

}

export const orderByName = ( a, b ) => {
    if ( a.nombre < b.nombre ){
      return -1;
    }
    if ( a.nombre > b.nombre ){
      return 1;
    }
    return 0;
}

export const formatMoney = (number) => {
    const formatter = new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 2
    });

    return formatter.format(number);
}

export const ZENSE_UID = 'GQmvrpgmcDfsz3s1iuhapiUhOtx1'
export const ZENSEADMIN_UID = 'TKcpqBFasuZhE7zcOQM4iDAOZXf2'