import React, { useEffect } from 'react'
import { Switch, Route} from 'react-router-dom'
import { loadReCaptcha } from 'react-recaptcha-v3'

// import Login from './componentes/auth/Login'
// import LocalizarComercios from './componentes/LocalizarComercios'
// import ProtectedRouteLocalizador from './componentes/auth/ProtectedRouteLocalizador'
// import ProtectedRouteLogout from './componentes/auth/ProtectedRouteLogout'

import Login from './componentes/auth/Login'
import Profile from './componentes/Profile'
import ProtectedRouteUser from './componentes/auth/ProtectedRouteUser'
import ProtectedRouteLogout from './componentes/auth/ProtectedRouteLogout'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Navbar from './componentes/layout/navegacion/Navbar'
import { createTheme, ThemeProvider as MuiThemeProvider, darken  } from '@material-ui/core/styles'
import styled, { ThemeProvider } from 'styled-components';
import './index.css'
import { Grid } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
let margin = ''

for (let index = 1; index <= 10; index++) {

  margin = margin + `.margin-${index} {margin: ${index*5}px;}`
  margin = margin + `.padding-${index} {padding: ${index*5}px;}`
  margin = margin + `.mt-${index} {margin-top: ${index*5}px;}`
  margin = margin + `.mb-${index} {margin-bottom: ${index*5}px;}`
  margin = margin + `.ml-${index} {margin-left: ${index*5}px;}`
  margin = margin + `.mr-${index} {margin-right: ${index*5}px;}`
  margin = margin + `.pt-${index} {padding-top: ${index*5}px;}`
  margin = margin + `.pb-${index} {padding-bottom: ${index*5}px;}`
  margin = margin + `.pl-${index} {padding-left: ${index*5}px;}`
  margin = margin + `.pr-${index} {padding-right: ${index*5}px;}`
  margin = margin + `.mv-${index} {margin-top: ${index*5}px; margin-bottom: ${index*5}px;}`
  margin = margin + `.mh-${index} {margin-left: ${index*5}px; margin-right: ${index*5}px;}`
  
}

console.log('marginVV', margin)

const ContainerApp = styled(({ ...props }) => <Grid {...props} />)`
${({ theme }) => `

    ${margin}

    .mt-50 {
      margin-top: 10px;
    }
`}
`


//MUI colores para el theme
const theme = createTheme({
  palette: {
    primary: {
      // light: '#515b5f',
      // main: '#263238',
      main: green[600],
      // dark: '#1a2327',
      contrastText: '#fff',
    },
    secondary: {
      // light: '#33c9dc',
      // main: '#00BCD4',
      main: red[800],
      // dark: '#008394',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: '"Roboto Condensed", sans-serif',
    subtitle1: {
      fontSize: 11,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 10,
      fontWeight: 400,
    },
    body1: {
      fontSize: 13,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    h6: {
      fontSize: 15,
      fontWeight: 400,
    },
    h5: {
      fontSize: 17,
      fontWeight: 400,
    },
    h4: {
      fontSize: 19,
      fontWeight: 400,
    },
    h3: {
      fontSize: 21,
      fontWeight: 400,
    },
    h2: {
      fontSize: 23,
      fontWeight: 400,
    },
    h1: {
      fontSize: 25,
      fontWeight: 400,
    },
  },
});

function App() {

  useEffect(() => {
    
    // Actualiza el título del documento usando la API del navegador
    // loadReCaptcha('6Ld7gsQUAAAAAMIgSEWkhT-Whtrx9OxVu3Cnsurm')
  },[]);

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
          <Navbar />                
            <Switch>
              {/* <Route exact path="/comercios/localizar" component={LocalizarComercios} /> */}
              <ContainerApp>
                <ProtectedRouteLogout exact path='/register' route="register" component={Login} />              
                <ProtectedRouteUser exact path='/profile' component={Profile} />            
                <ProtectedRouteLogout exact path='/' route="login" component={Login} />              
              </ContainerApp>
            </Switch>   
      </ThemeProvider>
      </MuiPickersUtilsProvider>      
      </MuiThemeProvider>
    </div>
  )
}

export {App, ContainerApp};
