import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { getFecha } from '../../../../allFunctions'
import { ComentarioLeido } from '../../../../store/actions/ordenPedidosActions'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button'
import Rating from '@material-ui/lab/Rating'
import CircularProgress from '@material-ui/core/CircularProgress'


import { withStyles } from '@material-ui/styles'

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})

const styles = theme => ({
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
    stars: {
        // display: 'flex',
        // flexDirection: 'column',
        '& > * + *': {
            marginTop: theme.spacing(4),
        },
    },

})

const ComentariosNuevos = (props) => {

    const [comentario, setComentario] = useState(null)
    const [loading, setLoading] = useState(false)


    useEffect(() => {

        let coment = null
        props.FSnuevosComentarios && props.FSnuevosComentarios.map(comentario => {
            coment = comentario
        })

        setComentario(coment)
    }, [props.FSnuevosComentarios])

    const handleComentarioLeido = async () => {
        setLoading(true)
        await props.ComentarioLeido(comentario)
        setLoading(false)
    }

    //*render

    if(!comentario) return null
    const { classes, openComentarios, setOpenComentarios } = props
    
    return (
        <Fragment>
            <Dialog 
            open={openComentarios} 
            maxWidth={'xs'} 
            fullWidth
            onClose={() => setOpenComentarios(false)}
            PaperProps={{
                style: {
                  backgroundColor: '#263238',
                  boxShadow: "none",
                  color: '#fff'
                }
            }}
            >

                <Box>
                    <Box p={1} flexGrow={1} align={'center'} className={classes.title} >
                        <Typography variant="h5" justify="center" >
                            COMENTARIOS
                        </Typography>
                    </Box>

                    <Box>
                        <Card variant='outlined'>
                            <Box minHeight={100}>
                                <Box px={1}>
                                    <Typography variant='h6' align='right'>
                                    {getFecha(comentario.createdAt.seconds, 1)}
                                    </Typography>
                                </Box>

                                <Box mt={1} align="center" className={classes.stars}>
                                    <Rating 
                                    name="size-large" 
                                    size="large" 
                                    value={parseInt(comentario.rating)}
                                    readOnly
                                    />
                                </Box>

                                <Box my={1} px={1} >
                                    <Typography variant="h5" align='justify' >
                                    {comentario.comentario}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box p={1} align='right'>
                                <Button variant='contained' color='secondary' disableElevation onClick={handleComentarioLeido}>
                                {loading ? <CircularProgress size={23}/> : 'Leido'}
                                </Button>
                            </Box>
                        </Card>
                    </Box>
                </Box>

            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        FSnuevosComentarios: state.storageDataReducer.FSnuevosComentarios,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         ComentarioLeido: (datos) => dispatch(ComentarioLeido(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ComentariosNuevos))

ComentariosNuevos.propTypes = {
    
}