import { 
    MOSTRAR_GUARDARCATMENU_EXITO,
    MOSTRAR_GUARDARAINGREDIENTES_EXITO
} from '../actionTypes'


const initState = {
    mostrarGuardarCatMenu: false
}

const categoriaMenuReducer = (state = initState, action) => {
    switch (action.type) {
        case MOSTRAR_GUARDARCATMENU_EXITO:
            return {
                ...state,
                mostrarGuardarCatMenu: action.payload
            }
        case MOSTRAR_GUARDARAINGREDIENTES_EXITO:
            return {
                ...state,
                mostrarGuardarAdicion: action.payload
            }
        default:
        return state
    }
}

export default categoriaMenuReducer