import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import MenuOrden from './MenuOrden'
import { getFecha, telFormat } from '../../allFunctions'
//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})

const OrdenPedido = (props) => {

    const [Data, setData] = useState()

    useEffect(() => {

    }, [])

    //*render
    if(!props.ordenPedidoData) return null
    const { ordenPedidoData, handleActualizarPaymentAtendido } = props
    console.log('ordenPedidoData',ordenPedidoData)
    
    let count = 0
    let total = 0
    return (
        <Fragment>
            <Box>
            <Card variant='outlined'>

                <Box minHeight={100}>
                    <Box textAlign='center' style={{backgroundColor: '#000'}}>
                        <Typography style={{color: '#fff', fontSize: '16px'}}>
                            ID: <span style={{color: '#00BCD4'}}>{ordenPedidoData.id_orden.toUpperCase().slice(7, 11)}</span>
                        </Typography>
                    </Box>

                    {ordenPedidoData.productos.map(producto => {
                            
                        count = count + 1
                        let totalProducto = parseFloat(producto.precio) * parseFloat(producto.cantidad)
                        // total = parseFloat(total) + (parseFloat(producto.cantidad) * parseFloat(producto.precioMenu))
    
                        producto.adiciones && producto.adiciones.map(adicion => {
    
                            adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {
                                // console.log('costo', ingrediente.costo)
                                // total = parseFloat(total) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                                totalProducto = parseFloat(totalProducto) + (parseFloat(ingrediente.costo) * parseFloat(producto.cantidad))
                                return
                            })
                            return
                        })

                        total = parseFloat(total) + parseFloat(totalProducto)
                        
                        return (
                            <MenuOrden
                                key={producto.id_producto}
                                producto={producto}
                                total={totalProducto}
                                ultimo={count === ordenPedidoData.productos.length ? true : false}
                                // handleEliminarProducto={handleEliminarProducto}
                            />
                        )
                    })}
                    <Box px={1} align='right'>
                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                        Total: $ {parseFloat(total).toFixed(2)}
                        </Typography>
                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                        comision Envio: $ -15.00
                        </Typography>
                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                        El repartidor pagara: $ {parseFloat(total - 15).toFixed(2) }
                        </Typography>
                    </Box>

                    {/* <Box m={2} px={1} align='center'>
                        <Typography style={{fontSize: '16px'}}>
                            {ordenPedidoData.nombre}
                        </Typography>
                        <Typography style={{fontSize: '16px'}}>
                            {ordenPedidoData.domicilio}
                        </Typography>
                        <Typography style={{fontSize: '16px'}}>
                            {ordenPedidoData.referencia}
                        </Typography>
                        <Typography style={{fontSize: '16px'}}>
                            {telFormat(ordenPedidoData.telefono)}
                        </Typography>
                    </Box> */}
                </Box>

                <Box>
                    <Button variant="contained" color="secondary" fullWidth onClick={() => handleActualizarPaymentAtendido(ordenPedidoData.id)}>
                        ATENDIDO
                    </Button>
                </Box>
            </Card>
            </Box>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrdenPedido))

OrdenPedido.propTypes = {
    
}