import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES


//*MATERIAL UI
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from "@material-ui/core/Typography"
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})

const MenusOrden = (props) => {
   
    useEffect(() => {
        
    }, [])


    //*render
    const { classes, menu } = props

    const total = parseFloat(menu.cantidad) * parseFloat(menu.precio)
    return (

        <Box>
            <Box px={1}>
                <Divider />
            </Box>
            <Box py={1} display="flex" className={classes.comercio}>
                <Box  align={'left'} flexGrow={1} px={1}>
                    <Typography variant='h5'>
                    ( {menu.cantidad} ) - {menu.nombreMenu}
                    </Typography>
                </Box>   
                <Box align={'right'} px={1}>
                    <Typography variant="h5">
                        $ {total.toFixed(2)}
                    </Typography>
                </Box>
            </Box>

            <Box>

            <Box px={3}>
                {menu.adiciones && menu.adiciones.map(adicion => {
                    return(
                        <Box key={adicion.id_adicion}>
                            <Typography variant='h6'><b>{adicion.titulo}</b></Typography>

                            {adicion.menuAdiciones.map(ingrediente => {
                                return(
                                    <Box key={ingrediente.id_ingrediente}>
                                        <Typography variant='h6'>- {ingrediente.nombre}</Typography>
                                    </Box>
                                )
                            })}

                        </Box>
                    )
                })}

                <Box>
                    <Typography variant="h6" color="error">
                    Comentario: {menu.comentario}
                    </Typography>
                </Box>
            </Box>
               
            </Box>
        </Box>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenusOrden))

MenusOrden.propTypes = {
    
}