import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import ProfileForm from './profile/ProfileForm'
import MenuComercio from './menuComercio/MenuComercio'
import NotificacionButton from './profile/configuraciones/notificaciones/NotificacionButton'
import Ordenes from './ordenes/Ordenes'
import OrdenesEnviadas from './ordenesEnviadas/OrdenesEnviadas'
import NoificacionAlerta from './profile/configuraciones/notificacionesAlerta/NoificacionAlerta'
import Message from './layout/Message'
import FullScreenLoading from './layout/FullScreenLoading'
import SnackAlert from './layout/SnackAlert'

import { ResetStateMessage } from '../store/actions/messageActions'
import { Box } from '@material-ui/core'

const Profile = (props) => {
     
   useEffect(() => {
    
   }, [])

   const init = () => {


    
   }

    const handleCloseMsg = () => {
        props.ResetStateMessage()
    }

    //*render
  
    // if(!props.storageDataReducer.FScomercio) return null
    const { navBarReducer, messageReducer, storageDataReducer } = props
    return (

        <Fragment>
            <div id="appLocalizador">
                <div id="perfil" style={{display: navBarReducer.mostrarPerfil ? '' : 'none'}}>
                    <ProfileForm />
                </div> 
                <div id="menu" style={{display: navBarReducer.mostrarMenu ? '' : 'none'}}>
                    <MenuComercio /> 
                </div>                    
                <div id="pedidos" style={{display: navBarReducer.mostrarOrdenes ? '' : 'none'}}>
                    <Ordenes />
                </div>
                <div id="envios" style={{display: navBarReducer.mostrarEnvios ? '' : 'none'}}>
                    <OrdenesEnviadas />
                </div>
            </div>

            <NoificacionAlerta />

            <Message
                info={messageReducer.info}
                warning={messageReducer.warning}
                error={messageReducer.error}
                msg={messageReducer.msg}
                handleCloseMsg={handleCloseMsg}
            />

            <NotificacionButton />
            
            <FullScreenLoading 
            cargando={messageReducer.cargando}
            />

            {/* {storageDataReducer.FScomercio.abierto ? 
            null
            :
            <SnackAlert message='Comercio Cerrado' variant='error'></SnackAlert>
            } */}
            
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return{        
        // abierto: state.firebase.profile.abierto,
        storageDataReducer: state.storageDataReducer,
        navBarReducer: state.navBarReducer,
        messageReducer: state.messageReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        ResetStateMessage: () => dispatch(ResetStateMessage())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Profile)

Profile.propTypes = {
    // conexionReducer: PropTypes.object.isRequired,
    // MostrarConexionError: PropTypes.func.isRequired
}