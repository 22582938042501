import md5 from 'md5'
import uid from 'uid'
import {     
    ACTIVAR_SOUNDALERT_EXITO,
    STORAGE_SOUNDSELECTED_EXITO
} from '../actionTypes'


// export const AbrirComercio = (datos) => {
//     return async (dispatch, getState, {getFirebase, getFirestore}) => {

//         const resultado = {
//             exito: false,
//             option: '',
//             message: ''
//         }

//         const firestore = getFirestore()
//         const userData = {
//             id: getState().firebase.auth.uid,
//             name: getState().firebase.auth.displayName,
//         }

//         const usuarioLogin = getState().perfilComercioReducer.usuarioLogin
//         if(!usuarioLogin){
//            resultado.option = 'preve'
//            resultado.message = 'Debe iniciar una sesion de usuario'
//            return resultado
//         }

//         const batch = firestore.batch()

//         const comerciosFoodRef = firestore.collection('comerciosFood').doc(userData.id)
//         batch.update(comerciosFoodRef, {
//             abierto: datos.abrir,
//            editedBy: userData
//         })

//         if(!datos.abrir){

//             const usuarios = getState().storageDataReducer.FSusuarios

//             usuarios.map(user => {
//                 user.login = false                
//             })

//             const userDataRef = firestore.collection('user_comerciosFood').doc(userData.id).collection('userData').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2')
//             batch.update(userDataRef, {
//                 usuarios: usuarios,
//                 editedBy: userData
//             })
//         }

//         await batch.commit()
//         .then(() => {
//             resultado.exito = true
//             resultado.option = 'reset'
//             return resultado
//         })
//         .catch(error => {
//             console.log(error)
//             resultado.option = 'error'
//             resultado.message = 'Se produjo un error'
//         })

//         return resultado
//     }
// }

export const AbrirComercio = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

        const comerciosFoodIDRef = firestore.collection('comerciosFood_id').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2')
        const comerciosFoodRef = firestore.collection('comerciosFood').doc(userData.id)
        const categoriasMenuRef = firestore.collection('comerciosFood').doc(userData.id).collection('categoriasMenu').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2')

        // let isReachable = false

        // await NetInfo.fetch().then(state => {
        //     isReachable = state.isInternetReachable
        // })

        // if(!isReachable){
        //     resultado.exito = false
        //     resultado.option = 'reset'
        //     AlertMessage('Error de conexión', 'Se perdio la conexión a internet')
        //     return resultado
        // }

        await firestore.runTransaction(async transaction => {

            const comerciosFoodID = await transaction.get(comerciosFoodIDRef)
            const comercioFood = await transaction.get(comerciosFoodRef)

            if(comerciosFoodID.exists){
                console.log('super entro');
            }
            if(comerciosFoodID && comerciosFoodID.exists && comercioFood && comercioFood.exists){

                const comerciosID = comerciosFoodID.data().comercios

                comerciosID.map(comercio => {
                    if(comercio.id_comercio === userData.id){

                        comercio.abierto = datos.abrir
                    }
                    return true
                })

                const batch = firestore.batch()

                batch.update(comerciosFoodIDRef, {
                    comercios: comerciosID,
                })

                batch.update(categoriasMenuRef, {
                    abierto: datos.abrir,
                })
                
                batch.update(comerciosFoodRef, {
                    abierto: datos.abrir,
                    editedBy: userData
                })

                await batch.commit()
                .then(() => {
                    resultado.exito = true
                    resultado.option = 'reset'
                    return resultado
                })
                .catch(error => {
                    console.log('error', error)
                    resultado.option = 'error'
                    resultado.message = 'Se produjo un error'
                })

            }

            return resultado
        })
        .then(result => {
            // console.log('Transaction success!');
            resultado.exito = true
            resultado.option = 'reset'
            return resultado

        }).catch(err => {
            // console.log('Transaction failure:', err);
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        });
        
        return resultado
    }
}

export const ActivarServicioDomGuuwii = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

       await firestore.collection('user_comerciosFood').doc(userData.id).update({
           servicioGuuwii: datos.activar,
           editedBy: userData
       })
       .then(() => {
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
       })
       .catch(error => {
           console.log(error)
           resultado.option = 'error'
           resultado.message = 'Se produjo un error'
       })

        return resultado
    }
}

export const VerificarCuentaAdmin = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const comerPassword = getState().storageDataReducer.FScomercioPassword.password
        if(comerPassword !==  md5(datos.password.trim())){
            resultado.option = 'error'
            resultado.message = 'No Autorizado'
            return resultado
        }


        resultado.exito = true
        resultado.option = 'reset'
        return resultado

    }
}

export const CrearCuentaUsuario = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

        if(datos.usuario.length < 6){
            resultado.option = 'preve'
            resultado.message = 'El usuario debe contener al menos 6 caracteres'
            return resultado
        }else if(datos.password.length < 6){
            resultado.option = 'preve'
            resultado.message = 'La contraseña debe contener al menos 6 caracteres'
            return resultado
        }

        const usuario = {
            id_usuario: uid(),
            usuario: datos.usuario.trim(),
            password: md5(datos.password.trim()),
            login: false
        }

       await firestore.collection('user_comerciosFood').doc(userData.id).collection('userData').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
           usuarios: firestore.FieldValue.arrayUnion(usuario),
           editedBy: userData
       })
       .then(() => {
            resultado.exito = true
            resultado.option = 'exito'
           resultado.message = 'Cuenta creada con exito'
            return resultado
       })
       .catch(error => {
           console.log(error)
           resultado.option = 'error'
           resultado.message = 'Se produjo un error'
       })

        return resultado
    }
}

export const VerificarUsuario = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

        const usuarios = getState().storageDataReducer.FSusuarios

        let encontrado = false
        usuarios.map(user => {
            if(user.id_usuario === datos.id_usuario && user.usuario === datos.usuario && user.password === md5(datos.password.trim())){
                encontrado = true
            }
        })

        if(encontrado){

            usuarios.map(user => {
                if(user.id_usuario === datos.id_usuario ){    
                    user.login = true
                }else{
                    user.login = false    
                }
            })

            await firestore.collection('user_comerciosFood').doc(userData.id).collection('userData').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
                usuarios: usuarios,
                editedBy: userData
            })
            .then(() => {
                 resultado.exito = true
                 resultado.option = 'reset'
                 return resultado
            })
            .catch(error => {
                console.log(error)
                resultado.option = 'error'
                resultado.message = 'Se produjo un error'
            })


        }else{
            resultado.option = 'error'
            resultado.message = 'No autorizado'
        }

        return resultado
    }
}

export const EliminarUsuario = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

        const usuarios = getState().storageDataReducer.FSusuarios.filter(user => user.id_usuario !== datos.id_usuario)

        await firestore.collection('user_comerciosFood').doc(userData.id).collection('userData').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
            usuarios: usuarios,
            editedBy: userData
        })
        .then(() => {
                resultado.exito = true
                resultado.option = 'reset'
                return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })


        return resultado
    }
}

export const EnviarSoporteTicket = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const profile = getState().firebase.profile
        const perfilComercioReducer = getState().perfilComercioReducer
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

        if(!perfilComercioReducer.usuarioLogin){
            resultado.option = 'preve'
            resultado.message = 'Necesita iniciar sesion de usuario'
            return resultado
        }

        const ticket = {
            atencionRating: 0,
            atendido: false,
            atendidoAt: new Date(),
            atendio: '',
            cancelado: false,
            canceladoAt: new Date(),
            createdAt: new Date(),
            id_emisor: userData.id,
            nivelSoporte: datos.nivelSoporte,
            nombreComercio: profile.nombreComercio,
            nombreEmisor: perfilComercioReducer.nombreUsuario,
            nombreProblema: datos.nombreProblema,
            perfil: profile.perfil,
            orden: '',
            telefonoComercio: profile.telefonoComercio,
        }

        // console.log(ticket)
        // const usuarios = getState().storageDataReducer.FSusuarios.filter(user => user.id_usuario !== datos.id_usuario)

        await firestore.collection('ticketSoporte').add(ticket)
        .then(() => {
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado
    }
}

export const CancelarTicketSoporte = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

        const allTickets = JSON.parse(JSON.stringify(getState().storageDataReducer.FSallTicketSoporte)) 
        
        datos.cancelado = true
        datos.canceladoAt = new Date()
        allTickets.push(datos)

        const batch = firestore.batch()

        const ticketSoporteRef = firestore.collection('ticketSoporte').doc(datos.id)
        batch.update(ticketSoporteRef, {
            cancelado: true,
            canceladoAt: new Date(),
        })

        const userDataRef = firestore.collection('user_comerciosFood').doc(userData.id).collection('userData').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2')
        batch.update(userDataRef, {
            ticketSoporte: allTickets,
            editedBy: userData
        })

        await batch.commit()
        .then(() => {

            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado
    }
}

export const GuardarAllTicketsSoporte = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

        return firestore.collection('user_comerciosFood').doc(userData.id).collection('userData').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
            ticketSoporte: datos,
            editedBy: userData
        })
        .then(() => {
            console.log('exito')
            return true
        })
        .catch(error => {
            console.log(error)
        })

    }
}


export const GuardarComerPassword = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
            fecha: new Date()
        }

        if(datos.length < 6){
            resultado.option = 'preve'
            resultado.message = 'La contraseña debe contener al menos 6 caracteres'
            return resultado
        }

        console.log(datos);

        await firestore.collection('user_comerciosFood').doc(userData.id).collection('userData').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
            comercioPassword: {openPassword: false, password: md5(datos.trim())},
            editedBy: userData
        })
        .then(() => {
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })
 
         return resultado
       
    }
}

export const GuardarHorarios = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const horarios = datos

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

       await firestore.collection('comerciosFood').doc(userData.id).update({
           horarios: horarios,
           editedBy: userData
       })
       .then(() => {
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
       })
       .catch(error => {
           console.log(error)
           resultado.option = 'error'
           resultado.message = 'Se produjo un error'
       })

        return resultado
    }
}

export const SetSoundSelected = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({ type: STORAGE_SOUNDSELECTED_EXITO, payload: datos })


    }
}

export const ActivarSoundAlert = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        // console.log('sound!!', datos.playSound);
        dispatch({ type: ACTIVAR_SOUNDALERT_EXITO, payload: datos.playSound })

    }
}

export const GuardarSonido = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
            fecha: new Date()
        }

        if(typeof(datos.sonido) !== 'string' || typeof(datos.repetir) !== 'boolean' ){
            resultado.option = 'preve'
            resultado.message = 'La contraseña debe contener al menos 6 caracteres'
            return resultado
        }

        await firestore.collection('user_comerciosFood').doc(userData.id).collection('userData').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
            soundSelected: datos,
            editedBy: userData
        })
        .then(() => {
            resultado.exito = true
            resultado.option = 'exito'
            resultado.message = 'Cambios guardados con exito'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })
 
         return resultado
       
    }
}
