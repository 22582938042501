import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Box, Divider, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import { getFecha, newDateZone } from '../../allFunctions'
import { Fragment } from 'react';
const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DiasSelect = (props) => {
  const classes = useStyles();
  const [selectedDay, setSelectedDay] = useState({id: 0, dia: 'Domingo', horarios: []});
  const [days, setDays] = useState([]);


  useEffect(() => {

    if(props.FSconfig && props.FSconfig?.dias){
      setDays(props.FSconfig?.dias)
      setSelectedDay(props.FSconfig?.dias[0])
    }
  
    return () => {

    }
  }, [props.FSconfig])
  

  const handleChange = (event) => {
    const value = event.target.value
    const dayData = days.filter(day => day?.id === value)[0]
    setSelectedDay(dayData)
  };



  console.log('selectedDay', selectedDay)

  return (
    <Box m={1}>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedDay?.id}
          onChange={handleChange}
          label="Age"
          fullWidth
        >
          {days.map(day => {
            return (
              <MenuItem key={day?.id} value={day?.id}>{day?.dia}</MenuItem>
            )
          })}
        </Select>
      </FormControl>

      <Box>
        <List>
          {selectedDay?.horarios.map(horario => {

            const dataHorario = horario.split(':')
            const dayNow = newDateZone()
            dayNow.setHours(parseInt(dataHorario[0]))
            dayNow.setMinutes(parseInt(dataHorario[1]))

            const seconds = dayNow.getTime() / 1000;

            return (
              <Fragment key={horario}>
                <ListItem button 
                
                // onClick={() => handleOpenConfig(horario)}
                >
                    <ListItemText primary={
                        <Typography key={horario}>
                        {getFecha(seconds, 4)}
                        </Typography>                          
                    } />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Fragment>
            )
          })}
          </List>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return{
      auth: state.firebase.auth, 
      profile: state.firebase.profile, 
      FSconfig: state.storageDataReducer.FSconfig,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      // UpdateCalendarioFecha: (datos) => dispatch(UpdateCalendarioFecha(datos)),
     
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiasSelect)
