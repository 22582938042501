import { ENVIAR_MESSAGE_INICIO,
    ENVIAR_MESSAGE_PREVE,
    ENVIAR_MESSAGE_EXITO,
    ENVIAR_MESSAGE_ERROR,
    RESET_STATE_ENVIARMESSAGE } from '../actionTypes'


const initState = {
    cargando: false,
    info: null,
    warning: null,
    error: null,
    msg: ''
}

const messageReducer = (state = initState, action) => {
    switch (action.type) {
        case ENVIAR_MESSAGE_INICIO:
            return {
                ...state,
                cargando: true,
                info: null,
                warning: null,
                error: null,
                msg: '',
            }
        case ENVIAR_MESSAGE_PREVE:
            return {
                ...state,
                cargando: false,
                info: null,
                warning: true,
                error: null,
                msg: action.payload,
            }
        case ENVIAR_MESSAGE_EXITO:
            return {
                ...state,
                cargando: false,
                info: true,
                warning: null,
                error: null,
                msg: action.payload
            }
        case ENVIAR_MESSAGE_ERROR:
            return {
                ...state,
                cargando: false,
                info: null,
                warning: null,
                error: true,
                msg: action.payload,
            }
        case RESET_STATE_ENVIARMESSAGE:
            return {
                ...state,
                cargando: false,
                info: null,
                warning: null,
                error: null,
            }
        default:
        return state
    }
}

export default messageReducer