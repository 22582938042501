import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { getFecha, ZENSEADMIN_UID, ZENSE_UID } from '../../allFunctions'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Card from '@material-ui/core/Card'
import LugaresDrawer from './LugaresDrawer'
import { Container } from '@material-ui/core';
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion"
import { ContainerApp } from '../../App'
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import { yellow, green, grey, red } from '@material-ui/core/colors'
import Swal from 'sweetalert2';
import { SetDisabledHorarioByAdmin, SetEnabledHorarioByAdmin } from '../../store/actions/ClientesAction';
import { SendMenssageAction } from '../../store/actions/messageActions';
import NuevoHorarioDrawer from './NuevoHorarioDrawer';

const CalendarioHorarioContainer = styled(({...props}) => <Box {...props} />)`    

${({ theme }) => `

.dialog-container {
  z-index: 1 !important;
}

.horarios-container {
  width: 100%;
}

.dialog-title-container {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${yellow["A400"]};
  border-radius: 0px 00px 8px 8px;
}

.calendar-card-container {
  border-radius: 15px 15px 10px 10px;
}

.motion-horario-container {
  width: calc(100% / 3 - 20px);
  height: 150px;
  border: none;
  cursor: pointer;
  margin: 10px;
  border-radius: 15px 15px 10px 10px;
  padding: 0px;
  ${theme.breakpoints.down('sm')} {
    height: 150px;
  }
}

.admin-container-height {
  height: 110px;
}


.calendar-nuevohorario {
  background-color: ${green["300"]};
  color: ${grey[900]};
  font-size: 18px;
  ${theme.breakpoints.down('sm')} {
    font-size: 11px;
  }

}

.calendar-disponibilidad {
  background-color: ${yellow["A700"]};
  color: ${grey[900]};
  font-size: 18px;
  ${theme.breakpoints.down('sm')} {
    font-size: 11px;
  }

}

.calendar-no-disponibilidad {
  background-color: ${grey[600]};
  color: #fff;
  font-size: 18px;
  ${theme.breakpoints.down('sm')} {
    font-size: 11px;
  }

}

.calendar-disponibilidad-num {
  background-color: ${yellow["A700"]};
  color: ${grey[900]};
  font-size: 20px;
  font-weight: bold;
  ${theme.breakpoints.down('sm')} {
    font-size: 13px;
  }

}

.calendar-no-disponibilidad-num {
  background-color: ${grey[600]};
  color: ${grey[600]};
  font-size: 20px;
  font-weight: bold;
  ${theme.breakpoints.down('sm')} {
    font-size: 13px;
  }

}

.calendar-horario-container {
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.breakpoints.down('sm')} {
    height: 40px;
  }
}

.calendar-horario {
  font-size: 22px;
  font-weight: bold;
  ${theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
}

.dialog-title {
  font-size: 24px;
  ${theme.breakpoints.down('sm')} {
    font-size: 20px;
  }
}

.calendar-horario-selected {
  color: ${green["300"]};
}

.horario-camasdisponibles-container {
  position: relative;
}

.horario-enabledaction-container {
  // position: absolute;
  top: 0;
  right: 5px;
  z-index: 999;
}

.block-icon {
  color: ${red["900"]};
}

.checkcircle-icon {
  color: ${green["600"]};
}

`}
`

const CalendarioHorarios = (props) => {

  const { fechaCalendarioSelected, openDayTimeDialog, handleCloseDayTimeDialog, handleOpenHorario, auth, profile } = props
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openHorarioNuevo, setOpenHorarioNuevo] = useState(false)
  const handleDisabledHorario = async (horarioData) => {

    const ocupados = horarioData?.lugares.filter(lugar => lugar?.ocupado === true)
    // if(ocupados?.length !== 0){

    //   Swal.fire({
    //     icon: 'error',
    //     title: '<strong>Contiene lugares reservados</strong>',
    //     confirmButtonColor: '#3085d6',
    //   })
    //   return
    // }

    props.SendMenssageAction({option: 'inicio'})

    const enabled = await props.SetDisabledHorarioByAdmin({
      idFecha: fechaCalendarioSelected?.id,
      idHorario: horarioData?.id,
    })

    if(enabled?.exito && enabled.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${enabled.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }else {
      Swal.fire({
        icon: 'success',
        title: `<strong>Bloquedo con exito</strong>`,
        showConfirmButton: false,
        timer: 2000
      })
    }


    props.SendMenssageAction(enabled)


  }

  const handleAskDisabledHorario = (horarioData) => {

    Swal.fire({
      title: `¿Desea bloquear este horario? ${getFecha(horarioData?.id, 4)}`,
      confirmButtonText: 'Bloquear',
      confirmButtonColor: '#3085d6',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleDisabledHorario(horarioData)
      } 
    })

  }

  const handleEnabledHorario = async (horarioData) => {

    props.SendMenssageAction({option: 'inicio'})

    const enabled = await props.SetEnabledHorarioByAdmin({
      idFecha: fechaCalendarioSelected?.id,
      idHorario: horarioData?.id,
    })

    if(enabled?.exito && enabled.message === 'success'){
      Swal.fire({
        icon: 'success',
        title: `<strong>Habilitado con exito</strong>`,
        showConfirmButton: false,
        timer: 2000
      })
    }

    props.SendMenssageAction(enabled)


  }

  const handleAskEnableddHorario = (horarioData) => {

    Swal.fire({
      title: `¿Desea habilitar este horario? ${getFecha(horarioData?.id, 4)}`,
      confirmButtonText: 'Habilitar',
      confirmButtonColor: '#3085d6',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleEnabledHorario(horarioData)
      } 
    })

  }

  const handleOpenNuevoHorario = (data) => {
    // console.log('data', data)
    setOpenHorarioNuevo(true)
  }
  
  const handleCloseHorarioNuevo = () => {
    setOpenHorarioNuevo(false)
  }
  if(!fechaCalendarioSelected) return null
  const fecha  = getFecha(fechaCalendarioSelected?.fecha?.seconds, 5)

  const [month, day, dayNum] = fechaCalendarioSelected.fullDate.split("-")
  

  return (
      <Dialog 
        fullScreen
        open={openDayTimeDialog} 
        onClose={handleCloseDayTimeDialog} 
        className='dialog-container'
        >
        <ContainerApp>
        <CalendarioHorarioContainer>
        {isMobile ?
        <Box className='dialog-title-container'>
          <Box ml={2}>
            <IconButton onClick={handleCloseDayTimeDialog}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box ml={2}>
            <Typography className="dialog-title">
              {day} {dayNum} de {month}
            </Typography>
          </Box>
      </Box>
        :
        null
        }

        <Container maxWidth="md">

          {isMobile ?null
          :
          <Box className='dialog-title-container'>

            <Box ml={2}>
              <IconButton onClick={handleCloseDayTimeDialog}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Box>
            <Box ml={2}>
              <Typography className='dialog-title '>
                {fecha}
              </Typography>
            </Box>

          </Box>
          }

          <Box className="horarios-container">
            {fechaCalendarioSelected && fechaCalendarioSelected.horarios.map(horario => {

              // if(!horario?.disponible) return
              const disponbles= horario.lugares.filter(lugar => lugar.ocupado === false)
              
              const horariosSelected = horario.lugares.find(lugar => lugar?.idCliente === auth?.uid)
              let selected = ''
              if(horariosSelected){
                selected = 'calendar-horario-selected'
              }

              return (
                <motion.button
                  key={horario?.id}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className={`motion-horario-container ${profile?.roll === 'cliente' ? '' : 'admin-container-height'}`}
                >
                <Card className="calendar-card-container" variant="outlined">
                  {/* <Box className="horario-camasdisponibles-container" onClick={horario?.disponible ? () => handleOpenHorario(horario) : null} > */}
                  <Box className="horario-camasdisponibles-container" onClick={horario?.disponible || auth?.uid === ZENSEADMIN_UID || auth?.uid === ZENSE_UID  ? () => handleOpenHorario(horario) : null} >
                    <Typography className={horario?.disponible ? 'calendar-disponibilidad': 'calendar-no-disponibilidad'}>
                    {horario?.disponible ? 'disponibles' : 'No disponible'}
                    </Typography>
                    <Typography className={horario?.disponible ? 'calendar-disponibilidad-num' : 'calendar-no-disponibilidad-num'}>
                    {horario?.disponible ? disponbles?.length : '_'}
                    </Typography>
                   
                  </Box>
                  <Box className="calendar-horario-container" onClick={horario?.disponible || auth?.uid === ZENSEADMIN_UID || auth?.uid === ZENSE_UID  ? () => handleOpenHorario(horario) : null}>
                    <Box>
                      <Typography className={`calendar-horario ${selected}`} >
                        {horario?.horarioDesc.toUpperCase()}
                      </Typography>
                    </Box>
                  </Box>

                  {profile?.roll === 'cliente' ?
                  null
                  :
                  <Box>
                    {horario?.disponible ?
                    <IconButton onClick={() => handleAskDisabledHorario(horario)} size="small">
                      <CheckCircleOutlineSharpIcon className="checkcircle-icon" />
                    </IconButton>
                    :
                    <IconButton onClick={() => handleAskEnableddHorario(horario)} size="small">
                      <BlockIcon className="block-icon" />
                    </IconButton>
                    }
                  </Box>
                  }
                  </Card>
                </motion.button>
              )
            })}

            {profile?.roll === 'admin' && (
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className={`motion-horario-container ${profile?.roll === 'cliente' ? '' : 'admin-container-height'}`}
                onClick={() => handleOpenNuevoHorario(fechaCalendarioSelected)}
              >
              <Card className="calendar-card-container" variant="outlined">
                <Box className="horario-camasdisponibles-container">
                  <Typography className={'calendar-nuevohorario'}>
                  &nbsp;
                  </Typography>
                  <Typography className={'calendar-nuevohorario'}>
                  &nbsp;
                  </Typography>
                  
                </Box>
                <Box className="calendar-horario-container">
                  <Box>
                    <Typography className={`calendar-horario`} >
                      Nuevo
                    </Typography>
                  </Box>
                </Box>

                <Box height={35}>
                    <Typography className={`calendar-horario`} >
                      horario
                    </Typography>
                </Box>
                </Card>
              </motion.button>
            )}


          </Box>

        </Container>          
        </CalendarioHorarioContainer>
        </ContainerApp>

        <NuevoHorarioDrawer
          open={openHorarioNuevo}
          handleClose={handleCloseHorarioNuevo}
          fechaCalendarioSelected={fechaCalendarioSelected}

        />
        </Dialog>
  );
}


const mapStateToProps = (state) => {
  return{
      auth: state.firebase.auth, 
      profile: state.firebase.profile, 
      FScalendarioData: state.calendarioReducer.calendarioData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      SetDisabledHorarioByAdmin: (datos) => dispatch(SetDisabledHorarioByAdmin(datos)),
      SetEnabledHorarioByAdmin: (datos) => dispatch(SetEnabledHorarioByAdmin(datos)),
      SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
     
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarioHorarios)