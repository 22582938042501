import React, { Component } from 'react'
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class ProtectedRouteUser extends Component {
    
    render() {
        const {profile, auth, component: Component, ...rest} = this.props
        // console.log('profilexUser', profile)
        // console.log('profilexUser', this.props)

        if(auth.uid){
            if(!profile?.roll) return null
            if(profile?.roll === 'admin' || profile?.roll === 'cliente'){
                return(
                    <Route {...rest} component={Component} />
                )
                
            }else{
                return(
                    <Redirect to='/' />
                )
            }    
           
        }else{ 
                return(           
                    <Redirect to='/' />
                )
        }
       
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(ProtectedRouteUser)

ProtectedRouteUser.propTypes = {   
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
}