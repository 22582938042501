//TYPES LOGIN
export const LOGIN_INICIA = 'LOGIN_INICIA'
export const LOGIN_EXITO = 'LOGIN_EXITO'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const RESET_STATE_LOGIN = 'RESET_STATE_LOGIN'

export const LOGOUT_EXITO = 'LOGOUT_EXITO'



//*NAVBAR

export const MOSTRAR_NAVBARSELECT = 'MOSTRAR_NAVBARSELECT'
export const MOSTRAR_PERFIL_EXITO = 'MOSTRAR_PERFIL_EXITO'
export const MOSTRAR_MENU_EXITO = 'MOSTRAR_MENU_EXITO'
export const MOSTRAR_ORDENES_EXITO = 'MOSTRAR_ORDENES_EXITO'
export const MOSTRAR_ENVIOS_EXITO = 'MOSTRAR_ENVIOS_EXITO'
export const RESET_NAVBARSELECT_EXITO = 'RESET_NAVBARSELECT_EXITO'

//*MESSAGES

export const ENVIAR_MESSAGE_INICIO = 'ENVIAR_MESSAGE_INICIO'
export const ENVIAR_MESSAGE_PREVE = 'ENVIAR_MESSAGE_PREVE'
export const ENVIAR_MESSAGE_EXITO = 'ENVIAR_MESSAGE_EXITO'
export const ENVIAR_MESSAGE_ERROR = 'ENVIAR_MESSAGE_ERROR'
export const RESET_STATE_ENVIARMESSAGE = 'RESET_STATE_ENVIARMESSAGE'

//*CALENDARIO

export const STORAGE_CALENDARIO_EXITO = 'STORAGE_CALENDARIO_EXITO'
export const STORAGE_CONFIG_EXITO = 'STORAGE_CONFIG_EXITO'
export const STORAGE_LUGARESRESERVADOS_EXITO = 'STORAGE_LUGARESRESERVADOS_EXITO'
export const STORAGE_USUARIOSCREDITOS_EXITO = 'STORAGE_USUARIOSCREDITOS_EXITO'
export const STORAGE_USUARIONUMCREDITOS_EXITO = 'STORAGE_USUARIONUMCREDITOS_EXITO'












//*PERFIL COMERCIO

export const ABRIR_COMERCIO_INICIO = 'ABRIR_COMERCIO_INICIO'
export const ABRIR_COMERCIO_EXITO = 'ABRIR_COMERCIO_EXITO'
export const ACTIVAR_SERVICIODOM_INICIO = 'ACTIVAR_SERVICIODOM_INICIO'
export const ACTIVAR_SERVICIODOM_EXITO = 'ACTIVAR_SERVICIODOM_EXITO'
export const ACTIVAR_PAGOTARJETA_INICIO = 'ACTIVAR_PAGOTARJETA_INICIO'
export const ACTIVAR_PAGOTARJETA_EXITO = 'ACTIVAR_PAGOTARJETA_EXITO'
export const ACTIVAR_SOUNDALERT_EXITO = 'ACTIVAR_SOUNDALERT_EXITO'

export const SET_USUARIOLOGIN_EXITO = 'SET_USUARIOLOGIN_EXITO'

export const GUARDAR_NUEVAUBICACION_INICIO = 'GUARDAR_NUEVAUBICACION_INICIO'
export const GUARDAR_NUEVAUBICACION_PREVE = 'GUARDAR_NUEVAUBICACION_PREVE'
export const GUARDAR_NUEVAUBICACION_EXITO = 'GUARDAR_NUEVAUBICACION_EXITO'
export const GUARDAR_NUEVAUBICACION_ERROR = 'GUARDAR_NUEVAUBICACION_ERROR'
export const RESET_STATE_NUEVAUBICACION = 'RESET_STATE_NUEVAUBICACION'


//*MENU COMERCIO

export const HABILITAR_MENU_INICIO = 'HABILITAR_MENU_INICIO'
export const HABILITAR_MENU_PREVE = 'HABILITAR_MENU_PREVE'
export const MOSTRAR_GUARDARCATMENU_EXITO = 'MOSTRAR_GUARDARCATMENU_EXITO'
export const MOSTRAR_GUARDARAINGREDIENTES_EXITO = 'MOSTRAR_GUARDARAINGREDIENTES_EXITO'

//*STORAGE DATA
export const STORAGE_DOMICILIOCOMERCIO_EXITO = 'STORAGE_DOMICILIOCOMERCIO_EXITO'
export const STORAGE_COMERCIO_EXITO = 'STORAGE_COMERCIO_EXITO'
export const STORAGE_CATEGORIASMENU_EXITO = 'STORAGE_CATEGORIASMENU_EXITO'
export const STORAGE_MENUADICIONES_EXITO = 'STORAGE_MENUADICIONES_EXITO'
export const STORAGE_INGREDIENTES_EXITO = 'STORAGE_INGREDIENTES_EXITO'
export const STORAGE_INGREDIENTESDISABLED_EXITO = 'STORAGE_INGREDIENTESDISABLED_EXITO'
export const STORAGE_MENUSID_EXITO = 'STORAGE_MENUSID_EXITO'
export const STORAGE_ORDENESFOOD_EXITO = 'STORAGE_ORDENESFOOD_EXITO'
export const STORAGE_MENUBLOQUEADO_EXITO = 'STORAGE_MENUBLOQUEADO_EXITO'
export const STORAGE_COMISIONFOOD_EXITO = 'STORAGE_COMISIONFOOD_EXITO'
export const STORAGE_REPARTIDORES_EXITO = 'STORAGE_REPARTIDORES_EXITO'
export const STORAGE_USUARIOS_EXITO = 'STORAGE_USUARIOS_EXITO'
export const STORAGE_CATSOPORTE_EXITO = 'STORAGE_CATSOPORTE_EXITO'
export const STORAGE_CATMENURESPUESTAS_EXITO = 'STORAGE_CATMENURESPUESTAS_EXITO'
export const STORAGE_PAYMENTINTENTS_EXITO = 'STORAGE_PAYMENTINTENTS_EXITO'
export const STORAGE_PAGOSHOY_EXITO = 'STORAGE_PAGOSHOY_EXITO'
export const STORAGE_PAGOS_EXITO = 'STORAGE_PAGOS_EXITO'
export const SET_NUMPAYMENTSNUEVOS_EXITO = 'SET_NUMPAYMENTSNUEVOS_EXITO'
export const SET_NUMPAYMENTSATENDIDOS_EXITO = 'SET_NUMPAYMENTSATENDIDOS_EXITO'
export const STORAGE_TICKETSOPORTEIDS_EXITO = 'STORAGE_TICKETSOPORTEIDS_EXITO'
export const STORAGE_ALLTICKETSOPORTE_EXITO = 'STORAGE_ALLTICKETSOPORTE_EXITO'
export const STORAGE_ALLORDENESFOOD_EXITO = 'STORAGE_ALLORDENESFOOD_EXITO'
export const STORAGE_ORDENESNOATENDIDAS_EXITO = 'STORAGE_ORDENESNOATENDIDAS_EXITO'
export const STORAGE_ORDENESREGRESADAS_EXITO = 'STORAGE_ORDENESREGRESADAS_EXITO'
export const STORAGE_ORDENESCANCELADAS_EXITO = 'STORAGE_ORDENESCANCELADAS_EXITO'
export const STORAGE_ORDENESFINALIZADAS_EXITO = 'STORAGE_ORDENESFINALIZADAS_EXITO'
export const STORAGE_COMERCIOPASSWORD_EXITO = 'STORAGE_COMERCIOPASSWORD_EXITO'
export const STORAGE_SOUNDSELECTED_EXITO = 'STORAGE_SOUNDSELECTED_EXITO'
export const STORAGE_APPVERSION_EXITO = 'STORAGE_APPVERSION_EXITO'

export const STORAGE_COMENTARIOS_EXITO = 'STORAGE_COMENTARIOS_EXITO'
export const STORAGE_NUEVOSCOMENTARIOS_EXITO = 'STORAGE_NUEVOSCOMENTARIOS_EXITO'

export const RESET_STATE_STORAGE = 'RESET_STATE_STORAGE'

//*CONFIG
export const GUARDAR_TIPODISPOSITIVO_EXITO = 'GUARDAR_TIPODISPOSITIVO_EXITO'
export const SET_TIEMPOACTUAL_EXITO = 'SET_TIEMPOACTUAL_EXITO'

 //*INSTALL FUNCIONALIDADES
 export const SET_SHOWINSTALLAPP_EXITO = 'SET_SHOWINSTALLAPP_EXITO'
 export const SET_SHOWINSTALLNOTIFICATION_EXITO = 'SET_SHOWINSTALLNOTIFICATION_EXITO'
 export const SHOW_INSTALLEDMESSAGE_EXITO = 'SHOW_INSTALLEDMESSAGE_EXITO'
 
 export const SET_GEOLOCATIONACTIVO_EXITO = 'SET_GEOLOCATIONACTIVO_EXITO'
 export const SET_ONLINE_EXITO = 'SET_ONLINE_EXITO'
 export const SET_APPACTIVA_EXITO = 'SET_APPACTIVA_EXITO'
