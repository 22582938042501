import React, { useState, useEffect, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

//*COMPONENTES
import StorageData from './StorageData'
import { logout } from '../../../store/actions/authActions'
import { MostrarSelectNavbar } from '../../../store/actions/navBarActions'

//*MATERIAL UI
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import StoreIcon from '@material-ui/icons/Store'
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import Badge from '@material-ui/core/Badge'
import CircularProgress from '@material-ui/core/CircularProgress'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import PaymentIcon from '@material-ui/icons/Payment'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import UpdateIcon from '@material-ui/icons/Update'
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { yellow } from '@material-ui/core/colors'
import { newDateZone } from '../../../allFunctions'
import PaymentIntent from '../../ordenesEnviadas/PaymentIntent';

const theme = createTheme({   
    palette: {
        primary: {
        // light: '#827717',
            main: '#5b5310',
            // dark: '#1a2327',
            contrastText: '#fff',
        },     
        secondary: {       
            main: '#9b9245',       
            contrastText: '#fff',
        }
    }  
})

const widthScreen =window.innerWidth
|| document.documentElement.clientWidth
|| document.body.clientWidth

const styles = theme => ({   
    styleNavButton: {
        width: widthScreen - 20,
        backgroundColor: yellow["A400"],        
    }    
})

const NavbarIn = (props) => {

    const { profile, classes, numPaymentsNuevos } = props       
    
    const [value, setValue] = useState('perfil')
    const [numOrdenesConfirmar, setNumOrdenesConfirmar] = useState(0)
    const [numOrdenesProceso, setnumOrdenesProceso] = useState(0)

       
    useEffect(() => {

        setValue(props.navBarValue)

    }, [props.navBarValue])

    const handleChange = (event, newValue) => {
        props.MostrarSelectNavbar(newValue)
        setValue(newValue)
    }
        
    //*render
    // console.log('value', value)
    // console.log('navbarin', profile)
        if(!profile.isLoaded) return null
        if(profile?.roll !== 'admin' && profile?.roll !== 'cliente'){
            // props.logout()
            return null
        }        

    const perfil = value === 'perfil' ? 'primary' : 'secondary'
    const menu = value === 'menu' ? 'primary' : 'secondary'
    const ordenes = value === 'ordenes' ? 'primary' : 'secondary'
    const envios = value === 'envios' ? 'primary' : 'secondary'
    
    // console.log('perfil', perfil)

    if(profile?.roll === 'cliente'){

        return (
            <Fragment>
                <ThemeProvider theme={theme}>
                    <BottomNavigation value={value} onChange={handleChange} className={classes.styleNavButton}>                        
                        <BottomNavigationAction label="Perfil" value="menu" icon={<PersonIcon color={menu} />} />
                        <BottomNavigationAction label="Calendario" value="perfil" icon={<EventIcon color={perfil} />} />
                        <BottomNavigationAction label="Mis clases" value="ordenes" icon={ <DirectionsRunIcon color={ordenes} /> } />
                    </BottomNavigation>                      
                </ThemeProvider>
                <StorageData />
            </Fragment>
        )
    }

    if(profile?.roll === 'admin')
    return (
        <Fragment>
            <ThemeProvider theme={theme}>
                <BottomNavigation value={value} onChange={handleChange} className={classes.styleNavButton}>                        
                    <BottomNavigationAction label="Calendario" value="perfil" icon={<EventIcon color={perfil} />} />
                    <BottomNavigationAction label="Usuarios" value="menu" icon={<PeopleIcon color={menu} />} />
                    {/* <BottomNavigationAction label="Pagos" value="ordenes" icon={
                        <Badge color="error" badgeContent={numPaymentsNuevos} invisible={false}>
                                                                                <PaymentIcon color={ordenes}  />
                                                                            </Badge>
                                                                            } 
                                                                            /> */}
                    <BottomNavigationAction label="Configuraciones" value="envios" icon={ <SettingsIcon color={envios} /> } />
                </BottomNavigation>                      
            </ThemeProvider>
            <StorageData />
        </Fragment>
    )

    return null
}

const mapDispatchToProps = (dispatch) => {
    return{
        MostrarSelectNavbar: (opcion) => dispatch(MostrarSelectNavbar(opcion)),
        logout: () => dispatch(logout())
    }
}

const mapStateToProps = (state) => {
    
    return{
       auth: state.firebase.auth,
       profile: state.firebase.profile,
       navBarValue: state.navBarReducer.navBarValue,
    //    configReducer: state.configReducer,
    //    FSordenesFood: state.storageDataReducer.FSordenesFood,
    //    numPaymentsNuevos: state.storageDataReducer.numPaymentsNuevos,
    //    pedidosReducer: state.pedidosReducer
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),  
    firestoreConnect((props) => {

        // const id_comercio = props.auth.uid
        
        const now = newDateZone()
        now.setHours(0, 0, 0, 0) // +5 hours for Eastern Time
        // console.log('nowXX',now)
        const lastDay = newDateZone()
        // alert(now.toDateString())
        // dateZone.ho

        let masDias = 14
        if(props?.profile?.roll === 'admin'){
            masDias = 14
        } else if(props?.auth?.uid === 'GQmvrpgmcDfsz3s1iuhapiUhOtx1'){
            masDias = 14
        }

        // const masDias = props?.profile?.roll === 'admin' ? 45 : 31
        lastDay.setDate(lastDay.getDate() + masDias)
        // now.setDate(now.getDate() - 11); 
        
        const where = [
            ['fecha', '>=', now],
            ['fecha', '<', lastDay],
        ]

        const consultaBD = {
            collection: `/calendario`,
            where: where,
            storeAs: 'FScalendario'
        }
        
        return[consultaBD]
    }),
    firestoreConnect((props) => {

        // const id_comercio = props.auth.uid

        let usuarios = '/usuariosabc'

        if(props?.profile?.roll === 'admin'){
            usuarios = '/usuarios'
        }

        const where = [
            ['roll', '==', 'cliente'],
        ]

        const consultaBD = {
            collection: `${usuarios}`,
            where: where,
            storeAs: 'FSusuarios'
        }
        
        return[consultaBD]
    }),
    firestoreConnect((props) => {

        const id_cliente = props.auth.uid
        const now = newDateZone()
        const where = [
            ['fechaVigencia', '>', now],
        ]

        const consultaBD = {
            collection: `/usuarios/${id_cliente}/creditos`,
            where: where,
            storeAs: 'FSclienteCreditos'
        }
        
        return[consultaBD]
    }),
    firestoreConnect((props) => {

        let id_cliente = ''
        if(props?.profile?.isLoaded && props?.profile?.roll && props?.profile?.roll === 'cliente'){
            id_cliente = props.auth.uid
        }
        const now = newDateZone()
        const where = [
            ['fecha', '>=', now],
            ['idCliente', '==', id_cliente],
        ]

        const consultaBD = {
            collection: `/listaEspera`,
            where: where,
            storeAs: 'FSlistaEspera'
        }
        
        return[consultaBD]
    }),
    firestoreConnect((props) => {

        let id_cliente = ''
        if(props?.profile?.isLoaded && props?.profile?.roll && props?.profile?.roll === 'cliente'){
            id_cliente = props.auth.uid
        }
        const now = newDateZone()
        const where = [
            ['fecha', '>=', now],
            ['idCliente', '==', id_cliente],
        ]

        const consultaBD = {
            collection: `/lugaresReservados`,
            where: where,
            storeAs: 'FSlugaresReservados'
        }
        
        return[consultaBD]
    }),
    firestoreConnect((props) => {

        let id_cliente = ''
        if(props?.profile?.isLoaded && props?.profile?.roll && props?.profile?.roll === 'cliente'){
            id_cliente = props.auth.uid
        }
        // const now = newDateZone()
        const where = [
            // ['fecha', '>=', now],
            ['id_cliente', '==', id_cliente],
            ['pagado', '==', "succeeded"],
        ]

        const consultaBD = {
            collection: `/paymentIntents`,
            where: where,
            limit: 3,
            orderBy: ['createdAt', 'desc'],
            storeAs: 'FSpaymentIntents'
        }
        
        return[consultaBD]
    }),
    firestoreConnect((props) => {

        // const id_comercio = props.auth.uid

        let paymentIntents = '/paymentIntentsabc'

        if(props?.profile?.roll === 'admin'){
            paymentIntents = '/paymentIntents'
        }

        const now = newDateZone()
        now.setHours(0, 0, 0, 0) // +5 hours for Eastern Time
        // console.log('nowXX',now)
        const initialDate = newDateZone()

        initialDate.setDate(initialDate.getDate() - 10)

        const where = [
            ['pagado', '==', "succeeded"],
            ['createdAt', '>=', initialDate],
            // ['fecha', '<', lastDay],
        ]

        const consultaBD = {
            collection: `${paymentIntents}`,
            where: where,
            orderBy: ['createdAt', 'desc'],
            storeAs: 'FSallPaymentIntents'
        }
        
        return[consultaBD]
    }),
    firestoreConnect((props) => {

        // const id_comercio = props.auth.uid

        const consultaBD = {
            collection: `/config`,
            storeAs: 'FSconfig'
        }
        
        return[consultaBD]
    }),
    // firestoreConnect((props) => {

    //     const id_comercio = props.auth.uid

    //     const consultaBD = {
    //         collection: `/comerciosFood`,
    //         doc: id_comercio,
    //         storeAs: 'FScomercio'
    //     }
        
    //     return[consultaBD]
    // }),
    // firestoreConnect((props) => {

        // const now = new Date()
        // now.setHours(0, 0, 0, 0) // +5 hours for Eastern Time
        // // now.setDate(now.getDate() - 11); 
        // // console.log('now',now)
        
        // const where = [
        //     ['id_comercio', '==', props.auth.uid], 
        //     ['ready', '==', true],
        //     ['createdAt', '>', now],
        // ]

    //     const consultaBD = {
    //         collection: '/ordenPedidosFood',
    //         where: where,
    //         storeAs: 'FSordenPedidosFood'
    //     }
        
    //     return[consultaBD]
    // }),
    // firestoreConnect((props) => {

    //     const where = [
    //         ['comercios_id', 'array-contains', props.auth.uid], 
    //         // ['online', '==', false]
    //     ]

    //     const consultaBD = {
    //         collection: `/repartidores`,
    //         where: where,
    //         storeAs: 'FSrepartidores'
    //     }
        
    //     return[consultaBD]
    // }),
    // firestoreConnect((props) => {

    //     const id_comercio = props.auth.uid

    //     const consultaBD = {
    //         collection: `/user_negociosFood/${id_comercio}/userData`,
    //         doc: '1mp1qc6TYGPiZL78Sgrl1Z2VqmI2',
    //         storeAs: 'FScomercioData'
    //     }
        
    //     return[consultaBD]
    // }),
    // firestoreConnect((props) => {

    //     const id_comercio = props.auth.uid

    //     const consultaBD = {
    //         collection: `/user_comerciosFood/${id_comercio}/ordenesFinalizadas`,
    //         doc: '1mp1qc6TYGPiZL78Sgrl1Z2VqmI2',
    //         storeAs: 'FSordenesFinalizadas'
    //     }
        
    //     return[consultaBD]
    // }),
    // firestoreConnect((props) => {

    //     const id_comercio = props.auth.uid

    //     const consultaBD = {
    //         collection: `/user_comerciosFood/${id_comercio}/notificaciones`,
    //         doc: '1mp1qc6TYGPiZL78Sgrl1Z2VqmI2',
    //         storeAs: 'FScomercioNotificaciones'
    //     }
        
    //     return[consultaBD]
    // }),
    // firestoreConnect((props) => {

    //     const where = [
    //         ['id_emisor', '==', props.auth.uid], 
    //         ['cancelado', '==', false], 
    //         ['atendido', '==', false], 
    //     ]

    //     const consultaBD = {
    //         collection: `/ticketSoporte`,
    //         where: where,
    //         storeAs: 'FSticketSoporte'
    //     }
        
    //     return[consultaBD]
    // }),
    // firestoreConnect((props) => {

    //     const consultaBD = {
    //         collection: `/zSystemConfigComercio`,
    //         doc: '1mp1qc6TYGPiZL78Sgrl1Z2VqmI2',
    //         storeAs: 'FSconfigComercio'
    //     }
        
    //     return[consultaBD]
    // }),

)(withStyles(styles)(withRouter(NavbarIn)))