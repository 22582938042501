import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types'

//*COMPONENTES
import PaymentIntent from './PaymentIntent'
import { ActivarSoundAlert } from '../../store/actions/perfilComercioActions'
import { SetNumPaymentsNuevos } from '../../store/actions/storageDataAction'
import { ActualizarPaymentVisto, ActualizarPaymentAtendido } from '../../store/actions/ordenPedidosActions'
import { SendMenssageAction } from '../../store/actions/messageActions'
import { telFormat, getFecha } from '../../allFunctions'
import Horarios from './Horarios'
import DiasSelect from './DiasSelect'
//*MATERIAL UI
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Box, Container, Card, Divider, Drawer, Button, Dialog, AppBar, Toolbar, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PaymentIcon from '@material-ui/icons/Payment';
import { withStyles } from '@material-ui/styles'
import { logout } from '../../store/actions/authActions';
import ConfigHorarios from './horairos/ConfigHorarios';
import TodayIcon from '@material-ui/icons/Today';
import Swal from 'sweetalert2';
import { CreateAllWeekHorarios, CreateNewAllWeekHorarios, GetLocalTime } from '../../store/actions/ClientesAction';
import styled from 'styled-components';
import HistorialPagosTarjeta from '../profile/HistorialPagosTarjeta';

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });

const ContainerConfig = styled(({ ...props }) => <Grid {...props} />)`
${({ theme }) => `

    margin-top: 60px;
`}
`;

const styles = theme => ({
    styleContenedor: {
        margin: 'auto',
        maxWidth: 720,
        marginTop: 80,
        [theme.breakpoints.down('xs')]: {
            marginTop: 60,
        }      
    },
})

const payment = []

const Ordenes = (props) => {

    const [inicializado, setInicializado] = useState(false)
    const [openPayment, setOpenPayment] = useState(false)
    const [openConfig, setOpenConfig] = useState(false)
    const [openHorarios, setOpenHorarios] = useState(false)
    const [openPagosTarjeta, setOpenPagosTarjeta] = useState(false)
    const [paymentIntentData, setPaymentIntentData] = useState(null)
    const [allpaymentIntents, setAllPaymentIntents] = useState(null)

    useEffect(() => {

        if(!inicializado){
            inicializar()
            return
        }
        
        if(props.FSpaymentIntents){
            const paymentIntents = []
            props.FSpaymentIntents.map(paymentIntent => {

                if(paymentIntent.status < 2){
                    payment.push(paymentIntent.id)
                    paymentIntents.push(paymentIntent)
                }

            })

            setAllPaymentIntents(paymentIntents)
        }

    }, [props.FSpaymentIntents, inicializado])

    const handleOpenConfig = (option) => {

        switch (option) {
            case 'horarios':
                setOpenHorarios(true)
                break;
            case 'pagosTarjeta':
                setOpenPagosTarjeta(true)
                break;
        
            default:
                break;
        }
        
        // setOpenConfig(true)
    }
    const handleCloseConfig = (option) => {

        switch (option) {
            case 'horarios':
                setOpenHorarios(false)
                break;
        
            default:
                break;
        }
    }

    const inicializar = async () => {

        await new Promise(resolve => setTimeout( resolve , 5000))  
        setInicializado(true)
    }

  
    const requestPermission = () => {
        if (!('Notification' in window)) {
          alert('Notification API not supported!');
          return;
        }
        
        Notification.requestPermission(function (result) {
          console.log('result',result)
          
        });
    }

    const nonPersistentNotification = () => {
        if (!('Notification' in window)) {
            alert('Notification API not supported!');
            return;
        }
        
        try {
            var notification = new Notification("Nueva Orden Recibida!");
        } catch (err) {
            alert('Notification API error: ' + err);
        }
    }
      

    const handleOpenPayment = (paymentData) => {

        if(!paymentData.visto){
            props.ActualizarPaymentVisto({id: paymentData.id})
        }
        
        setPaymentIntentData(paymentData)
        setOpenPayment(true)
        
    }

    const handleActualizarPaymentAtendido = async (id) => {

        props.SendMenssageAction({option: 'inicio'})
        const actualizar = await props.ActualizarPaymentAtendido({id: id})

        if(actualizar.exito){
            setOpenPayment(false)
            setPaymentIntentData(null)
        }
        props.SendMenssageAction(actualizar)
    }

    const handleClosePayment = () => {

        setOpenPayment(false)
        setPaymentIntentData(null)
        
    }

    const handleLogout = () => {
        props.logout()
    }

    const handleAskCreateHorariosWeek = () => {

        Swal.fire({
            title: "Desea crear el horario semanal",
            showDenyButton: true,
            // showCancelButton: true,
            confirmButtonText: 'SI CREAR',
            denyButtonText: `CANCELAR`,
          }).then((result) => {

            if (result.isConfirmed) {
                console.log('si quiero')
                handleCrearHorarioWeek()
            } else if (result.isDenied) {
                console.log('no quiero')
            }
          })
    }

    const handleCrearHorarioWeek = async () => {

        const date = new Date();
        
        if(date.getDay() !== 0){
            Swal.fire({
                icon: 'error',
                title: `<strong>El horario semanal solo se puede crear los Domingos</strong>`,
                confirmButtonColor: '#3085d6',
            })
            return
        }

        props.SendMenssageAction({option: 'inicio'})

        // const time = await props.GetLocalTime()

        // console.log('time', time)

        // if(!time?.exito && !time?.time){
        //     Swal.fire({
        //         icon: 'error',
        //         title: `<strong>Error al consultar Horario</strong>`,
        //         confirmButtonColor: '#3085d6',
        //     })

        //     return
        // }

        console.log('props.FSconfig?.dias', props.FSconfig?.dias)
        // return

        const crear =  await props.CreateNewAllWeekHorarios(props.FSconfig?.dias)

        console.log('crear', crear)

        if(crear?.exito && crear?.message !== 'success'){
            Swal.fire({
              icon: 'error',
              title: `<strong>${crear?.message}</strong>`,
              confirmButtonColor: '#3085d6',
            })
        }
      
        if(crear?.exito && crear?.message === 'success'){
            Swal.fire({
              icon: 'success',
              title: '<strong>Horario actualizado</strong>',
              showConfirmButton: false,
              timer: 2000
            })
        }
        props.SendMenssageAction(crear)
    }
    

    //*render

    return (
        <ContainerConfig >
            <Card elevation={0}>
                <Box mt={1} >

                    <Box display='flex' justifyContent="center" height={30} mx={2}>
                        <Box flexGrow={1} 
                        // onClick={handleLogout}
                        >
                        </Box>
                        <Box >
                            <Typography>
                            version 1.7.4
                            </Typography>
                            <Button variant='contained' color="primary" onClick={handleLogout}>
                            Salir
                            </Button>
                        </Box>
                    </Box>

                </Box>


                <Box mt={6}>
                    <List>
                        <Divider />
                        <ListItem button onClick={() => handleOpenConfig('horarios')}>
                            <ListItemIcon>
                                <ScheduleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Horarios" />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={handleAskCreateHorariosWeek}>
                                    <TodayIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={() => handleOpenConfig('pagosTarjeta')}>
                            <ListItemIcon>
                                <PaymentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Pagos con tarjeta" />
                        </ListItem>
                        <Divider />
                    </List>
                </Box>

            </Card>

            <ConfigHorarios
                openHorarios={openHorarios}
                handleCloseConfig={handleCloseConfig}
            />

            <HistorialPagosTarjeta
                openDrawer={openPagosTarjeta}
                handleCloseDrawer={() => setOpenPagosTarjeta(false)}
            />


            <Drawer
                anchor={'right'}
                open={openConfig}
                onClose={handleCloseConfig}
            >
            <Horarios />
            <DiasSelect />
          </Drawer>
        </ContainerConfig>
    )
    if(!allpaymentIntents) return null
    const { classes } = props

    let count

    count = allpaymentIntents.length
    
    return (
        <Fragment>
             <Grid item className={classes.styleContenedor} xs={12}  >

                <Box>
                    {allpaymentIntents && allpaymentIntents.map(FSpaymentIntent => {

                        return(
                            <Box p={1} key={FSpaymentIntent.id}>
                                <Card variant='outlined' style={{backgroundColor: FSpaymentIntent.status === 0 ? '#fff' : '#b2dfdb', cursor: 'pointer', marginTop: 20}} onClick={() => handleOpenPayment(FSpaymentIntent)}>
                                    <Box minHeight={100}>
                                        <Box textAlign='center' style={{backgroundColor: '#000'}}>
                                            <Typography style={{color: '#fff', fontSize: '16px'}}>
                                                ID: <span>{FSpaymentIntent.id_orden.toUpperCase().slice(0, 7)}</span><span style={{color: '#00BCD4'}}>{FSpaymentIntent.id_orden.toUpperCase().slice(7, 11)}</span>
                                            </Typography>
                                        </Box>
                                        <Box px={1} display='flex'>
                                            <Box flexGrow={1}>
                                            <Typography style={{fontSize: '16px', color: '#000'}}>
                                                asd
                                            </Typography>
                                            </Box>
                                            <Box>
                                            <Typography style={{fontSize: '16px'}}>
                                            {getFecha(FSpaymentIntent.createdAt.seconds, 2)}
                                            </Typography>
        
                                            </Box>
                                        </Box>
                                        {/* <Box px={1}>
                                            <Typography style={{fontSize: '16px'}}>
                                                {FSpaymentIntent.domicilio}
                                            </Typography>
                                            <Typography style={{fontSize: '16px'}}>
                                                {telFormat(FSpaymentIntent.telefono)}
                                            </Typography>
                                            <Typography style={{fontSize: '16px'}}>
                                                $ {parseFloat(FSpaymentIntent.total).toFixed(2)}
                                            </Typography>
                                        </Box> */}
                                    </Box>
                                </Card>
                            </Box>
                        )
                    })}
                    
                </Box>

            </Grid>

            <Dialog 
            open={openPayment}
            // TransitionComponent={Transition}
            maxWidth='sm'
            fullWidth
            onClose={handleClosePayment}
            >
                <Box>
                   {/* {paymentIntentData ? 

                   <PaymentIntent 
                   paymentIntent={paymentIntentData}
                   handleActualizarPaymentAtendido={handleActualizarPaymentAtendido}
                   />
                   :
                   null
                   } */}
                   
                </Box>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        FSpagosHoy: state.storageDataReducer.FSpagosHoy,
        FSpaymentIntents: state.storageDataReducer.FSpaymentIntents,
        FSconfig: state.storageDataReducer.FSconfig,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         ActivarSoundAlert: (datos) => dispatch(ActivarSoundAlert(datos)),
         SetNumPaymentsNuevos: (datos) => dispatch(SetNumPaymentsNuevos(datos)),
         ActualizarPaymentVisto: (datos) => dispatch(ActualizarPaymentVisto(datos)),
         ActualizarPaymentAtendido: (datos) => dispatch(ActualizarPaymentAtendido(datos)),
         CreateNewAllWeekHorarios: (datos) => dispatch(CreateNewAllWeekHorarios(datos)),
         CreateAllWeekHorarios: (time, datos) => dispatch(CreateAllWeekHorarios(time, datos)),
         GetLocalTime: (datos) => dispatch(GetLocalTime(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
        logout: (datos) => dispatch(logout(datos)),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Ordenes))

Ordenes.propTypes = {
    
}