import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { 
    VerificarCompatibilidad,
    TiempoActual,
    InstallApp, 
    InstallNotifications, 
    InstallGeolocation, 
    OnlineStatus 
 } from '../../../store/actions/configActions'
import { 
    StorageDomicilioComercio, 
    StorageComercioNotificaciones,
    StorageComercioData, 
    StorageOrdenesFinalizadas,
    StorageCategoriasMenu, 
    SetOrdenesFood, 
    StorageRepartidores, 
    StorageConfigComercio, 
    StorageOrdenPedidosFood,
    StorageCalendario ,
    StorageUsuarios,
    StorageLugaresReservados,
    StorageClienteCreditos,
    StorageConfig
} from '../../../store/actions/storageDataAction'

//*MATERIAL UI

import { withStyles } from '@material-ui/styles'
import { capitalLetter, getFecha, newDateZone } from '../../../allFunctions'

const styles = theme => ({
    style: {

    }
})

const StorageData = (props) => {

    const [ordenesFood, setOrdenesFood] = useState(null)

    useEffect(() => {
        props.VerificarCompatibilidad()
        props.TiempoActual()
        props.InstallApp()
        props.InstallNotifications()
        props.InstallGeolocation()
        props.OnlineStatus()
    }, [])

    useEffect(() => {

        const FScalendario = []
        if(props.FScalendario && props.FScalendario?.length !== 0){

            props.StorageCalendario(props.FScalendario)
        }else{

            props.StorageCalendario(FScalendario)
        }
        

    }, [props.FScalendario])

    useEffect(() => {

        const FSusuarios = []
        props.FSusuarios && props.FSusuarios.map(FSusuario => {

            let nombreCompleto = `${FSusuario?.nombre.trim()} ${FSusuario?.apellidos.trim()}`
            nombreCompleto = capitalLetter(nombreCompleto.trim().toLowerCase())
            FSusuarios.push({...FSusuario, nombreCompleto})
        })

        props.StorageUsuarios(FSusuarios)
    }, [props.FSusuarios])

    useEffect(() => {

        const FSlugaresReservados = []
        props.FSlugaresReservados && props.FSlugaresReservados.map(fechaCalendario => {
            FSlugaresReservados.push(fechaCalendario)
        })

        props.StorageLugaresReservados(FSlugaresReservados)
    }, [props.FSlugaresReservados])

    useEffect(() => {

        const FSclienteCreditos = []
        const FSlistaEspera = []

        props.FSclienteCreditos && props.FSclienteCreditos.map(clienteCreditos => {
            FSclienteCreditos.push(clienteCreditos)
        })

        props.FSlistaEspera && props.FSlistaEspera.map(listaEspera => {
            // console.log('fechaListaEspera', getFecha(listaEspera?.fecha?.seconds, 3))
            FSlistaEspera.push(listaEspera)
        })

        props.StorageClienteCreditos({FSclienteCreditos: FSclienteCreditos, FSlistaEspera: FSlistaEspera})
    }, [props.FSclienteCreditos, props.FSlistaEspera])

    useEffect(() => {

        if(props.FSconfig && props.FSconfig.length !== 0){
            props.StorageConfig(props.FSconfig[0])
        }

        
    }, [props.FSconfig])

    useEffect(() => {

        if(props.profile && props.FScomercio){
            
            props.FScomercio.map(FScomercio => {                
                props.StorageDomicilioComercio({
                    profile: props.profile,
                    comercio: FScomercio
                })
                // setComercio(FScomercio)
            })

       }
    }, [props.profile, props.FScomercio])

    useEffect(() => {

        const ordenes = []
        props.FSordenesFood && props.FSordenesFood.map(ordenFood => {
            ordenes.push(ordenFood)
        })

        props.SetOrdenesFood(ordenes)

    }, [props.FSordenesFood])

    useEffect(() => {

        props.FScomercioData && props.FScomercioData.map(FScomercioData => {

            props.StorageComercioData({
                usuarios: FScomercioData.usuarios,
                ticketSoporte: FScomercioData.ticketSoporte,
                ordenesFood: FScomercioData.ordenesFood,
                comercioPassword: FScomercioData.comercioPassword,
                soundSelected: FScomercioData.soundSelected,
                ordenesFinalizadas: FScomercioData.ordenesFinalizadas
            })
        })

    }, [props.FScomercioData])

    useEffect(() => {

        let comercioNotificaciones
        props.FScomercioNotificaciones && props.FScomercioNotificaciones.map(FScomercioNotificacion => {
            comercioNotificaciones = FScomercioNotificacion
        })
        props.StorageComercioNotificaciones(comercioNotificaciones)

    }, [props.FScomercioNotificaciones])

    useEffect(() => {

        props.FSordenesFinalizadas && props.FSordenesFinalizadas.map(FSordenesFinalizadas => {
            props.StorageOrdenesFinalizadas(FSordenesFinalizadas.ordenesFood)
        })

    }, [props.FSordenesFinalizadas])

    useEffect(() => {

        props.FScategoriasMenu && props.FScategoriasMenu.map(FScategoriasMenu => {
            props.StorageCategoriasMenu({
                categorias: FScategoriasMenu.categorias,
                menuAdiciones: FScategoriasMenu.menuAdiciones,
                ingredientes: FScategoriasMenu.ingredientes,
                menus: FScategoriasMenu.menus,
                menuBloqueado: FScategoriasMenu.bloqueado
            })
        })

    }, [props.FScategoriasMenu])

    useEffect(() => {

        const repartidores = []
        props.FSrepartidores && props.FSrepartidores.map(FSrepartidores => {
            repartidores.push(FSrepartidores)
        })

        props.StorageRepartidores(repartidores)

    }, [props.FSrepartidores])

    useEffect(() => {

        props.FSconfigComercio && props.FSconfigComercio.map(FSconfigComercio => {
            props.StorageConfigComercio({
                catSoporte: FSconfigComercio.catSoporte,
                catMenuRespuestas: FSconfigComercio.catMenuRespuestas,
                appVersion: FSconfigComercio.appVersion,
                comisionFood: FSconfigComercio.comisionFood
            })
        })

    }, [props.FSconfigComercio])

   
    
    return (
        <Fragment>
            
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        profile: state.firebase.profile,
        // FScomercio: state.firestore.ordered.FScomercio,     
        // FSordenesFood: state.firestore.ordered.FSordenesFood,
        // FScomercioNotificaciones: state.firestore.ordered.FScomercioNotificaciones,
        // FScomercioData: state.firestore.ordered.FScomercioData,
        // FSordenesFinalizadas: state.firestore.ordered.FSordenesFinalizadas,
        // FScategoriasMenu: state.firestore.ordered.FScategoriasMenu,    
        // FSrepartidores: state.firestore.ordered.FSrepartidores,
        // FSconfigComercio: state.firestore.ordered.FSconfigComercio,
        FSconfig: state.firestore.ordered.FSconfig,
        FScalendario: state.firestore.ordered.FScalendario,
        FSusuarios: state.firestore.ordered.FSusuarios,
        FSlugaresReservados: state.firestore.ordered.FSlugaresReservados,
        FSclienteCreditos: state.firestore.ordered.FSclienteCreditos,
        FSlistaEspera: state.firestore.ordered.FSlistaEspera,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
         SetOrdenesFood: (datos) => dispatch(SetOrdenesFood(datos)),
         StorageDomicilioComercio: (datos) => dispatch(StorageDomicilioComercio(datos)),
         StorageComercioData: (datos) => dispatch(StorageComercioData(datos)),
         StorageComercioNotificaciones: (datos) => dispatch(StorageComercioNotificaciones(datos)),
         StorageOrdenesFinalizadas: (datos) => dispatch(StorageOrdenesFinalizadas(datos)),
         StorageCategoriasMenu: (datos) => dispatch(StorageCategoriasMenu(datos)),
         StorageRepartidores: (datos) => dispatch(StorageRepartidores(datos)),
         StorageConfigComercio: (datos) => dispatch(StorageConfigComercio(datos)),
         StorageOrdenPedidosFood: (datos) => dispatch(StorageOrdenPedidosFood(datos)),
         VerificarCompatibilidad: (datos) => dispatch(VerificarCompatibilidad(datos)),
         TiempoActual: (datos) => dispatch(TiempoActual(datos)),
         InstallApp: (datos) => dispatch(InstallApp(datos)),
         InstallNotifications: (datos) => dispatch(InstallNotifications(datos)),
         InstallGeolocation: (datos) => dispatch(InstallGeolocation(datos)),
         OnlineStatus: (datos) => dispatch(OnlineStatus(datos)),
         StorageCalendario: (datos) => dispatch(StorageCalendario(datos)),
         StorageUsuarios: (datos) => dispatch(StorageUsuarios(datos)),
         StorageLugaresReservados: (datos) => dispatch(StorageLugaresReservados(datos)),
         StorageClienteCreditos: (datos) => dispatch(StorageClienteCreditos(datos)),
         StorageConfig: (datos) => dispatch(StorageConfig(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(StorageData))

StorageData.propTypes = {
    
}