import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { SnackbarProvider, useSnackbar } from 'notistack'

//*COMPONENTES
import { SendMenssageAction } from '../../../../store/actions/messageActions'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    root: {
        width: '100%',
        '& > * + *': {
          marginTop: theme.spacing(2),
        },
    },
    button: {
        color: '#fff'
    },
    fixedDown: {
        position: 'fixed',
        top: 'auto',
        bottom: 5,
        width: '100%',
        color: '#ffffff',
    },
    notificacion: {
        backgroundColor: '#e53935',
        color: '#ffffff',
        width: '100%'
    }
})

function Geolocation(props) {
    const { enqueueSnackbar } = useSnackbar()
  
    useEffect(() => {
      
        enqueueSnackbar(props.message, { 
            variant: props.variant,
            persist: true,
            action,
        })

    },[])

    const handleGeolocation = () => {
        props.SendMenssageAction({option: 'inicio'})
        window.location.reload(true)
    }

    const action = key => (
        <Fragment>
            <Button variant="outlined" className={props.classes.button} onClick={handleGeolocation}>
                Reintentar
            </Button>
        </Fragment>
    )
    
    return (
      <Fragment>
        
      </Fragment>
    )
}
  
function Conexion(props) {
const { enqueueSnackbar } = useSnackbar()

useEffect(() => {
    
    const variant = props.variant
    enqueueSnackbar(props.message, { variant, persist: true })
},[])

return (
    <Fragment>
    
    </Fragment>
)
}

const NoificacionAlerta = (props) => {

    const [inicializado, setInicializado] = useState(false)

    useEffect(() => {
        inicializar()
    }, [])

    const inicializar = async () => {

        await new Promise(resolve => setTimeout( resolve , 1000))  
        setInicializado(true)
    }

    const handleGeolocation = () => {
        props.SendMenssageAction({option: 'inicio'})
        window.location.reload(true)
    }

    //*render
    if(!inicializado) return null
    const { classes, configReducer } = props

    return (
        <Fragment>

            <Box className={classes.fixedDown}>

                <Slide direction="up" in={!configReducer.geolocationActivo}>
                    <Box px={1} p={0.5} style={{display: !configReducer.geolocationActivo ? '' : 'none'}} >
                        <Card className={classes.notificacion} elevation={3}>
                            <Box display="flex" alignItems="center" minHeight={50}>
                                <Box px={2} py={0.5} >
                                    <Typography variant="body1">
                                    Activa la geolocalizacion en tu dispositivo y/o navegador y vuelve a intentar
                                    </Typography>
                                </Box>
                                <Box px={1} >
                                    <Button variant="outlined" className={props.classes.button} onClick={handleGeolocation}>
                                        Reintentar
                                    </Button>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Slide>

                <Slide direction="up" in={!configReducer.online}>
                    <Box px={1} p={0.5} style={{display: !configReducer.online ? '' : 'none'}} >
                        <Card className={classes.notificacion} elevation={3}>
                            <Box display="flex" alignItems="center" minHeight={50} >
                                <Box px={2} py={0.5} >
                                    <Typography variant="body1" >
                                    Se ha perdido la conexion a internet
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Slide>
            </Box>
            
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        configReducer: state.configReducer,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NoificacionAlerta))

NoificacionAlerta.propTypes = {
    
}