import { connect } from 'react-redux'
import { withStyles } from '@material-ui/styles'

import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Dialog, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import styled from "styled-components";
import { DrawerTitle } from "../../elementos/drawer-title";
import { formatMoney, getFecha } from '../../allFunctions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ContainerHistorialPagos = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

  padding: ${theme.spacing(2)}px;

  .pago-main-container {
    display: flex;
    width: 100%;
  }

  .pago-totales-container {
    display: flex;
    flex-direction: column;
  }
  
  .pago-tarjeta-content{
    width: 100%;
  }

  .color-gray {
    color: #969B9C;
    font-weight: bold;
  }

  .color-blue-light {
    color: #167694;
    font-weight: bold;
  }

  .color-verde {
    color: green;
    font-weight: bold;
  }

`}
`


const HistorialPagosTarjeta = (props) => {

  const { openDrawer, handleCloseDrawer, FSpaymentIntents, FSallPaymentIntents, profile } = props;

  const fechas = []

  FSallPaymentIntents && FSallPaymentIntents.map(payment => {

      if(!fechas.includes(getFecha(payment.createdAt.seconds, 1))){
          
          fechas.push(getFecha(payment.createdAt.seconds, 1))
      }
  })

  const allPagos = []
  const paymentsHoy = []
  fechas.map(fecha => {

      const hoy = Math.floor(new Date().getTime()/1000.0)
      const pago = []
      let monto = 0
      let cobro = 0
      let neto = 0
      let fechaPago = ''
      FSallPaymentIntents.map(payment => {

          const date = new Date( payment.createdAt.seconds * 1000);
          date.setHours(0, 0, 0, 0) // +5 hours for Eastern Time

          if(fecha === getFecha(payment.createdAt.seconds, 1)){
              
              monto = parseInt(monto) + parseInt(payment.pagoDetalles.monto)
              cobro = parseInt(cobro) + parseInt(payment.pagoDetalles.cobro)
              neto = parseInt(neto) + parseInt(payment.pagoDetalles.neto)
              fechaPago = getFecha(payment.fechaPago, 1)
              pago.push({
                  id: payment.id,
                  nombre: payment.nombre,
                  creditos: payment.paquete.creditos,
                  fecha: fecha,
                  monto: payment.pagoDetalles.monto,
                  cobro: payment.pagoDetalles.cobro,
                  neto: payment.pagoDetalles.neto
              })                        
          }

          if(getFecha(hoy, 1) === getFecha(payment.createdAt.seconds, 1) && getFecha(hoy, 1) === fecha){

              paymentsHoy.push(payment)
          }

      })

      allPagos.push({
          fecha: fecha,
          fechaPago: fechaPago,
          pagos: pago,
          monto: monto,
          cobro: cobro,
          neto: neto
      })

  })


  return (
    <Dialog fullScreen open={openDrawer} onClose={handleCloseDrawer} >
      <ContainerHistorialPagos>

      <DrawerTitle title={profile?.roll === "admin" ? "Pagos con tarjeta" : "Mis pagos con Tarjeta"} handleCloseDrawer={handleCloseDrawer} />

      {allPagos.map(paymentIntent => {
          
          return (
            <Accordion key={paymentIntent?.fecha}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box>
                  <Box>
                    <Typography>{paymentIntent?.fecha}</Typography>
                  </Box>
                  <Box>
                    <Typography className="color-verde">{formatMoney((paymentIntent?.neto) / 100)}</Typography> 
                  </Box>
                  <Box>
                    <Typography className="color-gray">{paymentIntent?.fechaPago}</Typography> 
                  </Box>
                </Box>
              </AccordionSummary>
              {paymentIntent?.pagos.map(pago => {

                return (
                  <Box key={pago?.id}>
                    <Divider />
                    <AccordionDetails>
                        <Box className='pago-main-container'>
                          <Box flexGrow={1}>
                            <Box>
                              <Typography component="p" className="color-blue-light">
                                {pago?.nombre} 
                              </Typography>
                            </Box>
                            <Box>
                              <Typography component="p" className="color-gray">
                                Creditos: {pago?.creditos} 
                              </Typography>
                            </Box>
                          </Box>
                          <Box className='pago-totales-container'>
                            <Box>
                              <Typography className="color-blue-light">
                              {formatMoney((pago?.monto) / 100)}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography className="color-verde">
                              {formatMoney((pago?.neto) / 100)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    
                  </Box>
                )
              })}
            </Accordion>
          )
      })}

      <List component="nav" aria-label="secondary mailbox folders">
        {FSpaymentIntents && FSpaymentIntents.map(paymentIntent => {

          return (
            // <Typography>
            //   Payment: {getFecha(paymentIntent?.createdAt?.seconds, 2)}
            // </Typography>
            <>
            <ListItem>
              <ListItemText primary={getFecha(paymentIntent?.createdAt?.seconds, 1)} 
              secondary={`Creditos: ${paymentIntent?.paquete?.creditos}`}
              />
              <ListItemSecondaryAction>
               <ListItemText primary={`${formatMoney((paymentIntent?.pagoDetalles?.monto) / 100)}`} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            </>
          )

          })
        }
      </List>


      </ContainerHistorialPagos>
    </Dialog>

  )
}

const mapStateToProps = (state) => {
  return{
    profile: state.firebase.profile, 
    FSpaymentIntents: state.firestore.ordered.FSpaymentIntents,
    FSallPaymentIntents: state.firestore.ordered.FSallPaymentIntents,
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      // logout: (datos) => dispatch(logout(datos)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistorialPagosTarjeta);