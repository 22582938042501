import { 
    ACTIVAR_SOUNDALERT_EXITO,
    SET_USUARIOLOGIN_EXITO
} from '../actionTypes'


const initState = {
    playSound: false,
    usuarioLogin: false,
    nombreUsuario: ''
}

const perfilComercioReducer = (state = initState, action) => {
    switch (action.type) {       
        case ACTIVAR_SOUNDALERT_EXITO:
            return {
                ...state,
                playSound: action.payload,
            }
        case SET_USUARIOLOGIN_EXITO:
            return {
                ...state,
                usuarioLogin: action.payload.login,
                nombreUsuario: action.payload.usuario
            }
        default:
        return state
    }
}

export default perfilComercioReducer