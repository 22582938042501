import { useState } from "react";
import { connect } from "react-redux";

import { Button, Card, Dialog, Drawer, Typography } from "@material-ui/core"
import { Box, FormControl, InputAdornment, InputLabel, Select, TextField } from "@material-ui/core";
import styled from 'styled-components'
import { DrawerTitle } from "../../elementos/drawer-title";
import { ComprarCreditosCliente } from '../../store/actions/ClientesAction'
import { SendMenssageAction } from "../../store/actions/messageActions";
import { formatMoney, newDateZone } from "../../allFunctions";
import Swal from "sweetalert2";


const ContainerCreditosCliente = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `


  width: 350px;
  position: relative;
  height: 100%;

  ${theme.breakpoints.down('sm')} {
    width: 250px;
  }

  .paquetes-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      
    }
  }

  .paquete-credito {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    ${theme.breakpoints.down('sm')} {
    }
  }

  .paquete-credito: hover {
    background-color: #FEF9E7;
    
  }

  .paquete-credito-selected {
    background-color: #FCF3CF;
  }

  .cliente-nombre-container {
    padding: 10px;
    margin-bottom: 10px;
    height: 60px;
  }

  .clientenombre-container-selected {
    background-color: #D5F5E3;
  }

  .cliente-nombre {
    text-align: center;
  }

  .button-credito-container {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
    padding: 10px;
  }

  .action-credito-button {
    margin-top: 20px;
    height: 80px;
  }

`}
`

const ContainerCreditos = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

  position: relative;
  height: 100%;
  // padding: 10px;

  .paquetes-container {
    width: 100%;
    overflow: auto; 
    ${theme.breakpoints.down('sm')} {
      // flex-direction: column;
      
    }
  }

  .paquete-credito {
    width: calc(50% - 15px);
    height: 80px;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    ${theme.breakpoints.down('sm')} {
    }
  }

  .paquete-credito-selected {
    background-color: #FCF3CF;
  }

  .cliente-nombre-container {
    padding: 10px;
    margin-bottom: 10px;
    height: 60px;
  }

  .ml-1 {
    margin-left: 5px;
  }
  .ml-2 {
    margin-left: 10px;
  }

`}
`
const CreditosCliente = (props) => {

  const { openDrawer, selectedCliente, profile, FSconfig, handleComprarPaqueteCliente } = props

  const [selectedPaquete, setSelectedPaquete] = useState(null)

  const handleCloseDrawer = () => {
    setSelectedPaquete(null)
    props.handleCloseDrawer()
  }

  const handleSelectPaquete = (paquete) => {
    setSelectedPaquete(paquete)
  }

  const handleComprarPaquete = async() => {

    
    props.SendMenssageAction({option: 'inicio'})
    const comprar = await props.ComprarCreditosCliente({cliente: selectedCliente, paquete: selectedPaquete})
    if(comprar?.exito){
      Swal.fire({
        icon: 'success',
        title: `${selectedCliente?.nombre} ${selectedCliente?.apellidos}</br><strong>${selectedPaquete?.creditos} creditos</strong>`,
        confirmButtonColor: '#3085d6',
      })
      handleCloseDrawer()
    }

    props.SendMenssageAction(comprar)
  }


  const handleChangeCalcularComisionTarjeta = (precio) => {

    let comisionTarjeta = (( parseFloat(precio) * 0.036) + 3)
    const iva = parseFloat(comisionTarjeta) * 0.16
    comisionTarjeta = parseFloat(comisionTarjeta) + parseFloat(iva)
    
    // setComisionTarjeta(comisionTarjeta)
    console.log('comisionTarjeta', comisionTarjeta.toFixed(2))
    console.log('precio', precio)
    return parseFloat(comisionTarjeta) + parseFloat(precio)

}

  let paqueteIsSelected = ''

  if(selectedPaquete){
    paqueteIsSelected = 'clientenombre-container-selected'
  }


  let canBuy = false

  if(selectedCliente && selectedPaquete){
    canBuy = true
  }


  // if(!FSconfig || FSconfig.length === 0) return null
  if(profile?.roll === 'cliente'){

    return (
      <Dialog fullScreen open={openDrawer} onClose={handleCloseDrawer} >
        <ContainerCreditos>

          <DrawerTitle title={"Paquetes"} handleCloseDrawer={handleCloseDrawer} />

          <Box className="paquetes-container">
            {FSconfig && FSconfig?.paquetes.map(paquete => {

              let selected = ''
              if(selectedPaquete && selectedPaquete?.creditos === paquete?.creditos){
                selected = 'paquete-credito-selected'
              }

              return (
                <Card variant="outlined" className={`paquete-credito ml-2 ${selected}`} 
                key={paquete?.creditos} 
                onClick={() => handleSelectPaquete(paquete)}
                >
                  <Typography align="center">
                    Creditos: {paquete?.creditos}
                  </Typography>
                  <Typography className="ml-2">
                    Efectivo: {formatMoney(paquete?.costo)}
                  </Typography>
                  <Typography className="ml-2">
                    {/* Tarjeta: <span className="ml-1"/>{formatMoney(handleChangeCalcularComisionTarjeta(paquete?.costo))} */}
                    Tarjeta: <span className="ml-1"/>{formatMoney(paquete?.tarjeta)}
                  </Typography>
                  <Typography align="center">
                    Vigencia: {parseInt(paquete?.vigencia) / 7 } {paquete?.vigencia === 7 ? 'Semana' : 'Semanas'}
                  </Typography>
                </Card>
              )
            })}
          </Box>

          <Box mt={2} p={2}>
            <Typography>
              Los pagos en efectivo lo puedes realizar en el estudio
            </Typography>
            <Typography>
              Los pagos con tarjeta desde esta aplicación, <b>tienen un cargo extra</b>
            </Typography>
          </Box>


          <Box className={`cliente-nombre-container`}>
            <Button fullWidth className='action-credito-button margin' variant='contained' color="primary"
              disabled
              // disabled={!selectedPaquete}
              // onClick={selectedPaquete ? () => handleComprarPaqueteCliente(selectedPaquete): null}
              >
              Comprar
            </Button>
          </Box>

          </ContainerCreditos>
      </Dialog>
    )
  }


  return (
    <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >
      <ContainerCreditosCliente>

        <DrawerTitle title={"Paquetes de creditos"} handleCloseDrawer={handleCloseDrawer} />

        {profile?.roll === 'cliente' ?
        <Box className="paquetes-container">
          {FSconfig && FSconfig?.paquetes.map(paquete => {

            return (
              <Card variant="outlined" className={`paquete-credito`} 
              key={paquete?.creditos} 
              onClick={() => handleSelectPaquete(paquete)}
              >
                <Typography>
                  Creditos: {paquete?.creditos}
                </Typography>
                <Typography>
                  $ {parseFloat(paquete?.costo).toFixed(2)}
                </Typography>
                <Typography>
                  Vigencia: {parseInt(paquete?.vigencia) / 7 } {paquete?.vigencia === 7 ? 'Semana' : 'Semanas'}
                </Typography>
              </Card>
            )
          })}
        </Box>
        :
        <Box className="paquetes-container">
          {FSconfig && FSconfig?.paquetes.map(paquete => {

            let selected = ''
            if(selectedPaquete && selectedPaquete?.creditos === paquete?.creditos){
              selected = 'paquete-credito-selected'
            }
            return (
              <Card variant="outlined" className={`paquete-credito ${selected}`} 
              key={paquete?.creditos} 
              onClick={() => handleSelectPaquete(paquete)}
              >
                <Typography>
                  Creditos: {paquete?.creditos}
                </Typography>
                <Typography>
                  $ {parseFloat(paquete?.costo).toFixed(2)}
                </Typography>
                <Typography>
                  Vigencia: {parseInt(paquete?.vigencia) / 7 } {paquete?.vigencia === 7 ? 'Semana' : 'Semanas'}
                </Typography>
              </Card>
            )
          })}
        </Box>
        }

        {profile?.roll === 'cliente' ?
        <Box className={`cliente-nombre-container`}>
          <Button fullWidth className='action-credito-button margin' variant='contained'
            disabled={!selectedPaquete}
            onClick={selectedPaquete ? () => handleComprarPaqueteCliente(selectedPaquete): null}>
            Comprar
          </Button>
        </Box>
        :
        <Box className={'button-credito-container'}>
          <Box className={`cliente-nombre-container ${paqueteIsSelected}`} >
            <Typography className="cliente-nombre">
              {`${selectedCliente?.nombre} ${selectedCliente?.apellidos}`}
            </Typography>
            {selectedPaquete ?
            <>
              <Typography className="cliente-nombre">
                Creditos: {selectedPaquete?.creditos}
              </Typography>
              <Typography className="cliente-nombre">
                $ {parseFloat(selectedPaquete?.costo).toFixed(2)}
              </Typography>
            </>
            :
            null
            }
          </Box>
          <Button fullWidth className='action-credito-button' variant='contained'
            disabled={!canBuy}
            onClick={canBuy ? handleComprarPaquete : null}>
            Comprar
          </Button>
        </Box>
        }

        
      </ContainerCreditosCliente>

    </Drawer>
  )
}

const mapStateToProps = (state) => ({
  profile: state.firebase.profile, 
  FSusuarios: state.storageDataReducer.FSusuarios,
  FSconfig: state.storageDataReducer.FSconfig,
});

const mapDispatchToProps = (dispatch) => ({
  ComprarCreditosCliente: (datos) => dispatch(ComprarCreditosCliente(datos)),
  SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
  // CancelActivarIngredientes: (datos) => dispatch(CancelActivarIngredientes(datos)),
});

export default connect( mapStateToProps, mapDispatchToProps )(CreditosCliente);