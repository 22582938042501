import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'


//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Zoom from '@material-ui/core/Zoom'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Card from '@material-ui/core/Card'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: '100%',
    },
})

const initState ={
    id: '',
    dia: '',
    abre: '',
    abreTurno: '',
    cierra: '',
    cierraTurno: ''
}

const Horario = (props) => {

    const [checked, setChecked] = useState(false)
    const [horario, setHorario] = useState(initState)   

    useEffect(() => {
        if(props.horario){
            setHorario({
                id: props.horario.id,
                dia: props.horario.dia,
                abre: props.horario.abre,
                abreTurno: props.horario.abreTurno,
                cierra: props.horario.cierra,
                cierraTurno: props.horario.cierraTurno
            })

            if(props.horario.abre !== ''){
                setChecked(true)
            }
        }
        
    },[props.horario.abre])
    
    const handleChange = (e) => {
        setHorario({
            ...horario,
            [e.target.name] : e.target.value
        })      
    }

    const verificarHorario = (horario) => {
        const resultado = {
            exito: false,
            horario: {
                ...horario
            }
        }


        if(horario.abre !== '' && horario.abreTurno !== '' && horario.cierra !== '' && horario.cierraTurno !== ''){

            if(resultado.horario.abre !== '' && resultado.horario.abreTurno !== '' && resultado.horario.cierra !== '' && resultado.horario.cierraTurno !== ''){

                if(parseFloat(resultado.horario.abre) >= 1 && parseFloat(resultado.horario.abre) <= 12.59 && resultado.horario.abre.length > 0 && resultado.horario.abre.length < 6 &&
                    parseFloat(resultado.horario.cierra) >= 1 && parseFloat(resultado.horario.cierra) <= 12.59 && resultado.horario.cierra.length > 0 && resultado.horario.cierra.length < 6){

                    if(resultado.horario.abre.includes('.')){

                        const minutos = resultado.horario.abre.split('.')

                        if(minutos[1] === '30' || minutos[1] === '00'){
                            resultado.exito = true
                        }else{
                            resultado.exito = false
                            return resultado
                        }


                        if(minutos[0].length === 1){
                            resultado.horario.abre = `0${minutos[0]}.${minutos[1]}`
                        }

                    }else{
                        
                        if(resultado.horario.abre.length === 1){
                            resultado.horario.abre = `0${resultado.horario.abre}.00`
                        }else{
                            resultado.horario.abre = `${resultado.horario.abre}.00`
                        }
                    }

                    if(resultado.horario.cierra.includes('.')){

                        const minutos = resultado.horario.cierra.split('.')

                        if(minutos[1] === '30' || minutos[1] === '00'){
                            resultado.exito = true
                        }else{
                            resultado.exito = false
                            return resultado
                        }

                        if(minutos[0].length === 1){
                            resultado.horario.cierra = `0${minutos[0]}.${minutos[1]}`
                        }

                    }else{
                        
                        if(resultado.horario.cierra.length === 1){
                            resultado.horario.cierra = `0${resultado.horario.cierra}.00`
                        }else{
                            resultado.horario.cierra = `${resultado.horario.cierra}.00`
                        }
                    }

                    resultado.exito = true
                }

            }
            return resultado
        }else{
            return resultado
        }
    }

    const handleCheck = (e) => {

        if(e.target.checked){

            const result = verificarHorario(horario)
            if(result.exito){
                props.handleAgregarHorario(result.horario)
            }else{
                return
            } 
                
        }else{
            props.handleEliminarHorario(horario)
        }

        setChecked(e.target.checked)

    }



    const { classes } = props
        
    return (
        <Fragment key={horario.id}>
            <Box mt={1} py={1}>
                <Card className={classes.cardComercio} variant="outlined">
                    <Box pl={2}>
                        <FormControlLabel
                            
                            control={
                                <Checkbox
                                    checked={checked}
                                    value=""
                                    color="primary"
                                    onChange={handleCheck}
                                />
                            }
                            label={horario.dia}
                        />
                    </Box>
                    <Grid container >
                        <Grid item xs={3} className="">
                            <Box px={1} mb={1}>
                                <TextField    
                                    color="secondary"                                   
                                    id="abre"
                                    name="abre" 
                                    label="Abre"
                                    value={horario.abre}
                                    type='number'                             
                                    onChange={handleChange}
                                    autoComplete="off" 
                                    fullWidth
                                    disabled={checked}
                                    />
                            </Box>
                        </Grid> 
                        <Grid item xs={3} className="">
                            <Box px={1} mb={1}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Turno</InputLabel>
                                
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="abreTurno"
                                value={horario.abreTurno}
                                onChange={handleChange}
                                fullWidth
                                disabled={checked}
                                >
                                <MenuItem value={'AM'}>A.M.</MenuItem>
                                <MenuItem value={'PM'}>P.M.</MenuItem>                                
                                </Select>
                            </FormControl>
                            </Box>
                        </Grid> 
                        <Grid item xs={3} className="">
                            <Box px={1} mb={1}>
                                <TextField    
                                    color="secondary"                                   
                                    id="cierra"
                                    name="cierra" 
                                    label="Cierra"
                                    value={horario.cierra}
                                    type='number'                             
                                    onChange={handleChange}
                                    autoComplete="off" 
                                    fullWidth
                                    disabled={checked}
                                    />
                            </Box>
                        </Grid> 
                        <Grid item xs={3} className="">
                            <Box px={1} mb={1}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">Turno</InputLabel>
                                <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="cierraTurno"
                                value={horario.cierraTurno}
                                onChange={handleChange}
                                fullWidth
                                disabled={checked}
                                >
                                <MenuItem value={'AM'}>A.M.</MenuItem>
                                <MenuItem value={'PM'}>P.M.</MenuItem>                                
                                </Select>
                            </FormControl>
                            </Box>
                        </Grid>       
                    </Grid>
                </Card>
            </Box>
        </Fragment>
    )

}

export default withStyles(styles)(Horario)

Horario.propTypes = {
    horario: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    handleAgregarHorario: PropTypes.func.isRequired,
    handleEliminarHorario: PropTypes.func.isRequired 
}