import uid from 'uid';
import { getFecha, newDateZone } from '../../allFunctions'
import { newDateZoneSeconds } from '../../allFunctions/index';
function getDaysInMonth(month, year) {
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
      const fecha = new Date(date)
      days.push(fecha);
      date.setDate(date.getDate() + 1);

  }
  return days;
}

function getDaysInWeek(date) {
  
  const days = [];
  // const date = new Date(time?.utcTime);
  // date.setDate(date.getDate() + 12);
  for (let index = 0; index < 7; index++) {
    const fecha = new Date(date)
    days.push(fecha)
    date.setDate(date.getDate() + 1);
    
  }

  let limit = false
  const allDays = []
  days.map(day => {
    
    if(!limit){
      allDays.push(day)
    }

    if(day.getDay() === 6){
      limit = true
    }
    return true
  })

  return allDays;
}

const lugares = [
  {
      id: 1,
      ocupado: false,
      cliente: '',
      idCliente: ''
  },
  {
      id: 2,
      ocupado: false,
      cliente: '',
      idCliente: ''
  },
  {
      id: 3,
      ocupado: false,
      cliente: '',
      idCliente: ''
  },
  {
      id: 4,
      ocupado: false,
      cliente: '',
      idCliente: ''
  },
  {
      id: 5,
      ocupado: false,
      cliente: '',
      idCliente: ''
  },
  {
      id: 6,
      ocupado: false,
      cliente: '',
      idCliente: ''
  },
]


export const ResetPasswordCliente = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
        exito: false,
        option: 'reset',
        message: 'Se produjo un error'
    }

    const firebase = getFirebase()
    const funct = firebase.functions()

    const resetPassword = funct.httpsCallable('admon_ResetPassword')

    await resetPassword(data)
    .then(response => {
      if(response && response?.data && response?.data.exito){
        resultado.exito = true
        resultado.message = 'success'
      }

    })
    .catch(error => {
        console.log('error', error);
    })

    return resultado

  }
}


export const EditarClienteAction = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
      exito: false,
      option: 'reset',
      message: 'Se produjo un error'
  }

  const firestore = getFirestore()  

  await firestore.collection('usuarios').doc(data?.id).update(data)
  .then(() => {
    resultado.exito = true
    resultado.option = 'reset'
  })
  .catch(error => {
    console.log('error', error)
  })

  return resultado


  }
}


export const ComprarCreditosCliente = ({cliente, paquete}) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
        exito: false,
        option: '',
        message: ''
    }


    const firestore = getFirestore()  

    const creditos = parseInt(paquete?.creditos)
    const vigencia = newDateZone()
    vigencia.setHours(23,0,0,0)
    vigencia.setDate(vigencia.getDate() + parseInt(paquete?.vigencia))
    const idVigencia = vigencia.getTime() / 1000;

    const batch = firestore.batch()
    const creditosRef = firestore.collection('usuarios').doc(cliente?.id).collection('creditos').doc()
    const usuarioRef = firestore.collection('usuarios').doc(cliente?.id)

    batch.set(creditosRef, {
      idVigencia: idVigencia,
      uid: uid(),
      fechaVigencia: vigencia,
      creditos: creditos
    })

    batch.update(usuarioRef, {
      creditosVigenia: {
        createdAt: newDateZone(),
        idVigencia: idVigencia,
        fechaVenta: newDateZone(),
        fechaVigencia: vigencia,
        creditos: creditos,
        vipCredit: false
      }
    })

    await batch.commit()
    .then(() => {
        resultado.exito = true
        resultado.option = 'reset'
        return resultado
    })
    .catch(error => {
        console.log('error', error)
        resultado.option = 'error'
        resultado.message = 'Se produjo un error'
    })

    return resultado

  }
}


export const UpdateCalendarioFecha = (datos) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
        exito: false,
        option: '',
        message: ''
    }

    const dias = [
      {
        id: 0,
        dia: 'Domingo',
        horarios: [
          '0:0',
        ]
      },
      {
        id: 1,
        dia: 'Lunes',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:15',
          '18:30',
          '19:30'
        ]
      },
      {
        id: 2,
        dia: 'Martes',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:15',
          '18:30',
          '19:30'
        ]
      },
      {
        id: 3,
        dia: 'Miercoles',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:15',
          '18:30',
          '19:30'
        ]
      },
      {
        id: 4,
        dia: 'Jueves',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:15',
          '18:30',
          '19:30'
        ]
      },
      {
        id: 5,
        dia: 'Viernes',
        horarios: [
          '6:0',
          '7:0',
          '8:0',
          '9:0',
          '10:0',
          '17:0',
          '18:0',
        ]
      },
      {
        id: 6,
        dia: 'Sabado',
        horarios: [
          '9:15',
          '10:15'
        ]
      }
    ]

    const lugares = [
      {
          id: 1,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 2,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 3,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 4,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 5,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
      {
          id: 6,
          ocupado: false,
          cliente: '',
          idCliente: ''
      },
  ]

  const paquetes = [
    {
      creditos: '1',
      costo: '200',
      vigencia: 5
    },
    {
      creditos: '5',
      costo: '900',
      vigencia: 15
    },
    {
      creditos: '10',
      costo: '1700',
      vigencia: 20
    },
    {
      creditos: '15',
      costo: '2300',
      vigencia: 25
    },
    {
      creditos: '20',
      costo: '2850',
      vigencia: 30
    },
  ]

    const firestore = getFirestore()

    // await firestore.collection('config').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
    //   paquetes: paquetes
    // })
    // .then(() => {
    //   resultado.exito = true
    //   resultado.option = 'reset'
    // })
    // .catch(error => {
    //   console.log('error', error)
    // })

    return



    const batch = firestore.batch()
    const calendarioRef = firestore.collection('calendario')

    const allDays = getDaysInMonth(8, 2023)

    console.log('allDays', allDays)
    console.log('allDays', allDays.length)
    // return
    const allIdDocuments = []
    allDays.map(fecha => {

      const year = fecha.getFullYear()
      const month = parseInt(fecha.getMonth()) + 1
      const day = fecha.getDate()

      const idDocument = `${year}${month <= 9 ? '0'+month : month}${day <= 9 ? '0'+day : day}`
      allIdDocuments.push(idDocument)
      console.log(idDocument, '- dia: ', fecha.getDate())
      console.log(fecha, '- nomnreDia: ', dias[fecha.getDay()])

      const horariosData = dias[fecha.getDay()]

      const horariosDoc = []

      // const horariosRef = firestore.collection('calendario').doc(idDocument).collection('horarios')
      horariosData?.horarios && horariosData?.horarios.map(horarios => {
        const horario = horarios.split(':')
        // console.log('horarioTT', horario)
        fecha.setHours(0)
        fecha.setMinutes(0)
        fecha.setHours(fecha.getHours() + horario[0]);
        fecha.setMinutes(fecha.getMinutes() + horario[1]);
        const seconds = fecha.getTime() / 1000;
        console.log('secondsTT', seconds)
        console.log('secondsTT', getFecha(seconds, 3))

        horariosDoc.push({
          id: seconds,
          horarioDesc: getFecha(seconds, 4),
          disponible: true,
          lugares: lugares,
          listaEspera: []
        })


        // batch.set(horariosRef.doc(seconds.toString()), {
        //   disponible: true,
        //   lugares: lugares
        // })
        return true
      })

      fecha.setHours(0)
      fecha.setMinutes(0)
      batch.set(calendarioRef.doc(idDocument), {
        fecha: fecha,
        disponible: true,
        horarios: horariosDoc
      })
      
      return true
    })

    // return

    await batch.commit()
    .then(() => {
        resultado.exito = true
        resultado.option = 'reset'
        console.log('exito!!')
        return resultado
    })
    .catch(error => {
        console.log('error', error)
        resultado.option = 'error'
        resultado.message = 'Se produjo un error'
    })
       
    

    // const id = calendarioRef.doc().id

   


    // await firestore.collection('calendario').doc('2023125').update({
    //   fecha: new Date(),
    //   horarios: datos,
    // })
    // .then(() => {
    //     return resultado
    // })
    // .catch(error => {
    //     console.log(error)
    //     resultado.option = 'error'
    //     resultado.message = 'Se produjo un error'
    // })

    // return resultado

  }
}





export const SetReservePlaceDateByUser = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const FSclienteCreditos = getState().storageDataReducer.FSclienteCreditos
    
    let selctedClientCredito
    FSclienteCreditos.map(FScliCred => {

      if(FScliCred?.fechaVigencia?.seconds > data?.idHorario && !selctedClientCredito){
        selctedClientCredito = FScliCred
      }

      return true
    })

    if(!selctedClientCredito){
      resultado.exito = true
      resultado.option = 'reset'
      resultado.message = 'No hay creditos para la fecha seleccionada'
      return resultado
    }


    // return
    
    const firestore = getFirestore()
    
       
  //  await firestore.collection('calendario').get()
  //  .then(async snapshot => {

  //    const batch = firestore.batch()

  //     snapshot.forEach(doc => {
  //       console.log(doc.id, '=>', doc.data());

  //       const fecha = doc.data()

  //       const calendarioRef = firestore.collection('calendario').doc(doc?.id)

  //       fecha?.horarios.map(horario => {
  //         const horariotrue = getFecha(horario?.id, 4)
  //         horario.horarioDesc = horariotrue
  //       })

  //       console.log('fecha?.horarios', fecha?.horarios)
  //       batch.update(calendarioRef, {
  //           horarios: fecha?.horarios
  //       })

  //     });

  //     await batch.commit()
  //       .then(() => {
  //           resultado.exito = true
  //           resultado.option = 'reset'
  //           console.log('exito!!')
  //           return resultado
  //       })
  //       .catch(error => {
  //           console.log('error', error)
  //           resultado.option = 'error'
  //           resultado.message = 'Se produjo un error'
  //       })
  //  })
  // .catch(error => {
  //   console.log('error')
  // })

    

    
    const lugaresReservadosRef = firestore.collection('lugaresReservados').doc()
    const usuarioCreditosRef = firestore.collection('usuarios').doc(cliente?.id).collection('creditos').doc(selctedClientCredito?.id)

    const calendarioRef = firestore.collection('calendario').doc(idFecha)

    const decrement = firestore.FieldValue.increment(-1);

    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef)
      .then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        const calendarioHorarios = sfDoc.data()
        let horarioSeconds
        let ready = false
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {

          if(horario?.id === idHorario && horario?.disponible){
            console.log('entro horario')
            horario?.lugares.map(lugar => {
              if(lugar?.id === idLugar && !lugar.ocupado){
                lugar.ocupado = true
                lugar.idCliente = cliente?.id
                lugar.cliente = `${cliente?.nombre} ${cliente?.apellidos}`
                horarioSeconds = horario?.id
                lugar.idClienteCredito = selctedClientCredito?.id

                ready = true
              }
              
              return lugar
            })
          }

          horario?.lugares.map(lugar => {
            if(lugar?.id === 1 && lugar.ocupado && lugar.idCliente === ''){
              lugar.ocupado = false
            }
            
            return lugar
          })


          return horario
        })

        if(!ready){
          resultado.exito = true
          resultado.option = 'reset'
          resultado.message = 'Se produjo un error'
          return calendarioHorarios
        }

        transaction.update(calendarioRef, { 
          horarios: calendarioHorarios?.horarios 
        });

        const fecha = newDateZoneSeconds(horarioSeconds)
        transaction.set(lugaresReservadosRef, {
          fecha: fecha,
          idFecha: idFecha,
          idHorario: idHorario,
          idLugar: idLugar,
          idCliente: cliente?.id,
          idClienteCredito: selctedClientCredito?.id
        })

        transaction.update(usuarioCreditosRef, {
          creditos: decrement
        })

        resultado.message = 'success'

      })
    })
    .then(result => {
        console.log('Transaction success!', result);
        resultado.exito = true
        resultado.option = 'reset'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado


  }
}

export const SetCancelReservePlaceDateByUser = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente, idClienteCredito } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const FSlugaresReservados = getState().storageDataReducer.FSlugaresReservados

    const lugarReservado = FSlugaresReservados.find(lugReservado => lugReservado?.idFecha === idFecha && lugReservado?.idLugar === idLugar && lugReservado?.idCliente === cliente?.id && lugReservado?.idClienteCredito === idClienteCredito)

    const firestore = getFirestore()

    const calendarioRef = firestore.collection('calendario').doc(idFecha)
    const lugaresReservadosRef = firestore.collection('lugaresReservados').doc(lugarReservado?.id)
    const usuarioCreditosRef = firestore.collection('usuarios').doc(cliente?.id).collection('creditos').doc(idClienteCredito)

    const increment = firestore.FieldValue.increment(1);
    const decrement = firestore.FieldValue.increment(-1);

    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        
        const calendarioHorarios = sfDoc.data()
        
        // let listaEspera
        let isInListaEspera
        let usuarioListaEsperaCreditosRef
        let ListaEsperaCreditosRef
        let newLugaresReservadosRef
        let horarioSeconds
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {
          if(horario?.id === idHorario){

            const listaEspera = JSON.parse(JSON.stringify(horario?.listaEspera))
            if(listaEspera?.length !== 0){
              isInListaEspera = listaEspera[0]
              usuarioListaEsperaCreditosRef = firestore.collection('usuarios').doc(isInListaEspera?.idCliente).collection('creditos').doc(isInListaEspera?.idClienteCredito)
              ListaEsperaCreditosRef = firestore.collection('listaEspera').doc(isInListaEspera?.idListaEspera)
              newLugaresReservadosRef = firestore.collection('lugaresReservados').doc()
              horario.listaEspera = horario?.listaEspera.filter(lista => lista?.idCliente !== isInListaEspera?.idCliente)
              horarioSeconds = horario?.id
            }

            horario?.lugares.map(lugar => {

              if(lugar?.id === idLugar && lugar.ocupado && lugar?.idCliente === cliente?.id){
                lugar.ocupado = isInListaEspera ? true : false
                lugar.idCliente = isInListaEspera ? isInListaEspera?.idCliente : ''
                lugar.cliente = isInListaEspera?.cliente ? isInListaEspera?.cliente : ''
                lugar.idClienteCredito = isInListaEspera ? isInListaEspera?.idClienteCredito : ''
              }
              return lugar
            })
          }
          return horario
        })


        transaction.update(calendarioRef, { 
          horarios: calendarioHorarios?.horarios,
          // listaEspera: isInListaEspera ? listaEspera.shift() : listaEspera
        });

        transaction.delete(lugaresReservadosRef)
        
        transaction.update(usuarioCreditosRef, {
          creditos: increment
        })
        
        if(isInListaEspera){
          transaction.delete(ListaEsperaCreditosRef)
          transaction.update(usuarioListaEsperaCreditosRef, {
            creditos: decrement
          })
          const fecha = newDateZoneSeconds(horarioSeconds)
          transaction.set(newLugaresReservadosRef, {
            fecha: fecha,
            idFecha: idFecha,
            idHorario: idHorario,
            idLugar: idLugar,
            idCliente: isInListaEspera?.idCliente,
            idClienteCredito: isInListaEspera?.idClienteCredito
          })

        }
      

      })
    })
    .then(result => {
      resultado.exito = true
      resultado.option = 'reset'
      resultado.message = 'success'
      return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado


  }
}

export const RegistrarClienteAction = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
        exito: false,
        option: '',
        message: ''
    }

    const firestore = getFirestore()
    const firebase = getFirebase()   
    const funct = firebase.functions()

    const checkRecaptchaToken = funct.httpsCallable('check_recaptcha_token')
    let recaptchaResponse = false
    await checkRecaptchaToken(data)
    .then(response => {
      console.log('response', response)
      if(response && response?.data && response?.data?.success && response?.data?.score > 0.4){
        recaptchaResponse = true
      }

    })
    .catch(error => {
        console.log('error', error);
    })

    // if(!recaptchaResponse){
    //     resultado.option = 'error'
    //     resultado.message = 'Se produjo un Error'
    //     return resultado
    // }


    const createUserAccount = funct.httpsCallable('admon_CrearCuentaUsuario')

    await createUserAccount(data)
        .then(response => {
          if(response?.data?.exito){

            resultado.exito = true
            resultado.option = 'reset'
          }else{
            
            resultado.option = 'error'
            resultado.message = response?.data?.message
          }

          return resultado
      })
      .catch(error => {
          console.log('error', error);
          resultado.option = 'error'
          resultado.message = 'Se produjo un error'
      })

      if(resultado.exito){

        await firebase.auth().signInWithEmailAndPassword(
          `${data.usuario}@fitness.com`,
            data.password
        ).then((data) =>{    
            // dispatch({ type: LOGIN_EXITO })        
        })
        .catch((error) => {
          console.log('error.code', error.code)
            // let mensajeError
            // // console.log(error)
            // if(error.code === 'auth/user-not-found'){       
            //     mensajeError = 'Usuario o contraseña invalida'           
            // }else if(error.code === 'auth/wrong-password'){
            //     mensajeError = 'Usuario o contraseña invalida'
            // }else if(error.code === 'auth/invalid-email'){
            //     mensajeError = 'El formato del usuario es invalido'
            // }else if(error.code === 'auth/user-disabled'){
            //     mensajeError = 'Cuenta deshabilitada'
            // }else{
            //     mensajeError = 'Se produjo un Error'
            // }              
  
            // dispatch({ type: LOGIN_ERROR, payload: mensajeError })  
        })
      }


    // await firestore.collection('usuarios').doc(data.id_comercio).collection('categoriasMenu').add({
    //   ...data,
    //   createAt: new Date()
    // })
    // .then(doc => {
    //     resultado.exito = true
    //     resultado.option = 'reset'
    // })
    // .catch(error => {
    //     // console.log(error);
    //     resultado.option = 'error'
    //     resultado.message = 'Se produjo un error'
    // })

    
    // resultado.message = 'Cambios realizados con exito!!'



    return resultado

  }
}

export const SetListaEsperaByUser = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const FSclienteCreditos = getState().storageDataReducer.FSclienteCreditos
    
    let selctedClientCredito
    FSclienteCreditos.map(FScliCred => {

      if(FScliCred?.fechaVigencia?.seconds > data?.idHorario && !selctedClientCredito){
        selctedClientCredito = FScliCred
      }

      return true
    })

    if(!selctedClientCredito){
      resultado.exito = true
      resultado.option = 'reset'
      resultado.message = 'No hay creditos para la fecha seleccionada'
      return resultado
    }

    const firestore = getFirestore()

    const calendarioRef = firestore.collection('calendario').doc(idFecha)
    const usuarioCreditosRef = firestore.collection('usuarios').doc(cliente?.id).collection('creditos').doc(selctedClientCredito?.id)
    let listaEsperaRef = firestore.collection('listaEspera').doc()

    
    const increment = firestore.FieldValue.increment(1);

    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        const calendarioHorarios = sfDoc.data()
        // let horarioSeconds

        let canSetListaEspera
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {
          if(horario?.id === idHorario){

            const lugares = horario?.lugares.filter(lugar => lugar?.ocupado === false)

            if(lugares?.length === 0){
              canSetListaEspera = true
              horario.listaEspera.push({
                idCliente: cliente?.id,
                idClienteCredito: selctedClientCredito?.id,
                idListaEspera: listaEsperaRef?.id,
                cliente: `${cliente?.nombre} ${cliente?.apellidos}`
              })

            }else{
              listaEsperaRef = null
            }
          }
          return horario
        })

        if(canSetListaEspera){

          transaction.update(calendarioRef, { horarios: calendarioHorarios?.horarios });
         
          const fecha = newDateZoneSeconds(idHorario)
  
          transaction.set(listaEsperaRef, {
            fecha: fecha,
            idFecha: idFecha,
            idHorario: idHorario,
            idCliente: cliente?.id,
            idClienteCredito: selctedClientCredito?.id
          })
        }else{
          transaction.set(listaEsperaRef)

        }



      });

    })
    .then(result => {
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'success'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado


  }
}

export const SetCancelarListaEsperaByUser = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente, idClienteCredito } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    // const FSlugaresReservados = getState().storageDataReducer.FSlugaresReservados

    // resultado.exito = true
    // resultado.option = 'reset'
    // return resultado

    // const lugarReservado = FSlugaresReservados.find(lugReservado => lugReservado?.idFecha === idFecha && lugReservado?.idLugar === idLugar && lugReservado?.idCliente === cliente?.id && lugReservado?.idClienteCredito === idClienteCredito)

    // console.log('lugarReservado', lugarReservado)
    const firestore = getFirestore()

    const calendarioRef = firestore.collection('calendario').doc(idFecha)
    // const lugaresReservadosRef = firestore.collection('lugaresReservados').doc(lugarReservado?.id)
    // const usuarioCreditosRef = firestore.collection('usuarios').doc(cliente?.id).collection('creditos').doc(idClienteCredito)

    // const increment = firestore.FieldValue.increment(1);
    // const decrement = firestore.FieldValue.increment(-1);

    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        
        const calendarioHorarios = sfDoc.data()
        
        let listaEspera
        let isInListaEspera
        let usuarioListaEsperaCreditosRef
        let listaEsperaRef

        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {
          if(horario?.id === idHorario){

            listaEspera = JSON.parse(JSON.stringify(horario?.listaEspera))
            console.log('listaEsperaFF', listaEspera)
            if(listaEspera?.length !== 0){
              isInListaEspera = listaEspera.find(lista => lista?.idCliente === cliente?.id)
              listaEsperaRef = firestore.collection('listaEspera').doc(isInListaEspera?.idListaEspera)
              horario.listaEspera = listaEspera.filter(lista => lista?.idCliente !== cliente?.id)
            }
          }
          return horario
        })


        transaction.update(calendarioRef, { 
          horarios: calendarioHorarios?.horarios,
        });

        transaction.delete(listaEsperaRef)
      })
    })
    .then(result => {
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'success'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado

  }
}


export const SetDisabledHorarioByAdmin = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente, idClienteCredito } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const firestore = getFirestore()
    const calendarioRef = firestore.collection('calendario').doc(idFecha)


    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }


        const calendarioHorarios = sfDoc.data()
        let ocupado = false
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {
          if(ocupado) return horario

          if(horario?.id === idHorario){


            // const ocupados = horario?.lugares.filter(lugar => lugar?.ocupado === true)
            // console.log('ocupadosAction', ocupados?.length)

            // if(ocupados?.length !== 0){
            //   resultado.message = 'Contiene lugares reservados'
            //   ocupado = true
            //   return horario
            // }

            resultado.message = 'success'
            horario.disponible = false
          }
          return horario
        })

        if(ocupado) return calendarioHorarios

        transaction.update(calendarioRef, { 
          horarios: calendarioHorarios?.horarios,
        });

      })
    })
    .then(result => {
        resultado.exito = true
        resultado.option = 'reset'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado

  }
}

export const SetEnabledHorarioByAdmin = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente, idClienteCredito } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const firestore = getFirestore()
    const calendarioRef = firestore.collection('calendario').doc(idFecha)


    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }


        const calendarioHorarios = sfDoc.data()
        
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {
          if(horario?.id === idHorario){

            // const ocupados = horario?.lugares.filter(lugar => lugar?.ocupado === true)
            // console.log('ocupadosAction', ocupados?.length)

            // if(ocupados?.length !== 0){
            //   resultado.message = 'Contiene lugares reservados'
            //   return horario
            // }
            
            resultado.message = 'success'
            horario.disponible = true
          }
          return horario
        })

        transaction.update(calendarioRef, { 
          horarios: calendarioHorarios?.horarios,
        });

      })
    })
    .then(result => {
        resultado.exito = true
        resultado.option = 'reset'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado

  }
}

export const SetDisabledFechaByAdmin = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente, idClienteCredito } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const firestore = getFirestore()
    const calendarioRef = firestore.collection('calendario').doc(idFecha)


    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }


        const calendarioHorarios = sfDoc.data()
        let ocupado = false
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {

            if(ocupado) return horario

            const ocupados = horario?.lugares.filter(lugar => lugar?.ocupado === true)

            if(ocupados?.length !== 0){
              resultado.message = 'Contiene lugares reservados'
              ocupado = true
              return horario
            }

            resultado.message = 'success'
            horario.disponible = false


          return horario
        })

        if(ocupado){
          resultado.message = 'Contiene lugares reservados'
          return calendarioHorarios
        } 

        resultado.message = 'success'
        transaction.update(calendarioRef, { 
          disponible: false,
          horarios: calendarioHorarios?.horarios
        });

      })
    })
    .then(result => {
        resultado.exito = true
        resultado.option = 'reset'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado

  }
}

export const SetEnabledFechaByAdmin = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente, idClienteCredito } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const firestore = getFirestore()
    const calendarioRef = firestore.collection('calendario').doc(idFecha)


    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }


        const calendarioHorarios = sfDoc.data()
        let ocupado = false
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {

            if(ocupado) return horario

            const ocupados = horario?.lugares.filter(lugar => lugar?.ocupado === true)

            if(ocupados?.length !== 0){
              resultado.message = 'Contiene lugares reservados'
              ocupado = true
              return horario
            }

            resultado.message = 'success'
            horario.disponible = true

            return horario
        })

        // if(ocupado){
        //   resultado.message = 'Contiene lugares reservados'
        //   return calendarioHorarios
        // } 

        resultado.message = 'success'
        transaction.update(calendarioRef, { 
          disponible: true,
          // horarios: calendarioHorarios?.horarios
        });

      })
    })
    .then(result => {
        resultado.exito = true
        resultado.option = 'reset'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado

  }
}






export const SetReservePlaceDateByZense = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const firestore = getFirestore()
    const calendarioRef = firestore.collection('calendario').doc(idFecha)

    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef)
      .then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        const calendarioHorarios = sfDoc.data()
        let horarioSeconds
        let ready = false
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {
          if(horario?.id === idHorario && horario?.disponible){
            horario?.lugares.map(lugar => {
              if(lugar?.id === idLugar && !lugar.ocupado){
                lugar.ocupado = true
                lugar.idCliente = cliente?.id
                lugar.cliente = `${cliente?.nombre} ${cliente?.apellidos}`
                horarioSeconds = horario?.id

                ready = true
              }
              
              return lugar
            })
          }

          horario?.lugares.map(lugar => {
            if(lugar?.id === 1 && lugar.ocupado && lugar.idCliente === ''){
              lugar.ocupado = false
            }
            
            return lugar
          })


          return horario
        })

        if(!ready){
          resultado.exito = true
          resultado.option = 'reset'
          resultado.message = 'Se produjo un error'
          return calendarioHorarios
        }

        transaction.update(calendarioRef, { 
          horarios: calendarioHorarios?.horarios 
        });

        resultado.message = 'success'

      })
    })
    .then(result => {
        resultado.exito = true
        resultado.option = 'reset'
        return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado


  }
}

export const SetCancelReservePlaceDateByZense = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente, idClienteCredito } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const firestore = getFirestore()

    const calendarioRef = firestore.collection('calendario').doc(idFecha)
    const decrement = firestore.FieldValue.increment(-1);

    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        
        const calendarioHorarios = sfDoc.data()
        
        let isInListaEspera
        let usuarioListaEsperaCreditosRef
        let ListaEsperaCreditosRef
        let newLugaresReservadosRef
        let horarioSeconds
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {
          if(horario?.id === idHorario){

            const listaEspera = JSON.parse(JSON.stringify(horario?.listaEspera))
            if(listaEspera?.length !== 0){
              isInListaEspera = listaEspera[0]
              usuarioListaEsperaCreditosRef = firestore.collection('usuarios').doc(isInListaEspera?.idCliente).collection('creditos').doc(isInListaEspera?.idClienteCredito)
              ListaEsperaCreditosRef = firestore.collection('listaEspera').doc(isInListaEspera?.idListaEspera)
              newLugaresReservadosRef = firestore.collection('lugaresReservados').doc()
              horario.listaEspera = horario?.listaEspera.filter(lista => lista?.idCliente !== isInListaEspera?.idCliente)
              horarioSeconds = horario?.id
            }

            horario?.lugares.map(lugar => {

              if(lugar?.id === idLugar && lugar.ocupado && lugar?.idCliente === cliente?.id){
                lugar.ocupado = isInListaEspera ? true : false
                lugar.idCliente = isInListaEspera ? isInListaEspera?.idCliente : ''
                lugar.cliente = isInListaEspera?.cliente ? isInListaEspera?.cliente : ''
                lugar.idClienteCredito = isInListaEspera ? isInListaEspera?.idClienteCredito : ''
              }
              return lugar
            })
          }
          return horario
        })


        transaction.update(calendarioRef, { 
          horarios: calendarioHorarios?.horarios,
        });

        if(isInListaEspera){
          transaction.delete(ListaEsperaCreditosRef)
          transaction.update(usuarioListaEsperaCreditosRef, {
            creditos: decrement
          })
          const fecha = newDateZoneSeconds(horarioSeconds)
          transaction.set(newLugaresReservadosRef, {
            fecha: fecha,
            idFecha: idFecha,
            idHorario: idHorario,
            idLugar: idLugar,
            idCliente: isInListaEspera?.idCliente,
            idClienteCredito: isInListaEspera?.idClienteCredito
          })

        }
      

      })
    })
    .then(result => {
      resultado.exito = true
      resultado.option = 'reset'
      resultado.message = 'success'
      return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado


  }
}

export const RegistrarClienteActionByAdmin = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
        exito: false,
        option: '',
        message: ''
    }

    const firebase = getFirebase()   
    const funct = firebase.functions()

    const createUserAccount = funct.httpsCallable('admon_CrearCuentaUsuario')

    await createUserAccount(data)
        .then(response => {
          if(response?.data?.exito){

            resultado.exito = true
            resultado.option = 'reset'
          }else{
            
            resultado.option = 'error'
            resultado.message = response?.data?.message
          }

          return resultado
      })
      .catch(error => {
          console.log('error', error);
          resultado.option = 'error'
          resultado.message = 'Se produjo un error'
      })

    return resultado

  }
}

export const SetCancelClientReservationByAdmin = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFecha, idHorario, idLugar, cliente, idClienteCredito } = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    // return

    // const FSlugaresReservados = getState().storageDataReducer.FSlugaresReservados
    // console.log('FSlugaresReservados', FSlugaresReservados)

    // const lugarReservado = FSlugaresReservados.find(lugReservado => lugReservado?.idFecha === idFecha && lugReservado?.idLugar === idLugar && lugReservado?.idCliente === cliente?.id && lugReservado?.idClienteCredito === idClienteCredito)

    // console.log('lugarReservado', lugarReservado)
    const firestore = getFirestore()

    const calendarioRef = firestore.collection('calendario').doc(idFecha)
    const creditosRef = firestore.collection('usuarios').doc(cliente).collection('creditos').doc()

    const lugarReservadoRef = firestore.collection('lugaresReservados').where('idCliente', '==', cliente).where('idHorario', '==', idHorario)
    // lugarReservadoRef.where('idCliente', '==', cliente).where('idHorario', '==', idHorario)

    // const lugarReservado = await lugarReservadoRef.delete()
    const lugarReservado = await lugarReservadoRef.get()
    let idLugarReservado
    if(!lugarReservado?.empty){
      lugarReservado.forEach(async doc => {

        // const creditoData = doc.data()

        idLugarReservado = doc?.id
        // await firestore.collection('lugaresReservados').doc(doc.id).delete()
        // .then(resp => {
        //   console.log('resp', resp)
        // })
        // .catch(error => {
        //   console.log('error', error)          
        // })
      });
    }
    
    // return
    const lugareReservadoDeleteRef = firestore.collection('lugaresReservados').doc(idLugarReservado)
    const usuarioCreditosRef = firestore.collection('usuarios').doc(cliente).collection('creditos').doc(idClienteCredito)

    const increment = firestore.FieldValue.increment(1);
    const decrement = firestore.FieldValue.increment(-1);

    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef).then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        
        const calendarioHorarios = sfDoc.data()
        
        // let listaEspera
        // let isInListaEspera
        // let usuarioListaEsperaCreditosRef
        // let ListaEsperaCreditosRef
        // let newLugaresReservadosRef
        // let horarioSeconds
        calendarioHorarios?.horarios && calendarioHorarios?.horarios.map(horario => {
          if(horario?.id === idHorario){

            // const listaEspera = JSON.parse(JSON.stringify(horario?.listaEspera))
            // console.log('listaEsperaFF', listaEspera)
            // if(listaEspera?.length !== 0){
            //   isInListaEspera = listaEspera[0]
            //   usuarioListaEsperaCreditosRef = firestore.collection('usuarios').doc(isInListaEspera?.idCliente).collection('creditos').doc(isInListaEspera?.idClienteCredito)
            //   ListaEsperaCreditosRef = firestore.collection('listaEspera').doc(isInListaEspera?.idListaEspera)
            //   newLugaresReservadosRef = firestore.collection('lugaresReservados').doc()
            //   horario.listaEspera = horario?.listaEspera.filter(lista => lista?.idCliente !== isInListaEspera?.idCliente)
            //   horarioSeconds = horario?.id
            // }

            horario?.lugares.map(lugar => {

              if(lugar?.id === idLugar && lugar.ocupado && lugar?.idCliente === cliente){
                lugar.ocupado = false
                lugar.idCliente = ''
                lugar.cliente = ''
                lugar.idClienteCredito = ''
              }
              return lugar
            })
          }
          return horario
        })


        transaction.update(calendarioRef, { 
          horarios: calendarioHorarios?.horarios,
        });

        if(idLugarReservado){
          transaction.delete(lugareReservadoDeleteRef)
        }


        const vigencia = newDateZone()
        vigencia.setHours(23,0,0,0)
        vigencia.setDate(vigencia.getDate() + parseInt(150))
        const idVigencia = vigencia.getTime() / 1000;
        
        transaction.set(creditosRef, {
          createdAt: newDateZone(),
          idVigencia: idVigencia,
          uid: uid(),
          fechaVigencia: vigencia,
          creditos: 1,
          vipCredit: true
        })

        // transaction.update(usuarioCreditosRef, {
        //   creditos: increment
        // })
        
        // if(isInListaEspera){
        //   transaction.delete(ListaEsperaCreditosRef)
        //   transaction.update(usuarioListaEsperaCreditosRef, {
        //     creditos: decrement
        //   })
        //   const fecha = newDateZoneSeconds(horarioSeconds)
        //   transaction.set(newLugaresReservadosRef, {
        //     fecha: fecha,
        //     idFecha: idFecha,
        //     idHorario: idHorario,
        //     idLugar: idLugar,
        //     idCliente: isInListaEspera?.idCliente,
        //     idClienteCredito: isInListaEspera?.idClienteCredito
        //   })

        // }
      

      })
    })
    .then(result => {
      resultado.exito = true
      resultado.option = 'reset'
      resultado.message = 'success'
      return resultado

    }).catch(err => {
        console.log('Transaction failure:', err);
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    });

    return resultado


  }
}

export const AgregarNuevoHorario = (data) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const datos = JSON.parse(JSON.stringify(data))
    const {idFechaCalendario, idHorario, horarioDesc} = datos
    const resultado = {
      exito: false,
      option: '',
      message: ''
    }

    const firestore = getFirestore()
    const calendarioRef = firestore.collection('calendario').doc(idFechaCalendario)


    await firestore.runTransaction(async transaction => {

      return transaction.get(calendarioRef)
      .then(async (sfDoc) => {
        if (!sfDoc.exists) {
            throw "Document does not exist!";
        }

        const calendarioHorarios = sfDoc.data()

        function compareIdHorarios(a, b) {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        }

        calendarioHorarios?.horarios.push({
          id: idHorario,
          horarioDesc: horarioDesc,
          disponible: false,
          lugares: lugares,
          listaEspera: []
        })

        calendarioHorarios?.horarios.sort(compareIdHorarios)

        transaction.update(calendarioRef, { 
          horarios: calendarioHorarios?.horarios 
        });

          return calendarioHorarios
        })


      })
      .then(result => {
          resultado.exito = true
          resultado.option = 'reset'
          resultado.message = 'success'
          return resultado
  
      }).catch(err => {
          console.log('Transaction failure:', err);
          resultado.exito = true
          resultado.option = 'reset'
          resultado.message = 'Se produjo un error'
      });
  
      return resultado

  }
}

export const GuardarDiasHorarios = (datos) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

      const resultado = {
          exito: false,
          option: '',
          message: ''
      }

      const data = JSON.parse(JSON.stringify(datos))
      const firestore = getFirestore()

      await firestore.collection('config').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
          dias: data
      })
      .then(() => {
          
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'success'
        return resultado

      })
      .catch(error => {
          console.log('error', error)
          resultado.exito = true
          resultado.option = 'reset'
          resultado.message = 'Se produjo un error'
      })

      return resultado

  }
}

export const CreateNewAllWeekHorarios = (datos) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
      exito: true,
      option: '',
      message: ''
  }

  const firebase = getFirebase()   
  const funct = firebase.functions()

  const crearAllWeekHorarios = funct.httpsCallable('admon_CrearAllWeekHorarios')

  // const horarios = JSON.parse(JSON.stringify(datos))

  // horarios.shift();

  await crearAllWeekHorarios(datos)
      .then(response => {
        console.log('responsePP', response)
        if(response?.data?.exito){

          resultado.exito = true
          resultado.option = response?.data?.option
          resultado.message = response?.data?.message
        }else{
          
          resultado.option = 'reset'
          resultado.message = response?.data?.message
        }

        return resultado
    })
    .catch(error => {
        console.log('error', error);
        resultado.option = 'reset'
        resultado.message = 'Se produjo un error'
    })

  return resultado


  }
}


export const CreateAllWeekHorarios = (time, datos) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

      const resultado = {
          exito: false,
          option: '',
          message: ''
      }

      const firestore = getFirestore()
      const batch = firestore.batch()
      const calendarioRef = firestore.collection('calendario')
  
      const date = new Date(time?.utcTime);
      let allDays = getDaysInWeek(date)
  
      const today = allDays[0]

      let year = today.getFullYear()
      let month = parseInt(today.getMonth()) + 1
      let day = today.getDate()

      let idDocument = `${year}${month <= 9 ? '0'+month : month}${day <= 9 ? '0'+day : day}`

      let isCreated = await firestore.collection('calendario').doc(idDocument).get()

      if(isCreated?.data()){
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'Fecha duplicada'

        // return resultado
        const ArraydiasSemana = new Array("Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado")
        const addDays = 7 - parseInt(today.getDay())
        today.setDate(today.getDate() + addDays);
  
  
        allDays = getDaysInWeek(today)
    
        year = today.getFullYear()
        month = parseInt(today.getMonth()) + 1
        day = today.getDate()
  
        idDocument = `${year}${month <= 9 ? '0'+month : month}${day <= 9 ? '0'+day : day}`
  
        isCreated = await firestore.collection('calendario').doc(idDocument).get()
        
        if(isCreated?.data()){
          resultado.exito = true
          resultado.option = 'reset'
          resultado.message = 'Semana no disponible'
  
          return resultado        
        }

      } 

      const dias = datos

      const lugares = [
        {
            id: 1,
            ocupado: false,
            cliente: '',
            idCliente: ''
        },
        {
            id: 2,
            ocupado: false,
            cliente: '',
            idCliente: ''
        },
        {
            id: 3,
            ocupado: false,
            cliente: '',
            idCliente: ''
        },
        {
            id: 4,
            ocupado: false,
            cliente: '',
            idCliente: ''
        },
        {
            id: 5,
            ocupado: false,
            cliente: '',
            idCliente: ''
        },
        {
            id: 6,
            ocupado: false,
            cliente: '',
            idCliente: ''
        }
      ]

      // return
      const allIdDocuments = []
      allDays.map(fecha => {

        const year = fecha.getFullYear()
        const month = parseInt(fecha.getMonth()) + 1
        const day = fecha.getDate()
  
        const idDocument = `${year}${month <= 9 ? '0'+month : month}${day <= 9 ? '0'+day : day}`
        allIdDocuments.push(idDocument)
  
        const horariosData = dias[fecha.getDay()]
  
        const horariosDoc = []
  
        // const horariosRef = firestore.collection('calendario').doc(idDocument).collection('horarios')
        horariosData?.horarios && horariosData?.horarios.map(horarios => {
          const horario = horarios.split(':')
          fecha.setHours(0)
          fecha.setMinutes(0)
          fecha.setHours(fecha.getHours() + horario[0]);
          fecha.setMinutes(fecha.getMinutes() + horario[1]);
          const seconds = fecha.getTime() / 1000;
  
          horariosDoc.push({
            id: seconds,
            horarioDesc: getFecha(seconds, 4),
            disponible: true,
            lugares: lugares,
            listaEspera: []
          })
  
          return true
        })
  
        fecha.setHours(0)
        fecha.setMinutes(0)
        batch.set(calendarioRef.doc(idDocument), {
          fecha: fecha,
          disponible: false,
          horarios: horariosDoc
        })

        return true
      })

      await batch.commit()
      .then(() => {
          resultado.exito = true
          resultado.option = 'reset'
          resultado.message = 'success'
          return resultado
      })
      .catch(error => {
          console.log('error', error)
          resultado.option = 'error'
          resultado.message = 'Se produjo un error'
      })


      return resultado


    }
  }


export const GetClienteCreditos = (datos) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

    const resultado = {
      exito: false,
      option: '',
      message: ''
    } 

    const firestore = getFirestore()

    const now = newDateZone()
    now.setHours(0, 0, 0, 0)
    const clienteCreditosRef = firestore.collection('usuarios').doc(datos?.id).collection('creditos').where('fechaVigencia', '>=', now)
    // clienteCreditosRef.where('fechaVigencia', '>=', now)
    const creditos = await clienteCreditosRef.get()

    const allCreditos = []
    if(creditos?.empty){

    }else{
      creditos.forEach(doc => {

        const creditoData = doc.data()

        if(creditoData?.creditos > 0){
          creditoData.id = doc?.id
          allCreditos.push(creditoData)
        }
      });
    }
  
    return allCreditos

  }
}

export const UpdateClientCredit = (datos) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

      const resultado = {
          exito: false,
          option: '',
          message: ''
      }

      const firestore = getFirestore()

      await firestore.collection('usuarios').doc(datos?.idCliente).collection('creditos').doc(datos?.idCredito).update({
          creditos: datos?.creditos
      })
      .then(() => {
          
        resultado.exito = true
        resultado.option = 'reset'
        resultado.message = 'success'
        return resultado

      })
      .catch(error => {
          console.log('error', error)
          resultado.exito = true
          resultado.option = 'reset'
          resultado.message = 'Se produjo un error'
      })

      return resultado

  }
}

export const GetLocalTime = (datos) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

      const resultado = {
        exito: false,
        option: 'reset',
        message: 'Se produjo un error',
        time: null
    }

    
    const firebase = getFirebase()
    const funct = firebase.functions()
    
    const localTime = funct.httpsCallable('get_localTime')
    
    await localTime()
    .then(response => {
      if(response && response?.data && response?.data.exito){
          
        const utcTime = new Date(response?.data?.utcTime)
        const seconds = utcTime.getTime() / 1000;

        resultado.exito = true
        resultado.time = {
          utcTime,
          seconds
        }
      }
        
    })
    .catch(error => {
      console.log('error', error);
    })

    return resultado

  }
}

export const CreatePaymentIntent = (datos) => {
  return async (dispatch, getState, {getFirebase, getFirestore}) => {

      const resultado = {
          exito: false,
          option: '',
          message: '',
          paymentIntent: null
      }

      console.log('datos payment',datos)
      
      // return resultado

      const firebase = getFirebase()
      const funct = firebase.functions()
      const Cliente_CreatePaymentIntent = funct.httpsCallable('Cliente_CreatePaymentIntent')
      await Cliente_CreatePaymentIntent(datos)
      .then(response => {

          console.log('response',response)

          if(response.data.exito){
              resultado.exito = response.data.exito
              resultado.paymentIntent = response.data.paymentIntent
          }

      })
      .catch(error => {
          console.log('error', error);
      })

      return resultado
  
  }
}
