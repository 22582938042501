import React, { useState, Fragment } from "react";
import { TextField, Box } from "@material-ui/core";
import { TimePicker } from "@material-ui/pickers";
import moment from 'moment'
import { newDateZone } from "../../allFunctions";

export default function BasicTimePicker() {
  const [date, changeDate] = useState(newDateZone());

  const fecha = moment(date)
  console.log('dateFF', moment(date))
  console.log('date', date)
  console.log('fecha', fecha.hours())
  console.log('fecha', fecha.minutes())
  
  const handleChange = (data) => {
    
    console.log('dataRR', data)
  }

  return (
    <Fragment>
      <Box maxWidth={350}>
        <TimePicker 
        autoOk
        variant="static"
        openTo="hours"
        value={date}
        onChange={changeDate}
        />
      </Box>


      {/* <TimePicker
        clearable
        ampm={false}
        label="24 hours"
        value={selectedDate}
        onChange={handleDateChange}
      />

      <TimePicker
        showTodayButton
        todayLabel="now"
        label="Step = 5"
        value={selectedDate}
        minutesStep={5}
        onChange={handleDateChange}
      /> */}
    </Fragment>
  );
}