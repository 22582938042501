import { connect } from 'react-redux'

import { Box, Button, Card, Typography, Grid } from '@material-ui/core';
import { ZENSEADMIN_UID, ZENSE_UID } from '../../allFunctions';


const LugaresCliente = (props) => {

  const { horariosSelected, selectedCliente, selectedLugar, handleSelectLugar, handleSetLugarHorario, handleCancelReservation, hasReserve, handleSetListaEspera, handleCancelListaEspera, profile, auth, 
    handleZenseSetLugarHorario, handleCancelReservationZense, handleAskCancelClientReservation
  } = props

  let buttonColor = ''

  if(selectedLugar){
    buttonColor = 'lugar-button-reservar'
  }else if(hasReserve){
    buttonColor = 'lugar-button-cancelar'
  }

  const listaEspera = horariosSelected?.lugares.filter(lugar => lugar?.ocupado === false)
  const isInListaEspera = horariosSelected?.listaEspera.find(lista => lista?.idCliente === selectedCliente?.id)

  if(auth?.uid === ZENSE_UID){

    return (
      <>
        <Box className="lugares-container">
          {horariosSelected?.lugares.map(lugar => {
  
            let cama = `Cama ${lugar?.id}`
            let selected = ''
            if(lugar?.ocupado && selectedCliente?.id === lugar?.idCliente){
              selected = 'pilate-camaocupado-cliente'
            }else if(lugar?.ocupado){
              selected = 'pilate-cama-ocupado'
  
            }else if(selectedLugar && selectedLugar?.id === lugar.id){
              selected = 'pilate-cama-selected'
            }
  
            if(lugar?.ocupado && profile?.roll === 'admin'){
              cama = lugar?.cliente
            }
            return (
              <Card variant="outlined" className={`pilate-cama ${selected}`} key={lugar?.id} onClick={lugar?.ocupado && lugar?.idCliente !== ZENSE_UID ? null : lugar?.ocupado && lugar?.idCliente === ZENSE_UID ? () => handleCancelReservationZense(lugar) : () => handleZenseSetLugarHorario(lugar)}>
                <Typography>
                {lugar?.ocupado ? lugar?.cliente : cama}
                </Typography>
              </Card>
            )
          })}
  
          </Box>
  
          <Box className='button-lugar-container'>
  
            {listaEspera && listaEspera?.length === 0 ?
            <>
            <Typography className='lista-espera'>
              {isInListaEspera ? `Lista de espera posición: ${parseInt(horariosSelected?.listaEspera.map(lista => lista?.idCliente).indexOf(selectedCliente?.id)) + 1}` : `${horariosSelected?.listaEspera?.length} en espera`}
            </Typography>
            <Button fullWidth className={`action-lugar-button`} variant="contained" 
            // onClick={isInListaEspera ? handleCancelListaEspera : handleSetListaEspera}
            >
            {/* {isInListaEspera ? 'Salir de lista de espera' : 'Entrar a lista de espera'} */}
            </Button>
            </>
            : selectedCliente ?
            <Button fullWidth className={`action-lugar-button`} 
            color={selectedLugar ? 'primary' : hasReserve ? 'secondary' : 'default'}
            variant='contained' 
            // onClick={selectedLugar ? handleSetLugarHorario : hasReserve ? handleCancelReservation : null}
            disabled={true}
            >
             
            </Button>
            :
            null
            }
          </Box>
      </>
    )
  }

  if(auth?.uid === ZENSEADMIN_UID){

    return (
      <>
        <Box className="lugares-container">
          {horariosSelected?.lugares.map(lugar => {
  
            let cama = `Cama ${lugar?.id}`
            let selected = ''
            if(lugar?.ocupado && selectedCliente?.id === lugar?.idCliente){
              selected = 'pilate-camaocupado-cliente'
            }else if(lugar?.ocupado){
              selected = 'pilate-cama-ocupado'
  
            }else if(selectedLugar && selectedLugar?.id === lugar.id){
              selected = 'pilate-cama-selected'
            }
  
            if(lugar?.ocupado && profile?.roll === 'admin'){
              cama = lugar?.cliente
            }
            return (
              <Card variant="outlined" className={`pilate-cama ${selected}`} key={lugar?.id} 
              onClick={lugar?.ocupado || hasReserve ? () => handleAskCancelClientReservation(lugar) : () => handleSelectLugar(lugar)}
              >
                <Typography>
                {cama}
                </Typography>
              </Card>
            )
          })}
  
          </Box>
  
          <Box className='button-lugar-container'>
  
            {!hasReserve && listaEspera && listaEspera?.length === 0 ?
            <>
            <Typography className='lista-espera'>
              {isInListaEspera ? `Lista de espera posición: ${parseInt(horariosSelected?.listaEspera.map(lista => lista?.idCliente).indexOf(selectedCliente?.id)) + 1}` : `${horariosSelected?.listaEspera?.length} en espera`}
            </Typography>
            <Button fullWidth className={`action-lugar-button`} variant="contained" onClick={isInListaEspera ? handleCancelListaEspera : handleSetListaEspera}>
            {isInListaEspera ? 'Salir de lista de espera' : 'Entrar a lista de espera'}
            </Button>
            </>
            : selectedCliente ?
            <Button fullWidth className={`action-lugar-button`} 
            color={selectedLugar ? 'primary' : hasReserve ? 'secondary' : 'default'}
            variant='contained' 
            onClick={selectedLugar ? handleSetLugarHorario : hasReserve ? handleCancelReservation : null}
            disabled={!selectedLugar && !hasReserve}
            >
              {selectedLugar ? 'Reservar' : hasReserve ? 'Cancelar' : ''}
            </Button>
            :
            null
            }
          </Box>
      </>
    )


  }

  return (
    <Grid className="lugares-scroll-container">
      <Box className="lugares-container">
        {horariosSelected?.lugares.map(lugar => {

          let cama = `Cama ${lugar?.id}`
          let selected = ''
          if(lugar?.ocupado && selectedCliente?.id === lugar?.idCliente){
            selected = 'pilate-camaocupado-cliente'
          }else if(lugar?.ocupado){
            selected = 'pilate-cama-ocupado'

          }else if(selectedLugar && selectedLugar?.id === lugar.id){
            selected = 'pilate-cama-selected'
          }

          if(lugar?.ocupado && profile?.roll === 'admin'){
            cama = lugar?.cliente
          }
          return (
            <Card variant="outlined" className={`pilate-cama ${selected}`} key={lugar?.id} onClick={lugar?.ocupado || hasReserve ? null : () => handleSelectLugar(lugar)}>
              <Typography>
              {cama}
              </Typography>
            </Card>
          )
        })}

        </Box>

        <Box className='button-lugar-container'>

          {!hasReserve && listaEspera && listaEspera?.length === 0 ?
          <>
          <Typography className='lista-espera'>
            {isInListaEspera ? `Lista de espera posición: ${parseInt(horariosSelected?.listaEspera.map(lista => lista?.idCliente).indexOf(selectedCliente?.id)) + 1}` : `${horariosSelected?.listaEspera?.length} en espera`}
          </Typography>
          <Button fullWidth className={`action-lugar-button`} variant="contained" onClick={isInListaEspera ? handleCancelListaEspera : handleSetListaEspera}>
          {isInListaEspera ? 'Salir de lista de espera' : 'Entrar a lista de espera'}
          </Button>
          </>
          : selectedCliente ?
          <Button fullWidth className={`action-lugar-button`} 
          color={selectedLugar ? 'primary' : hasReserve ? 'secondary' : 'default'}
          variant='contained' 
          onClick={selectedLugar ? handleSetLugarHorario : hasReserve ? handleCancelReservation : null}
          disabled={!selectedLugar && !hasReserve}
          >
            {selectedLugar ? 'Reservar' : hasReserve ? 'Cancelar' : ''}
          </Button>
          :
          null
          }
        </Box>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth, 
    profile: state.firebase.profile, 
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      // logout: (datos) => dispatch(logout(datos)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LugaresCliente)