import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types'

//*COMPONENTES
import OrdenPedido from './OrdenPedido'
import { ActivarSoundAlert } from '../../store/actions/perfilComercioActions'
import { SetNumPaymentsNuevos } from '../../store/actions/storageDataAction'
import { ActualizarPaymentVisto, ActualizarPaymentAtendido } from '../../store/actions/ordenPedidosActions'
import { SendMenssageAction } from '../../store/actions/messageActions'
import { telFormat, getFecha } from '../../allFunctions'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'

import { withStyles } from '@material-ui/styles'
import { Container } from '@material-ui/core';
import { CardTitle } from '../../elementos/card-title';
import styled from 'styled-components';

const ContainerMisClases = styled(({ ...props }) => <Container {...props} />)`
  ${({ theme }) => `

  
  margin-top: 80px;

    .clase-horario {
        font-size: 18px;
    }

    .clase-horario-time {
        font-size: 18px;
        font-weight: bold;
    }

    .reservation-classes-container {
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5px;
    }


`}
`;

const styles = theme => ({
    styleContenedor: {
        margin: 'auto',
        maxWidth: 720,
        marginTop: 80,
        [theme.breakpoints.down('xs')]: {
            marginTop: 60,
        }      
    },
})

const payment = []

const Ordenes = (props) => {

    const { FSlugaresReservados, auth } = props

    const [inicializado, setInicializado] = useState(false)
    const [openOrdenPedido, setOpenOrdenPedido] = useState(false)
    const [ordenPedidoData, setOrdenPedidoData] = useState(null)
    const [allOrdenPedidosFood, setAllOrdenPedidosFood] = useState(null)

    useEffect(() => {

        // requestPermission()

    }, [])

    useEffect(() => {

        if(!inicializado){
            inicializar()
            return
        }
        

        if(props.FSordenesFood){
            let nuevo = false
            const ordenes = []
            props.FSordenesFood.map(ordenPedido => {

                if(ordenPedido.status === 1) return

                if(!payment.includes(ordenPedido.id)){
                    payment.push(ordenPedido.id)
                    nuevo = true
                }

                ordenes.push(ordenPedido)

            })

            if(nuevo){
                // props.ActivarSoundAlert({playSound: true})
                nonPersistentNotification()
                // console.log('nuevo',nuevo)                
            }
            
            setAllOrdenPedidosFood(ordenes)
            // document.title = `(${ordenes.length}) guuwii Pagos`;
            props.SetNumPaymentsNuevos(ordenes.length)

        }

    }, [props.FSordenesFood, inicializado])

    const inicializar = async () => {

        await new Promise(resolve => setTimeout( resolve , 5000))  
        setInicializado(true)
    }

  
    const requestPermission = () => {
        if (!('Notification' in window)) {
          alert('Notification API not supported!');
          return;
        }
        
        Notification.requestPermission(function (result) {
          console.log('result',result)
          
        });
    }

    const nonPersistentNotification = () => {
        if (!('Notification' in window)) {
            alert('Notification API not supported!');
            return;
        }
        
        try {
            var notification = new Notification("Nueva Orden Recibida!");
        } catch (err) {
            alert('Notification API error: ' + err);
        }
    }
      

    const handleOpenOrdenPedido = (ordenData) => {

        if(ordenData.status === 0){
            console.log('actualizar')
            // props.ActualizarPaymentVisto({id: ordenData.id})
        }

        setOrdenPedidoData(ordenData)
        setOpenOrdenPedido(true)
        
    }

    const handleActualizarPaymentAtendido = async (id) => {

        props.SendMenssageAction({option: 'inicio'})
        const actualizar = await props.ActualizarPaymentAtendido({id: id})

        if(actualizar.exito){
            setOpenOrdenPedido(false)
            setOrdenPedidoData(null)
        }
        props.SendMenssageAction(actualizar)
    }

    const handleCloseOrdenPedido = () => {

        setOpenOrdenPedido(false)
        setOrdenPedidoData(null)
        
    }

    if(auth?.uid === 'GQmvrpgmcDfsz3s1iuhapiUhOtx1') return (
        <ContainerMisClases maxWidth="md">

        <CardTitle title="Mis clases" />

        </ContainerMisClases>

    )

    return(
        <ContainerMisClases maxWidth="md">

            <CardTitle title="Mis clases" />

            <Box className='mt-2'>

                {FSlugaresReservados && FSlugaresReservados.map(lugarReservado => {

                    return (
                    <Box className="reservation-classes-container" key={lugarReservado?.idHorario}>
                        <Box flexGrow={1}>
                            <Typography  className='clase-horario'>
                            {getFecha(lugarReservado?.idHorario, 10)}
                            </Typography>
                        </Box>
                        <Box className='mr-2'>
                            <Typography className='clase-horario'>
                            {getFecha(lugarReservado?.idHorario, 4)}
                            </Typography>
                        </Box>
                        <Box className='ml-2'>
                            <Typography className='clase-horario-time'>
                            Cama {lugarReservado?.idLugar}
                            </Typography>
                        </Box>

                    </Box>

                    )
                })}
            </Box>
        </ContainerMisClases>
    )

    //*render
    if(!allOrdenPedidosFood) return null
    const { classes } = props

    let count

    count = allOrdenPedidosFood.length
    
    return (
        <Fragment>
             <Grid item className={classes.styleContenedor} xs={12}  >

                <Box>
                    {allOrdenPedidosFood && allOrdenPedidosFood.map(ordenPedido => {
                        console.log('ordenPedido', ordenPedido)

                        let total = 0
                        ordenPedido.productos.map(producto => {
                            total = parseFloat(total) + parseFloat(producto.precio)
                        })

                        return(
                            <Box p={1} key={ordenPedido.id}>
                                <Card variant='outlined' style={{backgroundColor: ordenPedido.status === 0 ? '#fff' : '#b2dfdb', cursor: 'pointer'}} onClick={() => handleOpenOrdenPedido(ordenPedido)}>
                                    <Box minHeight={100}>
                                        <Box textAlign='center' style={{backgroundColor: '#000'}}>
                                            <Typography style={{color: '#fff', fontSize: '20px'}}>
                                                ID: <span style={{color: '#00BCD4'}}>{ordenPedido.id_orden.toUpperCase().slice(7, 11)}</span>
                                            </Typography>
                                        </Box>
                                        <Box px={1} display='flex'>
                                            <Box flexGrow={1}>
                                            <Typography style={{fontSize: '16px'}}>
                                            $ {parseFloat(total).toFixed(2)}
                                            </Typography>
                                            </Box>
                                            <Box>
                                            <Typography style={{fontSize: '16px'}}>
                                            {getFecha(ordenPedido.createdAt.seconds, 3)}
                                            </Typography>
        
                                            </Box>
                                        </Box>
                                        {/* <Box px={1}>
                                            <Typography style={{fontSize: '16px'}}>
                                                {ordenPedido.domicilio}
                                            </Typography>
                                            <Typography style={{fontSize: '16px'}}>
                                                NUeva Orden
                                            </Typography>
                                            <Typography style={{fontSize: '16px'}}>
                                                $ {parseFloat(total).toFixed(2)}
                                            </Typography>
                                        </Box> */}
                                    </Box>
                                </Card>
                            </Box>
                        )
                    })}
                    
                </Box>

            </Grid>

            <Dialog 
            open={openOrdenPedido}
            // TransitionComponent={Transition}
            maxWidth='sm'
            fullWidth
            onClose={handleCloseOrdenPedido}
            >
                <Box>
                   {ordenPedidoData ? 

                   <OrdenPedido 
                   ordenPedidoData={ordenPedidoData}
                   handleActualizarPaymentAtendido={handleActualizarPaymentAtendido}
                   />
                   :
                   null
                   }
                   
                </Box>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        auth: state.firebase.auth, 
        FSpagosHoy: state.storageDataReducer.FSpagosHoy,
        FSordenesFood: state.storageDataReducer.FSordenesFood,
        FSlugaresReservados: state.storageDataReducer.FSlugaresReservados


    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         ActivarSoundAlert: (datos) => dispatch(ActivarSoundAlert(datos)),
         SetNumPaymentsNuevos: (datos) => dispatch(SetNumPaymentsNuevos(datos)),
         ActualizarPaymentVisto: (datos) => dispatch(ActualizarPaymentVisto(datos)),
         ActualizarPaymentAtendido: (datos) => dispatch(ActualizarPaymentAtendido(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Ordenes))

Ordenes.propTypes = {
    
}