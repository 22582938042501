import React, { useState, useEffect, Fragment, forwardRef, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

//*COMPONENTES
import LoginForm from './LoginForm'
import RegisterForm from '../usuarios/registro/RegisterForm'
import Message from '../layout/Message'
import { logout } from '../../store/actions/authActions'

//*MATERIAL UI
import Grow from '@material-ui/core/Grow'
import Zoom from '@material-ui/core/Zoom';
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import Divider from '@material-ui/core/Divider'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import clsx from 'clsx'
import Drawer from '@material-ui/core/Drawer'

import { withStyles } from '@material-ui/styles'
import RegistrarCliente from '../menuComercio/RegistrarCliente'
import { Button } from '@material-ui/core'
import RegistrarClienteForm from '../menuComercio/RegistrarClienteForm'
import styled from 'styled-components'
import TerminosCondiciones from './TerminosCondiciones'


const styles = theme => ({
    card: {
        width: 220,
        height: 40,
        cursor: 'pointer'
    },
    typo: {
        color: '#757575',
    },
    regresar: {
        backgroundColor: '#263238',
        cursor: 'pointer',
        color: '#ffffff'
    },
})

const ContainerRegistrarCuenta = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

    .registrar-cuenta-container {
        display: flex;
        justify-content: center;
    }

    .button-height {
        width: 250px;
        height: 40px;
    }

`}
`

const Login = (props) => {

    const [openRegistrarUsuario, setOpenRegistrarUsuario] = useState(false);
    const [terminosCondiciones, setTerminosCondiciones] = useState(false);
    const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        // props.logout()
    }, [])

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
        }

        const token = await executeRecaptcha('loginForm');
        setGoogleRecaptchaToken(token)
        // Do whatever you want with the token
    }, [executeRecaptcha]);


      // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    
  

    const handleOpenRegistrarUsuario = () => {
        setTerminosCondiciones(false)
        setOpenRegistrarUsuario(true)
    }

    const handleCloseRegistrarUsuario = () => {
        setOpenRegistrarUsuario(false)
    }

    const handleOpenTerminos = () => {
        setTerminosCondiciones(true)

    }

    const handleCloseTerminos = () => {
        setTerminosCondiciones(false)
    }
        
   
    //*render
    const { classes } = props


    return (          
        <ContainerRegistrarCuenta>
            {openRegistrarUsuario ?
             <Box align="center">
                <RegistrarClienteForm text={'registrar cuenta'} recaptchaToken={googleRecaptchaToken} handleCloseRegistrarUsuario={handleCloseRegistrarUsuario} handleReCaptchaVerify={handleReCaptchaVerify} />

            </Box>
            :
            <Grow in={true} >
            <Box id="loginForm">
                
            
                <Box >
                <LoginForm recaptchaToken={googleRecaptchaToken} handleReCaptchaVerify={handleReCaptchaVerify} />
                </Box>

                {googleRecaptchaToken ?
                <Box align='center'>
                    <Button variant="contained" color="default" className="button-height" onClick={handleOpenTerminos}>
                        <Typography>Registrar cuenta</Typography>
                    </Button>
                </Box>
                :
                null
                }

                
                <Box mt={5} p={3} align='center'>
                    <Typography variant="body2" justify="true">
                    Este sitio está protegido por reCAPTCHA y se aplica la <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Política de Privacidad</a> de Google y los <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Términos de servicio</a>
                    </Typography>
                </Box>

            </Box>
            </Grow>
            }

            <TerminosCondiciones open={terminosCondiciones} handleClose={handleCloseTerminos} handleOpenRegistrarUsuario={handleOpenRegistrarUsuario} />

            {/* <Message 
                info={authRegistrarReducer.info}
                warning={authRegistrarReducer.warning}
                error={authRegistrarReducer.error}
                msg={authRegistrarReducer.msg}
                handleCloseMsg={handleCloseMsg}
            /> */}
            
        </ContainerRegistrarCuenta> 
    )
    
}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        logout: () => dispatch(logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))

Login.propTypes = {
    classes: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired   
}

