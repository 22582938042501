import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { ActivarIngrediente, GuardarIngredientesActivos } from '../../store/actions/categoriaMenuActions'
import { SendMenssageAction } from '../../store/actions/messageActions'

//*MATERIAL UI
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from "@material-ui/core/Typography"
import BlockIcon from '@material-ui/icons/Block'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Drawer from '@material-ui/core/Drawer' 
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Card from "@material-ui/core/Card"
import Grid from '@material-ui/core/Grid'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    disabled: {
        color: '#ffcdd2'
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
    deshabilitar: {
        backgroundColor: '#e57373',
        '&:hover': {
            backgroundColor: "#e57373",
        },
        color: '#fff',
    },
    habilitar: {
        backgroundColor: '#00BCD4',
        '&:hover': {
            backgroundColor: "#00BCD4",
        },
        color: '#fff',
    },
    cursor: {
        cursor: 'pointer'
    }
})

const ListaIngredientes = (props) => {

    const [todoIngredientes, setTodoIngredientes] = useState(null)
    const [buscarIngrediente, setBuscarIngrediente] = useState('') 


    useEffect(() => {

        if(props.menuIngredientes){
            setTodoIngredientes(props.menuIngredientes)
        }

    }, [props.menuIngredientes])

    const handleDisabledIngrediente = (ingredienteData) => {
        props.ActivarIngrediente(ingredienteData)
    }

    const handleGuardarIngredientes = async () => {

        props.SendMenssageAction({option: 'inicio'})
        const guardar = await props.GuardarIngredientesActivos()
        props.SendMenssageAction(guardar)
    }

    const handleBuscarChange = (e) => {

        setBuscarIngrediente(e.target.value)
    }
    //*render
    const { classes, menuIngredientes } = props

    const copiaIngredientes = todoIngredientes
    const search = buscarIngrediente
    let filtrarTodoIngredientes = []

    if(todoIngredientes && todoIngredientes.length !== 0){

        filtrarTodoIngredientes = copiaIngredientes.filter(ingredinetes => {
            return ingredinetes.nombre.toLowerCase().indexOf(search.toLowerCase()) !== -1
        })

    }

    return (
        <Fragment>

            <Box p={1} display='flex'>
                <Box flexGrow={1} >
                    <TextField
                    variant="outlined"
                    label="Ingredinetes"
                    color='secondary'
                    onChange={handleBuscarChange}
                    fullWidth
                    />
                </Box>
                <Box>
                <Button variant="contained" disableElevation color="secondary" onClick={handleGuardarIngredientes} style={{height: '52px'}}>
                    GUARDAR CAMBIOS
                </Button>
                </Box>
            </Box>

            {filtrarTodoIngredientes && filtrarTodoIngredientes.map(ingrediente => {
                return(
                    <Fragment key={ingrediente.id}>
                        <Divider />
                        <ListItem ContainerComponent="div">
                            <ListItemIcon className={classes.cursor} onClick={() => handleDisabledIngrediente(ingrediente)}>
                                {ingrediente.disabled ?
                                <BlockIcon fontSize="small" color="error" />
                                :
                                <CheckCircleOutlineIcon fontSize="small" color="secondary" />
                                }
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography variant="h6">
                                        {ingrediente.nombre}
                                    </Typography>
                                }
                            />
                                <ListItemSecondaryAction >
                                    <ListItemText
                                        primary={`$ ${parseFloat(ingrediente.costo).toFixed(2)}` }
                                    />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </Fragment>
                )
            })}
           <Divider />
            
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         ActivarIngrediente: (datos) => dispatch(ActivarIngrediente(datos)),
         GuardarIngredientesActivos: (datos) => dispatch(GuardarIngredientesActivos(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListaIngredientes))

ListaIngredientes.propTypes = {
    
}