import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import CatSoporte from './CatSoporte'
import TicketSoporte from './TicketSoporte'
import { GuardarAllTicketsSoporte } from '../../../../store/actions/perfilComercioActions'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Card from '@material-ui/core/Card'

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { withStyles } from '@material-ui/styles'

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})

const styles = theme => ({
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    categorias: {
        backgroundColor: '#ffffff',
        [theme.breakpoints.up('sm')]: {
            minWidth: 720,
        } 
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
})

const Soporte = (props) => {

    const [allTicketSoporte, setAllTicketSoporte] = useState([])

    useEffect(() => {

        if(props.storageDataReducer.FSallTicketSoporte.length !== 0){

            const allTicketsSoporte = props.storageDataReducer.FSallTicketSoporte.sort(compare)

            if(allTicketsSoporte.length > 5){
                setAllTicketSoporte(allTicketsSoporte.slice(0, 5))
                props.GuardarAllTicketsSoporte(allTicketsSoporte.slice(0, 5))
            }else{
                setAllTicketSoporte(allTicketsSoporte)
            }
        }

    }, [props.storageDataReducer.FSallTicketSoporte])

    const compare = ( a, b ) => {
        if ( a.createdAt.seconds < b.createdAt.seconds ){
          return 1;
        }
        if ( a.createdAt.seconds > b.createdAt.seconds ){
          return -1;
        }
        return 0;
    }

    //*render
    if(!props.storageDataReducer.FScatSoporte) return null
    const { classes, openSoporte, setOpenSoporte } = props

    const FScatSoporte = props.storageDataReducer.FScatSoporte
    const FSticketSoporte = props.storageDataReducer.FSticketSoporte
    // const FSallTicketSoporte = props.storageDataReducer.FSallTicketSoporte
    return (
        <Fragment>
            <Dialog 
            open={openSoporte} 
            // TransitionComponent={TransitionSlide} 
            // maxWidth={'xs'} 
            fullScreen 
            onClose={() => 
            setOpenSoporte(false)}
            PaperProps={{
                style: {
                  backgroundColor: '#eceff1',
                  boxShadow: "none"
                }
              }}
            >
            <Grid item className={classes.styleContenedor} xs={12}  >
                
                <Box p={1} display="flex" className={classes.fixedUp} onClick={() => setOpenSoporte(false)}>
                    <Box mx={1}>
                        <ArrowBackIcon/>
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h6" justify="center" >
                            Tickets Para Soporte
                        </Typography>
                    </Box>
                    <Box mx={2}></Box>
                </Box>


                <Box mt={7} >
                    {FSticketSoporte && FSticketSoporte.map(ticketSoporte => {
                        return(
                            <TicketSoporte
                                key={ticketSoporte.id}
                                ticketSoporte={ticketSoporte}
                            />
                        )
                    })}
                </Box>

                <Box mx={1} mt={1} className={classes.categorias}>
                    <Card variant="outlined">
                    <TreeView
                        className={classes.root}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        >
                        {FScatSoporte && FScatSoporte.map(categoria => {
                            return(
                                <CatSoporte
                                    key={categoria.id_categoria}
                                    catSoporte={categoria}
                                />
                            )
                        })}
                    </TreeView>
                    </Card>
                </Box>

                <Box mt={2} >
                    {allTicketSoporte && allTicketSoporte.map(ticketSoporte => {
                        return(
                            <TicketSoporte
                                key={ticketSoporte.id}
                                ticketSoporte={ticketSoporte}
                            />
                        )
                    })}
                </Box>
                
            </Grid>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        storageDataReducer: state.storageDataReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         GuardarAllTicketsSoporte: (datos) => dispatch(GuardarAllTicketsSoporte(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Soporte))

Soporte.propTypes = {
    
}