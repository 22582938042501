import React, { useState, useEffect, forwardRef,Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import CardMedia from "@material-ui/core/CardMedia"
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'


import { withStyles } from '@material-ui/styles'

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})

const styles = theme => ({
    titleBar: {
        // background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    mediaCollage: {
        height: '202px', //? 360*202 or 720*405 -- 1920*1080 
        width: '160px'
    }, 
    overflowCollage: {
        width: '100%',
        height: 202,
        overflow: 'auto'
    },
    relativeCollage: {
        cursor: 'pointer',
        position: 'relative',
    },
    boxTitleCollage: {
        position: 'absolute',
        bottom: 0,
        backgroundColor: '#000',
        opacity: 0.3,
        height: 25,
        width: '144px'
    },
    mediaCarrusel: {
        height: '202px', //? 360*202 or 720*405 -- 1920*1080 
    }, 
    overflowCarrusel: {
        // width: '100%',
        // height: '100%',
        overflow: 'auto'
    },
    relativeCarrusel: {
        cursor: 'pointer',
        position: 'relative',
    },
    fixedDown: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        color: '#ffffff'
    },
    regresar: {
        backgroundColor: '#263238',
        cursor: 'pointer',
        color: '#ffffff'
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
}) 

const Images = (props) => {

    const [openCarrusel, setOpenCarrusel] = useState(false)
    
    //*render
    
    const { classes } = props
    
    return (
        <Fragment>
            <Box>
                <Box display="flex" justifyContent="flex-start" className={classes.overflowCollage}>
                {props.collageImages.map(collage => (
                <Box key={collage.nombreFoto} mr={.5} className={classes.relativeCollage} onClick={() => setOpenCarrusel(true)}>
                    <Box className={classes.boxTitleCollage} p={1} textAlign="center" ></Box>

                    <CardMedia
                    className={classes.mediaCollage}
                    image={collage.foto !== '' ? collage.foto : `${process.env.PUBLIC_URL}/assets/img/no-image.png`}
                    />
                </Box>

                ))}
                </Box>
            </Box>

            <Dialog 
            open={openCarrusel}
            TransitionComponent={TransitionSlide}
            onClose={() => setOpenCarrusel(false)}
            fullScreen 
            PaperProps={{
                style: {
                  backgroundColor: '#eceff1',
                  boxShadow: "none"
                }
              }}
            >
            <Grid item className={classes.styleContenedor} xs={12} >
                <Box p={1} display="flex" className={classes.regresar} onClick={() => setOpenCarrusel(false)}>
                    <Box mx={1}>
                        <ArrowBackIcon />
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h6" justify="center" >
                            Imagenes
                        </Typography>
                    </Box>
                    <Box mx={2}></Box>
                </Box>



                <Box className={classes.overflowCarrusel}>
                {props.collageImages.map(collage => (
                    <Box key={collage.nombreFoto} className={classes.relativeCarrusel} px={.5} pb={.5}>

                    <CardMedia
                    className={classes.mediaCarrusel}
                    image={collage.foto !== '' ? collage.foto : `${process.env.PUBLIC_URL}/assets/img/no-image.png`}
                    />
                </Box>
                ))}
                </Box>

            </Grid>
            </Dialog>

        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Images))

Images.propTypes = {
    
}