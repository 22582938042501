import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import Cuentas from './cuentas/Cuentas'
import Horarios from './horarios/Horarios'
import Soporte from './soporte/Soporte'
import Notificaciones from './notificaciones/Notificaciones'
import OrdenesFood from './ordenesFood/OrdenesFood'
import PagosFood from './pagos/PagosFood'
import Sonidos from './sonido/Sonidos'

//*MATERIAL UI
import Box from '@material-ui/core/Box'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider'

import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import ReceiptIcon from '@material-ui/icons/Receipt'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import ListAltIcon from '@material-ui/icons/ListAlt'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import LayersIcon from '@material-ui/icons/Layers'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    listItem: {
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: "#ffffff",
        },
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
})

const ConfigOptions = (props) => {

    const [openCuentas, setOpenCuentas] = useState(false)
    const [openHorarios, setOpenHorarios] = useState(false)
    const [openSoporte, setOpenSoporte] = useState(false)
    const [openNotificaciones, setOpenNotificaciones] = useState(false)
    const [openOrdenesFood, setOpenOrdenesFood] = useState(false)
    const [openPagosFood, setOpenPagosFood] = useState(false)
    const [openSonidos, setOpenSonidos] = useState(false)

    useEffect(() => {

    }, [])

    //*render
    const { classes, appVersion } = props
    return (
        <Fragment>
            <Box>
                <List>
                    {/* <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => setOpenCuentas(true)}
                    >
                        <ListItemIcon>
                            <PeopleAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cuentas de Usuario" />
                    </ListItem>
                    <Divider /> */}

                    {/* <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => setOpenHorarios(true)}
                    >
                        <ListItemIcon>
                            <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Horarios" />
                    </ListItem>
                    <Divider /> */}

                    {/* <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => setOpenSoporte(true)}
                    >
                        <ListItemIcon>
                            <ReceiptIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tickets Soporte" />
                    </ListItem>
                    <Divider /> */}

                    {/* <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => setOpenNotificaciones(true)}
                    >
                        <ListItemIcon>
                            <MailOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary="Notificaciones" />
                    </ListItem>
                    <Divider /> */}

                    {/* <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => setOpenOrdenesFood(true)}
                    >
                        <ListItemIcon>
                            <ListAltIcon />
                        </ListItemIcon>
                        <ListItemText primary="Historial Ordenes" />
                    </ListItem>
                    <Divider /> */}

                    <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => setOpenPagosFood(true)}
                    >
                        <ListItemIcon>
                            <MonetizationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pagos" />
                    </ListItem>
                    <Divider />

                    {/* <ListItem
                    button
                    className={classes.listItem}
                    onClick={() => setOpenSonidos(true)}
                    >
                        <ListItemIcon>
                            <VolumeUpIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sonido" />
                    </ListItem>
                    <Divider /> */}

                    <ListItem
                    className={classes.listItem}
                    >
                        <ListItemIcon>
                            <LayersIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Version: ${appVersion}`} />
                    </ListItem>
                    <Divider />
                </List>
                
            </Box>

            <Box>
                {/* <Box>
                    <Cuentas 
                        openCuentas={openCuentas}
                        setOpenCuentas={setOpenCuentas}
                    />
                </Box>

                <Box>
                    <Horarios
                        setOpenHorarios={setOpenHorarios}
                        openHorarios={openHorarios}
                    />
                </Box>

                <Box>
                    <Soporte 
                        openSoporte={openSoporte}
                        setOpenSoporte={setOpenSoporte}
                    />
                </Box>

                <Box>
                    <Notificaciones 
                        openNotificaciones={openNotificaciones}
                        setOpenNotificaciones={setOpenNotificaciones}
                    />
                </Box>
                 */}
                {/* <Box>
                    <OrdenesFood 
                        openOrdenesFood={openOrdenesFood}
                        setOpenOrdenesFood={setOpenOrdenesFood}
                    />
                </Box> */}

                <Box>
                    <PagosFood 
                        openPagosFood={openPagosFood}
                        setOpenPagosFood={setOpenPagosFood}
                    />
                </Box>
                
                {/* <Box>
                    <Sonidos
                        openSonidos={openSonidos}
                        setOpenSonidos={setOpenSonidos}
                    />
                </Box> */}
            </Box>
            
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        appVersion: state.configReducer.appVersion,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfigOptions))

ConfigOptions.propTypes = {
    
}