import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import FinalizadoDetalleCancelado from './FinalizadoDetalleCancelado'
import FinalizadoClienteCancelado from './FinalizadoClienteCancelado'
import FinaliadoMenu from './FinaliadoMenu'
import { getFecha } from '../../../../allFunctions'
//*MATERIAL UI
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Card from "@material-ui/core/Card"
import Dialog from '@material-ui/core/Dialog'
import Grow from '@material-ui/core/Grow'
import Rating from '@material-ui/lab/Rating'

import { withStyles } from '@material-ui/styles'

const Transition = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 500
    props.timeout.exit = 500
    return <Grow ref={ref} {...props} />
})


const styles = theme => ({
    cerrar: {
        backgroundColor: '#263238',
        color: '#fff'
    },
    activo: {
        backgroundColor: '#c8e6c9',
    },
    pasivo: {
        backgroundColor: '#ffcdd2',
    },
    width: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 720,
        } 
    },
    finalizado: {
        backgroundColor: '#4dd0e1',
        color: '#fff'
    },
})

const FinalizadoDetalle = (props) => {

    const [ordenFood, setOrdenFood] = useState()

    useEffect(() => {
        if(props.ordenFood){
            setOrdenFood(props.ordenFood)
        }

    }, [props.ordenFood])

    //*render
    if(!ordenFood) return null

    const { classes, openVerDetalle, setOpenVerDetalle } = props

    if(ordenFood.status === 530) return (

        <FinalizadoDetalleCancelado ordenFood={ordenFood} />
    )

    if(ordenFood.status === 540) return (

        <FinalizadoClienteCancelado ordenFood={ordenFood} />
    )
    return (
        <Fragment>
            <Box>
                <Box p={0.5} className={classes.width} >
                <Card variant="outlined">
                    <Box p={1} className={classes.finalizado}>
                        <Typography variant="h4" align="center">
                        FINALIZADO
                        </Typography>
                    </Box>
    
                    <Box p={1} align="right">
                        <Typography variant="body1" >
                            {getFecha(ordenFood.finalizadoAt.seconds, 3)}
                        </Typography>
                    </Box>
                    <Box p={1}>
                        <Typography variant="body1" align={'center'}>
                            {ordenFood.destino.domicilio}
                        </Typography>
                        <Typography variant="h6" align={'center'}>
                            ID: <b>{ordenFood.id_orden.toUpperCase()}</b>
                        </Typography>
                    </Box>

                    <Box mt={2} align="center" className={classes.stars}>
                        <Rating name="size-large" value={ordenFood.rating} disabled />
                        <Typography variant="body1" align={'center'}>
                            {ordenFood.comentario}
                        </Typography>
                    </Box>
    
                    <Box p={1}>
                        <Divider />
                    </Box>
    
                    <Box>
                        {ordenFood.productos && ordenFood.productos.map(menu => {
                            return(
                                <FinaliadoMenu 
                                    key={menu.id_producto}
                                    menu={menu}
                                />
                            )
                        })}
                        <Box display="flex">
                            <Box  align={'left'} flexGrow={1} px={3}>
                                <Typography variant="body1">
                                {ordenFood.recogerOrden ? 'Pasar a recoger' : 'Servicio Domicilio'}
                                </Typography>
                            </Box>                
                            <Box align={'right'} px={1}>
                                <Typography variant="body1">
                                $ {ordenFood.costoServicioDom}.00
                                </Typography>
                            </Box>
                        </Box>
                        
                    </Box>
                    <Box p={1}>
                        <Divider />
                    </Box>
                    <Box display="flex" className={classes.comercio}>
                        <Box  flexGrow={1} px={2} >
                            <Typography variant="body1">
                            Pago: <b>{ordenFood.metodoPago.metodo}</b>
                            </Typography>
                        </Box>                
                        <Box px={2} >
                            <Typography variant="body1">
                            <b>Total:</b>
                            </Typography>
                        </Box>                
                        <Box px={1} minWidth={60} align="right">
                            <Typography variant="body1"  >
                                <b>$ {(parseInt(ordenFood.totalVenta) + parseInt(ordenFood.costoServicioDom)).toFixed(2)}</b>
                            </Typography>
                        </Box>                
                    </Box>

                    <Box display="flex" justifyContent="flex-end">
                        <Box px={2} >
                            <Typography variant="body1">
                            <b>Comision venta:</b>
                            </Typography>
                        </Box>   
                        <Box px={1} minWidth={60} align="right">
                            <Typography variant="body1">
                                <b>- $ {ordenFood.comisionVenta.toFixed(2)}</b>
                            </Typography>
                        </Box>                
                    </Box>
                    
                    {/* <Box display="flex" justifyContent="flex-end" style={{display: ordenFood.metodoPago.metodo === 'Tarjeta' ? '' : 'none'}}>
                        <Box px={2} >
                            <Typography variant="body1">
                            <b>Comision pago tarjeta:</b>
                            </Typography>
                        </Box>   
                        <Box px={1} minWidth={60} align="right">
                            <Typography variant="body1">
                                <b>- $ {ordenFood.comisionTarjeta.toFixed(2)}</b>
                            </Typography>
                        </Box>                
                    </Box> */}

                    <Box m={1} align="center" >
                        <Box px={2} className={ordenFood.metodoPago.metodo === 'Tarjeta' ? classes.activo : classes.pasivo} >
                            <Typography variant="body1">
                            <b>{ordenFood.metodoPago.metodo === 'Tarjeta' ?
                                'Activo:'
                                :
                                'Pasivo:'
                                }
                            </b>
                            </Typography>
                            <Typography variant="body1">
                                <b>$ {ordenFood.metodoPago.metodo === 'Tarjeta' ?
                                ordenFood.totalActivo.toFixed(2)
                                :
                                ordenFood.totalPasivo.toFixed(2)
                                }</b>
                            </Typography>
                        </Box>   
                    </Box>

                    {/* <Box mt={1} px={1} align="center">
                        <Typography variant="body1">
                            El cliente pagara con: <br />
                            <b>{ordenFood.metodoPago.metodo}</b>
                        </Typography>
                    </Box> */}
                </Card>
                </Box>

            </Box>

        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FinalizadoDetalle))

FinalizadoDetalle.propTypes = {
    
}