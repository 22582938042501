import React, { useState, useEffect, forwardRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


//*COMPONENTES
import { CancelarTicketSoporte } from '../../../../store/actions/perfilComercioActions'
import { SendMenssageAction } from '../../../../store/actions/messageActions'
import { getFecha } from '../../../../allFunctions'

//*MATERIAL UI
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ReceiptIcon from '@material-ui/icons/Receipt'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grow from '@material-ui/core/Grow'

import { withStyles } from '@material-ui/styles'

const Transition = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 500
    props.timeout.exit = 500
    return <Grow ref={ref} {...props} />
})

const styles = theme => ({
    cancelar: {
        backgroundColor: '#e57373',
        '&:hover': {
            backgroundColor: "#e57373",
        },
        color: '#fff',
    },
    messageTitle: {
        backgroundColor: '#263238',
        color: '#fff'
    },
    message: {
        color: '#263238',
        fontWeight: 'bold'
    },
    cancelado: {
        backgroundColor: '#ef9a9a',
        color: '#fff'
    },
    atendido: {
        backgroundColor: '#4dd0e1',
        color: '#fff'
    },
    espera: {
        backgroundColor: '#fff9c4',
        color: '#000'
    }
})

const TicketSoporte = (props) => {

    const [openCancelarTicket, setOpenCancelarTicket] = useState(false)

    useEffect(() => {

    }, [])

    const handleCancelarTicketSoporte =  () => {

        props.SendMenssageAction({option: 'inicio'})
        setOpenCancelarTicket(false)
        setTimeout(async () => {
            
            const cancelar = await props.CancelarTicketSoporte(props.ticketSoporte)
    
            props.SendMenssageAction(cancelar)
        }, 300);
    }

    //*render
    const { classes, ticketSoporte } = props

    return (
        <Box p={0.5}>
            <Card variant="outlined" >
                <Box p={1} className={ticketSoporte.cancelado ? classes.cancelado : ticketSoporte.atendido ? classes.atendido : classes.espera}>
                    <Typography variant="h5" align="center">
                    {ticketSoporte.cancelado ? 'CANCELADO' : ticketSoporte.atendido ? 'ATENDIDO' : 'EN ESPERA'}
                    </Typography>
                </Box>

                <Box display="flex">
                    <Box p={1}>
                        <ReceiptIcon fontSize="small" />
                    </Box>
                    <Box p={1} align="center">
                        <Typography variant="h6">
                            {ticketSoporte.nombreProblema}
                        </Typography>
                    </Box>
                </Box>
                
                {ticketSoporte.cancelado ?

                    <Box>
                        <Box px={1} display="flex">
                            <Box flexGrow={1} align="left">
                                <Typography variant="h6">
                                    Enviado:
                                </Typography>
                            </Box>
                            <Box align="right">
                                <Typography variant="h6">
                                    Cancelado:
                                </Typography>
                            </Box>
                        </Box>
                        <Box px={1} display="flex">
                            <Box flexGrow={1} align="left">
                                <Typography variant="body1">
                                {getFecha(ticketSoporte.createdAt.seconds, 2)}
                                </Typography>
                            </Box>
                            <Box align="right">
                                <Typography variant="body1">
                                {getFecha(ticketSoporte.canceladoAt.seconds, 2)}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                : ticketSoporte.atendido ?

                <Box>
                    <Box px={1} display="flex">
                        <Box flexGrow={1} align="left">
                            <Typography variant="h6">
                                Enviado:
                            </Typography>
                        </Box>
                        <Box align="right">
                            <Typography variant="h6" >
                                Atendido:
                            </Typography>
                        </Box>
                    </Box>
                    <Box px={1} display="flex">
                        <Box flexGrow={1} align="left">
                            <Typography variant="body1">
                            {getFecha(ticketSoporte.createdAt.seconds, 2)}
                            </Typography>
                        </Box>
                        <Box align="right">
                            <Typography variant="body1" >
                            {getFecha(ticketSoporte.atendioAt.seconds, 2)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                :
                <Box>
                    <Box p={1} align="center">
                        <Button variant="contained" className={classes.cancelar} disableElevation onClick={() => setOpenCancelarTicket(true)}>
                           CANCELAR
                        </Button>
                    </Box>
    
                    <Box p={1} align="right">
                        <Typography variant="body1">
                            {getFecha(ticketSoporte.createdAt.seconds, 2)}
                        </Typography>
                    </Box>
                </Box>
                }
    
            </Card>

            <Dialog open={openCancelarTicket} TransitionComponent={Transition} maxWidth={'xs'} fullWidth onClose={() => setOpenCancelarTicket(false)}>                
                <Box p={1} textAlign="center" className={classes.messageTitle}>
                    <Typography variant="h6">
                        TICKET SOPORTE
                    </Typography>
                </Box>

                <Box align="center" p={2}  >
                    <Typography variant="h4" className={classes.message}>
                        {ticketSoporte.nombreProblema}
                    </Typography>
                </Box>

                <Box align="center" mb={2}>
                    <Button variant="contained" className={classes.cancelar} disableElevation onClick={handleCancelarTicketSoporte} >Cancelar</Button>
                </Box>                  
            </Dialog>
        </Box>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         CancelarTicketSoporte: (datos) => dispatch(CancelarTicketSoporte(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TicketSoporte))

TicketSoporte.propTypes = {
    
}