import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import './index.css';
import {App} from './App';
// import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './store/reducers/rootReducer'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { reduxFirestore, getFirestore } from 'redux-firestore'
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase'
import fbConfig from './config/fbConfig'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const store = createStore(rootReducer,     
    process.env.NODE_ENV === 'development' ?
    compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        reduxFirestore(fbConfig),
        reactReduxFirebase(fbConfig, {useFirestoreForProfile: true, userProfile: 'usuarios', attachAuthIsReady : true}),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
    :
    compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        reduxFirestore(fbConfig),
        reactReduxFirebase(fbConfig, {useFirestoreForProfile: true, userProfile: 'usuarios', attachAuthIsReady : true}),
    )
)

store.firebaseAuthIsReady.then(() => {
    ReactDOM.render(
        <GoogleReCaptchaProvider reCaptchaKey="6LciYO8kAAAAAM4xjxyrKnUaATUhq5H7P1y4qh7R">
        <BrowserRouter>
            <Provider store={store}>
                <Route path="/" component={App} />
            </Provider>
        </BrowserRouter>
        </GoogleReCaptchaProvider>,
        document.getElementById('root'));
    
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    
})

if('serviceWorker' in navigator){
   
    navigator.serviceWorker.register('/sw.js')
    .then(() => {
        
        //REGISTRA EL SW Y LLAMA LA FUNCION PARA VERIFICAR SI SE ESTA USANDO LA APP O EL NAVEGADOR
        // verifySW()
    })
}