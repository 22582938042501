import { 
    STORAGE_CATEGORIASMENU_EXITO,
    MOSTRAR_GUARDARCATMENU_EXITO,
    STORAGE_MENUADICIONES_EXITO,
    STORAGE_INGREDIENTES_EXITO,
    MOSTRAR_GUARDARAINGREDIENTES_EXITO
} from '../actionTypes'


export const HabilitarMenu = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        if(datos.id_categoria === '' || datos.id_menu === ''){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        }

        const categoriasMenu = getState().storageDataReducer.categoriasMenu

        categoriasMenu.map(categoria => {
            if(categoria.id_comercio === userData.id && categoria.id_categoria === datos.id_categoria){
                categoria.menu.map(menu => {
                    if(menu.id_menu === datos.id_menu){
                        menu.disabled = !datos.disabled
                    }
                })
            }
        })

        resultado.exito = true
        dispatch({ type: STORAGE_CATEGORIASMENU_EXITO, payload: categoriasMenu })
        dispatch({ type: MOSTRAR_GUARDARCATMENU_EXITO, payload: true })

        return resultado
    }
}

export const ActivarMenu = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        if(datos.id_categoria === '' || datos.id_menu === ''){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        }

        const categoriasMenu = getState().storageDataReducer.categoriasMenu

        categoriasMenu.map(categoria => {
            if(categoria.id_comercio === userData.id && categoria.id_categoria === datos.id_categoria){
                categoria.menu.map(menu => {
                    if(menu.id_menu === datos.id_menu){
                        menu.disabled = !datos.disabled
                    }
                })
            }
        })

        resultado.exito = true
        dispatch({ type: STORAGE_CATEGORIASMENU_EXITO, payload: categoriasMenu })
        dispatch({ type: MOSTRAR_GUARDARCATMENU_EXITO, payload: true })

        return resultado

    }
}

export const ActivarAdicion = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const FSmenuAdiciones = JSON.parse(JSON.stringify(getState().storageDataReducer.FSmenuAdiciones))

        FSmenuAdiciones && FSmenuAdiciones.map(menuAdicion => {

            menuAdicion.ingredientes.map(ingrediente => {

                if(ingrediente.id === datos.id){
                    ingrediente.disabled = !datos.disabled
                }
            })
        })

        dispatch({ type: STORAGE_MENUADICIONES_EXITO, payload: FSmenuAdiciones })
        dispatch({ type: MOSTRAR_GUARDARAINGREDIENTES_EXITO, payload: true })

    }
}

export const ActivarIngrediente = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const FSmenuIngredientes = JSON.parse(JSON.stringify(getState().storageDataReducer.FSmenuIngredientes))

        FSmenuIngredientes && FSmenuIngredientes.map(ingrediente => {
            if(ingrediente.id === datos.id){
                ingrediente.disabled = !datos.disabled
            }
        })

        dispatch({ type: STORAGE_INGREDIENTES_EXITO, payload: FSmenuIngredientes })

    }
}

export const CancelActivarIngredientes = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const FSIngredientesDisabled = JSON.parse(JSON.stringify(getState().storageDataReducer.FSIngredientesDisabled))
        const FSmenuIngredientes = JSON.parse(JSON.stringify(getState().storageDataReducer.FSmenuIngredientes))

        const allIngredientesDisabledID = []

        FSIngredientesDisabled && FSIngredientesDisabled.map(ingredienteDisabled => {
            allIngredientesDisabledID.push(ingredienteDisabled.id)
        })

        FSmenuIngredientes && FSmenuIngredientes.map(ingrediente => {
            
            if(allIngredientesDisabledID.includes(ingrediente.id)){
                ingrediente.disabled = true
            }else{
                ingrediente.disabled = false
            }

        })

        dispatch({ type: STORAGE_INGREDIENTES_EXITO, payload: FSmenuIngredientes })

    }
}

export const GuardarIngredientesActivos = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const FSmenuIngredientes = JSON.parse(JSON.stringify(getState().storageDataReducer.FSmenuIngredientes))
        const FSmenuAdiciones = JSON.parse(JSON.stringify(getState().storageDataReducer.FSmenuAdiciones))
        
        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()

        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

       const allIngredientesDisabled = []

       FSmenuIngredientes && FSmenuIngredientes.map(ingrediente => {

            if(ingrediente.disabled){
                allIngredientesDisabled.push(ingrediente.id)
            }
       })

       FSmenuAdiciones && FSmenuAdiciones.map(menuAdicion => {

            menuAdicion.ingredientes && menuAdicion.ingredientes.map(ingrediente => {
                
                if(allIngredientesDisabled.includes(ingrediente.id)){
                    ingrediente.disabled = true
                }else {
                    ingrediente.disabled = false
                }
            })
        })
        
        await firestore.collection('comerciosFood').doc(userData.id).collection('categoriasMenu').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
            menuAdiciones: FSmenuAdiciones,
            editedBy: userData
        })
        .then(() => {
            
            resultado.exito = true
            resultado.option = 'exito'
            resultado.message = 'Cambios realizados con exito!!'
            dispatch({ type: MOSTRAR_GUARDARAINGREDIENTES_EXITO, payload: false })

        })
        .catch(error => {
            console.log('error', error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado

    }
}

export const GuardarIngredientes = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const FSmenuAdiciones = JSON.parse(JSON.stringify(getState().storageDataReducer.FSmenuAdiciones))
        
        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()

        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        await firestore.collection('comerciosFood').doc(userData.id).collection('categoriasMenu').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
            menuAdiciones: FSmenuAdiciones,
            editedBy: userData
        })
        .then(() => {
            
            resultado.exito = true
            resultado.option = 'exito'
            resultado.message = 'Cambios realizados con exito!!'
            dispatch({ type: MOSTRAR_GUARDARAINGREDIENTES_EXITO, payload: false })

        })
        .catch(error => {
            console.log('error', error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado

    }
}

export const EditarDescripcionMenu = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        if(datos.id_categoria === '' || datos.id_menu === ''){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        }

        const categoriasMenu = getState().storageDataReducer.categoriasMenu

        categoriasMenu.map(categoria => {
            if(categoria.id_comercio === userData.id && categoria.id_categoria === datos.id_categoria){
                categoria.menu.map(menu => {
                    if(menu.id_menu === datos.id_menu){
                        menu.descripcion = datos.descripcion
                    }
                })
            }
        })

        resultado.exito = true
        dispatch({ type: STORAGE_CATEGORIASMENU_EXITO, payload: categoriasMenu })
        dispatch({ type: MOSTRAR_GUARDARCATMENU_EXITO, payload: true })

        return resultado


    }
}

export const GuardarCategoriasMenu = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }
        
        const firestore = getFirestore()

        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        if(!datos || datos.length === 0){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        }
        
        const categorias = JSON.parse(JSON.stringify(datos))

        await firestore.collection('comerciosFood').doc(userData.id).collection('categoriasMenu').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
            categorias: categorias,
            editedBy: userData
        })
        .then(() => {
            
            resultado.exito = true
            resultado.option = 'exito'
            resultado.message = 'Cambios realizados con exito!!'
            dispatch({ type: MOSTRAR_GUARDARCATMENU_EXITO, payload: false })

        })
        .catch(error => {
            console.log('error', error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        // return resultado

       


        // db.collection('comerciosFood').doc(userData.id).collection('categoriasMenu').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2')

        // const funct = firebase.functions()
        // const guardarCambiosCategoriaMenu = funct.httpsCallable('comercio_GuardarCambiosCategoriaMenu')

        // await guardarCambiosCategoriaMenu(datos)
        // .then(response => {
        //     if(response.data.exito){
        //         resultado.exito = true
        //         resultado.option = 'exito'
        //         resultado.message = 'Cambios Realizados con exito'
        //         dispatch({ type: MOSTRAR_GUARDARCATMENU_EXITO, payload: false })

        //     }else{
        //         resultado.exito = false 
        //         resultado.option = 'error'
        //         resultado.message = 'Se produjo un error'
        //     }
        // })
        // .catch(error => {            
        //     console.log('ERROR', error)
        //     resultado.option = 'error'
        //     resultado.message = 'Se produjo un error'
        // })

        return resultado

    }
}
