import { LOGIN_INICIA, 
    LOGIN_EXITO, 
    LOGIN_ERROR, 
    LOGOUT_EXITO,
    RESET_STATE_LOGIN } from '../actionTypes'

const initState = {
    login: false,
    loginError: false,
    mensajeError: '',
    cargar: false,
    cargandoGoogle: false,
    errorGoogle: false,
    msgErrorGoogle: '',
    user_uid: '' 
}

const loginReducer = (state = initState, action) => {
    switch (action.type) {
        case LOGIN_INICIA:
            return {
                ...state,
                login: false,
                loginError: false,
                mensajeError: '',
                cargar: true 
            }               
        case LOGIN_EXITO:
            return {
                ...state,
                login: true,
                loginError: false,
                mensajeError: '',
                cargar: false   
            } 
        case LOGIN_ERROR:
            return{
                ...state,
                login: false,
                loginError: true,
                mensajeError: action.payload,
                cargar: false                
            }
        case LOGOUT_EXITO:
            return state
        case RESET_STATE_LOGIN:
            return{                
                login: false,
                loginError: false,
                mensajeError: '',
                cargar: false,
                cargandoGoogle: false,
                errorGoogle: false,
                msgErrorGoogle: '', 
                user_uid: ''
            }
            
        default:
            return state
    }   
}



export default loginReducer