

import ListItem from "@material-ui/core/ListItem";
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import Tooltip from '@material-ui/core/Tooltip';

import Box from "@material-ui/core/Box";
import styled from "styled-components";
import { Divider, Typography } from "@material-ui/core";
import { capitalLetter, getFecha, newDateZoneSeconds } from "../../allFunctions";

const ContainerCliente = styled(({ ...props }) => <Box {...props} />)`
${({ theme }) => `

  .list-item-cliente {
    background-color: #D5F5E3;
  }

  .list-item-cliente: hover {
    background-color: #ABEBC6;
  }

  .divider-color {
    background-color: #FFFFFF;
    height: 2px;
  }

  .letra-negrita {
    font-weight: bold;
    color: #000;
    margin-left: 5px;
  }

`}
`

export const Cliente = (props) => {

  const { cliente, handleOpenDrawer } = props

  let creditos = <>
                 <Typography component="span" className="letra-negrita">
                  creditos:
                </Typography>
                <Typography component="span">
                  0
                </Typography>
                </>
  if(cliente?.creditosVigenia){

    creditos = <>
    <Typography component="span" >
     creditos:
   </Typography>
   <Typography component="span" className="letra-negrita">
     ( {cliente?.creditosVigenia?.creditos} )
   </Typography>
   <Typography component="span" className="letra-negrita">
     {getFecha(cliente?.creditosVigenia?.fechaVenta?.seconds, 3)}
   </Typography>
   </>

    // creditos = `creditos: ${cliente?.creditosVigenia?.creditos} -- Fecha: ${getFecha(cliente?.creditosVigenia?.fechaVenta?.seconds, 3)}`
  }

  // const nombre = `${cliente?.nombre.trim()} ${cliente?.apellidos.trim()}`
  
  return (
    <ContainerCliente>
      <ListItem button className="list-item-cliente">
        <ListItemText
          // primary={capitalLetter(nombre.trim().toLowerCase())}
          primary={cliente?.nombreCompleto}
          secondary={creditos}
        />
        <ListItemSecondaryAction>
          <Tooltip arrow title="Agregar creditos">
            <IconButton edge="end" onClick={() => handleOpenDrawer({opcion: 'creditos',  cliente: cliente})} >
              <BlurCircularIcon fontSize="small" />
            </IconButton>                  
          </Tooltip>
          <Tooltip arrow title="Editar cliente">
            <IconButton edge="end" onClick={() => handleOpenDrawer({opcion: 'editar', cliente: cliente})} >
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Cambiar contraseña">
            <IconButton edge="end" onClick={() => handleOpenDrawer({opcion: 'password', cliente: cliente})} >
              <LockIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider className="divider-color" />
    </ContainerCliente>
  )
}