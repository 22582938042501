import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import Sonido from './Sonido'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { withStyles } from '@material-ui/styles'

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})

const styles = theme => ({
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
})

const Sonidos = (props) => {


    //*render
    const { classes, openSonidos, setOpenSonidos } = props

    return (
        <Fragment>
            <Dialog 
            open={openSonidos} 
            // TransitionComponent={TransitionSlide} 
            // maxWidth={'xs'} 
            fullScreen 
            onClose={() => setOpenSonidos(false)}
            PaperProps={{
                style: {
                  backgroundColor: '#eceff1',
                  boxShadow: "none"
                }
              }}
            >
            <Grid item className={classes.styleContenedor} xs={12}  >
                
                <Box p={1} display="flex" className={classes.fixedUp} onClick={() => setOpenSonidos(false)}>
                    <Box mx={1}>
                        <ArrowBackIcon />
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h6" justify="center" >
                            Sonidos
                        </Typography>
                    </Box>
                    <Box mx={2}></Box>
                </Box>

                <Box mt={6}>
                    <Sonido />
                </Box>
                
            </Grid>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sonidos))

Sonidos.propTypes = {
    
}