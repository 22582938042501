import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'


//*MATERIAL UI
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import StoreIcon from '@material-ui/icons/Store'
import PersonIcon from '@material-ui/icons/Person'
import LanguageIcon from '@material-ui/icons/Language'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'

import { withStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

const styles = theme => ({
    status: {
        backgroundColor: '#ffffff',
    },
    guuwii: {
        backgroundColor: '#000',
        color: '#fff',
        fontWeight: 'bold',
        borderRadius: 10,
        padding: 2
    }
})

const ConfigStatus = (props) => {

    const [comercio, setComercio] = useState(null)
    const [usuarioLogin, setUsuarioLogin] = useState({
        login: false,
        nombreUsuario: ''
    })

    useEffect(() => {

        if(props.FScomercio){
            setComercio(props.FScomercio)
        }

    }, [props.FScomercio])

    useEffect(() => {

        if(props.perfilComercioReducer){
            setUsuarioLogin({
                ...usuarioLogin,
                login: props.perfilComercioReducer.usuarioLogin,
                nombreUsuario: props.perfilComercioReducer.nombreUsuario
            })
        }

    }, [props.perfilComercioReducer.usuarioLogin])
    

    //*render
    if(!comercio) return null
    const { classes, profile } = props
    return (
        <Fragment>
            <Box mt={1} className={classes.status}>
                <List >
                    <ListItem>
                        <ListItemIcon>
                            <LanguageIcon/>
                        </ListItemIcon>
                        <ListItemText primary="En linea" />
                        <ListItemSecondaryAction>
                        <ListItemIcon>
                            <FiberManualRecordIcon color={profile.online ? 'secondary' : 'error'} />
                        </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <StoreIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Comercio ${comercio.abierto ? 'Abierto' : 'Cerrado'}`} />
                        <ListItemSecondaryAction>
                        <ListItemIcon>
                            <FiberManualRecordIcon color={comercio.abierto ? 'secondary' : 'error'} />
                        </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem>
                    {/* <ListItem>
                        <ListItemIcon>
                            <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={usuarioLogin.login ? usuarioLogin.nombreUsuario : '' } />
                        <ListItemSecondaryAction>
                        <ListItemIcon>
                            <FiberManualRecordIcon color={usuarioLogin.login ? 'secondary' : 'error'} />
                        </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem>
                    
                    <ListItem>
                        <ListItemIcon>
                            <MotorcycleIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Servicio Domicilio${comercio.servicioDomGratis ? ': Gratis' : ''}`} />
                        <ListItemSecondaryAction>
                        <ListItemIcon>
                            <FiberManualRecordIcon color={comercio.servicioDom ? 'secondary' : 'error'} />
                        </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Box px={2} align={'center'}>
                                <Badge badgeContent={<Typography variant='h5' className={classes.guuwii}>gw</Typography>} />
                            </Box>
                        </ListItemIcon>
                        <ListItemText primary="Servicio guuwii" />
                        <ListItemSecondaryAction>
                        <ListItemIcon>
                            <FiberManualRecordIcon color={profile.servicioGuuwii ? 'secondary' : 'error'} />
                        </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CreditCardIcon />
                        </ListItemIcon>
                        <ListItemText primary="Pago con tarjeta" />
                        <ListItemSecondaryAction>
                        <ListItemIcon>
                            <FiberManualRecordIcon color={comercio.pagoTarjeta ? 'secondary' : 'error'} />
                        </ListItemIcon>
                        </ListItemSecondaryAction>
                    </ListItem> */}
                    
                </List>
            </Box>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        profile: state.firebase.profile,     
        FScomercio: state.storageDataReducer.FScomercio, 
        perfilComercioReducer: state.perfilComercioReducer,
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfigStatus))

ConfigStatus.propTypes = {
    
}