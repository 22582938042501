import React, { Component } from 'react'
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class ProtectedRouteLogout extends Component {
   
    render() {
        const {profile, auth, component: Component, ...rest} = this.props
        if(auth?.uid){
            if(!profile?.roll) return null                
            if(profile?.roll !== 'admin' && profile?.roll !== 'cliente'){
                return(
                    <Redirect to='/' />
                )
    
            }else{
                return(
                    <Redirect to='/profile' />
                )                
            }    
            
        }else{ 
            
            return(
                <Route {...rest} component={Component} />
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(ProtectedRouteLogout)

ProtectedRouteLogout.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
}
