import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import Horario from './Horario'
import { GuardarHorarios } from '../../../../store/actions/perfilComercioActions'
import { SendMenssageAction } from '../../../../store/actions/messageActions'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/styles'

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})

const styles = theme => ({
    fixedDown: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        color: '#ffffff',
        maxWidth: 720,
    },
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
})

const Horarios = (props) => {

    const [horarios, setHorarios] = useState(null)

    useEffect(() => {

        if(props.storageDataReducer.FScomercio){
            setHorarios(props.storageDataReducer.FScomercio.horarios)
        }
    },[props.storageDataReducer.FScomercio])

    const handleAgregarHorario = (data) => {

        const horariosF = JSON.parse(JSON.stringify(horarios))

        horariosF.map(horario => {
            if(horario.id === data.id){
                horario.abre = data.abre
                horario.abreTurno = data.abreTurno
                horario.cierra = data.cierra
                horario.cierraTurno = data.cierraTurno
            }
        })
        
        setHorarios(horariosF)
    }

    const handleEliminarHorario = (data) => {
        const horariosF = JSON.parse(JSON.stringify(horarios))

        horariosF.map(horario => {
            if(horario.id === data.id){
                horario.abre = ''
                horario.abreTurno = ''
                horario.cierra = ''
                horario.cierraTurno = ''
            }
        })

        setHorarios(horariosF)
    }

    const handleGuardarHorarios = async () => {

        props.SendMenssageAction({option: 'inicio'})
        const guardar = await props.GuardarHorarios(horarios)
        if(guardar.exito){
            props.setOpenHorarios(false)
        }
        props.SendMenssageAction(guardar)
    }

    if(!horarios) return null
    //*render
    const { classes, setOpenHorarios, openHorarios } = props

    return (
        <Fragment>
            <Dialog 
            open={openHorarios} 
            // TransitionComponent={TransitionSlide} 
            // maxWidth={'xs'} 
            fullScreen onClose={() => setOpenHorarios(false)}
            PaperProps={{
                style: {
                  backgroundColor: '#eceff1',
                  boxShadow: "none"
                }
              }}
            >
            <Grid item className={classes.styleContenedor} xs={12}  >

                <Box p={1} display="flex" className={classes.fixedUp} onClick={() => setOpenHorarios(false)}>
                    <Box mx={1}>
                        <ArrowBackIcon />
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h6" justify="center" >
                            Horarios
                        </Typography>
                    </Box>
                    <Box mx={2}></Box>
                </Box>

                <Box mt={5} mb={5}>
                {horarios && horarios.map(horario => {
                    return(
                        <Horario 
                            key={horario.id}
                            horario={horario}
                            handleAgregarHorario={handleAgregarHorario}
                            handleEliminarHorario={handleEliminarHorario}
                        />
                    )
                })}
                </Box>

                <Box className={classes.fixedDown}>
                    <Button variant="contained" color="secondary" fullWidth disableElevation onClick={handleGuardarHorarios} >
                        Guardar Cambios
                    </Button>
                </Box>

            </Grid>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        storageDataReducer: state.storageDataReducer,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         GuardarHorarios: (datos) => dispatch(GuardarHorarios(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Horarios))

Horarios.propTypes = {
    
}