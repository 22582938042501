import { Box, Typography } from "@material-ui/core"
import CancelIcon from '@material-ui/icons/Cancel';
import styled from 'styled-components'
import { getFecha } from '../../allFunctions'
const ContainerDrawerTitle = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

    position: relative;
    padding: 10px;
    text-align: center;
    justify-content: center;

    .close-icon-container {
      position: absolute;
      left: 0;
      top: 0;
      padding: 10px;
      cursor: pointer;
    }

    .drawer-camas-title {
      font-size: 18px;
      font-weight: bold;
      ${theme.breakpoints.down('sm')} {
        font-size: 14px;
      }
    }

    .drawer-camastitle-hora {
      font-size: 18px;
      font-weight: bold;
      ${theme.breakpoints.down('sm')} {
        font-size: 14px;
      }
    }

`}
`


export const DrawerTitle = (props) => {

  const { title, title2, handleCloseDrawer } = props

  return (
    <ContainerDrawerTitle>
      {title ? 
      <Typography className="drawer-camas-title">
      {title}
      </Typography>
      : 
      null
      }
      {title2 ?
      <Typography className="drawer-camastitle-hora">
      {getFecha(title2, 4)}
      </Typography>
      :
      null
      }

      <Box className='close-icon-container' onClick={handleCloseDrawer}>
        <CancelIcon />  
      </Box>
    </ContainerDrawerTitle>
  )
}