import React, {Fragment } from 'react'
import { connect } from 'react-redux'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

//*COMPONENTES
import CheckoutForm from "./CheckoutForm"

//*MATERIAL UI

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})
const promise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const PaymentCard = (props) => {

    const { idCliente, total, paquete, setOpenPagoTarjeta, nombre, apellido, telefono, domicilio, referencia, handlePaymentResult } = props

    // const handleChangeCalcularComisionTarjeta = () => {

    //     let comisionTarjeta = (( parseFloat(total) * 0.036) + 3)
    //     const iva = parseFloat(comisionTarjeta) * 0.16
    //     comisionTarjeta = parseFloat(comisionTarjeta) + parseFloat(iva)
        
    //     // setComisionTarjeta(comisionTarjeta)
    //     return comisionTarjeta

    // }


    // const comisionTarjeta = handleChangeCalcularComisionTarjeta()
    // const totalCompra = parseFloat(total) + parseFloat(comisionTarjeta)

    // console.log("totalCompra", totalCompra)
    console.log("promise", promise)

    return (
        <Fragment>
            <div className="App">
                <Elements stripe={promise}>
                    <CheckoutForm 
                    idCliente={idCliente} 
                    total={total} 
                    paquete={paquete}
                    setOpenPagoTarjeta={setOpenPagoTarjeta}
                    nombre={nombre}
                    apellido={apellido}
                    telefono={telefono}
                    domicilio={domicilio}
                    referencia={referencia}
                    handlePaymentResult={handlePaymentResult}
                    />
                </Elements>
            </div>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentCard))

PaymentCard.propTypes = {
    
}