import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { HabilitarMenu, EditarDescripcionMenu, ActivarMenu } from '../../store/actions/categoriaMenuActions'
import { SendMenssageAction } from '../../store/actions/messageActions'
import MenuAdiciones from './MenuAdiciones'

//*MATERIAL UI
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from "@material-ui/core/Typography"
import BlockIcon from '@material-ui/icons/Block'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Drawer from '@material-ui/core/Drawer' 
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Card from "@material-ui/core/Card"
import Grid from '@material-ui/core/Grid'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    disabled: {
        color: '#ffcdd2'
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
    deshabilitar: {
        backgroundColor: '#e57373',
        '&:hover': {
            backgroundColor: "#e57373",
        },
        color: '#fff',
    },
    habilitar: {
        backgroundColor: '#00BCD4',
        '&:hover': {
            backgroundColor: "#00BCD4",
        },
        color: '#fff',
    },
    cursor: {
        cursor: 'pointer'
    }
})

const Producto = (props) => {
    const [producto, setProducto] = useState(null)
    const [openMenu, setOpenMenu] = useState(false)
    const [openMenuAdiciones, setOpenMenuAdiciones] = useState(false)
    const [imageHeight, setImageHeight] = useState('100')
    const [menuBloqueado, setMenuBloqueado] = useState(false)

    useEffect(() => {
        
        setProducto({
            id_categoria: props.producto.id_categoria,
            id_menu: props.producto.id_menu,
            nombre: props.producto.nombre,
            precio: props.producto.precio,
            imageURL: props.producto.imageURL,
            descripcion: props.producto.descripcion,
            disabled: props.producto.disabled            
        })

    }, [props.producto, props.producto.disabled, props.producto.descripcion])

    useEffect(() => {
        setMenuBloqueado(props.storageDataReducer.menuBloqueado)
    }, [props.storageDataReducer.menuBloqueado])

    const handleChange = (e) => {
        setProducto({
            ...producto,
            [e.target.name] : e.target.value.toLowerCase()
        })
    }

    const handleEditarDescripcionMenu = async () => {
        props.SendMenssageAction({option: 'inicio'})

        const data = {
            id_categoria: producto.id_categoria,
            id_menu: producto.id_menu,
            descripcion: producto.descripcion,
        }
        setOpenMenu(false)
        await props.EditarDescripcionMenu(data)
        props.SendMenssageAction({option: 'reset'})       

    }

    const handleHabilitarMenu = async () => {
        props.SendMenssageAction({option: 'inicio'})

        const data = {
            id_categoria: producto.id_categoria,
            id_menu: producto.id_menu,
            disabled: producto.disabled  
        }
        setOpenMenu(false)
        await props.HabilitarMenu(data)
        props.SendMenssageAction({option: 'reset'})       

    }

    const handleActivarMenu = () => {
        props.ActivarMenu(props.producto)
    }

    const handleImageSize = () => {
        if(imageHeight === '100'){
            setImageHeight('202')
        }else{
            setImageHeight('100')
        }
    }

    
    const handleOpenMenu = () => {
        setOpenMenu(true)
    }

    //*render
    if(!producto) return null
    const { classes } = props

    return (
        <Fragment>
        <Divider />
            <ListItem>

                <ListItemIcon className={classes.cursor} onClick={handleActivarMenu}>
                    {props.producto.disabled ?
                    <CheckCircleOutlineIcon fontSize="small" color="secondary" />
                    : 
                    <BlockIcon fontSize="small" color="error" />
                    }
                </ListItemIcon>

                {/* {props.producto.id_adiciones && props.producto.id_adiciones.length !== 0 ?
                    <ListItemIcon className={classes.cursor} onClick={() => setOpenMenuAdiciones(true)}>
                        <AddCircleIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    : null
                } */}

                <ListItemText 
                    primary={
                        <Box display='flex'>

                            <Box flexGrow={1}>
                                <Typography variant="h6">
                                    {props.producto.nombreMenu}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6">
                                ${props.producto.precioMenu}.00
                                </Typography>
                            </Box>

                        </Box>
                    } 
                    secondary={
                        props.producto.descripcion
                    }
                />

            </ListItem>

            <MenuAdiciones
                menu={props.producto}
                setOpenMenuAdiciones={setOpenMenuAdiciones}
                openMenuAdiciones={openMenuAdiciones}
            />

            {/* <Drawer 
            anchor={'top'} 
            open={openMenu} 
            onClose={() => setOpenMenu(false)}
            PaperProps={{
                style: {
                    backgroundColor: '#eceff1',
                    boxShadow: "none"
                }
            }}
            >
                <Grid item className={classes.styleContenedor} xs={12}  >
                <Box p={1} >

                    <Card variant="outlined" className={classes.card}>
                        <Box px={1} align="center" >
                            <Box>
                                <Typography gutterBottom variant="h5" >
                                    {props.producto.nombreMenu}
                                </Typography>
                                <Typography gutterBottom variant="h5" >
                                   <b>${props.producto.precioMenu}.00</b>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" align={'justify'} >
                                    {props.producto.descripcion}
                                </Typography>
                            </Box>
                        </Box>

                        <Box mb={1} px={1}>
                            <TextField
                            label="Descripcion"
                            multiline
                            rowsMax="2"
                            fullWidth
                            name="descripcion"
                            value={menu.descripcion}
                            onChange={handleChange}
                        />
                        </Box>
                        <Box display="flex">

                            <Box flexGrow={1} p={1}>
                                <Button variant="contained" className={props.producto.disabled ? classes.habilitar : classes.deshabilitar } onClick={handleHabilitarMenu} disableElevation >
                                    {props.producto.disabled ? 'HABILITAR' : 'DESHABILITAR'} 
                                </Button>
                            </Box>
                            <Box p={1} >
                                <Button variant="contained" color="primary" onClick={handleEditarDescripcionMenu} disableElevation>
                                    GUARDAR DESCRIPCION
                                </Button>
                            </Box>
                        </Box>
                    </Card>

                </Box>
                </Grid>
            </Drawer> */}
        </Fragment>
    )

}


const mapStateToProps = (state) => {  
    return{
        storageDataReducer: state.storageDataReducer,

    }
}


const mapDispatchToProps = (dispatch) => {
    return{
         ActivarMenu: (datos) => dispatch(ActivarMenu(datos)),
         HabilitarMenu: (datos) => dispatch(HabilitarMenu(datos)),
         EditarDescripcionMenu: (datos) => dispatch(EditarDescripcionMenu(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Producto))

Producto.propTypes = {
    classes: PropTypes.object.isRequired,   
    
}