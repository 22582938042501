import { newDateZone } from '../../allFunctions'
import { 
    GUARDAR_TIPODISPOSITIVO_EXITO,
    SET_TIEMPOACTUAL_EXITO,
    SET_SHOWINSTALLAPP_EXITO,
    SET_SHOWINSTALLNOTIFICATION_EXITO,
    SHOW_INSTALLEDMESSAGE_EXITO,
    SET_GEOLOCATIONACTIVO_EXITO,
    SET_ONLINE_EXITO,
    SET_APPACTIVA_EXITO,
    ENVIAR_MESSAGE_INICIO,
    RESET_STATE_ENVIARMESSAGE
} from '../actionTypes'


const resultadoCompatibiliad = (datosCompatibilidad) => {

    const data = datosCompatibilidad

    const fails = {
        osFail: false,
        isTablet: false,
        browserFail: false,
        fail: false
    }

    if(data.osName !== 'Android' && data.osName !== 'iPhone'){
        fails.osFail = true
        fails.fail = true
    }
    
    if(data.osName === 'Android'){
        // && data.browserName !== 'Samsung' 
        if(data.browserName !== 'Chrome'){
            fails.browserFail = true
            fails.fail = true
        }

        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(window.userAgent)
        
        if(isTablet){
            fails.isTablet = true
            fails.fail = true
        }
    }

    if(data.osName === 'iPhone'){

        if(data.browserName !== 'Safari'){
            fails.browserFail = true
            fails.fail = true
        }
    }

    return fails

}

export const VerificarCompatibilidad = (data) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const module = {
            options: [],
            header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
            dataos: [
                { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
                { name: 'Windows', value: 'Win', version: 'NT' },
                { name: 'iPhone', value: 'iPhone', version: 'OS' },
                { name: 'iPad', value: 'iPad', version: 'OS' },
                { name: 'Kindle', value: 'Silk', version: 'Silk' },
                { name: 'Android', value: 'Android', version: 'Android' },
                { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
                { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
                { name: 'Macintosh', value: 'Mac', version: 'OS X' },
                { name: 'Linux', value: 'Linux', version: 'rv' },
                { name: 'Palm', value: 'Palm', version: 'PalmOS' },
            ],
            databrowser: [
                { name: 'Samsung', value: 'SamsungBrowser', version: 'SamsungBrowser' },
                { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
                { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
                { name: 'Safari', value: 'Safari', version: 'Version' },
                { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
                { name: 'Opera', value: 'Opera', version: 'Opera' },
                { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
                { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
            ],
            init: function () {
                var agent = this.header.join(' '),
                    os = this.matchItem(agent, this.dataos),
                    browser = this.matchItem(agent, this.databrowser);
                
                return { os: os, browser: browser };
            },
            matchItem: function (string, data) {
                var i = 0,
                    j = 0,
                    html = '',
                    regex,
                    regexv,
                    match,
                    matches,
                    version;
                
                for (i = 0; i < data.length; i += 1) {
                    regex = new RegExp(data[i].value, 'i');
                    match = regex.test(string);
                    if (match) {
                        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                        matches = string.match(regexv);
                        version = '';
                        if (matches) { if (matches[1]) { matches = matches[1]; } }
                        if (matches) {
                            matches = matches.split(/[._]+/);
                            for (j = 0; j < matches.length; j += 1) {
                                if (j === 0) {
                                    version += matches[j] + '.';
                                } else {
                                    version += matches[j];
                                }
                            }
                        } else {
                            version = '0';
                        }
    
                        return {
                            name: data[i].name,
                            version: parseFloat(version)
                        };
                    }
                }
                return { name: 'unknown', version: 0 };
            }
        }
    
        const data = module.init()
        const datosCompatibilidad = {
            osName: data.os.name,
            osVersion: data.os.version,
            browserName: data.browser.name,
            browserVersion: data.browser.version,
            userAgent: navigator.userAgent,
            appVersion: navigator.appVersion,
            platform: navigator.platform,
            vendor: navigator.vendor
        }

        datosCompatibilidad.fails =  resultadoCompatibiliad(datosCompatibilidad)

        let dispositivo = 'phone'

        if(datosCompatibilidad.osName === 'Windows' || datosCompatibilidad.osName === 'Macintosh' || datosCompatibilidad.osName === 'Linux' || datosCompatibilidad.fails.isTablet){
            dispositivo = 'pc'
        }
        
        dispatch({ type: GUARDAR_TIPODISPOSITIVO_EXITO, payload: dispositivo })
    }
}

export const TiempoActual = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        const progress = () => {

            dispatch({ type: SET_TIEMPOACTUAL_EXITO, payload:  Math.floor(newDateZone().getTime()/1000.0) })

        }

        let timerID = null
        // timerID = setInterval(progress, 1000)
    }
}



//*INSTALL APP

let deferredPrompt
let btnInstallApp = document.getElementById('btnInstallApp')

window.addEventListener('beforeinstallprompt', event => {
    //SI ENTRA AQUI SIGNIFICA QUE NO ESTA INSTALADA
    //SE CREA EL OBJETE QUE SIRVE PARA INSTALAR LA APP
    event.preventDefault()
    deferredPrompt = event 
    
    return false
})

export const InstallApp = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const userData = {
            id: getState().firebase.auth.uid,
            perfil: getState().firebase.profile.perfil,
        }

        btnInstallApp.addEventListener('click', event => {           
            //MUESTRA EL MENSAJE DE INSTALACION    
            deferredPrompt.prompt()
            //MUESTRA QUE SELECCIONO EL USUARIO
            deferredPrompt.userChoice
            .then((choiceResult) => {
                if(choiceResult.outcome === 'dismissed'){                   
                    //EL USUARIO DENEGO LA INSTALACION
                    //MOSTRAR LEYENDA QUE HACER PARA PODER INSTALAAR
                }else if(choiceResult.outcome === 'granted'){

                    dispatch({ type: ENVIAR_MESSAGE_INICIO })

                }
                // deferredPrompt = null        
            })                
        })

        //CORRE UN EVENTO QUE SE DISPARA CUANDO SE INSTALLE LA APLICACION
        window.addEventListener('appinstalled', (evt) => {
            //APLICACION INSTALADA CON EXITO
            dispatch({ type: SET_SHOWINSTALLAPP_EXITO, payload: false })
            dispatch({ type: RESET_STATE_ENVIARMESSAGE })
            dispatch({ type: SHOW_INSTALLEDMESSAGE_EXITO, payload: true })
            
        })        
        

        if ( navigator.serviceWorker) {  
            //VERIFICA SI SE ESTA USANDO LA APP INSTALADA
            if(window.matchMedia('(display-mode: standalone)').matches) {
                //ESTA USANDO LA APP INSTALADA
                dispatch({ type:SET_SHOWINSTALLAPP_EXITO, payload: false })

            }else{
                //ESTA USANDO EL VANEGADOR
                //VERIFICAR SI MOSTRARA EL BOTON DE INSTACION O UNA LEYENDA DE QUE PUEDE USAR LA APP INSTALADA    
                dispatch({ type:SET_SHOWINSTALLAPP_EXITO, payload: true })
            }
        }


    }
}


//*INSTALL NOTIFICATIONS
let btnNotification = document.getElementById('btnNotification')

const installPushNotification = (userData) => {

    btnNotification.setAttribute("ref", "push")
    if ('Notification' in window && navigator.serviceWorker) {  
            // btnNotification.setAttribute("ref", "push")
            btnNotification.addEventListener('click', event => {
    
                Notification.requestPermission( result => {
    
                    if(result !== 'granted'){
                        //  console.log('No ')
                    }else{
                        // btnNotification.setAttribute("ref", "")
                        configurePushSub(userData)
                    }
                })
            })      
        }
    // }
}

const configurePushSub = (userData) => {

    if ('Notification' in window && navigator.serviceWorker) { 

        let reg
        const data = {
            endpoint:'',
            uid: userData.id,
            perfil: userData.perfil
        }

        navigator.serviceWorker.ready
        .then((swReg) => {
            reg = swReg
            swReg.pushManager.getSubscription()

        })
        .then((sub) => {

            if(!sub){
                //create a subscription
                const vapidPublicKey = 'BDXbZN7Wvw8j26m8d2WuFf64kLI0o_IfxXADFHVmpSGLbSFXB3_vjcJivtzRU_n2p9mM29eAKyECVhmgW4JJ7ws'
                const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey)
                return reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: convertedVapidKey,
                })
            }else{
                return sub
            }
            
        })
        .then((newSub) => {
            
            data.endpoint = newSub.endpoint
            return fetch('https://comerciosmx-mzt.firebaseio.com/subscriptionPush.json', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(newSub)
            })
        })
        .then((response) => {
            if(response.ok){

                return fetch('https://comerciosmx-mzt.firebaseio.com/subscriptionData.json', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(data)
                })
            }

        })
        .then(() => {
            // notiSuccessInstall()

        })
        .catch(error => {
            console.log('error', error);
        })
    }

}

// const notiSuccessInstall = () => {
                
//     const options = {
//         body: 'Here is a notification body!',
//         icon: '/assets/img/icons/icon_96x96.png',
//         badge: '/assets/img/icons/icon_96x96.png',
//         vibrate: [100, 50, 100],
//         // renotify: true,
//         data: {
//             dateOfArrival: Date.now(),
//             primaryKey: 1
//         },
//         actions: [
//             {action: 'confirm', title: 'Aceptar', icon: '/assets/img/icons/icon_96x96.png'},
//             {action: 'close', title: 'Cancelar', icon: '/assets/img/icons/icon_96x96.png'},
//           ]
        
//     }
//     navigator.serviceWorker.ready
//     .then( swReg => {
//         swReg.showNotification('Instalacion Exitosa from server', options)
//         .then(respuesta => {
//             //  console.log('se mando el mensaje')
//             navigator.vibrate([3000, 250, 1000, 250, 1000, 250, 3000]);

//         })
//         .catch(error => {
//             //  console.log(error)
//         })
//     })   
// }

const urlBase64ToUint8Array = (base64String) => {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
  
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
  
    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export const InstallNotifications = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const userData = {
            id: getState().firebase.auth.uid,
            perfil: getState().firebase.profile.perfil,
        }


        if ('Notification' in window && navigator.serviceWorker) {  
                
            if ('permissions' in navigator) {
        
                navigator.permissions.query({
                    name: 'notifications'
                }).then(permission => {
        
                    if(permission.state === 'granted'){
                        dispatch({ type: SET_SHOWINSTALLNOTIFICATION_EXITO, payload: false })
                    }else if(permission.state === 'prompt'){
                        dispatch({ type: SET_SHOWINSTALLNOTIFICATION_EXITO, payload: true })
                        installPushNotification(userData)
                    }else if(permission.state === 'denied'){
                        dispatch({ type: SET_SHOWINSTALLNOTIFICATION_EXITO, payload: false })
                    }

                    permission.addEventListener('change', listener => {
        
                        if(listener.target.state === 'granted'){
                            dispatch({ type: SET_SHOWINSTALLNOTIFICATION_EXITO, payload: false })
                        }else if(listener.target.state === 'prompt'){
                            dispatch({ type: SET_SHOWINSTALLNOTIFICATION_EXITO, payload: true })
                            installPushNotification(userData)
                        }else if(listener.target.state === 'denied'){
                            dispatch({ type: SET_SHOWINSTALLNOTIFICATION_EXITO, payload: false })
                        }
        
                    })
                
                })
            }
        }

        // dispatch({ type: MOSTRAR_MAPA_EXITO, payload: 'mapa' })

    }

}


//*INSTALL GEOLOCATION

export const InstallGeolocation = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const userData = {
            id: getState().firebase.auth.uid,
            perfil: getState().firebase.profile.perfil,
        }

        if(navigator.geolocation){

            navigator.permissions.query({
                name: 'geolocation'
            }).then(permission => {

                let element 
                //VERUFUCA EN QUE STATUS ESTAN LOS PERMISOS
                if(permission.state === 'granted'){
                    dispatch({ type: SET_GEOLOCATIONACTIVO_EXITO, payload: true })

                }else if(permission.state === 'prompt'){

                }else if(permission.state === 'denied'){
                    dispatch({ type: SET_GEOLOCATIONACTIVO_EXITO, payload: false })

                }

                //CUANDO CAMBIAN LOS STATUS DE LOS PERMISOS
                permission.addEventListener('change', listener => {

                    if(listener.target.state === 'granted'){
                        dispatch({ type: SET_GEOLOCATIONACTIVO_EXITO, payload: true })
                        window.location.reload(true)

                    }else if(listener.target.state === 'prompt'){
                        window.location.reload(true)
                        
                    }else if(listener.target.state === 'denied'){
                        dispatch({ type: SET_GEOLOCATIONACTIVO_EXITO, payload: false })
                    }
                })

            })
        }

    }

}


//*ONLINE STATUS
export const OnlineStatus = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        
        if(!window.navigator.onLine){

            dispatch({ type: SET_ONLINE_EXITO, payload: false })
        }
        
        window.addEventListener('online', (e) => { 
            dispatch({ type: SET_ONLINE_EXITO, payload: true })
        })
        
        window.addEventListener('offline', (e) => { 
            dispatch({ type: SET_ONLINE_EXITO, payload: false })
        })
        
        if(window.document.hidden){
            dispatch({ type: SET_APPACTIVA_EXITO, payload: false })            
        }
        
        window.addEventListener('focus', () => {
            dispatch({ type: SET_APPACTIVA_EXITO, payload: true })
        })
        
        window.addEventListener('blur', () => {
            dispatch({ type: SET_APPACTIVA_EXITO, payload: false })
        })
    }

}