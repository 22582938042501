import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { EnviarSoporteTicket } from '../../../../store/actions/perfilComercioActions'
import { SendMenssageAction } from '../../../../store/actions/messageActions'

//*MATERIAL UI
import Typography from '@material-ui/core/Typography'
import TreeItem from '@material-ui/lab/TreeItem'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow'

import { withStyles } from '@material-ui/styles'

const Transition = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 500
    props.timeout.exit = 500
    return <Grow ref={ref} {...props} />
})

const styles = theme => ({
    messageTitle: {
        backgroundColor: '#263238',
        color: '#fff'
    },
    message: {
        color: '#263238',
        fontWeight: 'bold'
    } 
})

const ProblemaSoporte = (props) => {

    const [openProblemaSoporte, setOpenProblemaSoporte] = useState(false)

    useEffect(() => {

    }, [])

    const handleEnviarSoporteTicket = async () => {

        props.SendMenssageAction({option: 'inicio'})
        setOpenProblemaSoporte(false)
        const enviar = await props.EnviarSoporteTicket(props.problemaSoporte)
        props.SendMenssageAction(enviar)
    }

    //*render
    const { classes, problemaSoporte } = props
    return (
        <Fragment>
            <TreeItem 
            nodeId={problemaSoporte.id_problema} 
            label={<Typography variant="h6" justify="center" ><Box p={1}>{problemaSoporte.nombreProblema}</Box></Typography>} 
            onClick={() => setOpenProblemaSoporte(true)}
            />

            <Dialog open={openProblemaSoporte} TransitionComponent={Transition} maxWidth={'xs'} fullWidth onClose={() => setOpenProblemaSoporte(false)}>                
                <Box p={1} textAlign="center" className={classes.messageTitle}>
                    <Typography variant="h6">
                        TICKET SOPORTE
                    </Typography>
                </Box>

                <Box align="center" p={2}  >
                    <Typography variant="h4" className={classes.message}>
                        {problemaSoporte.nombreProblema}
                    </Typography>
                </Box>

                <Box align="center" mb={2}>
                    <Button variant="contained" color="secondary" disableElevation onClick={handleEnviarSoporteTicket} >Enviar</Button>
                </Box>                  
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         EnviarSoporteTicket: (datos) => dispatch(EnviarSoporteTicket(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProblemaSoporte))

ProblemaSoporte.propTypes = {
    
}