import { useEffect, useState } from 'react';
import { connect } from 'react-redux'

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import { Box, Button, Card, Typography } from '@material-ui/core';
import styled from 'styled-components'
import CancelIcon from '@material-ui/icons/Cancel';
import { getFecha, checkUserHasReservationInDay, checkUserCanCancelReservation, checkUserCanSetListaEspera } from '../../allFunctions'
import { StyledBox } from '../../elementos/styled-box/StyledBox';
import { DrawerTitle } from '../../elementos/drawer-title';
import Swal from 'sweetalert2'
import { SetReservePlaceDateByUser, SetCancelReservePlaceDateByUser, SetListaEsperaByUser, SetCancelarListaEsperaByUser,
  SetReservePlaceDateByZense, SetCancelReservePlaceDateByZense, SetCancelClientReservationByAdmin, GetLocalTime
} from '../../store/actions/ClientesAction'
import { SendMenssageAction } from "../../store/actions/messageActions";
import { green, red } from '@material-ui/core/colors'
import LugaresCliente from './LugaresCliente';
import storageDataReducer from '../../store/reducers/storageDataReducer';
const CalendarioHorarioContainer = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

      width: 350px;
      position: relative;
      height: 100%;
      
      ${theme.breakpoints.down('sm')} {
        width: 250px;
      }

      .header-title {
        margin-right: 20px;
      }

      .header-toolbar {
        flex: 1
      }

      .title-dialog-desktop {
        display: flex;
        align-items: center;
      }

      .lugares-scroll-container {

        overflow-y: scroll;
      }

      .lugares-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        ${theme.breakpoints.down('sm')} {
          flex-direction: column;
          
        }
      }

      .pilate-cama {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        ${theme.breakpoints.down('sm')} {
          height: 60px;
        }
      }

      .pilate-cama-selected {
        background-color: #FCF3CF;
      }

      .pilate-camaocupado-cliente {
        background-color: ${green["300"]};
      }

      .pilate-cama-ocupado {
        background-color: #ff6961;
      }
      
      .button-lugar-container {
        // position: absolute;
        bottom: 0;
        width: calc(100% - 20px);
        padding: 10px;
      }

      .lista-espera {
        text-align: center;
        font-size: 16px;
      }

      .action-lugar-button {
        margin-top: 20px;
        height: 80px;
      }

      .lugar-button-reservar {
        background-color: ${green["300"]};
      }

      .lugar-button-cancelar {
        background-color: ${red["400"]};
      }

  `}
`

const SwipeableTemporaryDrawer = (props) => {

  const { fechaCalendarioSelected, horariosSelected, openHorario, selectedCliente, auth, FSclienteNumCreditos } = props
  const [selectedLugar, setSelectedLugar] = useState(null)
  const [fechaCalendarioSelectedUpdated, setFechaCalendarioSelectedUpdated] = useState(fechaCalendarioSelected)
  const [horariosSelectedUpadated, setHorariosSelectedUpadated] = useState(horariosSelected)

  useEffect(() => {

    if(horariosSelected && props.FScalendarioData){
      const fechaCalendario = props.FScalendarioData.find(fcalend => fcalend?.id === fechaCalendarioSelected?.id)
      const horarioCalendario = fechaCalendario?.horarios.find(horCalend => horCalend?.id === horariosSelected?.id)
      if(selectedLugar){
        const lugar = horarioCalendario?.lugares.find(lug => lug?.id === selectedLugar?.id)

        if(lugar?.ocupado){
          setSelectedLugar(null)
        }

      }
      setHorariosSelectedUpadated(horarioCalendario)
    }
  }, [props.FScalendarioData, horariosSelected])

  const handleSelectLugar = (lugar) => {
    setSelectedLugar(lugar)
  }

  const handleCloseHorario = () => {
    setSelectedLugar(null)
    props.handleCloseHorario()
  }

  const handleSetLugarHorario = async () => {

    if(FSclienteNumCreditos <= 0){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: '<strong>No cuentas con creditos</strong>',
        text: `Realiza una compra para poder reservar`,
        confirmButtonColor: '#3085d6',
      })
      return
    }

    const hasReservation = checkUserHasReservationInDay(fechaCalendarioSelected, selectedCliente)

    if(hasReservation?.encontrado && auth?.uid !== 'GQmvrpgmcDfsz3s1iuhapiUhOtx1'){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: `<strong>${hasReservation?.mensaje}</strong>`,
        text: hasReservation?.encontrado,
        confirmButtonColor: '#3085d6',
      })
      return
    }

    props.SendMenssageAction({option: 'inicio'})

    const reservar = await props.SetReservePlaceDateByUser(
      {
        idFecha: fechaCalendarioSelected?.id,
        idHorario: horariosSelected?.id,
        idLugar: selectedLugar?.id,
        cliente: selectedCliente
      }
    )

    if(reservar?.exito && reservar?.message !== 'success'){
      handleCloseHorario()
      Swal.fire({
        icon: 'error',
        title: `<strong>${reservar?.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }

    if(reservar?.exito && reservar?.message === 'success'){
      handleCloseHorario()
      Swal.fire({
        icon: 'success',
        title: '<strong>Reservación exitosa</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(reservar)
  }

  const handleCancelReservation = async () => {

    props.SendMenssageAction({option: 'inicio'})

    const time = await props.GetLocalTime()

    if(!time?.exito && !time?.time){
        Swal.fire({
            icon: 'error',
            title: `<strong>Error al consultar Horario</strong>`,
            confirmButtonColor: '#3085d6',
        })

        props.SendMenssageAction(time)
        return
    }

    const canCancel = checkUserCanCancelReservation(fechaCalendarioSelected, horariosSelectedUpadated, selectedCliente, time)

    if(!canCancel){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: '<strong>No es posinle cancelar</strong>',
        html: 'Solo es posible cancelar con <strong>12 hrs.</strong> de anticipación',
        confirmButtonColor: '#3085d6',
      })
      props.SendMenssageAction({option: 'reset'})
      return
    }

    let lugar = horariosSelectedUpadated?.lugares.find(lugar => lugar?.idCliente === selectedCliente?.id)


    const cancel = await props.SetCancelReservePlaceDateByUser(
      {
        idFecha: fechaCalendarioSelected?.id,
        idHorario: horariosSelectedUpadated?.id,
        idLugar: lugar?.id,
        idClienteCredito: lugar?.idClienteCredito,
        cliente: selectedCliente,
      }
    )

    if(cancel?.exito && cancel?.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${cancel?.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }

    if(cancel?.exito && cancel?.message === 'success'){
      handleCloseHorario()
      Swal.fire({
        icon: 'success',
        title: '<strong>Cancelación exitosa</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(cancel)

  }

  const handleSetListaEspera = async () => {

    if(FSclienteNumCreditos <= 0){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: '<strong>No cuentas con creditos</strong>',
        text: `Realiza una compra para poder reservar`,
        confirmButtonColor: '#3085d6',
      })
      return
    }

    const hasReservation = checkUserHasReservationInDay(fechaCalendarioSelected, selectedCliente)

    if(hasReservation?.encontrado){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: `<strong>${hasReservation?.mensaje}</strong>`,
        text: hasReservation?.encontrado,
        confirmButtonColor: '#3085d6',
      })
      return
    }

    const canSetListaEspera = checkUserCanSetListaEspera(fechaCalendarioSelected, horariosSelectedUpadated, selectedCliente)

    if(!canSetListaEspera){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: '<strong>No es posinle entrar a la lista de espera</strong>',
        html: 'Solo es posible entrar con <strong>12 hrs.</strong> de anticipación',
        confirmButtonColor: '#3085d6',
      })
      return
    }

    props.SendMenssageAction({option: 'inicio'})

    const listaEspera = await props.SetListaEsperaByUser(
      {
        idFecha: fechaCalendarioSelected?.id,
        idHorario: horariosSelected?.id,
        idLugar: selectedLugar?.id,
        cliente: selectedCliente
      }
    )

    if(listaEspera?.exito && listaEspera?.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${listaEspera?.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }

    if(listaEspera?.exito && listaEspera?.message === 'success'){
      handleCloseHorario()
      Swal.fire({
        icon: 'success',
        title: '<strong>En lista de espera</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(listaEspera)

  }

  const handleCancelListaEspera = async () => {

    props.SendMenssageAction({option: 'inicio'})

    const cancelListaEspera = await props.SetCancelarListaEsperaByUser(
      {
        idFecha: fechaCalendarioSelected?.id,
        idHorario: horariosSelected?.id,
        idLugar: selectedLugar?.id,
        cliente: selectedCliente
      }
    )

    if(cancelListaEspera?.exito && cancelListaEspera?.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${cancelListaEspera?.message}</strong>`,
        // text: hasReservation,
        confirmButtonColor: '#3085d6',
      })
    }

    if(cancelListaEspera?.exito && cancelListaEspera?.message === 'success'){
      handleCloseHorario()
      Swal.fire({
        icon: 'success',
        title: '<strong>En lista de espera</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(cancelListaEspera)



    
  }
  
  //ZENSE FUNCTIONS

  const handleZenseSetLugarHorario = async (lugar) => {

    props.SendMenssageAction({option: 'inicio'})

    const reservar = await props.SetReservePlaceDateByZense(
      {
        idFecha: fechaCalendarioSelected?.id,
        idHorario: horariosSelected?.id,
        idLugar: lugar?.id,
        cliente: selectedCliente
      }
    )

    if(reservar?.exito && reservar?.message !== 'success'){
      handleCloseHorario()
      Swal.fire({
        icon: 'error',
        title: `<strong>${reservar?.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }

    if(reservar?.exito && reservar?.message === 'success'){
      handleCloseHorario()
      Swal.fire({
        icon: 'success',
        title: '<strong>Reservación exitosa</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(reservar)
  }

  const handleCancelReservationZense = async (lugarData) => {

    const canCancel = checkUserCanCancelReservation(fechaCalendarioSelected, horariosSelectedUpadated, selectedCliente)

    if(!canCancel){
      handleCloseHorario()

      Swal.fire({
        icon: 'error',
        title: '<strong>No es posinle cancelar</strong>',
        html: 'Solo es posible cancelar con <strong>12 hrs.</strong> de anticipación',
        confirmButtonColor: '#3085d6',
      })
      return
    }

    let lugar = horariosSelectedUpadated?.lugares.find(lugar => lugar?.idCliente === selectedCliente?.id && lugar?.id === lugarData?.id)

    props.SendMenssageAction({option: 'inicio'})

    const cancel = await props.SetCancelReservePlaceDateByZense(
      {
        idFecha: fechaCalendarioSelected?.id,
        idHorario: horariosSelectedUpadated?.id,
        idLugar: lugar?.id,
        idClienteCredito: lugar?.idClienteCredito,
        cliente: selectedCliente,
      }
    )

    if(cancel?.exito && cancel?.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${cancel?.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }

    if(cancel?.exito && cancel?.message === 'success'){
      handleCloseHorario()
      Swal.fire({
        icon: 'success',
        title: '<strong>Cancelación exitosa</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(cancel)

  }

  const handleAskCancelClientReservation = (horarioData) => {

    Swal.fire({
      title: `¿Desea cancelar la reservación de ${horarioData?.cliente}? `,
      confirmButtonText: 'Cancelar Reservación',
      confirmButtonColor: '#3085d6',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        handleCancelClientReservation(horarioData)
      } 
    })

  }

  const handleCancelClientReservation = async (lugarData) => {

    // return

    // const canCancel = checkUserCanCancelReservation(fechaCalendarioSelected, horariosSelectedUpadated, selectedCliente)

    // if(!canCancel){
    //   handleCloseHorario()

    //   Swal.fire({
    //     icon: 'error',
    //     title: '<strong>No es posinle cancelar</strong>',
    //     html: 'Solo es posible cancelar con <strong>6 hrs.</strong> de anticipación',
    //     confirmButtonColor: '#3085d6',
    //   })
    //   return
    // }

    // let lugar = horariosSelectedUpadated?.lugares.find(lugar => lugar?.idCliente === selectedCliente?.id && lugar?.id === lugarData?.id)

    props.SendMenssageAction({option: 'inicio'})

    const cancel = await props.SetCancelClientReservationByAdmin(
      {
        idFecha: fechaCalendarioSelected?.id,
        idHorario: horariosSelectedUpadated?.id,
        idLugar: lugarData?.id,
        idClienteCredito: lugarData?.idClienteCredito,
        cliente: lugarData?.idCliente
      }
    )

    if(cancel?.exito && cancel?.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${cancel?.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }

    if(cancel?.exito && cancel?.message === 'success'){
      handleCloseHorario()
      Swal.fire({
        icon: 'success',
        title: '<strong>Cancelación exitosa</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(cancel)

  }

  //
  const fecha  = getFecha(fechaCalendarioSelected?.fecha?.seconds, 9)
  const hasReserve = horariosSelectedUpadated?.lugares.find(lugar => lugar?.idCliente === selectedCliente?.id)
  //horariosSelectedUpadated

  return (
    <div>
          <Drawer
            anchor={'right'}
            open={openHorario}
            onClose={handleCloseHorario}
          >
          <CalendarioHorarioContainer >

              <DrawerTitle title={fecha} title2={horariosSelected?.id} handleCloseDrawer={handleCloseHorario} />

            <LugaresCliente 
            horariosSelected={horariosSelectedUpadated}
            selectedCliente={selectedCliente}
            selectedLugar={selectedLugar}
            handleSelectLugar={handleSelectLugar}
            handleSetLugarHorario={handleSetLugarHorario}
            handleCancelReservation={handleCancelReservation}
            hasReserve={hasReserve}
            handleSetListaEspera={handleSetListaEspera}
            handleCancelListaEspera={handleCancelListaEspera}

            handleZenseSetLugarHorario={handleZenseSetLugarHorario}
            handleCancelReservationZense={handleCancelReservationZense}
            handleAskCancelClientReservation={handleAskCancelClientReservation}
            />

            {/* <Box className="lugares-container">
              {horariosSelected?.lugares.map(lugar => {

                let selected = ''
                if(lugar?.ocupado && selectedCliente?.id === lugar?.idCliente){
                  selected = 'pilate-camaocupado-cliente'
                }else if(lugar?.ocupado){
                  selected = 'pilate-cama-ocupado'

                }else if(selectedLugar && selectedLugar?.id === lugar.id){
                  selected = 'pilate-cama-selected'
                }
                return (
                  <Card variant="outlined" className={`pilate-cama ${selected}`} key={lugar?.id} onClick={lugar?.ocupado || hasReserve ? null : () => handleSelectLugar(lugar)}>
                    <Typography>
                      Cama {lugar?.id}
                    </Typography>
                  </Card>
                )
              })}

            </Box>

            <Box className='button-lugar-container'>
              <Button fullWidth className='action-lugar-button' 
              variant='contained' 
              onClick={selectedLugar ? handleSetLugarHorario : hasReserve ? handleCancelReservation : null}
              disabled={!selectedLugar && !hasReserve}
              >
                {selectedLugar ? 'Reservar' : hasReserve ? 'Cancelar' : 'Accion'}
              </Button>
            </Box> */}

           </CalendarioHorarioContainer>
          </Drawer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth, 
    profile: state.firebase.profile, 
    FScalendarioData: state.calendarioReducer.calendarioData,
    FSclienteNumCreditos: state.storageDataReducer.FSclienteNumCreditos
  }
}

const mapDispatchToProps = (dispatch) => {
  return{
      SetReservePlaceDateByUser: (datos) => dispatch(SetReservePlaceDateByUser(datos)),
      GetLocalTime: (datos) => dispatch(GetLocalTime(datos)),
      SetCancelReservePlaceDateByUser: (datos) => dispatch(SetCancelReservePlaceDateByUser(datos)),
      SetListaEsperaByUser: (datos) => dispatch(SetListaEsperaByUser(datos)),
      SetCancelarListaEsperaByUser: (datos) => dispatch(SetCancelarListaEsperaByUser(datos)),
      SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
      // logout: (datos) => dispatch(logout(datos)),
      SetReservePlaceDateByZense: (datos) => dispatch(SetReservePlaceDateByZense(datos)),
      SetCancelReservePlaceDateByZense: (datos) => dispatch(SetCancelReservePlaceDateByZense(datos)),
      SetCancelClientReservationByAdmin: (datos) => dispatch(SetCancelClientReservationByAdmin(datos)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SwipeableTemporaryDrawer)