import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// import clsx from "clsx";
// import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Box, FormControl, InputAdornment, InputLabel, Select, TextField } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SearchIcon } from '@material-ui/icons/Search';
import styled from 'styled-components'

import { RegistrarClienteAction, EditarClienteAction } from '../../store/actions/ClientesAction'
import { SendMenssageAction } from "../../store/actions/messageActions";

import { esNumero, getAllMonths, getAllDays, getAllYears } from '../../allFunctions'
import { DrawerTitle } from "../../elementos/drawer-title";
import RegisterForm from "../usuarios/registro/RegisterForm";
import RegistrarClienteForm from "./RegistrarClienteForm";
const RegistrarCliente = (props) => {


  const { openDrawer, selectedCliente } = props


  const handleCloseDrawer = () => {
    props.handleCloseDrawer()
    // cleanRegisterData()
  }




  return (
    <div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >

        <DrawerTitle title={selectedCliente ? 'Editar Cliente' : 'Registrar Cliente'} handleCloseDrawer={handleCloseDrawer} />
        
        <RegistrarClienteForm selectedCliente={selectedCliente} handleCloseDrawer={handleCloseDrawer} />
        
      </Drawer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  FSusuarios: state.storageDataReducer.FSusuarios,
});

const mapDispatchToProps = (dispatch) => ({
  RegistrarClienteAction: (datos) => dispatch(RegistrarClienteAction(datos)),
  EditarClienteAction: (datos) => dispatch(EditarClienteAction(datos)),
  SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
  // CancelActivarIngredientes: (datos) => dispatch(CancelActivarIngredientes(datos)),
});

export default connect( mapStateToProps, mapDispatchToProps )(RegistrarCliente);