
import authReducer from './authReducer'
import configReducer from './configReducer'
import navBarReducer from './navBarReducer'
import messageReducer from './messageReducer'
import categoriaMenuReducer from './categoriaMenuReducer'
import perfilComercioReducer from './perfilComercioReducer'
import storageDataReducer from './storageDataReducer'
import calendarioReducer from './calendarioReducer'

import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
    loginUser: authReducer,    
    configReducer: configReducer,
    navBarReducer: navBarReducer,
    messageReducer: messageReducer,
    categoriaMenuReducer: categoriaMenuReducer,
    perfilComercioReducer: perfilComercioReducer,
    storageDataReducer: storageDataReducer,
    calendarioReducer: calendarioReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
})

export default rootReducer