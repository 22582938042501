import { 
  STORAGE_CALENDARIO_EXITO,
} from '../actionTypes'


const initState = {
  calendarioData: null,
}

const calendarioReducer = (state = initState, action) => {
  switch (action.type) {       
      case STORAGE_CALENDARIO_EXITO:
          return {
              ...state,
              calendarioData: action.payload,
          }
      // case SET_USUARIOLOGIN_EXITO:
      //     return {
      //         ...state,
      //         usuarioLogin: action.payload.login,
      //         nombreUsuario: action.payload.usuario
      //     }
      default:
      return state
  }
}

export default calendarioReducer