import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// import clsx from "clsx";
// import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { Box, FormControl, InputAdornment, InputLabel, Select, TextField, Typography } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SearchIcon } from '@material-ui/icons/Search';
import styled from 'styled-components'

import { RegistrarClienteAction, EditarClienteAction, RegistrarClienteActionByAdmin } from '../../store/actions/ClientesAction'
import { SendMenssageAction } from "../../store/actions/messageActions";

import { esNumero, getAllMonths, getAllDays, getAllYears, validarCorreo, onlyLettersNumbers, ZENSEADMIN_UID } from '../../allFunctions'
import { DrawerTitle } from "../../elementos/drawer-title";
import Spinner3 from "../layout/Spinner3";
import { onlyLetters } from '../../allFunctions/index';
import Swal from "sweetalert2";
import ClienteCreditos from "./ClienteCreditos";
const ContainerRegistrarCliente = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `
  width: 350px;
  position: relative;
  height: 100%;
  padding: 10px;
  margin-top: 10px;

  .input-form-user {
    margin-bottom: 20px;
  }

  .button-registrar-container {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
  }

  .action-registrar-button {
    height: 80px;
    margin-bottom: 20px;
  }

  .phone-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .input-emergencyphone {
    margin-left: 5px;
  }

  .date-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .input-date {
    margin-left: 5px;
  }

`}
`



const RegistrarClienteForm = (props) => {

  const { selectedCliente, text, handleCloseRegistrarUsuario, recaptchaToken, handleReCaptchaVerify, auth } = props

  const pass = auth?.uid ? 'zensepilates' : ''

  const year = new Date().getFullYear()
  const [nombres, setNombres] = useState('')
  const [apellidos, setApellidos] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [telefonoEmergencia, setTelefonoEmergencia] = useState('')
  const [usuario, setUsuario] = useState('')
  const [password, setPassword] = useState(pass)
  const [monthBirthday, setMonthBirthday] = useState("Enero")
  const [dayBirthday, setDayBirthday] = useState(1)
  const [yearBirthday, setYearBirthday] = useState(year)
  const [isLoading, setIsLoading] = useState(false)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  console.log('isMobile', isMobile)
  
  useEffect(() => {

    if(selectedCliente){
      setNombres(selectedCliente?.nombre)
      setApellidos(selectedCliente?.apellidos)
      setEmail(selectedCliente?.email)
      setTelefono(selectedCliente?.telefono)
      setTelefonoEmergencia(selectedCliente?.telefonoEmergencia)
      setMonthBirthday(selectedCliente?.monthBirthday)
      setDayBirthday(selectedCliente?.dayBirthday)
      setYearBirthday(selectedCliente?.yearBirthday)
    }

  },[selectedCliente])

  const handleChange = (e) => {
    const name = e.target.name
    const valor = e.target.value

    if(name === 'nombres'){
      if(!onlyLetters(valor)) return
      setNombres(valor)
    }
    if(name === 'apellidos'){
      if(!onlyLetters(valor)) return
      setApellidos(valor)
    }
    if(name === 'email'){
      setEmail(valor.trim().toLowerCase())
    }
    if(name === 'telefono'){

      if(!esNumero(valor) || valor.length > 10) return 
      setTelefono(valor.trim())
    }
    if(name === 'telefonoEmergencia'){
      if(!esNumero(valor) || valor.length > 10) return 
      setTelefonoEmergencia(valor.trim())
    }
    if(name === 'day'){
      setDayBirthday(valor)
    }
    if(name === 'month'){
      setMonthBirthday(valor)
    }
    if(name === 'year'){
      setYearBirthday(valor)
    }
    if(name === 'usuario'){
      if(!onlyLettersNumbers(valor)) return
      setUsuario(valor.trim().toLowerCase())
    }
    if(name === 'password'){
      if(!onlyLettersNumbers(valor)) return
      setPassword(valor.trim().toLowerCase())
    }
  }

  const cleanRegisterData = () => {
      setNombres('')
      setApellidos('')
      setEmail('')
      setTelefono('')
      setTelefonoEmergencia('')
      setMonthBirthday('Enero')
      setYearBirthday(year)
      setDayBirthday(0)
      setUsuario('')
      setPassword('')
  }

  const canSave = () => {

    if(nombres.trim() === "") return null
    if(apellidos.trim() === "") return null
    if(email.trim() === "") return null
    if(!dayBirthday) return null
    if(!monthBirthday) return null
    if(!yearBirthday) return null
    if(telefono.trim() === "") return null
    if(telefonoEmergencia.trim() === "") return null
    if(usuario.trim() === "") return null
    if(password.trim() === "") return null
    if(!validarCorreo(email.trim())) return 'El formato del correo no es valido'
    if(telefono.trim().length !== 10) return 'El telefono debe contener 10 digitos'
    if(telefonoEmergencia.trim().length !== 10) return 'El telefono de emergencia debe contener 10 digitos'
    if(!onlyLettersNumbers(usuario.trim())) return 'El usuario solo debe de contener letras y numeros'
    if(!onlyLettersNumbers(password.trim())) return 'El usuario solo debe de contener letras y numeros'
    if(usuario.trim().length < 8) return 'El usuario debe de contener al menos 8 caracteres'
    if(password.trim().length < 8) return 'la contraseña debe de contener al menos 8 caracteres'

    return 'success'

  }

  const canSaveEdit = () => {

    if(nombres.trim() === "") return null
    if(apellidos.trim() === "") return null
    if(email.trim() === "") return null
    if(!dayBirthday) return null
    if(!monthBirthday) return null
    if(!yearBirthday) return null
    if(telefono.trim() === "") return null
    if(telefonoEmergencia.trim() === "") return null
    if(!validarCorreo(email.trim())) return 'El formato del correo no es valido'
    if(telefono.trim().length !== 10) return 'El telefono debe contener 10 digitos'
    if(telefonoEmergencia.trim().length !== 10) return 'El telefono de emergencia debe contener 10 digitos'

    return 'success'

  }

  const handleRegistrarCliente = async () => {

    const canContinue = canSave()

    if(!canContinue){
      Swal.fire({
        icon: 'error',
        title: `<strong>Todos los campos son obligatorios</strong>`,
        confirmButtonColor: '#3085d6',
      })
      return
    }
    if(canContinue !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${canContinue}</strong>`,
        confirmButtonColor: '#3085d6',
      })
      return
    }
    const month = getAllMonths().find(month => month.mes === monthBirthday)

    console.log(parseInt(yearBirthday),month?.id, monthBirthday , parseInt(dayBirthday) )
    const data = {
      nombre: nombres,
      apellidos: apellidos,
      email: email,
      telefono: telefono,
      telefonoEmergencia: telefonoEmergencia,
      dayBirthday: dayBirthday,
      monthBirthday: monthBirthday,
      yearBirthday: yearBirthday,
      birthDay: new Date(parseInt(yearBirthday),month?.id , parseInt(dayBirthday), 0, 0, 0),
      usuario: usuario,
      password: password,
      recaptcha: recaptchaToken
    }

    if(text){
      setIsLoading(true)
    }else{
      props.SendMenssageAction({option: 'inicio'})
    }
    let guardar

    if(auth?.uid){
      guardar = await props.RegistrarClienteActionByAdmin(data)
    }else if(text) {
      guardar = await props.RegistrarClienteAction(data)
    }

    if(guardar?.exito){
      if(text){
        handleCloseRegistrarUsuario()
        
        return
      }
      cleanRegisterData()
      props.handleCloseDrawer()
      Swal.fire({
        icon: 'success',
        title: '<strong>Cliente Registrado</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }else{
      let message
      if(guardar.message === 'Este usuario ya esta en uso'){
        message = 'Esta usuario ya se registgro'
      }else {
        message = guardar.message
      }
      Swal.fire({
        icon: 'error',
        title: `<strong>${message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }

    props.SendMenssageAction(guardar)
    setIsLoading(false)

    if(text){
      handleReCaptchaVerify()
    }
  }

  const handleEditarCliente = async () => {

    const canContinue = canSaveEdit()

    if(!canContinue){
      Swal.fire({
        icon: 'error',
        title: `<strong>Todos los campos son obligatorios</strong>`,
        confirmButtonColor: '#3085d6',
      })
      return
    }
    if(canContinue !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${canContinue}</strong>`,
        confirmButtonColor: '#3085d6',
      })
      return
    }

    const data = {
      id: selectedCliente?.id,
      nombre: nombres,
      apellidos: apellidos,
      email: email,
      telefono: telefono,
      telefonoEmergencia: telefonoEmergencia,
      monthBirthday: monthBirthday,
      dayBirthday: dayBirthday,
      yearBirthday: yearBirthday
    }

    props.SendMenssageAction({option: 'inicio'})
    const editar = await props.EditarClienteAction(data)
    if(editar?.exito){
      cleanRegisterData()
      props.handleCloseDrawer()

      Swal.fire({
        icon: 'success',
        title: '<strong>Datos registrados</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }
    props.SendMenssageAction(editar)
  }

  const allMonths = getAllMonths()
  const allDays = getAllDays()
  const allYears = getAllYears()

  console.log('authWW', auth?.uid)
  return (
    <ContainerRegistrarCliente >
        <TextField
          label="Nombre"
          name="nombres"
          value={nombres}
          type="text"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          className="input-form-user"
        />
        <TextField
          label="Apellidos"
          name="apellidos"
          value={apellidos}
          type="text"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          className="input-form-user"
        />

        <Box className="date-container">
        <FormControl variant="outlined" className="input-form-user" fullWidth>
            <InputLabel htmlFor="outlined-age-native-simple">Dia de nacimiento</InputLabel>
              <Select
                native
                value={dayBirthday}
                onChange={handleChange}
                label="Dia de nacimiento"
                inputProps={{
                  name: 'day',
                }}
              >
                {allDays.map(day => {
                  return (
                    <option key={day} value={day} >{day}</option>
                  )
                })}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth className="input-form-user input-date">
            <InputLabel htmlFor="outlined-age-native-simple">Mes de nacimiento</InputLabel>
              <Select
                native
                value={monthBirthday}
                onChange={handleChange}
                label="Mes de nacimiento"
                inputProps={{
                  name: 'month',
                }}
              >
                {allMonths.map(month => {
                  return (
                    <option key={month?.id} value={month.mes} >{month.mes}</option>
                  )
                })}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth className="input-form-user input-date">
            <InputLabel htmlFor="outlined-age-native-simple">Ano de nacimiento</InputLabel>
              <Select
                native
                value={yearBirthday}
                onChange={handleChange}
                label="ano de nacimiento"
                inputProps={{
                  name: 'year',
                }}
              >
                {allYears.map(year => {
                  return (
                    <option key={year} value={year} >{year}</option>
                  )
                })}
            </Select>
          </FormControl>
          
        </Box>

        <TextField
          label="Correo"
          name="email"
          value={email}
          type="text"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          className="input-form-user"
        />
        <Box className="phone-container">
          <TextField
            label="Telefono"
            name="telefono"
            value={telefono}
            type={isMobile ? "number" : "text"}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user"
          />
          <TextField
            label="TelefonoEmergencia"
            name="telefonoEmergencia"
            value={telefonoEmergencia}
            type={isMobile ? "number" : "text"}
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user input-emergencyphone "
          />
        </Box>
        {selectedCliente ?
        null
        :
        <TextField
          label="Usuario"
          name="usuario"
          value={usuario}
          type="text"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          className="input-form-user"
        />
        }
        {selectedCliente || auth?.uid ?
        null
        :
        <TextField
          label="Contraseña"
          name="password"
          value={password}
          type="text"
          variant="outlined"
          fullWidth
          onChange={handleChange}
          className="input-form-user"
        />
        }

        {selectedCliente && auth?.uid === ZENSEADMIN_UID ?
        <>
        <Typography>
          USUARIO: {selectedCliente?.usuario}
        </Typography>
        <ClienteCreditos selectedCliente={selectedCliente}/>
        {/* <Typography>
          CONTRASEÑA: {selectedCliente?.password}
        </Typography> */}
        </>
        :
        null
        }


        <Box className={text ? '' : 'button-registrar-container'}>
          {isLoading ?
          <Box align="center">
            <Spinner3 />
          </Box>
          :
          <>
          <Button fullWidth className='action-registrar-button' variant='contained' onClick={selectedCliente ? handleEditarCliente : handleRegistrarCliente} >
                {text ? text : selectedCliente ? 'Editar cliente' : 'Registrar cliente'}
          </Button>
          {text ?
          <Button fullWidth className='action-registrar-button' variant='contained' color="secondary" onClick={handleCloseRegistrarUsuario} >
                REGRESAR
          </Button>
          :
          null
          }
          </>
          }
        </Box>


      </ContainerRegistrarCliente>
  )
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth, 
  FSusuarios: state.storageDataReducer.FSusuarios,
});

const mapDispatchToProps = (dispatch) => ({
  RegistrarClienteAction: (datos) => dispatch(RegistrarClienteAction(datos)),
  EditarClienteAction: (datos) => dispatch(EditarClienteAction(datos)),
  SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
  RegistrarClienteActionByAdmin: (datos) => dispatch(RegistrarClienteActionByAdmin(datos)),
  // CancelActivarIngredientes: (datos) => dispatch(CancelActivarIngredientes(datos)),
});

export default connect( mapStateToProps, mapDispatchToProps )(RegistrarClienteForm);