import { connect } from "react-redux";
import { SendMenssageAction } from "../../store/actions/messageActions";
import { useEffect, useState } from 'react';
import { GetClienteCreditos, UpdateClientCredit } from "../../store/actions/ClientesAction";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { getFecha } from "../../allFunctions";
import styled from "styled-components";
import Swal from "sweetalert2";

const ContainerClienteCreditos = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

  margin-top: 10px;

  .credito-cliente-container {
    display: flex;
    align-items: flex-end;
  }

  .text-cliente-vigencia {
    flex-grow: 1;
  }

  .input-cliente-credito {
    width: 60px;
    max-width: 60px;
    margin-right: 10px;
  }

  .text-bold {
    font-weight: 800;
  }

`}
`

const CreditoClienteValue = (props) => {

  const { credito  } = props
  const [currentCredito, setCurrentCredito] = useState(credito?.creditos)

  const handleUpdateCredit = () => {

    props.handleUpdateCredit({
      currentCredito,
      idCredito: credito?.id
    })
  }

  return (
    <Box>
      <TextField 
        size="small"
        className="input-cliente-credito"
        label="" 
        variant="outlined" 
        value={currentCredito}
        type="number"
        onChange={(e) => setCurrentCredito(e.target.value)}
      />
      <Button variant="contained" color="primary" onClick={handleUpdateCredit}>
        OK
      </Button>

    </Box>
  )
}


const ClienteCreditos = (props) => {

  const { selectedCliente } = props

  const [allClienteCreditos, setAllClienteCreditos] = useState([])
  useEffect(() => {
    
    handleGetClienteCreditos()
  
  }, [])

  const handleGetClienteCreditos = async () => {

    const creditosData = await props.GetClienteCreditos(selectedCliente)

    setAllClienteCreditos(creditosData)
  }

  const handleUpdateCredit = async ({currentCredito, idCredito}) => {

    if(!currentCredito || currentCredito === ''){
      Swal.fire({
        icon: 'error',
        title: `<strong>Registra un número</strong>`,
        confirmButtonColor: '#3085d6',
      })
      return
    }

    if(parseInt(currentCredito) < 0){
      Swal.fire({
        icon: 'error',
        title: `<strong>El número debe ser 0 o mayor a  0</strong>`,
        confirmButtonColor: '#3085d6',
      })
      return
    }

    props.SendMenssageAction({option: 'inicio'})

    const update = await props.UpdateClientCredit({
      creditos: parseInt(currentCredito),
      idCliente: selectedCliente?.id,
      idCredito: idCredito
    })

    if(update?.exito && update?.message !== 'success'){
        Swal.fire({
          icon: 'error',
          title: `<strong>${update?.message}</strong>`,
          confirmButtonColor: '#3085d6',
        })
    }

    if(update?.exito && update?.message === 'success'){
        Swal.fire({
          icon: 'success',
          title: '<strong>Creditos actualizados</strong>',
          showConfirmButton: false,
          timer: 2000
        })
    }
    props.SendMenssageAction(update)

  }
  
  if(allClienteCreditos?.length === 0) return null
  return (
    <ContainerClienteCreditos>
    Creditos:
    {allClienteCreditos.map(credito => {

      const fecha = getFecha(credito?.fechaVigencia?.seconds, 10)
      return (
        <Box key={credito?.uid} className="credito-cliente-container">

          <Typography className="text-cliente-vigencia">
            <span className="text-bold">Vigencia:</span> {fecha}
          </Typography>
          
          <CreditoClienteValue credito={credito} handleUpdateCredit={handleUpdateCredit} />
        </Box>
      )
    })}
    </ContainerClienteCreditos>
  )
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth, 
});

const mapDispatchToProps = (dispatch) => ({
  GetClienteCreditos: (datos) => dispatch(GetClienteCreditos(datos)),
  UpdateClientCredit: (datos) => dispatch(UpdateClientCredit(datos)),
  SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
});

export default connect( mapStateToProps, mapDispatchToProps )(ClienteCreditos);