import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'

//*COMPONENTES
// import MenuOrdenDetalle from './MenuOrdenDetalle'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import Divider from '@material-ui/core/Divider'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})

const Menu = (props) => {

    const [openCantidad, setOpenCantidad] = useState(false)

    useEffect(() => {

    }, [])


    //*render
    const { classes, producto, total, ultimo, handleEliminarProducto } = props

    const totalProducto = parseFloat(producto.cantidad) * parseFloat(producto.precioMenu)

    return (
        <Fragment>
            <Divider />
            <Box px={1} display='flex' alignItems="center" justifyContent="center" style={{minHeight: 50}} >
                
                <Box flexGrow={1}>
                    
                    <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                        {producto.cantidad} x - {producto.nombreMenu}
                    </Typography>

                </Box>
                {/* <Box p={1}>
                    <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                    $ {parseFloat(totalProducto).toFixed(2)}
                    </Typography>
                </Box> */}
            </Box>

            {producto.adiciones ? 
            <Box px={1}>
                {producto.adiciones && producto.adiciones.map(adicion => {

                    if(adicion.menuAdiciones && adicion.menuAdiciones.length === 0) return null
                    
                    return(
                        <Box key={adicion.id_adicion} display='flex' alignItems='center'>
                            <Box>
                                <Typography variant="body1" color="textPrimary" className={classes.secondaryText}>
                                    <b>- {adicion.titulo}</b>
                                </Typography>
                                
                                {adicion.menuAdiciones && adicion.menuAdiciones.map(ingrediente => {

                                    return(
                                        <Box px={1} key={ingrediente.id_ingrediente}>
                                            <Typography  variant="body1" color="textPrimary" className={classes.secondaryText} style={{color: ingrediente.nombre.includes("Sin ") ? '#c62828' : ''}}>
                                                - {ingrediente.nombre} <b>{ingrediente.costo !==0 ? ` + $ ${parseFloat(ingrediente.costo).toFixed(2)}` : ''}</b>
                                            </Typography>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                    )
                })}
                
            </Box>
            :
            null
            }

            <Box>
                <Typography variant="body1" color="textPrimary" style={{fontFamily: 'Montserrat'}}>
                <b>- Comentario:</b>
                </Typography>
                <Box px={1}>
                    <Typography variant="body1" color="textPrimary" style={{fontFamily: 'Montserrat'}}>
                    {producto.comentario}
                    </Typography>
                </Box>
                <Box px={1} pt={2} style={{minWidth: '80px'}}>
                    <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 14}}>
                    $ {parseFloat(total).toFixed(2)}
                    </Typography>
                </Box>
            </Box>


            {ultimo ?
            <Divider />
            : null
            }

            {/* <MenuOrdenDetalle 
            menu={producto} 
            openCantidad={openCantidad}
            setOpenCantidad={setOpenCantidad}
            /> */}
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        // SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Menu))

Menu.propTypes = {
    
}