import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import PaymentIntent from '../../../ordenesEnviadas/PaymentIntent'
import { getFecha } from '../../../../allFunctions'
//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/styles'

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})

const styles = theme => ({
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        flexBasis: '30.33%',
        flexShrink: 1,
    },
    root: {
        width: '100%',
    },
})

const PagosFood = (props) => {

    const [expanded, setExpanded] = React.useState(false);
    const [openPayment, setOpenPayment] = useState(false)
    const [paymentIntentData, setPaymentIntentData] = useState(null)

    const handleChange = (panel) => (event, isExpanded) => {

        setExpanded(isExpanded ? panel : false)

    };

    const handleOpenPayment = (id) => {

        const paymentData = props.FSpaymentIntents.filter(FSpayment => FSpayment.id_orden === id)[0]
        setPaymentIntentData(paymentData)
        setOpenPayment(true)
        
    }

    const handleClosePayment = () => {

        setOpenPayment(false)
        setPaymentIntentData(null)
        
    }

    

    useEffect(() => {

    }, [])

    //*render
    const { classes, openPagosFood, setOpenPagosFood, FSpagos } = props

    return (
        <Fragment>
            <Dialog 
            open={openPagosFood} 
            // TransitionComponent={TransitionSlide} 
            // maxWidth={'xs'} 
            fullScreen 
            onClose={() => setOpenPagosFood(false)}
            PaperProps={{
                style: {
                  backgroundColor: '#eceff1',
                  boxShadow: "none"
                }
              }}
            >
            <Grid item className={classes.styleContenedor} xs={12}  >
                
                <Box p={1} display="flex" className={classes.fixedUp} onClick={() => setOpenPagosFood(false)}>
                    <Box mx={1}>
                        <ArrowBackIcon />
                    </Box>
                    
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h6" justify="center" >
                            Pagos
                        </Typography>
                    </Box>
                    <Box mx={2}></Box>
                </Box>

                <Box mt={8}></Box>
                <div className={classes.root}>
                {FSpagos && FSpagos.map(FSpago => {

                    return (

                        <Accordion key={FSpago.fecha} expanded={expanded === FSpago.fecha} onChange={handleChange(FSpago.fecha)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography className={classes.heading}>{FSpago.fecha}</Typography>
                                <Typography className={classes.secondaryHeading}>$ {parseFloat(FSpago.monto / 100).toFixed(2)}</Typography>
                                <Typography className={classes.secondaryHeading}>$ {parseFloat(FSpago.neto / 100).toFixed(2)}</Typography>
                                <Typography className={classes.secondaryHeading}>{getFecha(FSpago.fechaPago, 1)}</Typography>
                            </AccordionSummary>
                            {/* <AccordionDetails> */}
                                
                                {FSpago.pagos.map(pago => {

                                    return (
                                        <Box p={1} key={pago.id}>
                                            <Card variant='outlined' style={{cursor: 'pointer'}} onClick={() => handleOpenPayment(pago.id)} >
                                                <Box minHeight={100}>

                                                    <Box px={1} textAlign='left' style={{backgroundColor: '#000'}}>
                                                        <Typography style={{color: '#fff', fontSize: '16px'}}>
                                                            ID: <span>{pago.id.toUpperCase().slice(0, 7)}</span><span style={{color: '#00BCD4'}}>{pago.id.toUpperCase().slice(7, 11)}</span>
                                                        </Typography>
                                                    </Box>

                                                    <Box px={1} >
                                                        <Box display='flex' width={150} >
                                                            <Box flexGrow={1}>
                                                                <Typography style={{color: '#000', fontSize: '16px'}}>
                                                                    Monto:
                                                                </Typography>
                                                            </Box>
                                                            <Box align='right'>
                                                                <Typography style={{color: '#000', fontSize: '16px'}}>
                                                                    <span style={{color: '#00BCD4', textAlign: 'right'}}>$ {parseFloat(pago.monto / 100).toFixed(2)}</span>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box display='flex' width={150} >
                                                            <Box flexGrow={1}>
                                                                <Typography style={{color: '#000', fontSize: '16px'}}>
                                                                    Cargo:
                                                                </Typography>
                                                            </Box>
                                                            <Box align='right'>
                                                                <Typography style={{color: '#000', fontSize: '16px'}}>
                                                                    <span style={{color: '#00BCD4', textAlign: 'right'}}>$ {parseFloat(pago.cobro / 100).toFixed(2)}</span>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box display='flex' width={150} >
                                                            <Box flexGrow={1}>
                                                                <Typography style={{color: '#000', fontSize: '16px'}}>
                                                                    Pago:
                                                                </Typography>
                                                            </Box>
                                                            <Box align='right'>
                                                                <Typography style={{color: '#000', fontSize: '16px'}}>
                                                                    <span style={{color: '#00BCD4', textAlign: 'right'}}>$ {parseFloat(pago.neto / 100).toFixed(2)}</span>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Card>
                                        </Box>

                                    )
                                })}
                                
                            {/* </AccordionDetails> */}
                        </Accordion>


                    )
                })}
     
                </div>

                <Dialog 
            open={openPayment}
            // TransitionComponent={Transition}
            maxWidth='sm'
            fullWidth
            onClose={handleClosePayment}
            >
                <Box>
                   {paymentIntentData ? 

                   <PaymentIntent 
                   paymentIntent={paymentIntentData}
                   />
                   :
                   null
                   }
                   
                </Box>
            </Dialog>
                
            </Grid>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        FSpagos: state.storageDataReducer.FSpagos,
        FSpaymentIntents: state.storageDataReducer.FSpaymentIntents,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PagosFood))

PagosFood.propTypes = {
    
}