import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import ComentariosNuevos from './ComentariosNuevos'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'
import Badge from '@material-ui/core/Badge'
import MessageIcon from '@material-ui/icons/Message';
import MailOutlineIcon from '@material-ui/icons/MailOutline'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    fixedDown: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        right: 0 ,
        margin: 10,
        // width: '100%',
        color: '#ffffff'
    },
})

const NotificacionButton = (props) => {

    const [comentarios, setComentarios] = useState(0)
    const [openComentarios, setOpenComentarios] = useState(false)

    useEffect(() => {

        if(props.FSnuevosComentarios){

            if(props.FSnuevosComentarios.length === 0){
                setOpenComentarios(false)
            }
            
            setComentarios(props.FSnuevosComentarios.length)
        }else{
            setComentarios(0)
            setOpenComentarios(false)
        }
        

    }, [props.FSnuevosComentarios])

    const handleOpenComentarios = () => {

        setOpenComentarios(true)
    }

    const { classes } = props
    return (
        <Fragment>
            <Box className={classes.fixedDown} style={{display: comentarios !== 0 ? '' : 'none'}} >
                <Fab color="primary" onClick={handleOpenComentarios}>
                    <Badge badgeContent={comentarios} color="secondary">
                        <MessageIcon />
                    </Badge>
                </Fab>
            </Box>

            <ComentariosNuevos
            openComentarios={openComentarios}
            setOpenComentarios={setOpenComentarios}
            />
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        FSnuevosComentarios: state.storageDataReducer.FSnuevosComentarios,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificacionButton))

NotificacionButton.propTypes = {
    
}