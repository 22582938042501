import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES


//*MATERIAL UI
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Typography from "@material-ui/core/Typography"
import Box from '@material-ui/core/Box'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})

const FinaliadoMenu = (props) => {
   
    useEffect(() => {

        
        
    }, [])


    //*render
    const { classes, menu } = props

    return (

        <Box display="flex" className={classes.comercio}>
            <Box  align={'left'} flexGrow={1} px={1}>
                <Typography variant="body1">
                {menu.cantidad} - {menu.nombreMenu}
                </Typography>
            </Box>                
            <Box align={'right'} px={1}>
                <Typography variant="body1">
                    $ {(parseInt(menu.cantidad) * parseInt(menu.precio))}.00
                </Typography>
            </Box>
        </Box>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FinaliadoMenu))

FinaliadoMenu.propTypes = {
    
}