import { 
    MOSTRAR_PERFIL_EXITO,         
    MOSTRAR_MENU_EXITO,
    MOSTRAR_ORDENES_EXITO,
    MOSTRAR_ENVIOS_EXITO
} from '../actionTypes'

export const MostrarSelectNavbar = (option) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        // const firestore = getFirestore()

        //  firestore.collection('ordenPedidosFood').doc('dmrfcAXj2AG6awhLvD9T').get()
        // .then(docs => {

        //     docs.forEach(doc => {

        //         console.log('doc id', doc.id);

        //     })
        // })
        // .catch(error => {
        //     console.log(error);
        // })


        
        // dispatch({ type: RESET_NAVBARSELECT_EXITO, payload: option })
        setTimeout(() => { 
            
            switch (option) {
                case 'perfil':  
                    dispatch({ type: MOSTRAR_PERFIL_EXITO, payload: option })               
                    break
                case 'menu':
                    dispatch({ type: MOSTRAR_MENU_EXITO, payload: option })
                    break
                case 'ordenes':
                    dispatch({ type: MOSTRAR_ORDENES_EXITO, payload: option })
                    break
                case 'envios':
                    dispatch({ type: MOSTRAR_ENVIOS_EXITO, payload: option })
                    break
                
                default:
                    return null
            }

         }, 300)

    }
}
