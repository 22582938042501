import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
// import OrdenFood from './OrdenFood'
import NoAtendidas from './NoAtendidas'
import Regresadas from './Regresadas'
import Canceladas from './Canceladas'
import Finalizadas from './Finalizadas'
import OrdenFood from './OrdenFood'
import { getFecha } from '../../../../allFunctions'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List';

import { withStyles } from '@material-ui/styles'

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})

const styles = theme => ({
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
    width: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 720,
        } 
    },
    
})

const OrdenesFood = (props) => {

    // const [allOrdenesFood, setAllOrdenesFood] = useState(null)
    // const [ordenesNoAtendidas, setOrdenesNoAtendidas] = useState(null)
    
    const [openNoAtendidas, setOpenNoAtendidas] = useState(false)
    const [openRegresadas, setOpenRegresadas] = useState(false)
    const [openCanceladas, setOpenCanceladas] = useState(false)
    const [openFinalizadas, setOpenFinalizadas] = useState(false)
    
    useEffect(() => {

    }, [])

    //*render
    const { classes, setOpenOrdenesFood, openOrdenesFood, FSordenesFinalizadas} = props
    return (
        <Fragment>

            <Dialog 
            open={openOrdenesFood} 
            // TransitionComponent={TransitionSlide} 
            // maxWidth={'xs'} 
            fullScreen 
            onClose={() => setOpenOrdenesFood(false)}
            PaperProps={{
                style: {
                  backgroundColor: '#eceff1',
                  boxShadow: "none"
                }
              }}
            >
            <Grid item className={classes.styleContenedor} xs={12}  >

                <Box p={1} display="flex" className={classes.fixedUp} onClick={() => setOpenOrdenesFood(false)}>
                    <Box mx={1}>
                        <ArrowBackIcon />
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h6" justify="center" >
                            Listado de Ordenes
                        </Typography>
                    </Box>
                    <Box mx={2}></Box>
                </Box>

                <Box mt={7}>
                <List >
                    {FSordenesFinalizadas && FSordenesFinalizadas.map(FSordenFood => {

                        let total = 0

                        FSordenFood.productos.map(producto => {
                            const totalProducto = parseFloat(producto.cantidad) * parseFloat(producto.precio)
                            total = total + totalProducto
                        })

                        return(
                            <OrdenFood 
                            key={FSordenFood.id_document} 
                            FSordenFood={FSordenFood}
                            total={total}
                            />
                        )
                    })}
                </List>
                </Box>



                {/* <Box px={2} mt={7} align="center">
                    <Box mt={2} >
                        <Button variant="contained" color="secondary" fullWidth disableElevation onClick={() => setOpenNoAtendidas(true)}>
                            Ordenes no atendidas
                        </Button>
                    </Box>
                    <Box mt={2}>
                        <Button variant="contained" color="secondary" fullWidth disableElevation onClick={() => setOpenRegresadas(true)}>
                            Ordenes regresadas
                        </Button>
                    </Box>
                    <Box mt={2}>
                        <Button variant="contained" color="secondary" fullWidth disableElevation onClick={() => setOpenCanceladas(true)}>
                            Ordenes Canceladas
                        </Button>
                    </Box>
                    <Box mt={2}>
                        <Button variant="contained" color="secondary" fullWidth disableElevation onClick={() => setOpenFinalizadas(true)}>
                            Ordenes Finalizadas
                        </Button>
                    </Box>
                </Box> */}

            </Grid>
            </Dialog>


            {/* <NoAtendidas 
            setOpenNoAtendidas={setOpenNoAtendidas}
            openNoAtendidas={openNoAtendidas}
            />

            <Regresadas
            setOpenRegresadas={setOpenRegresadas}
            openRegresadas={openRegresadas}
            />

            <Canceladas
            setOpenCanceladas={setOpenCanceladas}
            openCanceladas={openCanceladas}
            /> */}

            <Finalizadas
            setOpenFinalizadas={setOpenFinalizadas}
            openFinalizadas={openFinalizadas}
            />

            
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        FSordenesFinalizadas: state.storageDataReducer.FSordenesFinalizadas,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrdenesFood))

OrdenesFood.propTypes = {
    
}