import { ENVIAR_MESSAGE_INICIO,    
    ENVIAR_MESSAGE_PREVE,
    ENVIAR_MESSAGE_EXITO,
    ENVIAR_MESSAGE_ERROR,
    RESET_STATE_ENVIARMESSAGE } from '../actionTypes'

import { getFecha } from '../../allFunctions'


export const AceptarOrden = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const firebase = getFirebase()
        
        const data = JSON.parse(JSON.stringify(datos))
        
        const FSprofile = getState().firebase.profile
        const domicilioComercio = JSON.parse(JSON.stringify(getState().storageDataReducer.FSdomicilioComercio))
        domicilioComercio.telefonoComercio = FSprofile.telefonoComercio

        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        if(!data.id && data.id === ''){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        }

        data.tiempoProceso.elaboracion = { seconds: Math.floor(new Date().getTime()/1000.0)}

        const dataOrden = {
            editedBy: userData,
            tiempoProceso: data.tiempoProceso,
            status: 4,
            domicilioOrigen: domicilioComercio,
        }
        
        
        await firestore.collection('ordenPedidosFood').doc(data.id).update(dataOrden)
        .then(() => {

            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        if(resultado.exito && !data.ordenPrueba){

            if(data.metodoPago.metodo === 'Tarjeta'){

                firebase.functions().httpsCallable('cliente_RealizarPagoOrdenFood')(data)
                .then(response => {
                    // console.log('response', response);
                    
                    return resultado
                })
                .catch(error => {
                    console.log('error', error);
                })
            }else{

                firebase.functions().httpsCallable('Global_EnviarPushNotification')({
                    option: 'ordenEnProceso',
                    id_cliente: data.id_cliente,
                    id_orden: data.id_orden,
                    nombreComercio: data.nombreComercio,
                    systemName: data.domicilioDestino.systemName,
                })
                .catch(error => {
                    console.log('error', error)
                })
            }

        }

        return resultado

    }
}

export const EnviarSoporteTicket = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        
        const FSprofile = getState().firebase.profile
        const domicilioComercio = JSON.parse(JSON.stringify(getState().storageDataReducer.FSdomicilioComercio))
        domicilioComercio.telefonoComercio = FSprofile.telefonoComercio
        
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

        if(!datos || !datos.hasOwnProperty('id') || datos.id === ''){

            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        }


        // return resultado
        await firestore.collection('ordenPedidosFood').doc(datos.id).update({
            soporte: true,
            soporteFecha: { seconds: Math.floor(new Date().getTime()/1000.0)},
            soporteEmisor: 'comercio',
            domicilioOrigen: domicilioComercio
        })
        .then(() => {
            resultado.exito = true
            resultado.option = 'exito'
            resultado.message = 'Solicitud enviada'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado
    }
}

export const OrdenAtendida = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()

        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
        }

        if(!datos && !datos.hasOwnProperty('id') && datos.id === ''){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        }

        await firestore.collection('ordenPedidosFood').doc(datos.id).update({
            notificacionPagoTarjeta: true,
            ordenAtendida: true,
            editedBy: userData
        })
        .then(() => {
            
            resultado.exito = true
            resultado.option = 'reset'

        })
        .catch(error => {
            console.log('error', error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado

    }
}



//*

export const ConfirmarOrden = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        // const firebase = getFirebase()
        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
            fecha: new Date()
        }

        datos.tiempoProceso.revision = new Date()

        await firestore.collection('ordenPedidosFood').doc(datos.id).update({
            status: 3,
            tiempoProceso: datos.tiempoProceso,
            editedBy: userData
        })
        .then(() => {

            resultado.exito = true
            resultado.option = 'reset'
        })
        .catch(error => {
            console.log(error, 'error')
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado

    }
}

export const NotificacionPagoTarjeta = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        // const firebase = getFirebase()
        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        await firestore.collection('ordenPedidosFood').doc(datos.id).update({
            notificacionPagoTarjeta: true,
            editedBy: userData
        })
        .then(() => {

            resultado.exito = true
            resultado.option = 'reset'
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado

    }
}

const GuardarRoute = async (datos) => {

    const resultado = {
        exito: false,
        option: '',
        message: '',
        dataRoute: null
    }

    const origenLngLat = datos.origen
    const destinoLngLat = datos.destino
    
     const urlGeoSon = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${origenLngLat[0]},${origenLngLat[1]};${destinoLngLat[0]},${destinoLngLat[1]}.json?steps=true&geometries=geojson&language=es_ES&access_token=${window.mapboxgl.accessToken}`
    
    //FETCH QUE CONSULTA LA API PARA OBTENER DATOS
    await fetch(urlGeoSon)
    .then(resp => resp.json()) // Transform the data into json
    .then(data => {

        if(data.code === 'Ok'){

            const steps = []
            data.routes[0].legs[0].steps.map(step => {
                steps.push(step.maneuver.instruction)
            })

            const route = {
                drawRoute: data.routes[0].geometry.coordinates,
                steps: steps,
                duracion: data.routes[0].duration,
                distancia: data.routes[0].distance,
            }

            resultado.exito = true
            resultado.dataRoute = route

        }
        return resultado

     })
     .catch((error) => {
         console.log(error)
         resultado.message = 'Se produjo un error'
     }) 

     return resultado
}

export const RevisarOrden = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firebase = getFirebase()
        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        let error = false
        let encontrado = false
        let status
        let produc = []
        let produCancelado = []

        const data = JSON.parse(JSON.stringify(datos))

        data.productos.map(producto => {

            if(!producto.productoStatus.status){

                produc.push(`${producto.nombreMenu},`)
                produCancelado.push(`${producto.nombreMenu} - ${producto.productoStatus.message},`)
                encontrado = true
                if(producto.productoStatus.message === ''){
                    error = true
                }
            }
        })

        if(error){

            resultado.option = 'preve'
            resultado.message = 'Falta mensaje para el cliente'
            return resultado
        }

        let dataOrden
        let messageCancelado = null
        if(encontrado || !data.ordenStatus.status){

            const funct = firebase.functions()
            const cancelarOrdenFood = funct.httpsCallable('CancelarOrdenFood')

            data.tiempoProceso.revision = new Date()
            data.sendReady = false

            if(encontrado){

                data.editedBy = userData
                data.ordenStatus.status = false
                data.ordenStatus.message = `El comercio tiene problemas con el siguiente producto: ${produc} realize los cambios y envie la orden nuevamente`
                data.messageCancelado = `El comercio tuvo problemas con el siguiente producto: ${produCancelado}`
                data.status = 0

                await cancelarOrdenFood(data)
                .then(response => {
                    setTimeout(() => {
                        dispatch({ type: RESET_STATE_ENVIARMESSAGE })
                    }, 300)
                })
                .catch(error => {
                    console.log(error)
                    resultado.option = 'error'
                    resultado.message = 'Se produjo un error'
                    setTimeout(() => {
                        dispatch({ type: ENVIAR_MESSAGE_ERROR, payload: 'Se produjo un error' })
                    }, 300)
            
                })

            }else{

                const usuarios = getState().storageDataReducer.FSusuarios

                usuarios.map(user => {
                    user.login = false
                })

                data.ordenStatus.message = 'El comercio ha cerrado'
                data.usuarios = usuarios
                data.status = 500
                await cancelarOrdenFood(data)
                .then(response => {
                    setTimeout(() => {
                        dispatch({ type: RESET_STATE_ENVIARMESSAGE })
                    }, 300)
                })
                .catch(error => {
                    console.log(error)
                    resultado.option = 'error'
                    resultado.message = 'Se produjo un error'
                    setTimeout(() => {
                        dispatch({ type: ENVIAR_MESSAGE_ERROR, payload: 'Se produjo un error' })
                    }, 300)
            
                })
            }

        }else{
            const domicilioComercio = getState().storageDataReducer.domicilioComercio
            domicilioComercio.telefonoComercio = getState().firebase.profile.telefonoComercio

            const dataLngLat = {
                origen: [domicilioComercio.lng, domicilioComercio.lat],
                destino: [data.domicilioDestino.lng, data.domicilioDestino.lat],
            }

            if(data.metodoPago.metodo === 'Tarjeta'){
                status = 1
            }else{
                status = 4
            }

            data.tiempoProceso.elaboracion = new Date()

            dataOrden = {
                editedBy: userData,
                // productos: data.productos,
                // ordenStatus: data.ordenStatus,
                tiempoProceso: data.tiempoProceso,
                servicioGuuwii: status === 4 ? true : false,
                status: status,

                domicilioOrigen: domicilioComercio,
            }

            await firestore.collection('ordenPedidosFood').doc(data.id).update(dataOrden)
            .then(() => {

                resultado.exito = true
                resultado.option = 'reset'
                setTimeout(() => {
                    dispatch({ type: RESET_STATE_ENVIARMESSAGE })
                }, 300)
            })
            .catch(error => {
                console.log(error)
                resultado.option = 'error'
                resultado.message = 'Se produjo un error'
                setTimeout(() => {
                    dispatch({ type: ENVIAR_MESSAGE_ERROR, payload: 'Se produjo un error' })
                }, 300)
        
            })

        }

        return resultado
    }
}

export const AsignarRepartidor = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firebase = getFirebase()
        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
            fecha: new Date()
        }

        if(datos.repartidor.id === '' || datos.ordenEnviada.id === ''){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado

        }else if(datos.repartidor.id === datos.ordenEnviada.id_repartidor){
            resultado.option = 'preve'
            resultado.message = 'El repatidor ya esta asignado a esta orden'
            return resultado
        }

        await firestore.collection('ordenPedidosFood').doc(datos.ordenEnviada.id).update({
            id_repartidor: datos.repartidor.id,
            repartidor: {
                id_repartidor: datos.repartidor.id,
                nombre: datos.repartidor.nombre,
                foto: datos.repartidor.foto,
                status: 'wait',
                createdAt: new Date(),
                ordenEntregada: false
            },
            editedBy: userData
        })
        .then(() => {
            resultado.exito = true
            resultado.option = 'reset'
            
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        if(resultado.exito){
            const funct = firebase.functions()
            const enviarNotificacion = funct.httpsCallable('Global_EnviarPushNotification')
            enviarNotificacion({id_repartidor: datos.repartidor.id, type: 'AsignarRepartidor'})
            .then((response) => {
                // console.log('response', response);
            })
            .catch(error => {
                console.log('error', error)
            })
        }

        return resultado

    }
}

export const CancelarAsignacion = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        if(!datos.id && datos.id === ''){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        }
        

        await firestore.collection('ordenPedidosFood').doc(datos.id).update({
            id_repartidor: '',
            repartidor: {
                createdAt: '',
                foto: '',
                id_repartidor: '',
                nombre: '',
                status: 'not',
                ordenEntragada: false
            }
        })
        .then(() => {

            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        })

        return resultado
    }
}

export const ComentarioLeido = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        const comentarios = JSON.parse(JSON.stringify(getState().storageDataReducer.FScomentarios))
        
        comentarios.map(comentario => {
            if(comentario.id_comentario === datos.id_comentario){
                comentario.enterado = true
            }
            
            return true
        })

        await new Promise(resolve => setTimeout( resolve , 1000))  
        
        await firestore.collection('user_comerciosFood').doc(userData.id).collection('notificaciones').doc('1mp1qc6TYGPiZL78Sgrl1Z2VqmI2').update({
            comentarios: comentarios
        })
        .then(() => {
            
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado
    }
}

export const ActualizarPaymentVisto = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        console.log('enviando')
        
        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        await firestore.collection('paymentIntents').doc(datos.id).update({
            visto: true,
            editedBy: userData
        })
        .then(() => {
            
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado

    }
}

export const ActualizarPaymentAtendido = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        await firestore.collection('paymentIntents').doc(datos.id).update({
            atendido: true,
            editedBy: userData
        })
        .then(() => {
            
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado

    }
}


export const EnviarOrden = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firebase = getFirebase()
        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        datos.tiempoProceso.envio = new Date()
        
        await firestore.collection('ordenPedidosFood').doc(datos.id).update({
            status: 5,
            domicilioOrigen: datos.domicilioOrigen,
            tiempoProceso: datos.tiempoProceso,
            editedBy: userData
        })
        .then(() => {
            
            resultado.exito = true
            resultado.option = 'reset'
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        if(resultado.exito){
            if(datos.recogerOrden){
                const funct = firebase.functions()
                const enviarNotificacion = funct.httpsCallable('EnviarNotificacion')
                enviarNotificacion({
                    id_cliente: datos.id_cliente, 
                    id_comercio: datos.id_comercio, 
                    id_orden: datos.id_orden,
                    option: 'ordenLista'
                })
                .catch(error => {
                    console.log('error notificacion', error);
                })
            }
        }

        return resultado

    }
}

export const MensajeOrdenLista = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: true,
            option: 'reset',
            message: ''
        }

        const firebase = getFirebase()
        const funct = firebase.functions()
        const enviarNotificacion = funct.httpsCallable('EnviarNotificacion')
        enviarNotificacion({
            id_cliente: datos.id_cliente, 
            id_comercio: datos.id_comercio, 
            id_orden: datos.id_orden,
            option: 'ordenLista'
        })
        .catch(error => {
            console.log('error notificacion', error);
        })
        return resultado
    }
}

export const OrdenEntregada = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firebase = getFirebase()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        if(!datos.id && datos.id === ''){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado
        }

        datos.tiempoProceso.entrega =  new Date()

        // console.log('datos', datos);
        const funct = firebase.functions()
        const finalizarOrdenFood = funct.httpsCallable('FinalizarOrdenFood')
        await finalizarOrdenFood(datos)
        .then(response => {

            console.log(response);
            if(response.data.exito){
                resultado.exito = true
                resultado.option = 'exito'
                resultado.message = 'Orden entregada'
            }else{
                resultado.option = 'error'
                resultado.message = 'Se produjo un error' 
            }

            return resultado
            
        })    
        .catch(error => {
            console.log('error',error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'             
        })

        return resultado

    }
}

export const CancelarOrdenNoPagada = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firebase = getFirebase()
        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }
        
        let tiempoPago = datos.tiempoProceso.elaboracion.seconds
        tiempoPago = tiempoPago + (10 * 60)
        
        const timeNow = Math.floor(new Date().getTime()/1000.0)

        if(tiempoPago > timeNow){
            setTimeout(() => {
                dispatch({ type: ENVIAR_MESSAGE_PREVE, payload: 'Deben pasar al menos 10 min. para poder cancelar la orden' })
            }, 300)
            return
        }

        // return 

        const productos = []
        datos.productos.map(producto => {
            productos.push({
                id_producto: producto.id_producto,
                cantidad: producto.cantidad,
                nombreMenu: producto.nombreMenu
            })
            return true
        })

        const ordenCancelada = {
            createdAt: datos.createdAt,
            canceladoAt: new Date(),
            id_cliente: datos.id_cliente,
            id_comercio: datos.id_comercio,
            id_document: datos.id,
            id_orden: datos.id_orden,
            productos: productos,
            message: 'No acredito el pago de la orden',
            status: 520,
        }

        await firestore.collection('ordenPedidosFood').doc(datos.id).update({
            canceladoAt: new Date(),
            status: 520,
            finalizado: true,
            ordenStatus: {
                ...datos.ordenStatus,
                message: 'No acredito el pago de la orden'
            },
            editedBy: userData
        })
        .then(() => {

            setTimeout(() => {
                dispatch({ type: ENVIAR_MESSAGE_EXITO, payload: 'Se ha cancelado la orden' })
            }, 300)

            const funct = firebase.functions()
            const cancelarOrdenFood = funct.httpsCallable('CancelarOrdenFood')
            cancelarOrdenFood(ordenCancelada)
        })
        .catch(error => {
            console.log(error)
            setTimeout(() => {
                dispatch({ type: ENVIAR_MESSAGE_ERROR, payload: 'Se produjo un error' })
            }, 300)
        })

    }
}

export const CancelarOrden = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        const firebase = getFirebase()
        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName
        }

        if(datos.motivo === ''){

            setTimeout(() => {
                dispatch({ type: ENVIAR_MESSAGE_PREVE, payload: 'Debe capturar un motivo' })
            }, 300)
            return
        }

        const data = JSON.parse(JSON.stringify(datos.orden))


        data.ordenStatus.message = datos.motivo.toLowerCase()
        data.status = 530

        const funct = firebase.functions()
        const cancelarOrdenFood = funct.httpsCallable('CancelarOrdenFood')
        await cancelarOrdenFood(data)
        .then(response => {

            dispatch({ type: RESET_STATE_ENVIARMESSAGE })                
            
        })    
        .catch(error => {
            console.log('error',error)
            dispatch({ type: ENVIAR_MESSAGE_ERROR, payload: 'Se produjo un error' })                
        })

    }
}

export const AsignarRepartidorSinApp = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const resultado = {
            exito: false,
            option: '',
            message: ''
        }

        // console.log('datos', datos);
        // return resultado
        const firebase = getFirebase()
        const firestore = getFirestore()
        const userData = {
            id: getState().firebase.auth.uid,
            name: getState().firebase.auth.displayName,
            fecha: new Date()
        }

        if(datos.id === ''){
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
            return resultado

        }

        datos.tiempoProceso.envio = new Date()

        await firestore.collection('ordenPedidosFood').doc(datos.id).update({
            status: 5,
            id_repartidor: 'idRepartidorSInApp',
            repartidor: {
                id_repartidor: 'idRepartidorSInApp',
                nombre: 'Repartidor',
                foto: '',
                status: 'ok',
                createdAt: new Date(),
                ordenEntregada: true
            },
            tiempoProceso: datos.tiempoProceso,
            editedBy: userData
        })
        .then(() => {
            resultado.exito = true
            resultado.option = 'reset'
            
            return resultado
        })
        .catch(error => {
            console.log(error)
            resultado.option = 'error'
            resultado.message = 'Se produjo un error'
        })

        return resultado

    }
}

