import React, { Component } from 'react';

//MUI componentes
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography'

import { withStyles } from '@material-ui/styles';

const styles = {
    styleContenedor: {
    margin: 'auto',
    maxWidth: 400
    },
    styleError : {
        backgroundColor: '#FFF9C4',
        color: 'red'
    }
  };

class ErrorMensaje extends Component {

    render(){        

        let { mensaje } = this.props    

        return (
            <Box className={this.props.classes.styleError} align={'center'} mx={2} mt={2} p={1}>
                <Typography variant="body2">
                    {mensaje}
                </Typography>
            </Box>       
        )
    }    
   
}

export default withStyles(styles)(ErrorMensaje)

// export const ErrorMensaje = (props) => {
    
//     return (
//         <div>
//             <div className={`alert alert-${props.tipoMensaje} text-center`}>{props.mensaje}</div>
//         </div>
//     )

   
// }
