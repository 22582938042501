import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})

const PushNotifications = (props) => {

    const [Data, setData] = useState()

    useEffect(() => {


    }, [])

    const handleInputInstallApp = () => {
        const btnInstallApp = document.getElementById('btnInstallApp')
        btnInstallApp.click()
    }

    const handleInputIntallPush = () => {
        // navigator.vibrate([500, 250, 500, 250, 500, 250, 500, 250, 500, 250, 500]);
        const btnNotification = document.getElementById('btnNotification')
        btnNotification.click()
    }

    const handleInputIntallGeo = () => {
        const btnLocalizacion = document.getElementById('btnLocalizacion')
        btnLocalizacion.click()
    }

    //*render
    const { configReducer } = props

    return (
        <Fragment>
             <Box px={3} mt={3}>
                <Box align="center" style={{display: configReducer.showInstallApp ? '' : 'none'}} >
                    <Button variant="contained" color="secondary" fullWidth onClick={handleInputInstallApp}>
                        Instalar App
                    </Button>
                </Box>
            </Box>
             <Box px={3} mt={1}>
                <Box align="center" style={{display: configReducer.showInstallNotification ? '' : 'none'}} >
                    <Button variant="contained" color="secondary" fullWidth onClick={handleInputIntallPush}>
                        Instalar Notificaciones
                    </Button>
                </Box>
            </Box>

        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        configReducer: state.configReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PushNotifications))

PushNotifications.propTypes = {
    
}