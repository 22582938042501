import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONETES
import ProblemaSoporte from './ProblemaSoporte'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import TreeItem from '@material-ui/lab/TreeItem'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})

const CatSoporte = (props) => {

    const [Data, setData] = useState()

    useEffect(() => {

    }, [])

    //*render
    const { catSoporte } = props
    return (
        <Fragment>
            <TreeItem nodeId={catSoporte.id_categoria} label={<Box><Box p={1}><Typography variant="h3" justify="center" >{catSoporte.nombreCategoria}</Typography></Box><Divider/></Box>}>

                {catSoporte && catSoporte.problemas.map(problema => {
                    return(
                        <ProblemaSoporte
                            key={problema.id_problema}
                            problemaSoporte={problema}
                        />
                    )
                })}
            </TreeItem>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CatSoporte))

CatSoporte.propTypes = {
    
}