import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';
import { Box } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const TerminosCondiciones = (props) => {

  const { open, handleClose, handleOpenRegistrarUsuario } = props

  const [noLesiones, setNoLesiones] = useState(false)
  const [noEmbarazo, setNoEmbarazo] = useState(false)
  const [aceptoTerminos, setAceptoTerminos] = useState(false)

  useEffect(() => {
  
    if(open){

      setNoLesiones(false)
      setNoEmbarazo(false)
      setAceptoTerminos(false)
    }

  }, [open])
  

  const handleChange = (event) => {

    const valor = event.target.checked
    const name = event.target.name

    if(name === 'lesion'){
      setNoLesiones(valor)
    }
    if(name === 'embarazo'){
      setNoEmbarazo(valor)
    }
    if(name === 'acepto'){
      setAceptoTerminos(valor)

    }

  }

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  let next = false
  if(noLesiones && noEmbarazo && aceptoTerminos){
    next = true
  }

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Terminos y condiciones
        </DialogTitle>
        <DialogContent dividers>

          <Typography gutterBottom>
          Los diferentes paquetes se contratan por creditos que son las horas clase que podras recibir.
          </Typography>
          <Typography gutterBottom>
          Los paquetes son de uso personal y no se pueden compartir.
          </Typography>
          <Typography gutterBottom>
          Es necesario que te registres para contratar tu paquete y poder usar tus creditos.
          </Typography>
          <Typography gutterBottom>
          Solo podras reservar las horas que contrataste en tu paquete ( 1 crédito = 1 clase ).
          </Typography>
          <Typography gutterBottom>
          Solo se puede reservar 1 cama por hora.
          </Typography>
          <Typography gutterBottom>
          Si necesitas cancelar tu clase, tienes que hacerlo con 12 horas de anticipación para no perder tu crédito y puedas reagendar.
          </Typography>
          <Typography gutterBottom>
          Clase no cancelada no es reembolsable.
          </Typography>
          <Typography gutterBottom>
          Te recomendamos reservar tu cama con anticipacion y debes respetar el espacio de tus compañeros.
          </Typography>
          <Typography gutterBottom>
          Es muy importante que llegues a tiempo a tu clase para realizar tu calentamiento.
          </Typography>
          <Typography gutterBottom>
          El tiempo de tolerancia es de 5 min. y se recomienda llegar 5 min. antes.
          </Typography>
          <Typography gutterBottom>
          Si es tu primera vez avisanos y llega 10 minutos antes para darte las primeras indicaciones.
          </Typography>
          <Typography gutterBottom>
          Te recordamos que es una clase grupal, por lo que debes respetar a los demas y guardar silencio. Solo podras utilizar la cama que reservaste.
          </Typography>
          <Typography gutterBottom>
          Es necesario el uso de calcetas y traigas una toalla para tu uso personal.
          </Typography>
          <Typography gutterBottom>
          Durante la clase, los celulares deben permanecer en silencio y su uso no esta permitido. Si necesitas hacer una llamada debes salir del estudio y retomar tu clase cuando termines.
          </Typography>
          <Typography gutterBottom>
          Por tu seguridad sigue las indicaciones de tu coach. Solo podras utilizar los accesorios cuando se te indique.
          </Typography>
          <Typography gutterBottom>
          Al terminar tu clase no olvides regresar todo a su lugar y sanitizar tu cama.
          </Typography>
          <Typography gutterBottom>
          Formas de pago efectivo y tarjeta de debito / crédito.
          </Typography>
          <Typography gutterBottom>
          Te recomendamos pagar en efectivo ya que el pago con tarjeta tiene un cobro de comision del 3.6% adicional.
          </Typography>

        </DialogContent>
        <Box m={2}>
          <Box>
            <FormControlLabel
              control={<Checkbox color='primary' checked={noLesiones} onChange={handleChange} name="lesion" style={{margin: 0, padding: 2}} />}
              label="No cuento con una lesión actualmente"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox color='primary' checked={noEmbarazo} onChange={handleChange} name="embarazo" style={{margin: 0, padding: 2}} />}
              label="No estoy Embarazada"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox color='primary' checked={aceptoTerminos} onChange={handleChange} name="acepto" style={{margin: 0, padding: 2}} />}
              label="Acepto terminos y condiciones"
            />
          </Box>
        </Box>

        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={next ? handleOpenRegistrarUsuario : null} color="primary" disabled={!next} >
            SIGUIENTE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TerminosCondiciones