import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import Adicion from './Adicion'
import { GuardarIngredientes } from '../../store/actions/categoriaMenuActions'
import { SendMenssageAction } from '../../store/actions/messageActions'

//*MATERIAL UI
import Typography from "@material-ui/core/Typography"
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    fixedDown: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        color: '#ffffff',
        maxWidth: 720,
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },

})

const MenuAdiciones = (props) => {

    const [adicionesMenu, setAdicionesMenu] = useState(null)

    useEffect(() => {

        const dataMenu = props.menu
        const AllAdiciones = []

        dataMenu.id_adiciones && dataMenu.id_adiciones.map(id_adicion => {
            props.FSmenuAdiciones && props.FSmenuAdiciones.map(menuAdicion => {
                // console.log(id_adicion, '-', menuAdicion.id_adicion)
                if(id_adicion === menuAdicion.id_adicion && dataMenu.id_comercio === menuAdicion.id_comercio){
                    AllAdiciones.push(menuAdicion)
                }
            })
        })

        setAdicionesMenu(AllAdiciones)

    }, [props.FSmenuAdiciones, props.menu])

    const handleGuardarIngredientes = async () => {
        props.SendMenssageAction({option: 'inicio'})
        const guardar = await props.GuardarIngredientes()
        props.SendMenssageAction(guardar)
    }


    //*render
    if(!adicionesMenu) return null

    const {classes, openMenuAdiciones, setOpenMenuAdiciones, mostrarGuardarAdicion } = props

    return (
        <Fragment>
            <Dialog 
            open={openMenuAdiciones}
            fullScreen
            >
            <Grid item className={classes.styleContenedor} xs={12}  >

            <Box p={1} display="flex" className={classes.fixedUp} onClick={() => setOpenMenuAdiciones(false)}>
                <Box mx={1}>
                    <ArrowBackIcon />
                </Box>
                <Box flexGrow={1} align={'center'} >
                    <Typography variant="h6" justify="center" >
                        {props.menu.nombreMenu}
                    </Typography>
                </Box>
                <Box mx={2}></Box>
            </Box>

            <Box mt={10}>
            {adicionesMenu && adicionesMenu.map(adicion => {

                return(
                    <Box key={adicion.id_adicion}>
                        
                        <Adicion
                            adicion={adicion}
                        />

                    </Box>
                )
            })}
            </Box>

            <Box className={classes.fixedDown}>
                {mostrarGuardarAdicion ?
                <Button variant="contained" fullWidth color="secondary" onClick={handleGuardarIngredientes} disableElevation>
                    GUARDAR CAMBIOS
                </Button>
                : null
                }
            </Box>
            </Grid>




            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        FSmenuAdiciones: state.storageDataReducer.FSmenuAdiciones,
        mostrarGuardarAdicion: state.categoriaMenuReducer.mostrarGuardarAdicion,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         GuardarIngredientes: (datos) => dispatch(GuardarIngredientes(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MenuAdiciones))

MenuAdiciones.propTypes = {
    
}