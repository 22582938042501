import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { ActivarSoundAlert, SetSoundSelected, GuardarSonido } from '../../../../store/actions/perfilComercioActions'
import { SendMenssageAction } from '../../../../store/actions/messageActions'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import VolumeMuteIcon from '@material-ui/icons/VolumeMute'
import IconButton from '@material-ui/core/IconButton'


import { withStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'

const styles = theme => ({
    iconButton: {
        backgroundColor: '#263238',
        '&:hover': {
            backgroundColor: "#263238",
        },
        color: '#fff',
        cursor: 'pointer',
    },
})

const Sonido = (props) => {

    const [sonido, setSonido] = useState(null)
    const [repetir, setRepetir] = useState(null)
    const [playSound, setPlaySound] = useState(false)

    useEffect(() => {
        if(props.FSsoundSelected){
            setSonido(props.FSsoundSelected.sonido)
            setRepetir(props.FSsoundSelected.repetir ? 'si' : 'no')
        }
    }, [props.FSsoundSelected])

    useEffect(() => {
        setPlaySound(props.playSound)
    }, [props.playSound])

    const handleChangeSonido = (event) => {
        // setSonido(event.target.value)
        props.SetSoundSelected({sonido: event.target.value, repetir: repetir === 'si' ? true : false})
    }

    const handleChangeRepetir = (event) => {
        // setRepetir(event.target.value)
        props.SetSoundSelected({sonido: sonido , repetir: event.target.value === 'si' ? true : false})
    }

    const handlePlaySound = () => {
        props.ActivarSoundAlert({playSound: true})
    }

    const handleMuteSound = () => {
        props.ActivarSoundAlert({playSound: false})
    }

    const handleGuardarSonido = async () => {
        props.SendMenssageAction({option: 'inicio'})
        const guardar = await props.GuardarSonido({sonido: sonido , repetir: repetir === 'si' ? true : false})
        props.SendMenssageAction(guardar)
    }

    const { classes } = props

    return (
        <Fragment>
            <Box p={1}>
                <Card variant='outlined'>
                    <Box p={2} align='center'>
                        <Box>
                            <FormControl component="fieldset">
                                <Box align='center'>
                                    <FormLabel component="legend"><Typography variant='h5'>REPETIR</Typography></FormLabel>
                                </Box>
                                <RadioGroup row value={repetir} onChange={handleChangeRepetir}>
                                    <FormControlLabel value='si' control={<Radio />} labelPlacement="top" label="Si" />
                                    <FormControlLabel value='no' control={<Radio />} labelPlacement="top" label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
        
                    <Box p={2}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Sonidos</FormLabel>
                            <RadioGroup value={sonido} onChange={handleChangeSonido}>
                                <FormControlLabel value="sound_1.mp3" control={<Radio />} label="Sonido Uno" />
                                <FormControlLabel value="sound_2.mp3" control={<Radio />} label="Sonido Dos" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
        
                    <Box px={2}>
                    {playSound ?
                        <IconButton className={classes.iconButton} onClick={handleMuteSound} >                                
                            <VolumeUpIcon />
                        </IconButton>
                        :
                        <IconButton className={classes.iconButton} onClick={handlePlaySound}>
                            <VolumeMuteIcon />
                        </IconButton>
                    }
                    </Box>
        
                    <Box p={2} align='right'>
                        <Button variant='contained' color='secondary' disableElevation onClick={handleGuardarSonido}>
                            Guardar Cambios
                        </Button>
                    </Box>
                </Card>
            </Box>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        playSound: state.perfilComercioReducer.playSound,     
        FSsoundSelected: state.storageDataReducer.FSsoundSelected,     

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         ActivarSoundAlert: (datos) => dispatch(ActivarSoundAlert(datos)),
         GuardarSonido: (datos) => dispatch(GuardarSonido(datos)),
         SetSoundSelected: (datos) => dispatch(SetSoundSelected(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sonido))

Sonido.propTypes = {
    
}