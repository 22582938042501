import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { VerificarUsuario } from '../../../../store/actions/perfilComercioActions'
import { SendMenssageAction } from '../../../../store/actions/messageActions'

//*MATERIAL UI
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import PersonIcon from '@material-ui/icons/Person'
import Divider from '@material-ui/core/Divider'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grow from '@material-ui/core/Grow'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { teal } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'

import { withStyles } from '@material-ui/styles'

const Transition = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Grow ref={ref} {...props} />
})

const styles = theme => ({
    login: {
        color: teal[500],
    },
    usuarios: {
        backgroundColor: '#fff',
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
    cancelar: {
        backgroundColor: '#e57373',
        '&:hover': {
            backgroundColor: "#e57373",
        },
        color: '#fff',
    }
})

const Cuenta = (props) => {

    const [usuario, setUsuario] = useState(null)
    const [openUsuario, setOpenUsuario] = useState(false)

    useEffect(() => {

        if(props.usuario){

            setUsuario({
                id_usuario: props.usuario.id_usuario,
                usuario: props.usuario.usuario,
                login: props.usuario.login,
                password: ''
            })
    
        }

    }, [props.usuario])

    const handleChange = (e) => {
        setUsuario({
            ...usuario,
            [e.target.name] : e.target.value
        })
    }

    const handleVerificarUsuario = async () => {

        props.SendMenssageAction({option: 'inicio'})
        const verificar = await props.VerificarUsuario(usuario)
        if(verificar.exito){
            setUsuario({
                ...usuario,
                password: ''
            })
            setOpenUsuario(false)
        }

        props.SendMenssageAction(verificar)
    }

    const handleOpenUsuario = (datos) => {
        
        setUsuario({
            ...usuario,
            password: ''
        })

        setOpenUsuario(true)
    }   

    const handleOpenEliminarUsuario = () => {

        props.handleOpenEliminarUsuario(usuario)
    }


    if(!usuario) return null
    //*render
    const { classes } = props
    return (
        <Box>
            <ListItem button className={classes.usuarios} onClick={props.usuario.login ? null : handleOpenUsuario}>
                <ListItemIcon>
                    <PersonIcon className={props.usuario.login ? classes.login : ''}/>
                </ListItemIcon>
                <ListItemText primary={usuario.usuario} />
                <ListItemSecondaryAction>
                    <IconButton onClick={handleOpenEliminarUsuario}>
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />

            <Drawer anchor={'top'} open={openUsuario} >
            <Grid item className={classes.styleContenedor} xs={12}  >
                <Box p={2} align="center" >
                    <Typography variant="h5" >
                        {usuario.usuario}
                    </Typography>
                </Box>
                <Box px={6} mb={2}>
                <TextField
                    label="Contrasena"
                    name="password"
                    type="password"
                    value={usuario.password}
                    onChange={handleChange}
                    color="secondary"
                    autoComplete="off"
                    fullWidth
                />
                </Box>

                <Box display="flex">

                    <Box py={1} px={6} flexGrow={1} >
                        <Button variant="contained" className={classes.cancelar} onClick={()=> setOpenUsuario(false)} disableElevation >
                            CANCELAR
                        </Button>
                    </Box>
                    <Box py={1} px={6} >
                        <Button variant="contained" color="secondary" onClick={handleVerificarUsuario} disableElevation>
                            ACEPTAR
                        </Button>
                    </Box>
                </Box>
            </Grid>
            </Drawer>

        </Box>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         VerificarUsuario: (datos) => dispatch(VerificarUsuario(datos)),
         SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Cuenta))

Cuenta.propTypes = {
    
}