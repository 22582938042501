import React, { PureComponent } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import NavbarIn from './NavbarIn'
import NavbarOut from './NavbarOut'

//*MATERIAL UI
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import { yellow } from '@material-ui/core/colors'
class Navbar extends PureComponent {

    render() {
        if(!this.props.profile.isLoaded) return null
        const { auth, profile } = this.props
        let links =  <NavbarOut />
        // console.log('auth', auth)
        // console.log('profileW', profile)
        if(auth?.uid){
            links = profile?.roll ? <NavbarIn /> : <NavbarOut />
        }
        
        return (
            <AppBar style={{backgroundColor: yellow["A400"]}} elevation={0}>
                <Box ml={1} >
                    {links}                        
                </Box>
            </AppBar>
        )
    }
}

const mapStateToProps = (state) => {
    
    return{
       auth: state.firebase.auth,
       profile: state.firebase.profile
    }
}

export default connect(mapStateToProps)(Navbar)

Navbar.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
}