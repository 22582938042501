import React, { useState, useEffect, forwardRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import OrdenFood from './OrdenFood'

//*MATERIAL UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'

import { withStyles } from '@material-ui/styles'

const TransitionSlide = forwardRef(function Transition(props, ref) {
    props.timeout.enter = 200
    props.timeout.exit = 200
    return <Slide direction="right" ref={ref} {...props} />
})

const styles = theme => ({
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
})

const compare = ( a, b ) => {
    if ( a.createdAt.seconds < b.createdAt.seconds ){
      return 1
    }
    if ( a.createdAt.seconds > b.createdAt.seconds ){
      return -1
    }
    return 0
}

const NoAtendidas = (props) => {

    const [ordenesFood, setOrdenesFood] = useState(null)

    useEffect(() => {

        if(props.storageDataReducer.FSallOrdenesNoAtendidas){
            setOrdenesFood(props.storageDataReducer.FSallOrdenesNoAtendidas.sort(compare))
        }

    }, [props.storageDataReducer.FSallOrdenesNoAtendidas])


    if(!ordenesFood) return null

    const { classes , setOpenNoAtendidas, openNoAtendidas } = props
    return (
        <Fragment>
            <Dialog 
            open={openNoAtendidas} 
            // TransitionComponent={TransitionSlide} 
            // maxWidth={'xs'} 
            fullScreen 
            onClose={() => setOpenNoAtendidas(false)}
            PaperProps={{
                style: {
                    backgroundColor: '#eceff1',
                    boxShadow: "none"
                }
                }}
            >
            <Grid item className={classes.styleContenedor} xs={12}  >

                    <Box p={1} display="flex" className={classes.fixedUp} onClick={() => setOpenNoAtendidas(false)}>
                        <Box mx={1}>
                            <ArrowBackIcon />
                        </Box>
                        <Box flexGrow={1} align={'center'} >
                            <Typography variant="h6" justify="center" >
                                Ordenes No Atendidas
                            </Typography>
                        </Box>
                        <Box mx={2}></Box>
                    </Box>

                    <Box mt={7}>
                        {ordenesFood && ordenesFood.map(ordenFood => {
                            return(
                                <OrdenFood
                                key={ordenFood.id_orden}
                                ordenFood={ordenFood}
                                />
                            )
                        })}
                    </Box>

            </Grid>
            </Dialog>
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        storageDataReducer: state.storageDataReducer,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NoAtendidas))

NoAtendidas.propTypes = {
    
}