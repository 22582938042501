import React, { useState, useEffect, Fragment } from 'react'
import Sound from 'react-sound'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { ActivarSoundAlert } from '../../store/actions/perfilComercioActions'

//*MATERIAL UI

import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    style: {

    }
})

const SoundAlert = (props) => {

    const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED)
    const [sonido, setSonido] = useState(null)

    useEffect(() => {

        if(props.playSound){
            setPlayStatus(Sound.status.PLAYING)
        }else{
            setPlayStatus(Sound.status.STOPPED)
        }

    }, [props.playSound])

    // useEffect(() => {

    //     if(props.FSsoundSelected){
    //         setSonido(props.FSsoundSelected)
    //     }

    // }, [props.FSsoundSelected])


    const handleLoading = () => {

    }

    const handleLoad = () => {

        if(props.playSound){
            setPlayStatus(Sound.status.PLAYING)

        }
    }

    const handlePlaying = () => {
    }

    const handlePlay = () => {
        setPlayStatus(Sound.status.PLAYING)
    }

    const handleFinishPlay = () => {
        setPlayStatus(Sound.status.STOPPED)
        props.ActivarSoundAlert({playSound: false})
        // console.log('termino');

    }

    const onBufferChange = (state) => {
    }

    return (
        <Fragment>
            <Sound
                url={process.env.PUBLIC_URL + `/sounds/${props.FSsoundSelected.sonido}`}
                // url={process.env.PUBLIC_URL + `/sounds/sound_1.mp3`}
                playStatus={playStatus}
                playFromPosition={0 /* in milliseconds */}
                loop={props.FSsoundSelected.repetir}
                onLoading={handleLoading}
                onLoad={handleLoad}
                onPlaying={handlePlaying}
                onFinishedPlaying={handleFinishPlay}
                onBufferChange={onBufferChange}
                />
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        playSound: state.perfilComercioReducer.playSound,     
        FSsoundSelected: state.storageDataReducer.FSsoundSelected,     

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         ActivarSoundAlert: (datos) => dispatch(ActivarSoundAlert(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SoundAlert))

SoundAlert.propTypes = {
    
}