import { 
    STORAGE_DOMICILIOCOMERCIO_EXITO,
    STORAGE_COMERCIO_EXITO,
    STORAGE_CATEGORIASMENU_EXITO,
    STORAGE_MENUADICIONES_EXITO,
    STORAGE_INGREDIENTES_EXITO,
    STORAGE_INGREDIENTESDISABLED_EXITO,
    STORAGE_MENUSID_EXITO,
    SET_NUMPAYMENTSNUEVOS_EXITO,
    STORAGE_ORDENESFOOD_EXITO,
    STORAGE_MENUBLOQUEADO_EXITO,
    STORAGE_REPARTIDORES_EXITO,
    STORAGE_COMISIONFOOD_EXITO,
    STORAGE_USUARIOS_EXITO,
    STORAGE_USUARIOSCREDITOS_EXITO,
    STORAGE_USUARIONUMCREDITOS_EXITO,
    STORAGE_CATSOPORTE_EXITO,
    STORAGE_CATMENURESPUESTAS_EXITO,
    STORAGE_TICKETSOPORTEIDS_EXITO,
    STORAGE_PAYMENTINTENTS_EXITO,
    STORAGE_PAGOSHOY_EXITO,
    STORAGE_PAGOS_EXITO,
    STORAGE_ALLTICKETSOPORTE_EXITO,    
    STORAGE_ALLORDENESFOOD_EXITO,
    STORAGE_ORDENESNOATENDIDAS_EXITO,
    STORAGE_ORDENESREGRESADAS_EXITO,
    STORAGE_ORDENESCANCELADAS_EXITO,
    STORAGE_ORDENESFINALIZADAS_EXITO,
    STORAGE_APPVERSION_EXITO,
    STORAGE_COMERCIOPASSWORD_EXITO,
    STORAGE_COMENTARIOS_EXITO,
    STORAGE_SOUNDSELECTED_EXITO,
    STORAGE_NUEVOSCOMENTARIOS_EXITO,
    SET_USUARIOLOGIN_EXITO,
    STORAGE_CALENDARIO_EXITO,
    STORAGE_LUGARESRESERVADOS_EXITO,
    STORAGE_CONFIG_EXITO
} from '../actionTypes'

import { getFecha, newDateZone, newDateZoneSeconds } from '../../allFunctions'

const compareCreditosVigencia = ( a, b ) => {
    if ( a.fechaVigencia.seconds < b.fechaVigencia.seconds ){
      return -1;
    }
    if ( a.fechaVigencia.seconds > b.fechaVigencia.seconds ){
      return 1;
    }
    return 0;
}

export const StorageCalendario = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {


        const FScalendario = JSON.parse(JSON.stringify(datos))
        const now = newDateZone()

        if(FScalendario && FScalendario?.length !== 0){

            FScalendario.map(fechaCalendario => {

                const fechaCal = newDateZoneSeconds(fechaCalendario.fecha?.seconds)
                if(fechaCal < now){
                    fechaCalendario?.horarios.map(horario => {
                        const fechaHorario = newDateZoneSeconds(horario?.id)
    
                        if(fechaHorario < now){
                            horario.disponible = false
                        }
    
                    })
                }
            })

            dispatch({ type: STORAGE_CALENDARIO_EXITO, payload: FScalendario })
        }else{
            dispatch({ type: STORAGE_CALENDARIO_EXITO, payload: null })
        }
    }
}

export const StorageUsuarios = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        if(datos && datos?.length !== 0){
            dispatch({ type: STORAGE_USUARIOS_EXITO, payload: datos })
        }else{
            dispatch({ type: STORAGE_USUARIOS_EXITO, payload: null })
        }
    }
}

export const StorageClienteCreditos = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const {FSclienteCreditos, FSlistaEspera } = JSON.parse(JSON.stringify(datos))

        if(FSclienteCreditos && FSclienteCreditos?.length !== 0){

            FSclienteCreditos.sort(compareCreditosVigencia)

            FSclienteCreditos.map(clienteCreditos => {

                const listaEspera = FSlistaEspera.filter(espera => espera?.idClienteCredito === clienteCreditos?.id)

                if(listaEspera && listaEspera?.length !== 0){
                    clienteCreditos.ListaEspera = listaEspera?.length
                    clienteCreditos.creditos = parseInt(clienteCreditos.creditos) - parseInt(listaEspera?.length)
                }

            })
            const dataCreditos = FSclienteCreditos.filter(dato => parseInt(dato.creditos) > 0 || parseInt(dato?.ListaEspera) > 0)

            let creditos = 0
            dataCreditos.map(datCred => {
                creditos = creditos + parseInt(datCred?.creditos)
            })

            dispatch({ type: STORAGE_USUARIONUMCREDITOS_EXITO, payload: creditos })
            dispatch({ type: STORAGE_USUARIOSCREDITOS_EXITO, payload: dataCreditos })
        }else{
            dispatch({ type: STORAGE_USUARIOSCREDITOS_EXITO, payload: null })
        }
    }
}

export const StorageLugaresReservados = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        if(datos && datos?.length !== 0){
            dispatch({ type: STORAGE_LUGARESRESERVADOS_EXITO, payload: datos })
        }else{
            dispatch({ type: STORAGE_LUGARESRESERVADOS_EXITO, payload: null })
        }
    }
}

export const StorageConfig = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {
        if(datos){
            dispatch({ type: STORAGE_CONFIG_EXITO, payload: datos })
        }else{
            dispatch({ type: STORAGE_CONFIG_EXITO, payload: null })
        }
    }
}

export const StorageDomicilioComercio = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const domicilioComercio = {
            domicilio: `${datos.profile.numero} - ${datos.profile.calle}, ${datos.profile.colonia}`,
            lat: datos.comercio.latitud,
            lng: datos.comercio.longitud,
            cp: datos.comercio.cp
        }

        dispatch({ type: STORAGE_DOMICILIOCOMERCIO_EXITO, payload: domicilioComercio })
        dispatch({ type: STORAGE_COMERCIO_EXITO, payload: datos.comercio })

    }
}

export const StorageCategoriasMenu = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const menusID = []

        if(datos && datos.categorias){
            const categoriasMenu = JSON.parse(JSON.stringify(datos.categorias))
            const menuAdiciones = JSON.parse(JSON.stringify(datos.menuAdiciones))
            const ingredientes = JSON.parse(JSON.stringify(datos.ingredientes))

            const allIngredientesDisabledID = []
            const allIngredientesDisabled = []
            menuAdiciones && menuAdiciones.map(menuAdicion => {

                menuAdicion.ingredientes && menuAdicion.ingredientes.map(ingrediente => {
                    
                    if(ingrediente.disabled && !allIngredientesDisabledID.includes(ingrediente.id)){
                        allIngredientesDisabledID.push(ingrediente.id)
                    }
                })
            })

            ingredientes && ingredientes.map(ingrediente => {
                if(allIngredientesDisabledID.includes(ingrediente.id)){
                    ingrediente.disabled = true
                    allIngredientesDisabled.push({
                        id: ingrediente.id,
                        nombre: ingrediente.nombre,
                        costo: ingrediente.costo,
                    })
                }else{
                    ingrediente.disabled = false
                }
            })
            
            dispatch({ type: STORAGE_CATEGORIASMENU_EXITO, payload: categoriasMenu })
            dispatch({ type: STORAGE_MENUADICIONES_EXITO, payload: menuAdiciones })
            dispatch({ type: STORAGE_INGREDIENTES_EXITO, payload: ingredientes })
            dispatch({ type: STORAGE_INGREDIENTESDISABLED_EXITO, payload: allIngredientesDisabled })
            dispatch({ type: STORAGE_MENUSID_EXITO, payload: menusID })
            dispatch({ type: STORAGE_MENUBLOQUEADO_EXITO, payload: datos.menuBloqueado })
            
        }else{
            dispatch({ type: STORAGE_CATEGORIASMENU_EXITO, payload: null })
            dispatch({ type: STORAGE_MENUADICIONES_EXITO, payload: null })

            dispatch({ type: STORAGE_MENUSID_EXITO, payload: null })
            dispatch({ type: STORAGE_MENUBLOQUEADO_EXITO, payload: false })
        }


        // await fetch(`${process.env.REACT_APP_API_URL}/comercios/${datos}`, {
        //     method: 'GET', // or 'PUT'
        //     headers:{
        //         'Content-Type': 'application/json'
        //     },
        //     credentials: "include",
        // }).then(res => res.json())
        // .catch(error => {
        //     console.log('error',error)
            
        // })
        // .then(response => {

        //     const data = response
        //     if(data.success){
        //         dispatch({ type: SET_NOMBRECOMERCIO_EXITO, payload: {nombreComercio: data.data.nombre_comercio, idComercio: datos} })
        //     }else{
        //         dispatch({ type: SET_NOMBRECOMERCIO_EXITO, payload: {nombreComercio: 'Ikapital', idComercio: 'ikapital1010'} })
        //     }

        // })
        
        // dispatch({ type: STORAGE_CATEGORIASMENU_EXITO, payload: datos.categoriasMenu })
        // dispatch({ type: STORAGE_MENUADICIONES_EXITO, payload: menuAdiciones })

        // dispatch({ type: STORAGE_MENUSID_EXITO, payload: menusID })
        // dispatch({ type: STORAGE_MENUBLOQUEADO_EXITO, payload: datos.menuBloqueado })

    }
}

export const StorageComercioNotificaciones = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        if(datos){

            const comentarios = datos.comentarios.filter(comentario => comentario.enterado !== true)

            dispatch({ type: STORAGE_COMENTARIOS_EXITO, payload: datos.comentarios })
            dispatch({ type: STORAGE_NUEVOSCOMENTARIOS_EXITO, payload: comentarios })
        }else{
            dispatch({ type: STORAGE_COMENTARIOS_EXITO, payload: [] })
            dispatch({ type: STORAGE_NUEVOSCOMENTARIOS_EXITO, payload: [] })
        }

    }
}

export const StorageComercioData = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {


        if(datos && datos.hasOwnProperty('ordenesFinalizadas')){
            
            dispatch({ type: STORAGE_ORDENESFINALIZADAS_EXITO, payload: datos.ordenesFinalizadas })
            dispatch({ type: STORAGE_SOUNDSELECTED_EXITO, payload: datos.soundSelected })
        }else{
            dispatch({ type: STORAGE_ORDENESFINALIZADAS_EXITO, payload: null })
        }


    //     let login = false
    //     let nombreUsuario = ''
    //     datos.usuarios.map(usuario => {
    //         if(usuario.login){
    //             login = true
    //             nombreUsuario = usuario.usuario
    //         }
    //     })
    //     if(login){
    //         dispatch({ type: SET_USUARIOLOGIN_EXITO, payload: {login: true, usuario: nombreUsuario} })
    //     }else{
    //         dispatch({ type: SET_USUARIOLOGIN_EXITO, payload: {login: false, usuario: ''} })
    //     }

    //     dispatch({ type: STORAGE_USUARIOS_EXITO, payload: datos.usuarios })
    //     dispatch({ type: STORAGE_ALLTICKETSOPORTE_EXITO, payload: datos.ticketSoporte })
    //     dispatch({ type: STORAGE_ALLORDENESFOOD_EXITO, payload: datos.ordenesFood })
    //     dispatch({ type: STORAGE_COMERCIOPASSWORD_EXITO, payload: datos.comercioPassword })
    //     dispatch({ type: STORAGE_SOUNDSELECTED_EXITO, payload: datos.soundSelected })

    //     const ordenesNoAtendidas = []
    //     const ordenesRegresadas = []
    //     const ordenesCanceladas = []

    //     datos.ordenesFood.map(orden => {

    //         if(orden.status === 500){
    //             ordenesNoAtendidas.push(orden)
    //         }

    //         if(orden.status === 510){
    //             ordenesRegresadas.push(orden)
    //         }

    //         if(orden.status === 530){
    //             ordenesCanceladas.push(orden)
    //         }

    //     })

    //     dispatch({ type: STORAGE_ORDENESNOATENDIDAS_EXITO, payload: ordenesNoAtendidas })
    //     dispatch({ type: STORAGE_ORDENESREGRESADAS_EXITO, payload: ordenesRegresadas })
    //     dispatch({ type: STORAGE_ORDENESCANCELADAS_EXITO, payload: ordenesCanceladas })

    }
}


const compare = ( a, b ) => {
    if ( a.createdAt.seconds < b.createdAt.seconds ){
      return 1;
    }
    if ( a.createdAt.seconds > b.createdAt.seconds ){
      return -1;
    }
    return 0;
}

export const SetOrdenesFood = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        const menusID = getState().storageDataReducer.menusID

        const data = JSON.parse(JSON.stringify(datos))

        if(data && data.length !== 0){            

            dispatch({ type: STORAGE_ORDENESFOOD_EXITO, payload: data.sort(compare) })
        }else{
            dispatch({ type: STORAGE_ORDENESFOOD_EXITO, payload: [] })
        }

        // datos.map(orden => {
        //     let problemaID = false
            
        //     orden.productos.map(menu => {

        //         const result = menusID.filter(menuID => menuID.id_menu === menu.id_menu)

        //        if(result.length === 0){
        //            problemaID = true
        //        }
        //     })

        //     if(problemaID){

        //     }else{

        //         orden.productos.map(menu => {

        //             menusID.map(menuID => {

        //                 if(menu.id_menu === menuID.id_menu){
        //                     menu.nombreMenu = menuID.nombreMenu
        //                     menu.precioMenu = menuID.precioMenu
        //                 }
        //             })
                    
        //         })
        //         ordenesFood.push(orden)
        //     }
        // })    

        // ordenesFood.sort(compare)


        // dispatch({ type: STORAGE_ORDENESFOOD_EXITO, payload: ordenesFood })

    }
}

export const StorageRepartidores = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({ type: STORAGE_REPARTIDORES_EXITO, payload: datos })

    }
}

export const StorageConfigComercio = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({ type: STORAGE_CATSOPORTE_EXITO, payload: datos.catSoporte })
        dispatch({ type: STORAGE_CATMENURESPUESTAS_EXITO, payload: datos.catMenuRespuestas })
        dispatch({ type: STORAGE_APPVERSION_EXITO, payload: datos.appVersion })
        dispatch({ type: STORAGE_COMISIONFOOD_EXITO, payload: datos.comisionFood })

    }
}

export const StorageOrdenPedidosFood = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        if(datos && datos.length !== 0){


            // const fechas = []
            // datos.map(payment => {

            //     if(!fechas.includes(getFecha(payment.createdAt.seconds, 1))){
                    
            //         fechas.push(getFecha(payment.createdAt.seconds, 1))
            //     }
            // })

            // const allPagos = []
            // const paymentsHoy = []
            // fechas.map(fecha => {

            //     const hoy = Math.floor(new Date().getTime()/1000.0)
            //     const pago = []
            //     let monto = 0
            //     let cobro = 0
            //     let neto = 0
            //     let fechaPago = ''
            //     datos.map(payment => {

            //         const date = new Date( payment.createdAt.seconds * 1000);
            //         date.setHours(0, 0, 0, 0) // +5 hours for Eastern Time

            //         if(fecha === getFecha(payment.createdAt.seconds, 1)){
                        
            //             monto = parseInt(monto) + parseInt(payment.pagoDetalles.monto)
            //             cobro = parseInt(cobro) + parseInt(payment.pagoDetalles.cobro)
            //             neto = parseInt(neto) + parseInt(payment.pagoDetalles.neto)
            //             fechaPago = payment.fechaPago
            //             pago.push({
            //                 id: payment.id_orden,
            //                 fecha: fecha,
            //                 monto: payment.pagoDetalles.monto,
            //                 cobro: payment.pagoDetalles.cobro,
            //                 neto: payment.pagoDetalles.neto
            //             })                        
            //         }

            //         if(getFecha(hoy, 1) === getFecha(payment.createdAt.seconds, 1) && getFecha(hoy, 1) === fecha){

            //             paymentsHoy.push(payment)
            //         }

            //     })

            //     allPagos.push({
            //         fecha: fecha,
            //         fechaPago: fechaPago,
            //         pagos: pago,
            //         monto: monto,
            //         cobro: cobro,
            //         neto: neto
            //     })

            // })

            // dispatch({ type: STORAGE_PAYMENTINTENTS_EXITO, payload: datos })
            dispatch({ type: STORAGE_ORDENESFOOD_EXITO, payload: datos.sort(compare) })

            // dispatch({ type: STORAGE_PAGOS_EXITO, payload: allPagos })
            // dispatch({ type: STORAGE_PAGOSHOY_EXITO, payload: paymentsHoy })
        } else {
            dispatch({ type: STORAGE_ORDENESFOOD_EXITO, payload: [] })

            // dispatch({ type: STORAGE_PAYMENTINTENTS_EXITO, payload: null })
            // dispatch({ type: STORAGE_PAGOS_EXITO, payload: null })

        }

    }
}

export const SetNumPaymentsNuevos = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({ type: SET_NUMPAYMENTSNUEVOS_EXITO, payload: datos })

    }
}

export const StorageOrdenesFinalizadas = (datos) => {
    return async (dispatch, getState, {getFirebase, getFirestore}) => {

        // dispatch({ type: STORAGE_ORDENESFINALIZADAS_EXITO, payload: datos })

    }
}