import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

//*COMPONENTES
import { logout } from '../../../store/actions/authActions'

//*MATERIAL UI
import Button from '@material-ui/core/Button'

class NavbarOut extends PureComponent {
    render() {
        const { auth } = this.props
        let btnLogout 
        if(auth.uid){
            
            btnLogout = <Link to="/" className="linki" onClick={this.props.logout}><Button color="inherit" >Salir</Button></Link>           
        }
        return (
            <Fragment>
                {btnLogout}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    
    return{
       auth: state.firebase.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        logout: () => dispatch(logout())
    }
}

export default  connect(mapStateToProps, mapDispatchToProps)(NavbarOut)

NavbarOut.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired    
}