import { 
    MOSTRAR_PERFIL_EXITO,         
    MOSTRAR_ORDENES_EXITO,
    MOSTRAR_MENU_EXITO,
    MOSTRAR_ENVIOS_EXITO,
    RESET_NAVBARSELECT_EXITO } from '../actionTypes'

const initState = {
    navBarValue: 'perfil',
    mostrarPerfil: true,
    mostrarMenu: false,
    mostrarOrdenes: false,
    mostrarEnvios: false
}

const navBarReducer = (state = initState, action) => {
    switch (action.type) {
        case MOSTRAR_MENU_EXITO:
            return {
                ...state,
                navBarValue: action.payload,
                mostrarPerfil: false,
                mostrarMenu: true,
                mostrarOrdenes: false,
                mostrarEnvios: false  
            }           
        case MOSTRAR_ORDENES_EXITO:
            return {
                ...state,
                navBarValue: action.payload,
                mostrarPerfil: false,
                mostrarMenu: false,
                mostrarOrdenes: true,
                mostrarEnvios: false  
            }         
        case MOSTRAR_PERFIL_EXITO:
            return {
                ...state,
                navBarValue: action.payload,
                mostrarPerfil: true,
                mostrarMenu: false,
                mostrarOrdenes: false,
                mostrarEnvios: false 
            }    
        case MOSTRAR_ENVIOS_EXITO:
            return {
                ...state,
                navBarValue: action.payload,
                mostrarPerfil: false,
                mostrarMenu: false,
                mostrarOrdenes: false,
                mostrarEnvios: true 
            }    
                 
        case RESET_NAVBARSELECT_EXITO:
            return {
                navBarValue: '',
                mostrarPerfil: true,
                mostrarOrdenes: false,
                mostrarMenu: false 
            }         
        default:
            return state
    }   
}



export default navBarReducer