
//*MATERIAL UI
import { Box, Typography, Divider } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import styled from 'styled-components'

const StyledCardTitle = styled(({ ...props }) => <Box {...props} />)`
${({ theme }) => `

.cardtitle-container {
  display: flex;
  justify-content: center;
  color: ${grey[700]};
  padding: 10px;
}

.card-title {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
}

.color-grey500 {
  background-color: ${grey[300]};
  margin-left: 10px;
  margin-right: 10px;
  height: 2px;
}

`}
`;

export const CardTitle = (props) => {

  const { title } = props

  return (
    <StyledCardTitle>
        <Box className='cardtitle-container'>
            <Typography className='card-title'>
                {title}
            </Typography>
        </Box>
        <Divider className='color-grey500' />
    </StyledCardTitle>
  )
}