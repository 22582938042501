import { Container, Box, Card, Typography, TextField, Button } from '@material-ui/core'
import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

//*COMPONENTES


//*ELEMENTOS
import { CardTitle } from '../../../elementos/card-title/CardTitle'
//*MATERIAL UI
import { grey } from '@material-ui/core/colors'

const StyledRefisterForm = styled(({ ...props }) => <Box {...props} />)`
  ${({ theme }) => `

  .form-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .textfield-form {
    width: calc((100% - 40px) / 2)
    // margin-left: 10px;
  }

  .registrar-button {
    margin-top: 10px;
  }

`}
`;

const RegisterForm = (props) => {

    const [nombre, setNombre] = useState('')
    const [apellidos, setApellidos] = useState('')
    const [fechaNacimiento, setFechaNacimiento] = useState('')
    const [correo, setCorreo] = useState('')
    const [telefono, setTelefono] = useState('')
    const [telefonoEmergencia, setTelefonoEmergencia] = useState('')


    const handleChange = (e) => {

        const valor = e.target.value
        const name = e.target.name

        if(name === 'nombre'){
            setNombre(valor)
        }
        if(name === 'apellidos'){
            setApellidos(valor)
        }
        if(name === 'fechaNacimiento'){
            setFechaNacimiento(valor.trim())
        }
        if(name === 'correo'){
            setCorreo(valor.trim())
        }
        if(name === 'telefono'){
            setTelefono(valor.trim())
        }
        if(name === 'telefonoEmergencia'){
            setTelefonoEmergencia(valor.trim())
        }
        
    }
    
    const handleSubmit = (e) => {
        e.preventDefault()

        console.log('nombre', nombre)
        
    }
   
    // console.log(props.cat_tipoCocina);

    // const catTipoCocina = props.cat_tipoCocina[0].tipoCocina
    return (
        <Fragment>
            <Container>
                <Box border={1}>
                    <Card>
                        <CardTitle title={'REGISTRAR USUARIO'} />
                        <StyledRefisterForm>
                            <form onSubmit={handleSubmit} >

                            <Box className='form-container'>
                                <TextField    
                                color="secondary"                                   
                                id="nombre"
                                name="nombre" 
                                label="Nombre"
                                value={nombre}
                                type='text'                             
                                onChange={handleChange}
                                autoComplete="off" 
                                className='textfield-form'
                                // fullWidth 
                                error
                                helperText="Incorrect entry."
                                />
                                <TextField    
                                color="secondary"                                   
                                id="apellidos"
                                name="apellidos" 
                                label="Apellidos"
                                value={apellidos}
                                type='text'                             
                                onChange={handleChange}
                                autoComplete="off" 
                                className='textfield-form'
                                // fullWidth 
                                />
                                <TextField    
                                color="secondary"                                   
                                id="fechaNacimiento"
                                name="fechaNacimiento" 
                                label="Fecha de nacimiento"
                                value={fechaNacimiento}
                                type='text'                             
                                onChange={handleChange}
                                autoComplete="off" 
                                className='textfield-form'
                                // fullWidth 
                                />
                                <TextField    
                                color="secondary"                                   
                                id="correo"
                                name="correo" 
                                label="Correo electrónico"
                                value={correo}
                                type='text'                             
                                onChange={handleChange}
                                autoComplete="off" 
                                className='textfield-form'
                                // fullWidth 
                                />
                                <TextField    
                                color="secondary"                                   
                                id="telefono"
                                name="telefono" 
                                label="Teléfono"
                                value={telefono}
                                type='text'                             
                                onChange={handleChange}
                                autoComplete="off" 
                                className='textfield-form'
                                // fullWidth 
                                />
                                <TextField    
                                color="secondary"                                   
                                id="telefonoEmergencia"
                                name="telefonoEmergencia" 
                                label="Teléfono de emergencia"
                                value={telefonoEmergencia}
                                type='text'                             
                                onChange={handleChange}
                                autoComplete="off" 
                                className='textfield-form'
                                // fullWidth 
                                />
                            </Box>

                            <Button variant='contained' color='primary' fullWidth className='registrar-button' onClick={handleSubmit} > 
                                Registrar
                            </Button>

                        
                            </form>
                        </StyledRefisterForm>
                    </Card>
                </Box>
            </Container>
            
        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
        // FScat_tipoCocina: state.firestore.ordered.FScat_tipoCocina,

    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        //  EditarCuentaComercio: (datos) => dispatch(EditarCuentaComercio(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
