import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//*COMPONENTES
import { getFecha } from '../../../../allFunctions'
import ConfirmarMenusOrden from './ConfirmarMenusOrden'

//*MATERIAL UI
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Dialog from '@material-ui/core/Dialog'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'


import { withStyles } from '@material-ui/styles'

const styles = theme => ({
    message: {
        backgroundColor: '#fff9c4',
        color: '#263238',
    },
    width: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 720,
        } 
    },
    cancelado: {
        backgroundColor: '#e57373',
        color: '#fff'
    },
    styleContenedor: {
        [theme.breakpoints.up('sm')]: {
            margin: 'auto',
            minWidth: 720,
        } 
    },
    fixedUp: {
        position: 'fixed',
        top: 0,
        // bottom: 0,
        width: '100%',
        backgroundColor: '#263238',
        color: '#ffffff',
        zIndex: 1,
        maxWidth: 720,
        cursor: 'pointer',
    },
    list: {
        backgroundColor: '#fff',
    },
    comision: {
        color: '#e57373',
    }
})

const OrdenFood = (props) => {

    const [openOrdenDetalle, setOpenOrdenDetalle] = useState(false)

    useEffect(() => {

    }, [])

    //*render

    if(!props.FSordenFood) return null
    const { classes, FSordenFood, total } = props

    const totalVenta = parseFloat(FSordenFood.comisionVentaComercio) - parseFloat(total)

    return (
        <Fragment>
            <ListItem button className={classes.list} onClick={() => setOpenOrdenDetalle(true)}>
                <ListItemIcon>
                    <RestaurantMenuIcon />
                </ListItemIcon>
                <ListItemText 
                    primary={<Typography variant='h6'>ID: {FSordenFood.id_orden.toUpperCase()}</Typography>} 
                    secondary={<Typography variant='h6'>{getFecha(FSordenFood.tiempoProceso.envio.seconds, 2)}</Typography>} 
                />
                <ListItemSecondaryAction>
                    <ListItemText 
                        primary={<Typography variant='h6' align='right' >Total: $ {parseFloat(total).toFixed(2)}</Typography>} 
                        secondary={<Typography variant='h6' align='right'>Comisión: $ - {parseFloat(FSordenFood.comisionVentaComercio).toFixed(2)}</Typography>} 
                    />
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />

            <Dialog 
                open={openOrdenDetalle} 
                fullScreen 
                onClose={() => setOpenOrdenDetalle(false)}
                PaperProps={{
                    style: {
                      backgroundColor: '#eceff1',
                      boxShadow: "none"
                    }
                  }}
                >
                <Grid item className={classes.styleContenedor} xs={12} >

                <Box p={1} display="flex" className={classes.fixedUp} onClick={() => setOpenOrdenDetalle(false)}>
                    <Box mx={1}>
                        <ArrowBackIcon />
                    </Box>
                    <Box flexGrow={1} align={'center'} >
                        <Typography variant="h6" justify="center" >
                            Detalle de la Orden
                        </Typography>
                    </Box>
                    <Box mx={2}></Box>
                </Box>

                    <Box mt={7}>
                        <Typography variant='h6' align='center'>ID: {FSordenFood.id_orden.toUpperCase()}</Typography>
                        <Box>
                            <Typography variant='h6' align='left'>En proceso: {getFecha(FSordenFood.tiempoProceso.elaboracion.seconds, 4)}</Typography>
                            <Typography variant='h6' align='left'>Enviado: {getFecha(FSordenFood.tiempoProceso.envio.seconds, 4)}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='h6' align='right'>Total producto: $ {parseFloat(total).toFixed(2)}</Typography>
                            <Typography variant='h6' align='right'>Comisión venta: $ {parseFloat(FSordenFood.comisionVentaComercio).toFixed(2)}</Typography>
                            <Typography variant='h6' align='right'>Total: $ {parseFloat(totalVenta).toFixed(2)}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='h6' align='left'>El cliente pago: {FSordenFood.metodoPago.metodo}</Typography>
                        </Box>

                        <Box>
                            {FSordenFood.productos.map(producto => {
                                return(
                                    <ConfirmarMenusOrden
                                     key={producto.id_producto} 
                                     menu={producto}
                                     />
                                )
                            })}
                        </Box>


                    </Box>

                </Grid>
            </Dialog>

        </Fragment>
    )

}

const mapStateToProps = (state) => {  
    return{
        //stateName: state.firestore.ordered.stateName,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
         //dispatchName: (datos) => dispatch(dispatchName(datos)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrdenFood))

OrdenFood.propTypes = {
    
}