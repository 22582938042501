import { useState } from "react";
import { connect } from "react-redux";
import { Button, Card, Drawer, Typography } from "@material-ui/core"
import { Box, FormControl, InputAdornment, InputLabel, Select, TextField } from "@material-ui/core";

import styled from 'styled-components'

import { ResetPasswordCliente } from '../../store/actions/ClientesAction'
import { SendMenssageAction } from "../../store/actions/messageActions";
import { DrawerTitle } from "../../elementos/drawer-title";
import Swal from "sweetalert2";
import { logout } from "../../store/actions/authActions";



const ContainerPasswordCliente = styled(({...props}) => <Box {...props} />)`    
${({ theme }) => `

  width: 350px;
  position: relative;
  height: 100%;
  padding: 10px;
  margin-top: 10px;

  .input-form-user {
    margin-bottom: 20px;
  }

  .cliente-nombre {
    text-align: center;
    margin-bottom: 10px;
    font-size: 18px;

  }

  .button-password-container {
    position: absolute;
    bottom: 0;
    width: calc(100% - 20px);
  }

  .action-password-button {
    height: 80px;
    margin-bottom: 20px;
  }

`}
`


const ClientePassword = (props) => {

  const { openDrawer, selectedCliente, profile, auth, logout } = props

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const handleCloseDrawer = () => {
    setPassword('')
    setConfirmPassword('')
    props.handleCloseDrawer()
  }

  const handleChange = (e) => {
    const name = e.target.name
    const valor = e.target.value

    if(name === 'password'){
      setPassword(valor.trim().toLowerCase())
    }
    if(name === 'confirmPassword'){
      setConfirmPassword(valor)
    }

  }

  const handleChangePassword = async () => {

    props.SendMenssageAction({option: 'inicio'})

    console.log('selectedCliente?.id', selectedCliente?.id)
    const reset = await props.ResetPasswordCliente({
      id: profile?.roll === 'cliente' ? auth?.uid : selectedCliente?.id,
      password:  profile?.roll === 'cliente' ? password.trim() : 'zensepilates'
    })

    if(reset?.exito && reset?.message !== 'success'){
      Swal.fire({
        icon: 'error',
        title: `<strong>${reset?.message}</strong>`,
        confirmButtonColor: '#3085d6',
      })
    }

    if(reset?.exito && reset?.message === 'success'){
      handleCloseDrawer()
      if(profile?.roll === 'cliente'){
        setTimeout(() => {
          logout()
        }, 300);
      }
      Swal.fire({
        icon: 'success',
        title: '<strong>Cambio exitoso</strong>',
        showConfirmButton: false,
        timer: 2000
      })
    }


    props.SendMenssageAction(reset)

  }

  let disabled = false

  if(profile?.roll === 'cliente' && password?.length < 8){
    disabled = true
  }


  return (
    <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >
        <DrawerTitle title={'Cambiar contraseña'} handleCloseDrawer={handleCloseDrawer} />
        <ContainerPasswordCliente>

          {profile?.roll === 'cliente' ?
            <TextField
              label="Contraseña"
              name="password"
              value={password}
              type="text"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              className="input-form-user"
            />
          :
          null
          }

         
          {/* <TextField
            label="Confirmar contraseña"
            name="confirmPassword"
            value={confirmPassword}
            type="text"
            variant="outlined"
            fullWidth
            onChange={handleChange}
            className="input-form-user"
          /> */}

          <Box className='button-password-container'>

          {profile?.roll === 'admin' ? 
          <Typography className="cliente-nombre">
              {`${selectedCliente?.nombre} ${selectedCliente?.apellidos}`}
            </Typography>
          :
          null
          }
          <Typography className="cliente-nombre">
              {selectedCliente?.usuario}
            </Typography>

            <Button fullWidth className='action-password-button' variant='contained' 
            disabled={disabled}
            onClick={profile?.roll === 'admin' ? handleChangePassword : password?.length >= 8 ? handleChangePassword : null} >
                Cambiar contraseña
            </Button>
          </Box>

        </ContainerPasswordCliente>
      </Drawer>
  )
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth, 
  profile: state.firebase.profile, 
  FSusuarios: state.storageDataReducer.FSusuarios,
});

const mapDispatchToProps = (dispatch) => ({
  ResetPasswordCliente: (datos) => dispatch(ResetPasswordCliente(datos)),
  SendMenssageAction: (datos) => dispatch(SendMenssageAction(datos)),
  logout: (datos) => dispatch(logout(datos)),

  // CancelActivarIngredientes: (datos) => dispatch(CancelActivarIngredientes(datos)),
});

export default connect( mapStateToProps, mapDispatchToProps )(ClientePassword);