import React, { useState, useEffect, forwardRef, useRef, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { UpdateCalendarioFecha } from '../../store/actions/ClientesAction'
import { logout } from '../../store/actions/authActions'
//*COMPONENTES
import ConfigStatus from './ConfigStatus'
import ProfileConfig from './ProfileConfig'
import Funcionalidades from './configuraciones/installFuncionalidades/Funcionalidades'
import { newDateZone, telFormat } from '../../allFunctions'
//*MATERIAL
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import Card from '@material-ui/core/Card'
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import { red, teal } from "@material-ui/core/colors"
import CardActions from "@material-ui/core/CardActions"
import SettingsIcon from '@material-ui/icons/Settings'
import Button from '@material-ui/core/Button'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import ReceiptIcon from '@material-ui/icons/Receipt'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import ListAltIcon from '@material-ui/icons/ListAlt'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import { Container } from '@material-ui/core'
import styled from 'styled-components'
import { getCalendario } from '../../allFunctions'

import CalendarDays from './CalendarDays'
const ContainerCalendario = styled(({ ...props }) => <Container {...props} />)`
  ${({ theme }) => `

  
  margin-top: 80px;



`}
`;

const theme = createMuiTheme({
    palette: {     
      primary: {       
        main: '#00797e',       
        contrastText: '#fff',
      },
      secondary: {       
        main: '#e57373',       
        contrastText: '#fff',
      },
      fixedDown: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        color: '#ffffff'
    },
}})

const styles = theme => ({
    profile: {
        display: 'flex',
        margin: 'auto'
    },
    styleContenedor: {
        margin: 'auto',
        maxWidth: 720,
        marginTop: 80,
        [theme.breakpoints.down('xs')]: {
            marginTop: 60,
        }      
    },
    config: {
        backgroundColor: theme.palette.background.paper,
    },
    avatar: {
        backgroundColor: red[500]
    },
    porrtada: {
        height: '204px', //? 360*202 or 720*405 -- 1920*1080 
        // paddingTop: "36.25%" // 16:9
    },   
    portadaPC: {
        height: '408px', //? 360*202 or 720*405 -- 1920*1080 
        // paddingTop: "36.25%" // 16:9
    },   
    iconConfig: {
        marginLeft: 'auto',
    },
    appInstall: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        width: '100%',
        color: '#ffffff'
    },
    login: {
        color: teal[500],
    },
})

const defaultProps = {
    bgcolor: 'background.paper',
    bordercolor: 'text.primary',
    m: 1,
    border: 1,
    style: { width: '5rem', height: '5rem', borderRadius: '50%' },
}

const ProfileForm = (props) => {

    const { auth,  profile, classes, navBarReducer } = props
    const [comercio, setComercio] = useState(null)
    const [openConfiguracion, setOpenConfiguracion] = useState(false)

    useEffect(() => {

        // const horarios = getCalendario()
        // props.logout()
        // console.log('horarios', horarios.horarios)
        // props.UpdateCalendarioFecha(horarios.horarios)


     


        // const geocoder = new window["google"].maps.Geocoder();

        // geocoder.geocode({
        //     componentRestrictions: {
        //       country: 'MX',
        //       postalCode: '82100'
        //     }
        //   }, function(results, status) {
        //     console.log('resultGeocode', results)
        //     console.log('status', status)
        //     if (status == 'OK') {
        //     //   map.setCenter(results[0].geometry.location);
        //     //   var marker = new google.maps.Marker({
        //     //     map: map,
        //     //     position: results[0].geometry.location
        //     //   });
        //     } else {
        //       window.alert('Geocode was not successful for the following reason: ' + status);
        //     }
        //   });

    }, [])

    
    //*render      
    const show = navBarReducer.mostrarPerfil
    const skeletonCargandoImagen = <Box>

        <Skeleton height={50} width="100%" style={{ marginTop: 30 }} className={classes.profile} />
        <Skeleton variant="circle" width={80} height={80} style={{ marginTop: 10 }} className={classes.profile} animation="wave" />
        <Skeleton height={30} width="50%" style={{ marginTop: 5 }} className={classes.profile} />
        {/* <Skeleton height={20} width="80%" style={{ marginTop: 20 }} className={classes.profile} animation="wave" /> */}

    </Box>     

    function getDaysInMonth(month, year) {
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
            const fecha = new Date(date)
            days.push(fecha);
            date.setDate(date.getDate() + 1);

        }
        return days;
    }

    // const allDays = getDaysInMonth(0, 2023)

    // console.log('allDays', allDays.length)

    // allDays.map(day => {
    //     console.log(day.getDate())
    //     console.log(day.getMonth())
    //     console.log(day.getFullYear())
    // })
    if(!profile) return null
    return(
        <ContainerCalendario maxWidth='md'>
            <CalendarDays />
            {/* {profile?.roll === 'cliente' ?
            <ProfileCliente profile={profile} />
            :
            } */}
            {/* <ContainerCalendario border={1} mt={10}>

                {allDays.map(day => {

                    return (
                        <Box className="styled-chip-day">

                        </Box>
                    )
                })}

            </ContainerCalendario> */}
        </ContainerCalendario>
    )

    if(!comercio){
        
        return skeletonCargandoImagen
    } 


    return (
        <Fade in={show} >

            <Box>
                <Grid item className={classes.styleContenedor} >
                    <Grid item className={classes.styleBackGround} xs={12}  >
                        
                    <Box >
                        <Fragment>                            
                            <Box>
                                <Card className={classes.root} variant="outlined" elevation={0}>
                                <CardHeader
                                    avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        {comercio.iniciales}
                                    </Avatar>
                                    }
                                    action={
                                    <IconButton onClick={() => setOpenConfiguracion(true)} >
                                        <SettingsIcon />
                                    </IconButton>
                                    }
                                    title={
                                        <Typography gutterBottom variant="h6" >
                                            {comercio.nombre}
                                        </Typography>
                                    }
                                    subheader={
                                        <Typography gutterBottom variant="body1" >
                                            {comercio.tipoNegocio}
                                        </Typography>
                                    }
                                />
                                <CardMedia
                                    className={props.dispositivo === 'pc' ? classes.portadaPC : classes.porrtada}
                                    image={comercio?.portadaImage?.foto !== '' ? comercio.portadaImage.foto : `${process.env.PUBLIC_URL}/assets/img/no-image.png`}
                                    title=""
                                />
                                
                                <CardActions disableSpacing>
                                    {/* <Box className={classes.iconConfig}>
                                        <IconButton  onClick={() => setOpenMonetizacion(true)}>
                                            <ListAltIcon />
                                        </IconButton>
                                        <IconButton className={classes.iconConfig} onClick={() => setOpenMonetizacion(true)}>
                                            <MonetizationOnIcon />
                                        </IconButton>
                                    </Box> */}
                                    
                                    
                                </CardActions>
                                </Card>
                            </Box>

                            <Box my={1}>
                                <Card variant='outlined' onClick={null}>
                                    <Box>
                                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 20, textAlign: 'center'}}>
                                        {comercio.nombreComercio}
                                        </Typography>
                                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 20, textAlign: 'center', color: comercio.abierto ? '#2e7d32' : '#c62828'}}>
                                        {comercio.abierto ? 'ABIERTO' : 'CERRADO' }
                                        </Typography>
                                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16, textAlign: 'center'}}>
                                        {comercio.calle} {comercio.numero} <br /> {comercio.colonia}
                                        </Typography>
                                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16, textAlign: 'center'}}>
                                        {comercio.ciudad}, Sinaloa
                                        </Typography>
                                        <Typography style={{fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 16, color: '#2196f3', textAlign: 'center', cursor: 'pointer'}} >
                                        Tel: {telFormat(profile.telefonoComercio)}
                                        </Typography>
                                    </Box>
                                </Card>
                            </Box>
    
                            {/* <Typography variant="body1" color="textSecondary" align={'center'} >
                                    Tel: {profile.telefonoComercio}
                            </Typography> */}
    
                            {/* <Box >
                                <ConfigStatus />
                            </Box> */}
    
                            {/* <Box className={classes.config} mt={1}>
                                <ProfileConfig 
                                    setOpenConfiguracion={setOpenConfiguracion}
                                    openConfiguracion={openConfiguracion}
                                />
                            </Box> */}
    
                            {/* <Funcionalidades /> */}
                        
                        </Fragment>                      
                    </Box>            
                    
                    </Grid>
                </Grid>
    
            </Box>
        </Fade> 
                        
    )
}

const mapStateToProps = (state) => {
    return{
        auth: state.firebase.auth, 
        profile: state.firebase.profile, 
        navBarReducer: state.navBarReducer,
        storageDataReducer: state.storageDataReducer,
        dispositivo: state.configReducer.dispositivo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return{
        UpdateCalendarioFecha: (datos) => dispatch(UpdateCalendarioFecha(datos)),
        logout: (datos) => dispatch(logout(datos)),
       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileForm))

ProfileForm.propTypes = {   
    classes: PropTypes.object.isRequired,
}